import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as services from "../../services/services.json";
import ClearIcon from '@material-ui/icons/Clear';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Tooltip,
    Typography,
    Grid,
    Box,
    TextField,
    Checkbox,
    FormControlLabel,
    Fab,
    ImageList,
    ImageListItem,
    IconButton,
    ImageListItemBar,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import { APP } from '../../redux/actions/actions';
import CloseIcon from '@material-ui/icons/Close';



const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



class DialogNodeBB extends Component {
    constructor(props) {
        super(props);
        this.setState({
            urLTopic: ""
        });

    }


    // "http://217.172.12.150:8000/sessioneNEW.php?langID=it&username=App1&mod[]=Verify_user_login&mod[]=get_topic_data&topicID="+this.props.appstate.ResourceManager.resources[index].nodebb.tid
    init = (index) => {
        console.log(this.props.appstate.ResourceManager.resources[index]);
        let elem = this.props.appstate.ResourceManager.resources[index];
        let completeUrl = "";
        if (elem.status === "APPROVED") {
            completeUrl = "https://nodebb-greenscent.net/BB/sessioneNEW.php?langID=" + this.props.appstate.App.currentLanguage + "&username=" + this.props.appstate.App.user.name + "&mod%5B%5D=Verify_user_login&mod%5B%5D=get_topic_data&noBottom=1&noTop=1&topicID=" + elem.nodebb.response.tid
            // completeUrl= "http://217.172.12.150:8000/sessioneNEW.php?langID=+"+this.props.appstate.App.currentLanguage +"&username="+this.props.appstate.App.user.name +"&mod[]=Verify_user_login&mod[]=get_topic_data&topicID="+elem.nodebb.response.tid;
        } else {
            completeUrl = "https://nodebb-greenscent.net/BB/sessioneNEW.php?langID=" + this.props.appstate.App.currentLanguage + "&username=Guest&mod%5B%5D=Verify_user_login&mod%5B%5D=get_topic_data&noBottom=1&noTop=1&topicID=" + elem.nodebb.response.tid;
            // completeUrl= "http://217.172.12.150:8000/sessioneNEW.php?langID="+this.props.appstate.App.currentLanguage +"&username=Guest&mod[]=Verify_user_login&mod[]=get_topic_data&topicID="+elem.nodebb.response.tid;
        }
        console.log("URL TOPIC")
        console.log(completeUrl)
        this.setState({
            // item: this.props.appstate.ResourceManager.resources[index],
            urLTopic: completeUrl
        });
    }

    componentDidMount() {
        this.props.onRef(this); //for the parent
    }

    //metodo per la chiusura della dialog
    onclose = () => {
        this.props.onClose();
    }

    render() {

        let jsonlang = this.props.appstate.App.language;

        return (
            <>
                {this.props.renderNodeBB === true ?
                    <>

                        <div className="iframe">
                            <Dialog
                                disableBackdropClick
                                disableEscapeKeyDown
                                TransitionComponent={Transition}
                                open={this.props.open}
                                onClose={this.onclose}
                                aria-labelledby="dialog-add"
                                fullWidth
                                maxWidth={'xl'}
                            >
                                <Fab
                                    style={{
                                        margin: 0,
                                        top: 0,
                                        right: 0,
                                        bottom: 'auto',
                                        left: 'auto',
                                        position: 'fixed'
                                    }}
                                    role="button"
                                    color="primary"
                                    size="large"
                                    aria-label="Add"
                                    onClick={this.onclose}
                                >
                                    <CloseIcon
                                    />
                                </Fab>
                                {/* <DialogTitle id="dialog-add">
                                    {"TOPIC"}
                                </DialogTitle> */}
                                <DialogContent dividers>
                                    <iframe
                                        title="nodebbtopic"
                                        src={this.state.urLTopic}
                                        // style={{ border: "0px", height: "80vh", width: "80vw", margin: "5px" }}
                                        style={{
                                            width: "100%",
                                            //height: "calc(100vh - 75px)",
                                            height: "calc(100vh)",
                                            border: "unset",
                                            //marginTop: "-38px",
                                        }}

                                        loading="lazy"
                                        allowFullScreen=""
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>


                                </DialogContent>
                                {/* <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={this.onclose}
                                        color="primary"
                                        startIcon={<ClearIcon />}>
                                        <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.instcancel}</Typography>
                                        </Tooltip>
                                    </Button>
                                </DialogActions> */}
                            </Dialog>
                        </div>

                    </>
                    : <></>}
            </>
        )
    }
}
export default connect(mapStateToProps)(DialogNodeBB);
