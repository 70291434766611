import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import {
  // postService,
  getService,
  deleteService
} from "../../services/services";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import DialogYesNo from "../Dialog/DialogYesNo"
import UserModifyDialog from "./UserModifyDialog"
import CancelIcon from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './Domains.css';

import {
  Table,
  // TableBody,
  TableCell ,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  // AppBar,
  InputBase,
  IconButton,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slide,
  Dialog,
  Typography,
  Tooltip,
  Link
} from '@material-ui/core'


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class Userspanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users:[],
      usersCopy:[],
      orderStatus:"desc",
      orderRole:"desc",
      orderMail:"desc",
      orderShortName:"desc",
      orderName:"desc",
      orderDate:"desc",
      radioValue:"name",
      deletedDialog:false,
      selectedUser:{},
      dialogModify:false,
      utente:{}
    };
  }
  componentDidMount(){
    this.getUsers();
  }

  getUsers=()=>{
    // console.log("getUsers")
    var self=this;
    let ser = process.env.REACT_APP_DOMAIN + services.user_GET.replace("{APPNAME}", this.props.appstate.App.name);
    ser = ser.replace("{ID}", this.props.appstate.App.user.userid);
    ser = ser.replace("{DOMAINID}", this.props.appstate.App.user.domain);
    getService(ser,
      "",
      function (err, result) {
        if (err) {
          console.log("errore get utenti" + err);
        } else if (result.status === 200) {
          //  console.log("utenti arrivati");
          // console.log(result.response);
          var dataArray=[];
          for (var i=0 ;i < result.response.length; i++ ){
            for (var j=0 ;j < result.response[i].users.length; j++ ){
              let elem={};
              elem=result.response[i].users[j];
              elem.shortname=result.response[i]._id;
              let date=new Date(result.response[i].users[j].createdOn)
              elem.registered=date.toLocaleDateString()
              dataArray.push(elem);
            }
          }
          // console.log(dataArray)
          dataArray.sort (function (a,b)
          {
            return a.name.localeCompare(b.name);
          }
        );
        self.setState({users:dataArray,usersCopy:dataArray,orderName:"asc"});
      }
    }
  );
}
deleteUser =(e)=>{
  var self=this;
  console.log("delete User function " + this.state.selectedUser.name);
  //chiudi YesNo dialog
  this.props.appstate.YesNoDialog.open = false;
  this.props.appstate.YesNoDialog.title = "";
  this.props.appstate.YesNoDialog.message = "" ;
  //dialog di conferma e loading
  this.props.appstate.Modal.openModalLoading = true;
  this.props.appstate.Modal.openModalLoadingTextActive = false;
  this.props.dispatch(APP(this.props.appstate));
  //fare chiamata di cancellazione
  deleteService(
    process.env.REACT_APP_DOMAIN + services.deleteusere_DELETE,
    { id:this.state.selectedUser._id  , appname: this.props.appstate.App.name , myself:this.props.appstate.App.user.userid,domainId:this.state.selectedUser.domain._id},
    "",
    function (err, result) {
      if (err) {
        console.log(err)
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.userdeleteerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      if (result.status === 204) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.userdeleteok;
        self.props.dispatch(APP(self.props.appstate));
        self.getUsers();
        return;
      }

    }
  );
}
openDialogForDelete =(e)=>{
  console.log("delete User modal");
  // this.setState({selectedUserId:e.currentTarget.value});
  // salva l'utente che vuoi cancellare e construisci la stringa personalizzata per chiedere se l'utente è sicuro
  let items= this.state.users.slice(); //copy array
  let index = items.findIndex(x => x._id ===e.currentTarget.value);
  if (index!==-1){
    this.props.appstate.YesNoDialog.open = true;
    this.props.appstate.YesNoDialog.title = this.props.appstate.App.language.canceluser;
    this.props.appstate.YesNoDialog.message = this.props.appstate.App.language.messagecanceluser+" " +items[index].name+"?";
    this.setState({selectedUser:items[index]});
    this.props.dispatch(APP(this.props.appstate))
  }

}


openDialogForModify=(event)=>{
  let items= this.state.users.slice(); //copy array
  let index = items.findIndex(x => x._id ===event.currentTarget.value);
  if (index!==-1){
    this.setState({dialogModify:true, utente:this.state.users[index]});
  }
}
closeDialogForModify=()=>{
  this.setState({dialogModify:false},this.getUsers);
}

//barra filtri function
order=(event) =>{
  switch (event.currentTarget.value) {
    case "shortname":
    if (this.state.orderName ==="asc"){
      let orderArray= this.itemsSortInverse("shortname");
      this.setState({users:orderArray,orderName:"desc"});
    }else{
      let orderArray=this.itemsSort("shortname");
      this.setState({users:orderArray,orderName:"asc"});
    }
    break;
    case "name":
    if (this.state.orderShortName ==="asc"){
      let orderArray= this.itemsSortInverse("name");
      this.setState({users:orderArray,orderShortName:"desc"});
    }else{
      let orderArray=this.itemsSort("name");
      this.setState({users:orderArray,orderShortName:"asc"});
    }
    break;
    case "email":
    if (this.state.orderMail ==="asc"){
      let orderArray= this.itemsSortInverse("email");
      this.setState({users:orderArray,orderMail:"desc"});
    }else{
      let orderArray=this.itemsSort("email");
      this.setState({users:orderArray,orderMail:"asc"});
    }
    break;
    case "role":
    if (this.state.orderRole ==="asc"){
      let orderArray= this.itemsSortInverse("role");
      this.setState({users:orderArray,orderRole:"desc"});
    }else{
      let orderArray=this.itemsSort("role");
      this.setState({users:orderArray,orderRole:"asc"});
    }
    break;
    case "status":
    if (this.state.orderStatus ==="asc"){
      let orderArray= this.itemsSortInverse("status");
      this.setState({users:orderArray,orderStatus:"desc"});
    }else{
      let orderArray=this.itemsSort("status");
      this.setState({users:orderArray,orderStatus:"asc"});
    }
    break;
    case "date":
    if (this.state.orderDate ==="asc"){
      let orderArray= this.itemsSortInverse("registered");
      this.setState({users:orderArray,orderDate:"desc"});
    }else{
      let orderArray=this.itemsSort("registered");
      this.setState({users:orderArray,orderDate:"asc"});
    }
    break;

    default:
    break;
  }

}

itemsSort =(itemToOrder) =>{
  let ascend = this.state.users.slice(); //copy array
  ascend.sort (function (a,b)
  {
    return a[itemToOrder].localeCompare(b[itemToOrder]);
  }
);
return ascend;
}

itemsSortInverse (itemToOrder)
{
  var descend = this.state.users.slice();
  descend.sort (function (a, b)
  {
    return b[itemToOrder].localeCompare(a[itemToOrder]);
  }
);
return descend;
}

searchInput = (event) => {
  // console.log(event.currentTarget.value);
  let searchingArray = this.state.usersCopy.slice(); //copy array
  //controllo per quale campo voglio fare la ricerca
  var fieldToSearch="";
  console.log(this.state.radioValue)
  switch (this.state.radioValue) {
    case "name":
    fieldToSearch="name"
    break;
    case "shortname":
    fieldToSearch="shortname"
    break;
    case "email":
    fieldToSearch="email"
    break;
    default:
    break;
  }
  let filtered = searchingArray.filter(function (el, index, arr) {
    // Filtering here
    return ((el[fieldToSearch].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1)
  });
  this.setState({ users: filtered });
}

searchInputCancel = () => {
  document.getElementById("searchusers").value = "";
  this.setState({ users: this.state.usersCopy, currentTab:0 });
}
handleRadio=(e)=>{
  this.setState({radioValue:e.target.value},this.searchInputCancel);
}
//fine barra filtri function
render(){
  let jsonlang = this.props.appstate.App.language;
  return(
    <>
    <div className="pad10">
      <Paper
        style={{
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width:"60%"
        }}
        >
          <RadioGroup row name="usercheck" value={this.state.radioValue} onChange={this.handleRadio}>
            {/* <Tooltip title={<h2>{jsonlang.name.toUpperCase()}</h2>}> */}
            <Tooltip title={<h2>NAME</h2>}>
            <FormControlLabel value="name" control={<Radio color="primary"/>} label="NAME" />
          </Tooltip>
          <Tooltip title={<h2>EMAIL</h2>}>
          {/* <Tooltip title={<h2>{jsonlang.email.toUpperCase()}</h2>}> */}
          <FormControlLabel value="email" control={<Radio color="primary"/>} label="EMAIL" />
        </Tooltip>
        {/* <Tooltip title={<h2>{jsonlang.instshortname.toUpperCase()}</h2>}> */}
        <Tooltip title={<h2>INSTITUTION NAME</h2>}>
        <FormControlLabel value="shortname" control={<Radio color="primary"/>} label="INSTITUTION NAME"/>
      </Tooltip>
    </RadioGroup>
    <Divider
      style={{
        height: 28,
        margin: 4,
      }}
      orientation="vertical"
    />
    <InputBase
      style={{
        marginLeft: 8,
        flex: 1,
      }}
      id="searchusers"
      onChange={this.searchInput}
      placeholder={jsonlang.search}
    />
    <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
    <IconButton
      type="submit"
      color="primary"
      aria-label="search"
      className="pad10"
      onChange={this.searchInput}
      >
        <Search />
      </IconButton>
    </Tooltip>
    <Divider
      style={{
        height: 28,
        margin: 4,
      }}
      orientation="vertical"
    />
    <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
    <IconButton color="primary" aria-label="directions">
      <CancelIcon
        onClick={this.searchInputCancel}
      />
    </IconButton>
  </Tooltip>
</Paper>
</div>
<div className="pad10">
  <TableContainer component={Paper}>
    <Table size="large" aria-label="list users">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <div>
            {/* <Typography variant="button" display="block" gutterBottom> */}
              {/* {jsonlang.name} */}
              <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.name.toUpperCase()}</h2>}>
              <Button value="name" onClick={this.order}>
                {/* {jsonlang.name}  */}
                NAME
                <ImportExportIcon/>
              </Button>
              </Tooltip>
            {/* </Typography> */}
            </div>
          </TableCell>
          <TableCell align="center">
            {/* <Typography variant="button" display="block" gutterBottom> */}
              {/* {jsonlang.email} */}
              <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.email.toUpperCase()}</h2>}>
              <Button value="email" onClick={this.order}>
                {/* {jsonlang.email} */}
                EMAIL
                <ImportExportIcon/>
              </Button>
              </Tooltip>
            {/* </Typography> */}
          </TableCell>
          <TableCell align="center">
            {/* <Typography variant="button" display="block" gutterBottom> */}
              {/* {jsonlang.instshortname} */}
              <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.instshortname.toUpperCase()}</h2>}>
              <Button value="shortname" onClick={this.order}>
                {/* {jsonlang.instshortname}  */}
                INSTITUTION SHORT NAME
                <ImportExportIcon/>
            </Button>
            </Tooltip>
          {/* </Typography> */}
        </TableCell>
        <TableCell align="center">
          {/* <Typography variant="button" display="block" gutterBottom> */}
            {/* {jsonlang.role} */}
            <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.role.toUpperCase()}</h2>}>
            <Button value="role" onClick={this.order}>
            {/* {jsonlang.role}  */}
            ROLE
            <ImportExportIcon/>
            </Button>
            </Tooltip>
          {/* </Typography> */}
        </TableCell>
        <TableCell align="center">
          {/* <Typography variant="button" display="block" gutterBottom> */}
            {/* Status */}
            <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;STATUS</h2>}>
            <Button value="status" onClick={this.order}>
              STATUS
              <ImportExportIcon/>
            </Button>
            </Tooltip>
          {/* </Typography> */}
        </TableCell>
        <TableCell align="center">
          {/* <Typography variant="button" display="block" gutterBottom> */}
            {/* {jsonlang.registered} */}
            <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.registered.toUpperCase()}</h2>}>
            <Button value="date" onClick={this.order}>
              {/* {jsonlang.registered} */}
              REGISTERED
              <ImportExportIcon/>
            </Button>
            </Tooltip>
          {/* </Typography> */}
        </TableCell>
        {this.props.appstate.App.user.role!=="superadmin"   ?
        <TableCell align="center">
          <Typography variant="button" display="block" gutterBottom>
            {jsonlang.action}
          </Typography>
        </TableCell>
        :<></>}
      </TableRow>
    </TableHead>
    {this.state.users.map((item,index) =>
      <TableRow key={index} tabIndex="1">

        <TableCell align="center">
          <div>
            <Tooltip title={<h2>{item.name.toUpperCase()}</h2>} >
            <Typography
              className="ellips"
               style={ item.status === "pending" ?{ color: "red" } :{ } }
              >
                {item.name}
              </Typography>
            </Tooltip>
          </div>
        </TableCell>
          <TableCell align="center">
            <Tooltip title={<h2>{item.email.toUpperCase()}</h2>} >
            <div>
            {/* <ThemeProvider theme={createMuiTheme(this.props.appstate.App.theme.themelink)}> */}
              <Tooltip title={<h2>{item.email.toUpperCase()}</h2>}>
              <Typography color='default' className="ellips">
                <Link
                  role="link"
                  color="inherit"
                  // component="button"
                  aria-label={item.email}
                  href={"mailto:"+item.email}
                  >
                    {item.email}
                  </Link>
                </Typography>
              </Tooltip>
            {/* </ThemeProvider> */}
            </div>
          </Tooltip>
        </TableCell>
      <TableCell align="center">
        <div>
          <Tooltip title={<h2>{item.shortname.toUpperCase()}</h2>}>
          <Typography color='default' className="ellips">
            {item.shortname}
          </Typography>
        </Tooltip>
      </div>
    </TableCell>
    <TableCell align="center">
      <div>
        <Tooltip title={<h2> {item.role ==="domainadmin" ? "ADMIN" :"STANDARD" }</h2>}>
        <Typography color='default' className="ellips">
          {item.role ==="domainadmin" ? "ADMIN" :"STANDARD" }
          {/* {item.role} */}
        </Typography>
      </Tooltip>
    </div>
  </TableCell>
  <TableCell align="center">
    <div>
      <Tooltip title={<h2>{item.status ==="pending"? jsonlang.notapproved : jsonlang.approved}</h2>}>
      <Typography
        className="ellips"
         style={ item.status === "pending" ?{ color: "red" } :{  } }
        >
          {item.status ==="pending"? jsonlang.notapproved : jsonlang.approved}
        </Typography>
      </Tooltip>
    </div>
  </TableCell>

  <TableCell align="center">
    <div>
      <Tooltip title={<h2>{item.registered}</h2>}>
      <Typography color='default' className="ellips">
        {item.registered}
      </Typography>
    </Tooltip>
  </div>
</TableCell>
{/* {this.props.appstate.App.user.role!=="superadmin"   ?
<TableCell align="center">
  <Tooltip title={<h2>{jsonlang.remove}</h2>}>
  <Button
    color="primary"
    value={item._id}
    onClick={this.openDialogForDelete}
    disabled={item.role!=="domainadmin" && item.status==="approved" ? false : true }
    >
      <DeleteForeverIcon/>
    </Button>
  </Tooltip>
  <Tooltip title={<h2>{jsonlang.modify}</h2>}>
  <Button
    color="primary"
    value={item._id}
    onClick={this.openDialogForModify}
    disabled={item.role==="domainadmin" || item.status==="approved"  ? true : false }
    >
      <EditIcon/>
    </Button>
  </Tooltip>

</TableCell>
:  <></>} */}


{this.props.appstate.App.user.role==="superadmin"
  ? <></>
  :
  [
    (item.role==="domainadmin"
    ? <TableCell align="center"> <Typography color='default' className="ellips">
      {/* {jsonlang.noaction} */}
      N.A.
    </Typography></TableCell>
     :
       [ (item.status==="approved"
          ?
          <TableCell align="center">
            <Tooltip title={<h2>{jsonlang.remove.toUpperCase()}</h2>}>
            <Button
              color="primary"
              value={item._id}
              onClick={this.openDialogForDelete}
              >
                <DeleteForeverIcon/>
              </Button>
            </Tooltip>
            </TableCell>
          : <></>
        ),
        ( item.status==="pending"
          ?
          <TableCell align="center">
            <Tooltip title={<h2>{jsonlang.modify.toUpperCase()}</h2>}>
            <Button
              color="primary"
              value={item._id}
              onClick={this.openDialogForModify}
              >
                <EditIcon/>
              </Button>
            </Tooltip>
            </TableCell>
          : <></>
    )]
   )
  ]
}
</TableRow>
)}
</Table>
</TableContainer>
</div>
<DialogYesNo callback={this.deleteUser}/>
<Dialog
  disableBackdropClick
  open={this.state.dialogModify}
  TransitionComponent={Transition}
  onClose={this.closeDialogForModify}
  aria-labelledby="dialog-title"
  fullWidth
  maxWidth={'md'}
  >
    <div>
      <UserModifyDialog
        utente={this.state.utente}
        closeCallback={this.closeDialogForModify}
      />
    </div>
  </Dialog>
</>
)
}
}export default connect(mapStateToProps)(Userspanel);
