import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import {
  // postService,
  getService } from "../../services/services";
import DomainsCreateDialog from "./DomainsCreateDialog";
import DomainsModifyDialog from "./DomainsModifyDialog"
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Search from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './Domains.css';

import {
  Fab ,
  Table,
  // TableBody,
  TableCell ,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  // TextField,
  Tooltip,
  Paper,
  Button,
  // AppBar,
  InputBase,
  IconButton,
  Link,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slide,
  Dialog

} from '@material-ui/core'

/*
TODO:
1- grafica -da finire
2- email cliccabile per l'utente -fatto
3- serve dialog di attesa durante la creazione dell'istituzione culturale
4- gestione messaggi di errore puntuali dalle dialog
*/


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class Domains extends Component {

  constructor(props) {
    super(props);
    this.state = {
      domains:[],
      domainsCopy:[], //for search
      orderShortName:"asc",
      orderLegalName:"desc",
      orderCountry:"desc",
      radioValue:"name",
      modDialogFlag:false,
      modificationData:{}
    };
  }
  modifyDomainsDialog=(event) =>{
    console.log("modifyDomainsDialog")
    console.log(event.currentTarget.value)
    let items= this.state.domains.slice(); //copy array
    let index = items.findIndex(x => x._id ===event.currentTarget.value);
    var self=this;
    // this.props.appstate.Modal.openModalLoading = true;
    // this.props.dispatch(APP(this.props.appstate));
    if (index!==-1){
      let url=services.new_domainsusers_GET.replace("{ID}", self.state.domains[index]._id);
      url=url.replace("{APPNAME}", self.props.acamar.app);
      getService(process.env.REACT_APP_DOMAIN +url ,
      "",
      function (err, result) {
        if (err) {
          console.log("errore get users" + err);
        } else if (result.status === 200) {
          console.log("utenti arrivati");
          console.log(result.response);
          let data={};
          data.domain=Object.assign({}, self.state.domains[index]);
          data.oldDomainAdmin=self.state.domains[index].domainadmin;
          data.oldShortName=self.state.domains[index].shortname;
          //attezione test con vecchi domini per retrocompatibilità         
          data.domain.cloudfrontflag=(self.state.domains[index].cloudfrontflag ? self.state.domains[index].cloudfrontflag: false);
          data.domain.backupaws=(self.state.domains[index].backupaws ? self.state.domains[index].backupaws: false);
          data.domain.bucketname=(self.state.domains[index].bucketname ?self.state.domains[index].bucketname: "");
          data.domain.cloudfrontdomain=(self.state.domains[index].cloudfrontdomain ? self.state.domains[index].cloudfrontdomain: "");
          data.usersList=result.response;
          console.log("DATA BULL");
          console.log(data);
          self.setState({modificationData:data},self.openModifyDialog);
        }
      }
    );
  }

}
createDomainsDialog=(event) =>{
  console.log("createDomainsDialog")
  this.props.appstate.CreateDomDialog.open = true;
  this.props.dispatch(APP(this.props.appstate));
}

componentDidMount(){
  this.getDomains();
}

domainDialogCallback=()=>{
  this.setState({modDialogFlag:false},this.getDomains);
}

getDomains=()=>{
  var self=this;
  getService(process.env.REACT_APP_DOMAIN + services.new_domainsandadmins_GET.replace("{APPNAME}", self.props.acamar.app),
    "",
    function (err, result) {
      if (err) {
        console.log("errore get domini");
      } else if (result.status === 200) {
        console.log("domini arrivati");
        console.log(result.response);
        result.response.sort (function (a,b)
        {
          return a.shortname.localeCompare(b.shortname);
        }
      );
      self.setState({domains:result.response,domainsCopy:result.response,orderShortName:"asc"});
    }
  }
);
}

order=(event) =>{
  switch (event.currentTarget.value) {
    case "name":
    if (this.state.orderShortName ==="asc"){
      let orderArray= this.itemsSortInverse("shortname");
      this.setState({domains:orderArray,orderShortName:"desc"});
    }else{
      let orderArray=this.itemsSort("shortname");
      this.setState({domains:orderArray,orderShortName:"asc"});
    }
    break;
    case "legalname":
    if (this.state.orderLegalName ==="asc"){
      let orderArray= this.itemsSortInverse("legalname");
      this.setState({domains:orderArray,orderLegalName:"desc"});
    }else{
      let orderArray=this.itemsSort("legalname");
      this.setState({domains:orderArray,orderLegalName:"asc"});
    }
    break;
    case "country":
    if (this.state.orderCountry ==="asc"){
      let orderArray= this.itemsSortInverse("country");
      this.setState({domains:orderArray,orderCountry:"desc"});
    }else{
      let orderArray=this.itemsSort("country");
      this.setState({domains:orderArray,orderCountry:"asc"});
    }
    break;
    default:
    break;
  }

}

itemsSort =(itemToOrder) =>{
  let ascend = this.state.domains.slice(); //copy array
  ascend.sort (function (a,b)
  {
    return a[itemToOrder].localeCompare(b[itemToOrder]);
  }
);
return ascend;
}

itemsSortInverse (itemToOrder)
{
  var descend = this.state.domains.slice();
  descend.sort (function (a, b)
  {
    return b[itemToOrder].localeCompare(a[itemToOrder]);
  }
);
return descend;
}

searchInput = (event) => {
  // console.log(event.currentTarget.value);
  let searchingArray = this.state.domainsCopy.slice(); //copy array
  //controllo per quale campo voglio fare la ricerca
  var fieldToSearch="";
  switch (this.state.radioValue) {
    case "name":
    fieldToSearch="shortname"
    break;
    case "legalname":
    fieldToSearch="legalname"
    break;
    case "country":
    fieldToSearch="country"
    break;
    default:
    break;
  }
  let filtered = searchingArray.filter(function (el, index, arr) {
    // Filtering here
    return ((el[fieldToSearch].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1)
  });
  this.setState({ domains: filtered });
}

searchInputCancel = () => {
  document.getElementById("searchdomains").value = "";
  this.setState({ domains: this.state.domainsCopy, currentTab:0 });
}
handleRadio=(e)=>{
  this.setState({radioValue:e.target.value},this.searchInputCancel);
}
openModifyDialog=()=>{
  // this.props.appstate.Modal.openModalLoading = false;
  // this.props.dispatch(APP(this.props.appstate));
  this.setState({modDialogFlag:true});
}
oncloseDialog=()=>{
  this.setState({modDialogFlag:false});
}
render(){

  let jsonlang = this.props.appstate.App.language;

  return (
    <div className="pad10">
      <DomainsCreateDialog callback={this.domainDialogCallback}/>
      {/* <DomainsModifyDialog callback={this.domainDialogCallback}/> */}

      <Dialog
        disableBackdropClick
        open={this.state.modDialogFlag}
        onClose={this.oncloseDialog}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth = {'md'}
        >
          <DomainsModifyDialog
            closeCallback={this.oncloseDialog}
            callback={this.domainDialogCallback}
            dataToModify={this.state.modificationData}/>
          </Dialog>
          <div className="bottom20">
            {/* <IconButton color="primary">
            <Search />
          </IconButton> */}
          {/* <InputBase
            id="searchdomains"
            onChange={this.searchInput}
          /> */}
          {/* <TextField id="searchdomains" label="search" onChange={this.searchInput} /> */}
          {/* <CancelRounded color="primary" onClick={this.searchInputCancel}></CancelRounded> */}

          <Paper
            style={{
              padding: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width:"60%"
            }}
            >
              <RadioGroup row name="domainscheck" value={this.state.radioValue} onChange={this.handleRadio}>
                {/* <Tooltip title={<h2>{jsonlang.name.toUpperCase()}</h2>}> */}
                <Tooltip title={<h2>NAME</h2>}>
                <FormControlLabel value="name" control={<Radio color="primary"/>} label="NAME" />
                </Tooltip>
                {/* <Tooltip title={<h2>{jsonlang.legalname.toUpperCase()}</h2>}> */}
                <Tooltip title={<h2>LEGAL NAME</h2>}>
                <FormControlLabel value="legalname" control={<Radio color="primary"/>} label="LEGAL NAME"/>
                </Tooltip>
                {/* <Tooltip title={<h2>{jsonlang.country.toUpperCase()}</h2>}> */}
                <Tooltip title={<h2>COUNTRY</h2>}>
                <FormControlLabel value="country" control={<Radio color="primary"/>} label="COUNTRY" />
                </Tooltip>
              </RadioGroup>
              <Divider
                style={{
                  height: 28,
                  margin: 4,
                }}
                orientation="vertical"
              />
              <InputBase
                style={{
                  marginLeft: 8,
                  flex: 1,
                }}
                id="searchdomains"
                onChange={this.searchInput}
                placeholder={jsonlang.search}
              />
              <Tooltip title={<h2>{jsonlang.searchdomain.toUpperCase()}</h2>}>
              <IconButton
                type="submit"
                color="primary"
                aria-label="search"
                className="pad10"
                onChange={this.searchInput}
                >
                  <Search />
                </IconButton>
              </Tooltip>
              <Divider
                style={{
                  height: 28,
                  margin: 4,
                }}
                orientation="vertical"
              />
              <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
              <IconButton color="primary" aria-label="directions">
                <CancelIcon
                  onClick={this.searchInputCancel}
                />
              </IconButton>
            </Tooltip>
          </Paper>

        </div>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="table domains">
            <TableHead>
              {/* <TableHead  bgcolor="grey.300"> */}
              {/* <TableHead  bgcolor="background.paper"> */}
              <TableRow>
                <TableCell align="center">
                  {/* <Typography variant="button" display="block" gutterBottom> */}
                    {/* {jsonlang.name} */}
                    <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.name.toUpperCase()}</h2>}>
                    <Button
                      value="name"
                      onClick={this.order}>
                      {/* {jsonlang.name}  */}
                      NAME
                      <ImportExportIcon/>
                    </Button>
                    </Tooltip>
                  {/* </Typography> */}
                </TableCell>
                <TableCell align="center">
                  {/* <Typography variant="button" display="block" gutterBottom> */}
                    {/* {jsonlang.legalname} */}
                    <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.legalname.toUpperCase()}</h2>}>
                    <Button
                      value="legalname"
                      onClick={this.order}>
                      {/* {jsonlang.legalname}  */}
                      LEGAL NAME
                      <ImportExportIcon/>
                    </Button>
                    </Tooltip>
                  {/* </Typography> */}
                </TableCell>
                <TableCell align="center">
                  {/* <Typography variant="button" display="block" gutterBottom> */}
                    {/* {jsonlang.country} */}

                    <Tooltip title={<h2>{jsonlang.orderby.toUpperCase()}&nbsp;{jsonlang.country.toUpperCase()}</h2>}>
                    <Button
                      value="country"
                      onClick={this.order}
                      >
                        {/* {jsonlang.country}  */}
                        COUNTRY
                        <ImportExportIcon/>
                      </Button>
                      </Tooltip>
                    {/* </Typography> */}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      {/* {jsonlang.adminname} */}
                      ADMINISTRATOR NAME
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      {/* {jsonlang.adminemail} */}
                      ADMINISTRATOR EMAIL
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      {/* {jsonlang.action} */}
                      ACTION
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {this.state.domains.map((item,index) =>
                <TableRow key={index} tabIndex="1">
                  <TableCell align="center">
                    <div>
                      <Tooltip title={<h2>{item.shortname.toUpperCase()}</h2>}>
                      <Typography color='default' className="ellips">
                        {item.shortname}
                      </Typography>
                    </Tooltip>
                  </div>
                </TableCell>

                <TableCell align="center">
                  <div>
                    <Tooltip title={<h2>{item.legalname.toUpperCase()}</h2>}>
                    <Typography color='default' className="ellips">
                      {item.legalname}
                    </Typography>
                  </Tooltip>
                </div>
              </TableCell>

              <TableCell align="center">
                <div>
                  <Tooltip title={<h2>{item.country.toUpperCase()}</h2>}>
                  <Typography color='default' className="ellips">
                    {item.country}
                  </Typography>
                </Tooltip>
              </div>
            </TableCell>
            <TableCell align="center">
              <div>
                <Tooltip title={<h2>{item.administrator[0].name.toUpperCase()}</h2>}>
                <Typography color='default' className="ellips">
                  {item.administrator[0].name}
                </Typography>
              </Tooltip>
            </div>
          </TableCell>
          {/* <ThemeProvider theme={createMuiTheme(this.props.appstate.App.theme.themelink)}> */}
            <TableCell align="center">
              <Tooltip
                title={<h2>{item.administrator[0].email.toUpperCase()}</h2>}
                >
                  <div>
                    <Typography color='default' className="ellips">
                      <Link
                        role="link"
                        color="inherit"
                        aria-label={item.administrator[0].email}
                        href={"mailto:"+item.administrator[0].email}
                        >
                          {item.administrator[0].email}
                          {/* <a href={"mailto:"+item.administrator[0].email}>{item.administrator[0].email}</a> */}
                        </Link>
                      </Typography>
                    </div>
                  </Tooltip>
                </TableCell>
              {/* </ThemeProvider> */}
              <TableCell align="center">
                {/* <Button value={item._id} onClick={this.modifyDomainsDialog} >{jsonlang.modify}</Button> */}
                <Tooltip title={<h2>{jsonlang.modify.toUpperCase()}</h2>}>
                <IconButton
                  aria-label={jsonlang.modify}
                  color="primary"
                  value={item._id}
                  onClick={this.modifyDomainsDialog}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      <div className="top20">
        <Tooltip title={<h2>{jsonlang.addinst.toUpperCase()}</h2>}>
        <Fab color="primary"onClick={this.createDomainsDialog}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  </div>
);
}
}export default connect(mapStateToProps)(Domains);
