import { createStore } from "redux";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import { persistStore, persistCombineReducers } from "redux-persist";
import reducers from "../redux/statecore";




export const configureStore = function configureStore(callback) {
  let params = new URLSearchParams(window.location.search);
  let appname = params.get("app");
  if (!appname) {
    appname = window.localStorage["currentapp"];
  }
  
  if (appname) {
    const config = {
      key: appname,
      storage,
    };
    const statecore = persistCombineReducers(config, reducers);
    const store = createStore(
      statecore /* preloadedState, */,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    persistStore(store, {}, () => {
      callback(store, persistStore);
    });
  }
};
