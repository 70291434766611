import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";

import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import LanguageIcon from '@material-ui/icons/Language';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class FlagsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({});
  }

  init = () => {

  }

  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  onclose = () => {
    this.props.appstate.FlagsDialog.render = false;
    this.props.appstate.FlagsDialog.openModalFlags = false;
    this.props.appstate.FlagsDialog.items = [];
    this.props.dispatch(APP(this.props.appstate));
  }
  itemClick = (event) => {
    this.props.callback(event.currentTarget.name);
    this.props.appstate.FlagsDialog.render = false;
    this.props.appstate.FlagsDialog.openModalFlags = false;
    this.props.appstate.FlagsDialog.items = [];
    this.props.dispatch(APP(this.props.appstate));
  }
  render() {
    let jsonlang = this.props.appstate.App.language;
    let items = this.props.appstate.FlagsDialog.items;
    return (
      <>
      {this.props.appstate.FlagsDialog.render === true ?
        <div className="iframe">
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            open={this.props.appstate.FlagsDialog.openModalFlags}
            onClose={this.onclose}
            aria-labelledby="dialog-add"
            maxWidth={'sm'}
            fullWidth
            >
              <DialogTitle id="dialog-add">
                <LanguageIcon
                />
                <span style={{
                  padding: 5,
                  position: 'fixed',
                }}>
                {'LANGUAGES SET'}
              </span>
            </DialogTitle>
            <DialogContent dividers>
              {items.length>0 ?
                <Grid container justifyContent="center" spacing={3}>
                  {items.map((item, index) =>
                    <IconButton name={item} onClick={this.itemClick}>
                      <img name={item} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item + ".svg"}></img>
                    </IconButton>
                  )}



                </Grid>
                :
                <Typography >{jsonlang.notlanguage.toUpperCase()}</Typography>
              }
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>OK</h2>}>
                <Typography variant="button">OK</Typography>
              </Tooltip>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      : <></>}
    </>
  )
}
}
export default connect(mapStateToProps)(FlagsDialog);
