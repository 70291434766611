import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  // DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  // IconButton,
  Tooltip,
  Slide,
  Typography
} from "@material-ui/core";

// import BatteryCharging30Rounded from '@material-ui/icons/BatteryCharging30Rounded';

// import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogEditConfirm extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  onclose = () => {
    //this.props.appstate.Modal.openModalLoading = false;
    this.props.appstate.Modal.openModalEditConfirm = false;
    this.props.appstate.ResourceManager.render = false;
    this.props.appstate.ResourceManager.openModalResources = false;
    this.props.appstate.ResourceManager.openModalResourcesHeader = false;
    this.props.appstate.ResourceManager.edit = false;
    this.props.appstate.ResourceManager.openModalIconLang = false;
    this.props.appstate.ResourceManager.index = 0;
    this.props.appstate.ResourceManager.id = 0;
    this.props.appstate.ResourceManager.resourceInfo = {};
    //    this.props.appstate.Modal.openModalLoadingTextExtra="";
    //  this.props.appstate.Modal.openModalLoadingText="";
    this.props.dispatch(APP(this.props.appstate));
  }

  edit = () => {
    //this.props.appstate.Modal.openModalLoading = false;
    this.props.appstate.Modal.openModalEditConfirm = false;
    //  this.props.appstate.Modal.openModalLoadingTextExtra="";
    //this.props.appstate.Modal.openModalLoadingText="";
    this.props.dispatch(APP(this.props.appstate));
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <Dialog
          open={this.props.appstate.Modal.openModalEditConfirm}
          onClose={this.onclose}
          TransitionComponent={Transition}
          aria-label="dialog-title"
          fullWidth
          maxWidth={'sm'}
          disableBackdropClick
          disableEscapeKeyDown
          >
            {/* <DialogTitle id="simple-dialog-title"><BatteryCharging30Rounded fontSize="large"/></DialogTitle> */}
            <DialogContent>


              <Typography
                variant='h6'
                justify='center'
                align='center'
                >
                  {jsonlang.editdone}
                </Typography>

              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.edit}
                  color="primary"
                  >
                    <Tooltip title={<h2>{jsonlang.modify}</h2>}>
                    <Typography >{jsonlang.modify}</Typography>
                  </Tooltip>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.onclose}
                  color="primary">
                  <Tooltip title={<h2>{jsonlang.close}</h2>}>
                  <Typography >{jsonlang.close}</Typography>
                </Tooltip>
              </Button>
            </DialogActions>

          </Dialog>
        </div>
      )
    }
  }
  export default connect(mapStateToProps)(DialogEditConfirm);
