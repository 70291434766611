import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputBase,
  Divider,
  Grid, ImageListItemBar, ImageList, ImageListItem, IconButton, Paper
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import ClearIcon from '@material-ui/icons/Clear';
import * as services from "../../services/services.json";
import { getService } from "../../services/services";
import { APP } from '../../redux/actions/actions';
import {
  getResourceDataForLanguage
} from "../../Utils/utility";

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogResourcesManagerAnnotator extends Component {
  constructor(props) {
    super(props);
    this.state = ({

    });
  }
  init = (type) => {
    console.log("init della dialog per avere le risorse ");
    this.setState({
      items: [],
      searchValue: "",
      itemscopy: []
    });
    this.getResources(type);
  }

  componentDidMount() {
    console.log(process.env.REACT_APP_DOMAIN + "/images/icons/sound-bars.png")
    this.props.onRef(this); //for the parent
  }


  itemsSort = (itemToOrder, dataArray) => {
    let ascend = dataArray.slice();
    ascend.sort(function (a, b) {
      return a[itemToOrder].localeCompare(b[itemToOrder]);
    }
    );
    return ascend;
  }
  getResources = (type) => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let ser =
      process.env.REACT_APP_DOMAIN +
      services.resourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        this.props.appstate.App.name
      );
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DEST}", "resource");
    ser = ser + "&type=" + type;
    getService(ser, "", function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText =
          self.props.appstate.App.language.resourcerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      if (result.status === 200) {
        self.props.appstate.Modal.openModalLoading = false;
        console.log(result.response)
        let helpElemArray = self.itemsSort("name", result.response);
        self.props.appstate.DialogResourcesManagerAnnotator.resources = helpElemArray;
        self.props.dispatch(APP(self.props.appstate));
        self.setState({ items: self.props.appstate.DialogResourcesManagerAnnotator.resources, itemscopy: helpElemArray });
        return;
      }
      self.props.appstate.DialogResourcesManagerAnnotator.render = false;
      self.props.appstate.DialogResourcesManagerAnnotator.openModalResources = false;
      self.props.appstate.DialogResourcesManagerAnnotator.index = 0;
      self.props.appstate.DialogResourcesManagerAnnotator.id = 0;
      self.props.appstate.DialogResourcesManagerAnnotator.resourceInfo = {};
      self.props.appstate.DialogResourcesManagerAnnotator.type = "";
      self.props.appstate.Modal.openModalLoadingTextActive = true;
      self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.noresource;
      self.props.dispatch(APP(self.props.appstate));
      return;
    });
  };

  onclose = () => {
    this.props.appstate.DialogResourcesManagerAnnotator.render = false;
    this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = false;
    this.props.appstate.DialogResourcesManagerAnnotator.index = 0;
    this.props.appstate.DialogResourcesManagerAnnotator.id = 0;
    this.props.appstate.DialogResourcesManagerAnnotator.resourceInfo = {};
    this.props.appstate.DialogResourcesManagerAnnotator.type = "";
    this.props.dispatch(APP(this.props.appstate));
  }

  coverUrl = (item) => {
    let ser =
      process.env.REACT_APP_DOMAIN +
      services.singleresourcemanager_GET_AUTH.replace(
        "{APPNAME}",
        this.props.appstate.App.name
      );
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{RESOURCEID}", item._id);
    ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
    ser = ser.replace("{COVER}", true);
    ser = ser.replace("{MEDIALAN}", getResourceDataForLanguage(item));
    ser = ser.replace("{MEDIARES}", "first");
    ser = ser + "&t=" + new Date().getTime();

    return ser;
  };

  dialogResourcesManagerAnnotatorCallback = (event) => {
    this.props.callback(this.state.itemscopy[event.currentTarget.id]);
    this.onclose();
  }
  getUTCData = (data) => {
    try {
      return new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "UTC",
        timeZoneName: "short",
      }).format(new Date(data));
    } catch (error) {
      console.log(error);
      return "";

    }
  };

  searchInput = (event) => {
    if (event.target.value.length > 0) {
      let filtered = this.state.items.filter(
        function (el, index, arr) {
          let found = false;
          let activeFilter = [];
          if (
            el.name
              .toUpperCase()
              .indexOf(event.currentTarget.value.toUpperCase()) !== -1
          ) {
            activeFilter.push("name");
            found = true;
          }

          let keysdesc = Object.keys(el.description);
          for (let i = 0; i < keysdesc.length; i++) {
            if (
              el.description[keysdesc[i]]
                .toUpperCase()
                .indexOf(event.currentTarget.value.toUpperCase()) !== -1
            ) {
              activeFilter.push("description");
              found = true;
              break;
            }
          }
          el.filter = activeFilter;
          return found;
        }
      );
      this.setState(
        { itemscopy: filtered, searchValue: event.target.value }
      );
    } else {
      this.setState(
        {
          itemscopy: this.state.items,
          searchValue: event.target.value,
        }
      );
    }
  }

  searchInputCancel = () => {
    // document.getElementById("resourcesearchinput").value = "";
    this.setState(
      { itemscopy: this.state.items, searchValue: "" }
    );
  };


  getUriLang = (selectedUri) => {
    let url = "";
    if (typeof (selectedUri) === 'string') { //retrocompatibilità
      url = selectedUri;
    } else {
      let itemHELP = {};
      itemHELP.medialan = selectedUri;
      let lang = getResourceDataForLanguage(itemHELP);
      url = selectedUri[lang];
    }
    return url;
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <>
        {this.props.appstate.DialogResourcesManagerAnnotator.render === true ?
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            // open={this.props.appstate.DialogResourcesManagerAnnotator.openModalResources} 
            open={this.props.open}
            onClose={this.onclose}
            aria-labelledby="dialog-add"
            fullWidth
            maxWidth={'xs'}
          >
            <DialogTitle id="dialog-add">
              <Paper elevation={3}>

                <Grid container justifyContent="center" xs={12}>
                  <Grid item xs={11}>
                    <InputBase
                      id="resourcesearchinput"
                      onChange={this.searchInput}
                      placeholder={jsonlang.search}
                      value={this.state.searchValue}
                      style={{ paddingLeft: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={1} >
                    {/* <SearchIcon
                      color="primary"
                      aria-label="search"
                      fontSize="mini"
                    /> */}
                    <ClearIcon
                      color="primary"
                      onClick={this.searchInputCancel}
                      fontSize="mini"
                      style={{ marginTop: "5px" }}

                    />
                  </Grid>

                </Grid>
              </Paper>

            </DialogTitle>
            <DialogContent>
              {/* style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }} */}
              <ImageList cols={2} >
                {(this.state.itemscopy && this.state.itemscopy.length > 0) ?
                  this.state.itemscopy.map((item, index) => (
                    <ImageListItem key={item.img} onClick={this.dialogResourcesManagerAnnotatorCallback} id={index} index={index} name={index}>
                      {/* <img src={this.coverUrl(item._id, getResourceDataForLanguage(item))} alt={item.name} /> */}
                      {(item.type !== "audio" && item.type !== "webcloud" && item.type !== "audiocloud" && item.type !== "textcloud" && item.type !== "videocloud" && item.type !== "imagecloud") ?
                        <img id={index} src={this.coverUrl(item)} alt={item.name} index={index} name={index} />
                        :
                        <img id={index} src={
                          item.type === "audio"
                            ? "/images/icons/sound-bars.png"
                            : item.type === "imagecloud"
                              ? item.url ? this.getUriLang(item.url) : "/images/icons/ext_res.png"
                              : item.type === "videocloud"
                                ? item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/video.png"
                                : item.type === "audiocloud"
                                  ? "/images/icons/sound-bars.png"
                                  : item.type === "filecloud"
                                    ? "/images/icons/ext_res.png"
                                    : item.type === "documentcloud"
                                      ? "/images/icons/ext_res.png"
                                      : item.type === "textcloud"
                                        ? "/images/icons/text.png"
                                        : item.type === "webcloud"
                                          ? item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/ext_res.png"
                                          : item.type === "document"
                                            ? "/images/icons/document.png"
                                            : this.coverUrl(item._id, getResourceDataForLanguage(item))
                        }
                          // {"/images/icons/sound-bars.png"} 
                          alt={item.name} index={index} name={index} />}
                      <ImageListItemBar
                        title={item.name}
                      // actionIcon={                                         
                      //     <IconButton >
                      //       <DeleteIcon style={{ color: "white" }} onClick={this.dialogResourcesManagerAnnotatorCallback} id={index} index={index} name={index} />
                      //     </IconButton>                      
                      // }
                      />
                    </ImageListItem>
                  ))
                  : (<></>)}
              </ImageList>


              {/* <List>
                {
                  (this.state.itemscopy && this.state.itemscopy.length > 0) ?
                    (this.state.itemscopy.map((item, index) => (
                      <ListItem onClick={this.dialogResourcesManagerAnnotatorCallback} id={index} index={index} name={index}>
                        <ListItemAvatar>
                          {(item.type !== "audio" && item.type !== "webcloud" && item.type !== "audiocloud" && item.type !== "textcloud" && item.type !== "videocloud" && item.type !== "imagecloud") ?
                            <Avatar>
                              <img id={index} src={this.coverUrl(item)} alt={item.name} index={index} name={index} />
                            </Avatar>

                            :
                            null
                          }
                        </ListItemAvatar>
                        <ListItemText primary={item.name} secondary={this.getUTCData(item.modifiedAt)} />
                      </ListItem>
                    ))) : (<></>)}
              </List> */}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                  <Typography variant="button">{jsonlang.instcancel}</Typography>
                </Tooltip>
              </Button>
            </DialogActions>
          </Dialog>
          : <></>}
      </>
    )
  }
}
export default connect(mapStateToProps)(DialogResourcesManagerAnnotator);
