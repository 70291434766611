import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
// import * as data from '../services/services.json';
// import { WHOLE } from '../redux/actions/actions';
// import ReactDOM from 'react-dom';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class Iframecomp extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {

  }


  msgToChild =(event)=>{
    let iframeContentWindow = event.currentTarget.contentWindow;
    let appstate = Object.assign({}, this.props.appstate);
    appstate.App.theme.refmaterial = {};
    iframeContentWindow.postMessage({
      type: "WHOLE", acamar: this.props.acamar, appstate: appstate
    }, this.props.appstate.App.currentappchild);
  }

  render() {
    return (
        <iframe
          id="iframecontent"
          style={{
            width: "100%",
            //height: "calc(100vh - 75px)",
            height: "calc(100vh)",
            border: "unset",
            //marginTop: "-38px",
          }}
          allow="clipboard-read; clipboard-write"
          src={this.props.appstate.App.currentappchild}
          onLoad={this.msgToChild}>
        </iframe>
    )
  }
}

export default connect(mapStateToProps)(Iframecomp);
