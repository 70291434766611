
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP} from '../../redux/actions/actions';
// import * as services from "../../services/services.json";
// import { postService, getService } from "../../services/services";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  // Grid,
  Typography,
  // FormControl,
  // TextField,
  // InputLabel,
  // IconButton,
  // MenuItem,
  Tooltip,
  // InputAdornment,
  // OutlinedInput
} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogYesNo extends Component {
  // constructor(props) {
  //   super(props);
  //
  // }
  onclose = () => {
    this.props.appstate.YesNoDialog.open = false;
    this.props.dispatch(APP(this.props.appstate));
  }
  render() {
    let jsonlang = this.props.appstate.App.language;
    return(
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.props.appstate.YesNoDialog.open}
        onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth = {'sm'}
        >
          <DialogTitle >
            <HelpIcon
              aria-label="confirm Icon"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            />
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {jsonlang.canceluser.toUpperCase()}
          </span>

        </DialogTitle>
        <DialogContent>
          {this.props.appstate.YesNoDialog.message.toUpperCase()}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
            >
              <Tooltip title={<h2>{jsonlang.no}</h2>}>
              <Typography >{jsonlang.no}</Typography>
            </Tooltip>
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={ this.props.callback}
            color="primary">
            <Tooltip title={<h2>{jsonlang.yes}</h2>}>
            <Typography >{jsonlang.yes}</Typography>
          </Tooltip>
        </Button>
      </DialogActions>
    </Dialog>
  )}
};
export default connect(mapStateToProps)(DialogYesNo);
