import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP
} from '../../redux/actions/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  ListSubheader,
  ListItem,
  ListItemText,
  List
} from "@material-ui/core";
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as services from "../../services/services.json";
import {
  getService
} from "../../services/services";

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ShowRoomDomainsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      choicedomain: "",
      render: false,
      domains:[]
    });
  }
  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  init = () => {
    this.setState({
      choicedomain: "",
      render: false
    }, this.getDomains);
  }

  onclose = () => {
    this.props.appstate.ShowRoomDomainsDialog.open = false;
    this.props.dispatch(APP(this.props.appstate));
    // this.props.closeCallback();
  }

  callback = (event) => {
    this.props.appstate.ShowRoomDomainsDialog.open = false;
    this.props.appstate.ShowRoomDomainsDialog.render = false;
    this.props.dispatch(APP(this.props.appstate));
    this.props.callback(event.currentTarget.id);
  }

  getDomains = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let ser = process.env.REACT_APP_DOMAIN + services.new_domains_GET.replace("{APPNAME}", self.props.acamar.app);
    getService(
      ser,
      "",
      function (err, result) {
        if (err) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.resourcerror;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        if (result.status === 200) {
          self.props.appstate.Modal.openModalLoading = false;

          if (result.response.length===1){
            self.props.appstate.ShowRoomDomainsDialog.open = false;
            self.props.appstate.ShowRoomDomainsDialog.render = false;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback(result.response[0]._id);
          }else {
            self.props.appstate.ShowRoomDomainsDialog.render = true;
            self.props.dispatch(APP(self.props.appstate));
            self.setState({ domains: result.response});
          }
          return;
        }
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.noresource;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
    );
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <>
      {this.props.appstate.ShowRoomDomainsDialog.render === true ?
        <div className="iframe">
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            open={this.props.appstate.ShowRoomDomainsDialog.open}
            onClose={this.onclose}
            aria-labelledby="dialog-add"
            fullWidth
            maxWidth={'xs'}
            >
              <DialogTitle id="dialog-add">
                Domains
              </DialogTitle>
              <DialogContent dividers>
                <List subheader={<li />}>
                {this.state.domains.map((domain, i) =>
                  <li>
                    <ul>
                      <ListItem button key={i} id={domain._id} onClick={this.callback}>
                        <ListItemText primary={domain.shortname}/>
                      </ListItem>
                    </ul>
                  </li>
                )}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.instcancel}</Typography>
              </Tooltip>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      : <></>}
    </>)
  }
};
export default connect(mapStateToProps)(ShowRoomDomainsDialog);
