import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
  // import ReactDOM from 'react-dom';
  import './Home.css';

  // import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
  // import SettingsIcon from '@material-ui/icons/Settings';

  // =====================================
  // ============ COMPONENTS  ============
  // =====================================
  import {
    AppBar,
    // Box,
    // Button,
    // ButtonGroup,
    // Container,
    // CssBaseline,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogTitle,
    // Divider,
    // Fab,
    // FormControl,
    // FormControlLabel,
    // FormGroup,
    // FormLabel,
    // Grid,
    // Icon,
    // IconButton,
    // InputLabel,
    Link,
    // ListSubheader,
    // Menu,
    // MenuItem,
    // Paper,
    // Radio,
    // RadioGroup,
    // Select,
    // Slide,
    // Switch,
    // TextField,
    // Toolbar,
    Tooltip,
    Typography
  } from "@material-ui/core";

  import ShowRoomDomainsDialog from '../ShowRoom/ShowRoomDomainsDialog';

  const mapStateToProps = state => {
    return {
      acamar: state.acamar,
      appstate: state.appstate
    };
  };

  class Home extends Component {
    // constructor(props) {
    //   super(props);
    // }

    componentDidMount() {
      this.props.appstate.Modal.openModalLoading = false;
      this.props.appstate.Resource.openModalResources = false;
      this.props.appstate.ResourceManager.openModalResourcesManager = false;
      this.props.dispatch(APP(this.props.appstate));
      this.checkUserPrefereces();
    }

    static getDerivedStateFromProps(props, state) {
      if (props.store) {
        return {
          store: props.store
        }
      }


      return null
    }







    checkUserPrefereces=()=>{
    //  console.log("prefer "+ JSON.stringify(this.props.appstate.App.user.preferences));
      if(this.props.appstate.App.user.userid!==""){
      //  console.log("prefer sono loggato");
        if(this.props.appstate.App.user.preferences){
            //ho le preferences dell'utente
        }else{
        //sono loggato ma non vi è il campo preferences
        this.props.appstate.App.user.preferences = {};
        this.props.appstate.App.user.preferences.accessibility = {
          contrast:"light",
          yellowLinks:"colornormal",
          underlineLinks:"linksdefault",
          fontSize : "fontnormal",
          familyFont : "familydefault"
        }
        this.props.appstate.App.user.preferences.language=this.props.appstate.App.currentLanguage;
        //apro le dialog per settare le preferenze
          this.props.appstate.Modal.openModalAccessibility=true;
         this.props.appstate.Modal.openModalLanguage=true;
          this.props.dispatch(APP(this.props.appstate));
        }
      }
      else{
          //console.log("prefer non sono loggato");
        //non sono loggato

      }


    }

    loadApp = (event) => {
      switch (event.currentTarget.name) {
        case "storyapp":
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_CAT_STORIE;
        this.props.dispatch(APP(this.props.appstate));
        break;
        case "socialmedia":
        console.log("event "+event.currentTarget.name);
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_SOCIAL_MEDIA;
        console.log("event "+this.props.appstate.App.currentappchild );
        this.props.dispatch(APP(this.props.appstate));
        break;
        case "annotatorapp":
        this.props.history.push('/App');
        this.props.appstate.App.currentappchild = process.env.REACT_APP_ANNOTATORAPP;
        this.props.dispatch(APP(this.props.appstate));
        break;
        default:
        break;
      }
    }

    loadDialogShowRoomDetail = () => {
      if (this.props.appstate.App.user.logged === false) {
        this.dialogShowRoomDetailRef.init();
        this.props.appstate.ShowRoomDomainsDialog.open = true;
        this.props.dispatch(APP(this.props.appstate));
      } else {
        this.props.history.push({ pathname: '/ShowRoomDetail', search: '?domain=' + this.props.appstate.App.user.domain});
      }
    }

    dialogShowRoomDetailCallback = (result) => {
      //console.log(result);
      this.props.history.push({ pathname:'/ShowRoomDetail',search:'?domain=' + result });
    }

    getFooter = () => {
      switch (this.props.appstate.App.name.toUpperCase()) {
        case 'SOCLOSE':
        return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
          <img
            // style={{height: 56}}
            src='/images/partner/footer.png'
            alt="logo-eu_label"/>
          </AppBar>);
          case 'VDA':
          return(<div></div>);
          case 'SMSTAPP':
          return(<div></div>);
          case 'GREENSCENT':
          return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
            <img
              // style={{height: 56}}
              src='/images/partner/barra.png'
              alt="logo-eu_label"/>
            </AppBar>);
            case 'SMST':
            return(<div></div>);
            case 'IDEHA':
            return(<div></div>);
            default:
            return(<div></div>);
          }


        }


        render() {
          let jsonlang = this.props.appstate.App.language;
          let currentconfig = this.props.acamar.configuration;

          return (

            <div>
              <ShowRoomDomainsDialog callback={this.dialogShowRoomDetailCallback} onRef={childthis => (this.dialogShowRoomDetailRef = childthis)} />
              <div
                style={{paddingBottom:56}}
                >

                  <div className="heading">
                    <Typography variant="h3" display="block">{this.props.acamar.configuration.title}</Typography>
                  </div>
                  {/* <ThemeProvider theme={createMuiTheme(this.props.appstate.App.theme.themelink)}> */}

                  <>

                  <div class="contain">

                    <div class={this.props.appstate.App.name.toUpperCase() === "SOCLOSE" || this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "row" : "row2"}>
                      <div class="row__inner">


                        {/* ============================INIZIO ResourcesManager                        */}
                        {/* <div class="tile"> */}
                         {currentconfig.Section!=null && currentconfig.Section["ResourceManager"]!=null && currentconfig.Section["ResourceManager"] ?
                        <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                          <div class="tile__media">
                            <img
                              alt="immagine copertina gallery"
                              className="tile__img"
                              src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_create.png" : "/images/sfondi-home/resource_manager.jpg"}
                              onClick={() => { this.props.history.push({
                                pathname: '/ResourcesManager',
                                search: '?section=create',
                                state: { detail: 'some_value' }
                            });}}
                            />
                          </div>
                          <div class="tile_no_details">
                            <div class="tile__title">
                              <Tooltip title={<h2>{jsonlang.resourcemanager.toUpperCase()}</h2>}>
                              <Link
                                role="link"
                                color="inherit"
                                component="button"
                                aria-label={jsonlang.resourcemanager.toUpperCase()}
                                onClick={() => { this.props.history.push({
                                  pathname: '/ResourcesManager',
                                  search: '?section=create',
                                  state: { detail: 'some_value' }
                              });}}
                                >
                                  <Typography variant="h6">{jsonlang.resourcemanager.toUpperCase()}</Typography>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        : <></>
                     }

                        {/* ============================FINE ResourcesManager */}
                        {/* ============================INIZIO citizen journalism */}
                        {currentconfig.Section!=null && currentconfig.Section["citizen"]!=null && currentconfig.Section["citizen"] ?

                        <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                          <div class="tile__media">
                            <img
                              alt="immagine copertina citizen joutrnalism"
                              className="tile__img"
                              name="socialmedia"
                              src="/images/sfondi-home/gs-citizen.png"
                              onClick={() => { this.props.history.push('/Journalism');}}
                            />
                          </div>
                          <div class="tile_no_details">
                            <div class="tile__title">
                              <Tooltip title={<h2>{jsonlang.citizenjournalism.toUpperCase()}</h2>}>
                              <Link
                                role="link"
                                name="socialmedia"
                                color="inherit"
                                component="button"
                                aria-label={jsonlang.citizenjournalism.toUpperCase()}
                                onClick={() => { this.props.history.push('/Journalism');}}
                                >
                                  <Typography variant="h6">{jsonlang.citizenjournalism.toUpperCase()}</Typography>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </div> : <></>
                      }
                      {/* ============================FINE citizen journalism */}

                        {/* ============================INIZIO SOCIAL MEDIA */}
                        {currentconfig.Section!=null && currentconfig.Section["SocialMedia"]!=null && currentconfig.Section["SocialMedia"] ?

                        <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                          <div class="tile__media">
                            <img
                              alt="immagine copertina socialmedia"
                              className="tile__img"
                              name="socialmedia"
                              src="/images/sfondi-home/social-media-engagement.jpg"
                              onClick={this.loadApp}
                            />
                          </div>
                          <div class="tile_no_details">
                            <div class="tile__title">
                              <Tooltip title={<h2>{jsonlang.socialmedia.toUpperCase()}</h2>}>
                              <Link
                                role="link"
                                name="socialmedia"
                                color="inherit"
                                component="button"
                                aria-label={jsonlang.socialmedia.toUpperCase()}
                                onClick={this.loadApp}
                                >
                                  <Typography variant="h6">{jsonlang.socialmedia.toUpperCase()}</Typography>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </div> : <></>
                      }
                      {/* ============================FINE SOCIAL MEDIA */}


                        {/* ============================INIZIO showroom  */}
                        {currentconfig.Section!=null && currentconfig.Section["Showroom"]!=null && currentconfig.Section["Showroom"] ?

                        <div class="tile">
                          <div class="tile__media">
                            <img alt="immagine copertina gallery" className="tile__img"
                              src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_explore.png" : "/images/sfondi-home/showroom.jpg"}
                            onClick={this.loadDialogShowRoomDetail}/>
                          </div>
                          <div class="tile_no_details"> {/* eliminare "no" per attivare classe overlay -> tile__details */}
                          <div class="tile__title">

                            <Tooltip title={<h2>{jsonlang.showroom.toUpperCase()}</h2>}>
                            <Link
                              role="link"
                              color="inherit"
                              component="button"
                              aria-label={jsonlang.showroom.toUpperCase()}
                              onClick={this.loadDialogShowRoomDetail}
                              >
                                <Typography variant="h6">{jsonlang.showroom.toUpperCase()}</Typography>
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      : <></>
                    }

                      {/* ============================FINE showroom  */}
                       {/* ============================INIZIO allmedia                        */}
                        {/* <div class="tile"> */}
                        {currentconfig.Section!=null && currentconfig.Section["allmedia"]!=null && currentconfig.Section["allmedia"] ?
                        <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                          <div class="tile__media">
                            <img
                              alt="immagine copertina gallery"
                              className="tile__img"
                              src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_allmedia.jpg" : "/images/sfondi-home/resource_manager.jpg"}
                              onClick={() => { this.props.history.push({
                                pathname: '/ResourcesManager',
                                search: '?section=allmedia',
                                state: { detail: 'some_value' }
                            });}}
                            />
                          </div>
                          <div class="tile_no_details">    
                            <div class="tile__title">
                              <Tooltip title={<h2>{jsonlang.allmedia.toUpperCase()}</h2>}>
                              <Link
                                role="link"
                                color="inherit"
                                component="button"
                                aria-label={jsonlang.allmedia.toUpperCase()}
                                onClick={() => { this.props.history.push({
                                  pathname: '/ResourcesManager',
                                  search: '?section=allmedia',
                                  state: { detail: 'some_value' }
                              });}}
                                >
                                  <Typography variant="h6">{jsonlang.allmedia.toUpperCase()}</Typography>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        : <></>
                     }

                        {/* ============================FINE allmedia */}

                      {/* ============================INIZIO storycatalogue   */}
                      {currentconfig.Section!=null && currentconfig.Section["Catalog"]!=null && currentconfig.Section["Catalog"] ?
                      <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"? "tile" : "tile disabled"}>
                        <div class="tile__media">
                          <img
                            alt="immagine copertina story catalogue"
                            name="storyapp" className="tile__img"
                            src={this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ? "/images/sfondi-home/gs_library.png" : "/images/sfondi-home/story_manager.jpg"}
                          onClick={() => { this.props.history.push('/Catalog');}}/>
                        </div>
                        <div class="tile_no_details">
                          <div class="tile__title">
                            <Tooltip title={<h2>{this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ?jsonlang.showroom.toUpperCase():jsonlang.storycatalogue.toUpperCase()}</h2>}>
                            <Link
                              name="storyapp"
                              role="link"
                              color="inherit"
                              component="button"
                              aria-label={jsonlang.storycatalogue.toUpperCase()}
                              // onClick={this.loadApp}
                              onClick={() => { this.props.history.push('/Catalog');}}
                              >
                                <Typography variant="h6">{this.props.appstate.App.name.toUpperCase() === "GREENSCENT" ?jsonlang.showroom.toUpperCase():jsonlang.storycatalogue.toUpperCase()}</Typography>
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      : <></>
                    }

                      {/* ============================FINE storycatalogue   */}

                        {/* ============================INIZIO poimanager   */}
                        {currentconfig.Section!=null && currentconfig.Section["POIManager"]!=null && currentconfig.Section["POIManager"] ?

                   <div className={this.props.appstate.App.user.logged === true &&  this.props.appstate.App.user.role!=="superadmin"  ? "tile" : "tile disabled"}>
                     <div class="tile__media">
                       <img
                         alt="immagine copertina socialmedia"
                         className="tile__img"
                         name="luoghi"
                         src="/images/sfondi-home/places.jpg"
                         onClick={() => { this.props.history.push('/Places');}}
                       />
                     </div>
                     <div class="tile_no_details">
                       <div class="tile__title">
                         <Tooltip title={<h2>{jsonlang.places.toUpperCase()}</h2>}>
                         <Link
                           role="link"
                           name="luoghi"
                           color="inherit"
                           component="button"
                           aria-label={jsonlang.places.toUpperCase()}
                           onClick={() => { this.props.history.push('/Places');}}
                           >
                             <Typography variant="h6">{jsonlang.places.toUpperCase()}</Typography>
                           </Link>
                         </Tooltip>
                       </div>
                     </div>
                   </div> : <></>
                 }
                   {/* ============================FINE poimanager   */}


                  </div>
                </div>

              </div>

            </>
            {/* <Box display="flex" justifyContent="center" m={1} p={1}>
            <Box>
            {'Copyright © '}
            <Link
            role="link"
            color="inherit"
            aria-label="SO CLOSE HOME PAGE"
            href="https://so-close.eu"

            target="blank"
            >
            <span>SO CLOSE</span>
          </Link>{' '}
          {new Date().getFullYear()}
          {' '}
        </Box>
      </Box> */}
      {/* </ThemeProvider> */}
    </div>
    {this.getFooter()}
  </div>
)
}
}

export default connect(mapStateToProps)(Home);
