import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import FlagsDialog from './FlagsDialog';
import LanDialog from './LanDialog';
import InfoDialog from './InfoDialog';
import languages from '../../config/languages.json';
import languages2 from '../../config/languages.json';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
import * as mediatypes from "../../Utils/mediatype.json";
import './ResourcesEditManager.css';

import {
  TextField,
  Tooltip,
  MenuItem,
  Switch,
  Grid,
  Divider,
  IconButton,
  OutlinedInput,
  Select,
  InputLabel,
  FormControl,
  ListItemIcon,
  Typography,
  Link,
  Checkbox,
  Toolbar,
  Button
} from "@material-ui/core";

import { getRights,getExternalMediaType } from "../../Utils/utility";
import RoomIcon from '@material-ui/icons/Room';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/Info';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};



class TextCloudHandler extends Component {
    constructor(props) {
      super(props);
      this.state = ({  
        dataLanguage:"", 
        text:"",       
        langfilter: new Array(this.props.acamar.configuration.Languages.list.length).fill(false),
        textstate:""
      })
    }
    componentDidMount() {
      this.init();      
    }
    init=(lan)=>{
      let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
      // this.props.dataform({})     
      let parseTextCloud;
      try {
        parseTextCloud = itemData.textcloudcontent;
      } catch (e) {
        parseTextCloud = "";
      } 
      this.setState({
        text: parseTextCloud,
        textstate: itemData.textcloudcontent ? itemData.textcloudcontent[this.getKeyLang(parseTextCloud)] : "",
        dataLanguage:itemData.textcloudcontent ? this.getKeyLang(parseTextCloud) : ""
      });
    }
    selectLanguage = (event) => {
      this.worldLangHandler(event.target.value);
    }
    callBackFlagsDialog = (lang) => {
      this.worldLangHandler(lang);
    }
    worldLangHandler = (lang) => {
      let langfiltercopy = this.state.langfilter.slice().fill(false);
      let currentText = this.state.text[lang.toLowerCase()];      
      if (!currentText) {
        currentText = "";
      }      
      this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy, textstate: currentText })
 
    }

      getKeyLang = (strinput) => {
        if (this.props.acamar.configuration.Languages && strinput) {
          let keylang = "en";
          if (strinput[this.props.acamar.configuration.Languages.default]) {
            return this.props.acamar.configuration.Languages.default;
          } else if (strinput[keylang]) {
            return keylang;
          } else {
            let allKey = Object.keys(strinput);
            if (allKey.length > 0) {
              return allKey[0];
            }
          }
        }
        return "";
      }

      textfiedlvalue = (event) => {
        this.setState({ textstate: event.target.value });
        }
      
      saveTextForLang = () => {
        let textCopy = Object.assign({}, this.state.text);
        if (this.state.textstate === "") {
          // se l'elemento di lingua esiste lo cancelllo altrimenti niente
          if (textCopy[this.state.dataLanguage]) {
            delete textCopy[this.state.dataLanguage]
          }
        } else {
          //creo o aggiorno l'elemento di lingua
          textCopy[this.state.dataLanguage] = this.state.textstate;
        }
        this.setState({ text: textCopy }, this.props.dataform(textCopy));
    
      }
      openFlagsDialog = () => {
        this.flagsDialogRef.init();
        this.props.appstate.FlagsDialog.render = true;
        this.props.appstate.FlagsDialog.openModalFlags = true;
        let textearr = Object.keys(this.state.text);
        // let descarr = Object.keys(this.state.description);
          this.props.appstate.FlagsDialog.items = textearr;
        this.props.dispatch(APP(this.props.appstate));
      }


    render() {
        let jsonlang = this.props.appstate.App.language;
        let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
        return (
          <>
          <FlagsDialog onRef={childthis => (this.flagsDialogRef = childthis)} callback={this.callBackFlagsDialog}/>
          <Grid item  xs={4}>
          <Toolbar>
            <Select
              style={{ minWidth: 200, maxHeight: 70}}
              labelId="media_languages_choose"
              id="media_languages_select"
              value={this.state.dataLanguage.toLowerCase()}
              onChange={this.selectLanguage}
              displayEmpty
              >
                <MenuItem disabled value="">
                  <em>{jsonlang.language.toUpperCase()}</em>
                </MenuItem>
                {languages2.map((item, index) =>
                  <MenuItem key={index} value={item.code} selected>
                    <ListItemIcon>
                      <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                    </ListItemIcon>
                    <Typography variant="inherit">{item.name}</Typography>
                  </MenuItem>
                )}
              </Select>
              <IconButton color="primary"
                role="button"
                size="small"
                aria-label={jsonlang.adda.toUpperCase()}
                onClick={this.openFlagsDialog}
                >
                  <PlaylistAddCheckIcon style={{ fontSize: 40 }}
                  />
                </IconButton>                
               
                  
            </Toolbar>
          </Grid> 
          <TextField
          variant="outlined"
          fullWidth
          id="textcloud"
          label="text"
          defaultValue=""
          onBlur={this.saveTextForLang}
          value={this.state.textstate}
          onChange={this.textfiedlvalue}/>
          </>
        )
    }
}


export default connect(mapStateToProps)(withRouter(TextCloudHandler));