import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as services from "../../services/services.json";
import { postService, deleteService, getService, multipartformService } from "../../services/services";
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RoomIcon from '@material-ui/icons/Room';
import {
    getResourceDataForLanguage
} from "../../Utils/utility";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Tooltip,
    Typography,
    Grid,
    Box,
    TextField,
    Checkbox,
    FormControlLabel,
    Fab,
    ImageList,
    ImageListItem,
    IconButton,
    ImageListItemBar,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from "@material-ui/core";
import { APP } from '../../redux/actions/actions';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogResourcesManagerAnnotator from "../ResourcesManager/DialogResourcesManagerAnnotator.jsx"
import DialogResourcesManager from "../ResourcesManager/DialogResourcesManager.jsx"
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import * as mediatypes from "../../Utils/mediatype.json";


const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});





class ChangeReportStatusDialog extends Component {
    constructor(props) {
        super(props);
        console.log("constructor create warning ")
        this.state = ({
            type: this.props.type, //tipo di dialog se edit o create 
            status: "",
            note: "",
            newStatus:"",
            item:{}
        });
    }



    init = (index) => {
        console.log("init change status")
        console.log(this.props.appstate.ResourceManager.resources[index])
        this.setState({
            type: this.props.type, //tipo di dialog se edit o create 
            status: this.props.appstate.ResourceManager.resources[index].status,
            note: this.props.appstate.ResourceManager.resources[index].note ? this.props.appstate.ResourceManager.resources[index].note : "",
            item:this.props.appstate.ResourceManager.resources[index],
            newStatus:""
        });
        // this.getTags()
    }

    componentDidMount() {
        this.props.onRef(this); //for the parent
    }

    //metodo per la chiusura della dialog
    onclose = () => {
        //this.props.appstate.ResourceManager.render = false;
        //  this.props.appstate.ResourceManager.openModalResourcesCatalog = false;
        //this.props.appstate.ResourceManager.edit = false;
        // this.props.appstate.ResourceManager.openModalIconLang = false;
        // this.props.appstate.ResourceManager.index = 0;
        // this.props.appstate.ResourceManager.id = 0;
        // this.props.appstate.ResourceManager.resourceInfo = {};
        // this.props.dispatch(APP(this.props.appstate));
        this.props.onClose();
    }


   
    saveChangeStatusWarnings = () => {
        let data = {};
        data.appname = this.props.appstate.App.name;
        data.domain = this.props.appstate.App.user.domain;
        data.warningid=this.state.item._id;
        data.status=this.state.newStatus; 
        data.note=this.state.note;     
        
        if (this.state.newStatus==="APPROVED"){
            console.log("AGGIUNGO DATI NODEBB")
            data.uid= this.state.item.ownerdetails[0].nodebb.uid; // uid del utente proprietario della segnalazione 
            data.title=this.state.item.name;
            data.content=this.state.item.description;
        }
        console.log("dati da inviare per cambio stato")
        console.log(data)           
        let self = this;
        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;
        this.props.dispatch(APP(this.props.appstate));
        let jsonlang = this.props.appstate.App.language;
        postService(process.env.REACT_APP_DOMAIN + services.warning_change_status_POST, data,
            "", "",
            function (err, result) {
                if (err) {
                    console.log("errore")
                    console.log(err)
                    self.props.appstate.Modal.openModalLoading = false;
                    self.props.appstate.Modal.openModalLoadingTextActive = true;
                    self.props.appstate.Modal.openModalLoadingText = jsonlang.change_status_error.toUpperCase();
                    self.props.dispatch(APP(self.props.appstate));
                    return;
                }
                if (result.status === 201) {
                  
                    
                    // if (result.response) {
                    //     self.props.appstate.ResourceManager.resources.unshift(result.response.ops[0]);
                    //     self.props.appstate.ResourceManager.annotatorinfo = Object.assign(
                    //         {},
                    //         result.response
                    //     );
                    // }
                    // self.props.dispatch(APP(self.props.appstate));
                    // self.props.callback();
                } else if (result.status === 200) {
                    console.log("stato 200")
                    console.log(result.response)
                    // self.props.appstate.ResourceManager.openModalResources = false;
                    self.props.appstate.ResourceManager.openModalResources = false;
                    self.props.appstate.Modal.openModalLoadingTextActive = true;
                    self.props.appstate.Modal.openModalLoadingText = jsonlang.change_status_ok.toUpperCase();
                    let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response.value._id);                    
                    self.props.appstate.ResourceManager.resources[itemIndex].status = result.response.value.status;
                    self.props.appstate.ResourceManager.resources[itemIndex].note = result.response.value.note;    
                    self.props.appstate.ResourceManager.resources[itemIndex].nodebb = result.response.value.nodebb;                    
                    self.props.dispatch(APP(self.props.appstate));
                    self.props.callback();
                    // self.onclose();
                } else {
                    
                    self.props.appstate.Modal.openModalLoading = false;
                    console.log("sono in else" + result.status);
                    self.props.dispatch(APP(this.props.appstate));
                }
            })
    }
    //gestisce input testuali 
    handleTextMethod = (e) => {
        this.setState({ note: e.target.value });
    }
    
    getStatusLabel = () => {
        let jsonlang = this.props.appstate.App.language;
        
        switch (this.state.status.toUpperCase()) {        
            case "INSERTED":
                return  jsonlang.inserted_report.toUpperCase();
            case "APPROVED":
                return  jsonlang.approved_report.toUpperCase();
            case "REJECTED":
                return  jsonlang.rejected_report.toUpperCase();
            case "SOLVED":
                return  jsonlang.solved_report.toUpperCase();
            default:
                break;
        }

    }
    handleChangeStatus = (e) => {
        console.log("handel status change ")
        console.log(e.target.value)
        this.setState({newStatus:e.target.value})
    }

    render() {

        let jsonlang = this.props.appstate.App.language;
        return (
            <>
                {this.props.renderCreate === true ?
                    <>
                        
                        <div className="iframe">
                            <Dialog
                                disableBackdropClick
                                disableEscapeKeyDown
                                TransitionComponent={Transition}
                                open={this.props.open}
                                onClose={this.onclose}
                                aria-labelledby="dialog-add"
                                fullWidth
                                maxWidth={'xl'}
                            >
                                <DialogTitle id="dialog-add">
                                    {jsonlang.change_status_title.toUpperCase() + ","+jsonlang.current_status.toUpperCase() +": \""+ this.getStatusLabel()+"\"" }
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Box margin={1}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={3}>
                                                <FormControl >
                                                    <InputLabel id="demo-simple-select-label">STATUS</InputLabel>
                                                    {/* {this.state.status === "INSERTED" ? */}
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            autoWidth={false}
                                                            value={this.state.newStatus}
                                                            onChange={this.handleChangeStatus}
                                                            style={{"width":"150px"}}
                                                          
                                                        >
                                                            <MenuItem value="APPROVED" disabled={this.state.status === "INSERTED" ? false:true}>{jsonlang.approved_report.toUpperCase()}</MenuItem>
                                                            <MenuItem value="REJECTED" disabled={this.state.status === "INSERTED" ? false:true}>{jsonlang.rejected_report.toUpperCase()}</MenuItem>
                                                            <MenuItem value="SOLVED" disabled={this.state.status !== "INSERTED" ? false:true}>{jsonlang.solved_report.toUpperCase()}</MenuItem>
                                                        </Select>
                                                        {/* :
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            
                                                            value={this.state.newStatus}
                                                            onChange={this.handleChangeStatus}
                                                            
                                                        >
                                                            <MenuItem value="SOLVED">{jsonlang.solved_report.toUpperCase()}</MenuItem>

                                                        </Select>
                                                    }                                                     */}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="descriptionStatus"
                                                    label={jsonlang.note.toUpperCase()}
                                                    defaultValue={this.state.note}
                                                    onChange={this.handleTextMethod}
                                                    multiline
                                                    inputProps={{ maxLength: 140 }}

                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={this.onclose}
                                        color="primary"
                                        startIcon={<ClearIcon />}>
                                        <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.instcancel}</Typography>
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        disabled={(this.state.newStatus!=="" && this.state.status!==this.state.newStatus) ? false:true}                                        
                                        variant="contained"
                                        size="small"
                                        onClick={this.saveChangeStatusWarnings}
                                        color="primary"
                                        startIcon={<SaveIcon />}>
                                        <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.save}</Typography>
                                        </Tooltip>
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                    </>
                    : <></>}
            </>
        )
    }
}
export default connect(mapStateToProps)(ChangeReportStatusDialog);
