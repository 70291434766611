import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Registration from "./components/Registration/Registration";
import Configuration from "./components/Configuration/Configuration";
import Settings from "./components/Settings/Settings";
import Resources from "./components/Resources/Resources";
import ResourcesManager from "./components/ResourcesManager/ResourcesManager";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore } from "./store/store";
import Header from "./components/Header/Header";
import IframeComp from "./components/Iframecomp";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  createMuiTheme,
  ThemeProvider,
  // responsiveFontSizes,
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import * as services from "./services/services.json";
import { getService } from "./services/services";
import { WHOLE, APP } from "./redux/actions/actions";
import load from 'little-loader';
import ShowRoomDetail from "./components/ShowRoom/ShowRoomDetail";
import Catalog from "./components/Catalog/Catalog";
import Places from "./components/Places/Places";
import Warning from "./components/Warnings/Warning";

//import { getConfigLink, getConfigContrast } from "./config/getConfig";


//const themefirst = 'http://localhost:3004/public/soclose/themes/theme_1620292898989_themefirst.js';
//const themelink = 'http://localhost:3004/public/soclose/themes/theme_1620292956329_themelink.js';

configureStore(function (store, persistor) {
  const Main = () => (
    <>
      <Provider store={store}>
        <Router>
          <Header id="Header" refresh={renderRefresh} />

          <Switch>
            {/*<Route exact path="/" render={() => <></>} />*/}
            <div style={{ paddingTop: 65 }}>
              <Route exact path="/" />
              <Route exact path="/Home" component={Home} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/Registration" component={Registration} />
              <Route exact path="/Configuration" component={Configuration} />
              <Route exact path="/Settings" component={Settings} />
              <Route exact path="/Resources" component={Resources} />
              <Route exact path="/ResourcesManager" component={ResourcesManager} />
              <Route exact path="/ShowRoomDetail" component={ShowRoomDetail} />
              <Route exact path="/Catalog" component={Catalog} />
              <Route exact path="/Places" component={Places} />
              <Route exact path="/App" component={IframeComp} />
              <Route exact path="/Journalism" component={Warning} />

            </div>
          </Switch>
        </Router>
      </Provider>
    </>
  );
  getConfiguration();
  function renderRefresh() {
    /*settingsAccessibility(store.getState().appstate); perchè questa chiamata ??? */
    ReactDOM.render(

      <ThemeProvider
        theme={
          store.getState().appstate.App.theme.refmaterial
        }
      >
        {store.getState().appstate.App.theme.valuethemelink === "yellow" ?
          <ThemeProvider
            theme={(outherTheme) => ({
              ...outherTheme, ...window.getYellowTheme()
            })}>
            <CssBaseline />
            <Main />
          </ThemeProvider>
          :
          store.getState().appstate.App.theme.valuethemelink === "bothyelunder" ?
            <ThemeProvider
              theme={(outherTheme) => ({
                ...outherTheme, ...window.getBothYelUnderTheme()
              })}>
              <CssBaseline />
              <Main />
            </ThemeProvider>
            :
            store.getState().appstate.App.theme.valuethemelink === "underline" ?
              <ThemeProvider
                theme={(outherTheme) => ({
                  ...outherTheme, ...window.getUnderlineTheme()
                })}>
                <CssBaseline />
                <Main />
              </ThemeProvider>
              :
              <ThemeProvider
                theme={(outherTheme) => ({
                  ...outherTheme, ...window.getNoTheme()
                })}>
                <CssBaseline />
                <Main />
              </ThemeProvider>
        }
      </ThemeProvider>,
      document.getElementById("header"),
      () => {
        //ReactDOM.render(<RouterContent />, document.getElementById("content"));
      }
    );
  }

  function getConfiguration() {
    //var thisRef = this;
    let params = new URLSearchParams(window.location.search);
    let appname = params.get("app");

    if (!appname) {
      appname = window.localStorage["currentapp"];
    }

    if (appname) {
      window.localStorage["currentapp"] = appname;
      getService(
        process.env.REACT_APP_DOMAIN + services.config_GET.replace("{APPNAME}", appname),
        "",
        function (err, result) {
          if (err) {
            console.log(err);
            return;
          }
          if (result.status === 200) {
            store.dispatch(WHOLE(result.response));
            let currentconfig = store.getState().appstate;
            currentconfig.App.name = result.response.app;
            /*reset bottoni dialog accessibility*/
            /*currentconfig.App.accessibility.valuecontrast="light";
            currentconfig.App.accessibility.valuecolored="colornormal";
            currentconfig.App.accessibility.valuelinks="linksdefault";
            currentconfig.App.accessibility.valuefont = "fontnormal";
            currentconfig.App.accessibility.valuefamilyfont = "familydefault";
            settingsAccessibility(currentconfig);
             console.log("value accessibility:" +JSON.stringify(currentconfig.App.accessibility));*/

            //console.log(process.env);
            //console.log(process.env['REACT_APP_THEMEFIRST_' + currentconfig.App.name]);
            //you will have to load the file js indicated in configuration
            load(process.env['REACT_APP_THEMEFIRST_' + currentconfig.App.name], function (err) {
              if (currentconfig.App.theme.valuethemefirst === "dark") {
                currentconfig.App.theme.themecontrast = window.getDarkTheme();
              }
              else {
                currentconfig.App.theme.themecontrast = window.getLightTheme();
              }
              //currentconfig.App.theme.themecontrast = window.getLightTheme();
              currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);
              //store.dispatch(WHOLE(result.response));
              // store.dispatch(APP(currentconfig));
              load(process.env['REACT_APP_THEMELINK_' + currentconfig.App.name], function (err) {
                if (currentconfig.App.user.userid !== "") {
                  console.log("render --- " + currentconfig.App.theme.valuethemefirst);
                  if (currentconfig.App.theme.valuethemefirst === "Lexend") {
                    currentconfig.App.theme.themecontrast = window.getLexend();
                    currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);
                  }
                  else if (currentconfig.App.theme.valuethemefirst === "LexendDark") {
                    currentconfig.App.theme.themecontrast = window.getLexendDark();
                    currentconfig.App.theme.refmaterial = createMuiTheme(currentconfig.App.theme.themecontrast);

                  }
                }
                currentconfig.App.theme.themelink = window.getNoTheme();
                store.dispatch(APP(currentconfig));
                let lang = result.response.configuration.Languages.default;
                console.log("lang " + lang);
                if (currentconfig.App.currentLanguage !== "" && lang !== currentconfig.App.currentLanguage)
                  lang = currentconfig.App.currentLanguage;
                getDefaultLanguage(result.response.app, lang);
              });

            });

          } else {
            renderRefresh();
          }
        }
      );
    } else {
      let currentconfig = store.getState().appstate;
      load(process.env['REACT_APP_THEMEFIRST_' + currentconfig.App.name], function (err) {
        currentconfig.App.theme.refmaterial = store.getState().appstate.App.theme.valuethemefirst === "light" ? createMuiTheme(window.getLightTheme()) : createMuiTheme(window.getDarkTheme())
        store.dispatch(APP(currentconfig));
        load(process.env['REACT_APP_THEMELINK_' + currentconfig.App.name], function (err) {
          /**
 * problemi asincronia - 12/10/22 -> spostato dispatch e chiamata (codice aggiunto)
 */
          currentconfig.App.accessibility.valuecontrast = "light";
          currentconfig.App.accessibility.valuecolored = "colornormal";
          currentconfig.App.accessibility.valuelinks = "linksdefault";
          currentconfig.App.accessibility.valuefont = "fontnormal";
          currentconfig.App.accessibility.valuefamilyfont = "familydefault";
          settingsAccessibility(currentconfig);
          renderRefresh(); //TEST
        });
      });
    }
  }

  function settingsAccessibility(currentconfig) {
    //qui da rigestire con l'utente
    if (currentconfig.App.user.userid !== "") {
      if (currentconfig.App.user.preferences) {
        // vi è il campo preferences
        if (currentconfig.App.user.preferences.language !== "") {
          currentconfig.App.currentLanguage = currentconfig.App.user.preferences.language;
        }
        if (currentconfig.App.user.preferences.accessibility) {
          currentconfig.App.accessibility.valuefont = currentconfig.App.user.preferences.accessibility.fontSize;
          currentconfig.App.accessibility.valuecontrast = currentconfig.App.user.preferences.accessibility.contrast;
          currentconfig.App.accessibility.valuecolored = currentconfig.App.user.preferences.accessibility.yellowLinks;
          currentconfig.App.accessibility.valuelinks = currentconfig.App.user.preferences.accessibility.underlineLinks;
          currentconfig.App.accessibility.valuefamilyfont = currentconfig.App.user.preferences.accessibility.familyFont;
          //settaggio temi
          console.log(currentconfig.App.user.preferences.accessibility.familyFont + " " + currentconfig.App.accessibility.valuecontrast);
          if (currentconfig.App.user.preferences.accessibility.familyFont === "Lexend" &&
            currentconfig.App.accessibility.valuecontrast === "light") {
            currentconfig.App.theme.valuethemefirst = "Lexend";
          } else
            if (currentconfig.App.user.preferences.accessibility.familyFont === "Lexend" &&
              currentconfig.App.accessibility.valuecontrast === "dark") {
              currentconfig.App.theme.valuethemefirst = "LexendDark";
            }
            else if (currentconfig.App.user.preferences.accessibility.familyFont === "familydefault") {
              if (currentconfig.App.accessibility.valuecontrast === "dark") {
                currentconfig.App.theme.valuethemefirst = "dark";
              }
              else {
                currentconfig.App.theme.valuethemefirst = "light";
              }
            }

          if (currentconfig.App.accessibility.valuecolored === "coloryellow") {
            if (currentconfig.App.accessibility.valuelinks === "linksdefault") {
              currentconfig.App.theme.valuethemelink = "yellow";
            }
            else {
              currentconfig.App.theme.valuethemelink = "bothyelunder";
            }
          }
          else if (currentconfig.App.accessibility.valuecolored === "colornormal") {
            if (currentconfig.App.accessibility.valuelinks === "linksdefault") {
              currentconfig.App.theme.valuethemelink = "notheme";
            }
            else {
              currentconfig.App.theme.valuethemelink = "underline";
            }
          }
          else if (currentconfig.App.accessibility.valuelinks === "linksunderline") {
            if (currentconfig.App.accessibility.valuecolored === "coloryellow") {
              currentconfig.App.theme.valuethemelink = "bothyelunder";
            }
            else {
              currentconfig.App.theme.valuethemelink = "underline";
            }
          } else {
            if (currentconfig.App.accessibility.valuecolored === "coloryellow") {
              currentconfig.App.theme.valuethemelink = "yellow";
            }
            else {
              currentconfig.App.theme.valuethemelink = "notheme";
            }
          }
        }
      } else {
        //sono loggato ma non vi è il campo preferences
        currentconfig.App.accessibility.valuecontrast = "light";
        currentconfig.App.accessibility.valuecolored = "colornormal";
        currentconfig.App.accessibility.valuelinks = "linksdefault";
        currentconfig.App.accessibility.valuefont = "fontnormal";
        currentconfig.App.accessibility.valuefamilyfont = "familydefault";
        currentconfig.App.theme.valuethemefirst = "light";
        currentconfig.App.theme.valuethemelink = "notheme";
      }
    }
    else {
      //non sono loggato
      if (currentconfig.App.accessibility.valuecolored === "colornormal" &&
        currentconfig.App.accessibility.valuelinks === "linksdefault") {
        currentconfig.App.theme.valuethemelink = "notheme";
      }
      if (currentconfig.App.accessibility.valuecontrast === "light" &&
        currentconfig.App.accessibility.valuefamilyfont === "familydefault") {
        currentconfig.App.theme.valuethemefirst = "light";
      }

    }
    store.dispatch(APP(currentconfig));
    //return currentconfig;

  }

  function getDefaultLanguage(appname, defaultlang) {
    let serviceurl =
      process.env.REACT_APP_DOMAIN + services.language_GET.replace("{APPNAME}", appname);
    serviceurl = serviceurl.replace("{LANG}", defaultlang);
    getService(serviceurl, "", function (err, result) {
      if (err) {
        return;
      }
      let currentconfig = store.getState().appstate;
      if (result.status === 200) {
        currentconfig.App.currentLanguage = defaultlang;
        currentconfig.App.language = result.response.labels.language;
        //store.dispatch(APP(currentconfig)); è gia presente in settingsAccessibility
      }
      /**
       * problemi asincronia - 12/10/22 -> spostato dispatch e chiamata (codice aggiunto)
       */
      currentconfig.App.accessibility.valuecontrast = "light";
      currentconfig.App.accessibility.valuecolored = "colornormal";
      currentconfig.App.accessibility.valuelinks = "linksdefault";
      currentconfig.App.accessibility.valuefont = "fontnormal";
      currentconfig.App.accessibility.valuefamilyfont = "familydefault";
      settingsAccessibility(currentconfig);
      //console.log("value accessibility:" +JSON.stringify(currentconfig.App.accessibility));
      renderRefresh(); //già presente
    });
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
