import {
  IconButton,
  InputBase,
  Divider,
  // Tabs,
  // Tab,
  AppBar,
  // InputAdornment,
  Toolbar,
  // Switch,
  Tooltip,
  // TextField,
  Fab,
  Card,
  CardHeader,
  CardMedia,
  Typography,
  Avatar,
  // CardContent,
  CardActions,
  Checkbox,
  Grid,
  // Link,
  ListItem

} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  faClosedCaptioning,
  faCopyright,
  // faBan,
} from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@material-ui/icons/Add";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import CancelRounded from "@material-ui/icons/CancelRounded";
// import CropFreeIcon from "@material-ui/icons/CropFree";
// import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import Search from "@material-ui/icons/Search";
import RoomIcon from "@material-ui/icons/Room";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
// import Audiotrack from "@material-ui/icons/Audiotrack";
// import Apps from "@material-ui/icons/Apps";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
// import DeleteIcon from "@material-ui/icons/Delete";
// import EditIcon from "@material-ui/icons/Edit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { APP } from "../../redux/actions/actions";
import EventNoteIcon from '@material-ui/icons/EventNote';
import {
  getService,
  // deleteService,
  postService,
  // multipartformService,
} from "../../services/services";
import * as services from "../../services/services.json";
import "./Catalog.css";
import DialogDisclaimer from '../Dialog/DialogDisclaimer.jsx';
import DialogResourcesManager from "../Catalog/DialogResourcesManager";
import { GeoMap, loadMarkers } from "./mapcomp";
import MapDialog from "./MapDialog";
import PreviewDialog from "./PreviewDialog";
import ShareIcon from "@material-ui/icons/Share";
// import ImageIcon from "@material-ui/icons/Image";
// import CropOriginalIcon from "@material-ui/icons/CropOriginal";
// import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
// import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
// import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  getRights,
  itemsSort,
  itemsSortInverse,
  itemsSortDate,
  itemsSortInverseDate,
  getExternalMediaType,
  getResourceDataForLanguage
} from "../../Utils/utility";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Chip from "@material-ui/core/Chip";
import DescriptionIcon from "@material-ui/icons/Description";
import DialogDelete from "../Dialog/DialogDelete";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DialogRights from "../Catalog/DialogRights";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import MenuCard from '../Catalog/MenuCard';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';

const mapStateToProps = (state) => {
  return {
    acamar: state.acamar,
    appstate: state.appstate,
  };
};

class Catalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [] ,//this.props.appstate.ResourceManager.resources,
      showtile: {},
      mapmark: {},
      map: false,
      audiofilter: false,
      videofilter: false,
      imagefilter: false,
      documentfilter: false,
      filtersarray: [],
      publicRight: false,
      ccRight: false,
      otherRight: false,
      mineFilter:false,
      rightSelected: "",
      copyResState: [],
      searchValue: "",
      datedown: true,
      dateup: false,
      namedown: false,
      nameup: false,
      orderSelected: "datedown",
      openDisclaimer:false,

    };
  }
  componentDidMount() {
    this.props.appstate.ResourceManager.resources=[];
    this.props.dispatch(APP(this.props.appstate));
    this.getResources();
  }

  getResources = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.appstate.ResourceManager.fromannotator = false;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let ser =
    process.env.REACT_APP_DOMAIN +
    services.resourcemanager_GET_AUTH.replace(
      "{APPNAME}",
      this.props.appstate.App.name
    );
    //ser = ser.replace("{APPNAME}", this.props.appstate.App.name);
    //ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DEST}", "resource");
    ser=ser+"&type=webcloud"
    getService(ser, "", function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText =
        self.props.appstate.App.language.resourcerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      if (result.status === 200) {
        self.props.appstate.Modal.openModalLoading = false;
        let orderArray = self.order(
          self.state.orderSelected,
          false,
          result.response
        );
        // let orderArray= itemsSortDate("modifiedAt",result.response);
        self.props.appstate.ResourceManager.resources = orderArray;
        self.props.dispatch(APP(self.props.appstate));
        self.setState({ items: self.props.appstate.ResourceManager.resources });
        return;
      }
      self.props.appstate.Modal.openModalLoadingTextActive = true;
      self.props.appstate.Modal.openModalLoadingText =
      self.props.appstate.App.language.noresource;
      self.props.dispatch(APP(self.props.appstate));
      return;
    });
  };

  upload = () => {
    this.resourcesManagerDialogRef.init();
    this.mapDialogRef.init();
    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    this.props.appstate.ResourceManager.render = true;
    this.props.appstate.ResourceManager.openModalResourcesCatalog = true;
    this.props.appstate.ResourceManager.fromannotator = false;
    this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
    this.props.dispatch(APP(this.props.appstate));
  };

  edit = (event) => {
    this.resourcesManagerDialogRef.init();
    this.mapDialogRef.init();
    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    // ricerca l'indice giusto in resources
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      (element) => element._id === event
    );
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    this.props.appstate.ResourceManager.index = indexItems;
    this.props.appstate.ResourceManager.id = event;
    this.props.appstate.ResourceManager.edit = true;
    this.props.appstate.ResourceManager.render = true;
    this.props.appstate.ResourceManager.openModalResourcesCatalog = true;
    this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
    this.props.dispatch(APP(this.props.appstate));
  };
  openRightsDetail = (event) => {
    console.log("openRightsDetail")

    //this.setState({ items: this.props.appstate.ResourceManager.resources });
    // ricerca l'indice giusto in resources
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      (element) => element._id === event.currentTarget.id
    );
    // this.resourcesManagerDialogRef.init(this.props.appstate.ResourceManager.resources[indexItems]);
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    console.log(this.props.appstate.ResourceManager.resources[indexItems])
    this.props.appstate.DialogRights.item = this.props.appstate.ResourceManager.resources[indexItems];
    this.props.appstate.DialogRights.open = true;
    this.props.dispatch(APP(this.props.appstate));
  };
  // delete = (event) => {
  //   //console.log(event.currentTarget.id);
  //   this.props.appstate.Modal.openModalDelete = true;
  //   this.props.appstate.Modal.openModalType = "manager";
  //   let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
  //     (element) => element._id === event.currentTarget.id
  //   );
  //   // this.props.appstate.ResourceManager.index = event.currentTarget.name;
  //   this.props.appstate.ResourceManager.index = indexItems;
  //   this.props.appstate.ResourceManager.id = event.currentTarget.id;
  //   const itemcopy = Object.assign(
  //     {},
  //     this.state.items[event.currentTarget.name]
  //   );
  //   itemcopy.clientcover = this.coverUrl(event.currentTarget.id, Object.keys(this.state.items[event.currentTarget.name].medialan)[0]);
  //   this.props.appstate.ResourceManager.resourceInfo = itemcopy;
  //   this.props.dispatch(APP(this.props.appstate));
  // };

  // urlCopy = (event) => {
  //   //console.log(event.currentTarget.id);
  //   let index = event.currentTarget.id;
  //   if (index) {
  //     const temp = document.createElement("input");
  //     document.body.appendChild(temp);
  //     temp.value = this.resourceUrl(index,Object.keys(this.state.items[event.currentTarget.name].medialan)[0] );
  //     temp.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(temp);

  //     this.previewDialogRef.init();
  //     this.props.appstate.PreviewDialog.openModalPreview = true;
  //     this.props.appstate.PreviewDialog.render = true;
  //     this.props.appstate.PreviewDialog.item =this.state.items[event.currentTarget.name];
  //     this.props.appstate.PreviewDialog.share = true;
  //     if (this.state.items[event.currentTarget.name].type==="webcloud"){
  //       this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].url;
  //     } else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[event.currentTarget.name].streamextloaded===true){
  //       this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].streamext;
  //     }else{
  //       this.props.appstate.PreviewDialog.itemurl = temp.value;
  //     }
  //     this.props.dispatch(APP(this.props.appstate));
  //   }
  // };
  delete = (id , name) => {
    // console.log(event);

    this.props.appstate.Modal.openModalDelete = true;
    this.props.appstate.Modal.openModalType = "manager";
    let indexItems = this.props.appstate.ResourceManager.resources.findIndex(
      // (element) => element._id === event.currentTarget.id
      (element) => element._id === id
    );
    // this.props.appstate.ResourceManager.index = event.currentTarget.name;
    this.props.appstate.ResourceManager.index = indexItems;
    // this.props.appstate.ResourceManager.id = event.currentTarget.id;
    this.props.appstate.ResourceManager.id = id;
    const itemcopy = Object.assign(
      {},
      // this.state.items[event.currentTarget.name]
      this.state.items[name]
    );
    // itemcopy.clientcover = this.coverUrl(event.currentTarget.id, Object.keys(this.state.items[event.currentTarget.name].medialan)[0]);
    itemcopy.clientcover = this.coverUrl(id, Object.keys(this.state.items[name].medialan)[0]);
    this.props.appstate.ResourceManager.resourceInfo = itemcopy;
    this.props.dispatch(APP(this.props.appstate));
  };

  handleClickOpenDisclaim = () => {

    this.setState({openDisclaimer:true});
    this.dialogDisclaimer.init(this.props.appstate.App.user.domain);
  }

  handleCloseDisclaim = () => {
    this.setState({openDisclaimer:false});
  };

  urlCopy = (event) => {
    //console.log(event.currentTarget.id);
    let index = event.currentTarget.id;
    if (index) {
      const temp = document.createElement("input");
      document.body.appendChild(temp);
      temp.value = this.resourceUrl(index,Object.keys(this.state.items[event.currentTarget.name].medialan)[0] );
      temp.select();
      document.execCommand("copy");
      document.body.removeChild(temp);

      this.previewDialogRef.init();
      this.props.appstate.PreviewDialog.openModalPreview = true;
      this.props.appstate.PreviewDialog.render = true;
      this.props.appstate.PreviewDialog.item =this.state.items[event.currentTarget.name];
      this.props.appstate.PreviewDialog.share = true;
      if (getExternalMediaType().findIndex(x => x.value == this.state.items[event.currentTarget.name].type)!==-1){
        let selectedUri= this.state.items[event.currentTarget.name].url;
        let url="";
          if (typeof(selectedUri)==='string'){ //retrocompatibilità
            url = selectedUri;
          }else {
            let itemHELP={};
            itemHELP.medialan=selectedUri;
            let lang =  getResourceDataForLanguage(itemHELP);
            url = selectedUri[lang];
          }

        this.props.appstate.PreviewDialog.itemurl = url;
      } else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[event.currentTarget.name].streamextloaded===true){
        this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].streamext;
      }else{
        this.props.appstate.PreviewDialog.itemurl = temp.value;
      }
      this.props.dispatch(APP(this.props.appstate));
    }
  };
  imageOnMouseOver = (event) => {
    //console.log(event.currentTarget.id);
    let showtile = {};
    showtile[event.currentTarget.id] = true;
    this.setState({ showtile: showtile });
  };

  imageOnMouseOut = (event) => {
    //console.log(event.currentTarget.id);
    let showtile = {};
    showtile[event.currentTarget.id] = false;
    this.setState({ showtile: showtile });
  };

  searchInput = (event) => {
    if (event.target.value.length > 0) {
      let self = this;
      let filtered = this.props.appstate.ResourceManager.resources.filter(
        function (el, index, arr) {
          let found = false;
          let activeFilter = [];
          if (
            el.name
            .toUpperCase()
            .indexOf(event.currentTarget.value.toUpperCase()) !== -1
          ) {
            activeFilter.push("name");
            found = true;
          }
          if (el.description){
          let keysdesc = Object.keys(el.description);
          if (keysdesc.length>0){
            for (let i = 0; i < keysdesc.length; i++) {
              if (
                el.description[keysdesc[i]]
                .toUpperCase()
                .indexOf(event.currentTarget.value.toUpperCase()) !== -1
              ) {
                activeFilter.push("description");
                found = true;
                break;
              }
            }
          }
        }
          /* if ((el.description[self.getKeyLang(el.description)].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
          activeFilter.push("description");
          found = true;
        }*/
        if (el.title){
        let keystitle = Object.keys(el.title);
        if (keystitle.length>0){
          for (let i = 0; i < keystitle.length; i++) {
            if (
              el.title[keystitle[i]]
              .toUpperCase()
              .indexOf(event.currentTarget.value.toUpperCase()) !== -1
            ) {
              activeFilter.push("title");
              found = true;
              break;
            }
          }
        }
      }
        /* if ((el.title[self.getKeyLang(el.title)].toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1) {
        activeFilter.push("title");
        found = true;
      }*/

      if (el.tags) {
        if (
          self
          .getKeyLang(el.tags)
          .toUpperCase()
          .indexOf(event.currentTarget.value.toUpperCase()) !== -1
        ) {
          activeFilter.push("tags");
          found = true;
        }
      }
      el.filter = activeFilter;
      return found;
    }
  );
  this.setState(
    { items: filtered, searchValue: event.target.value },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
} else {
  this.setState(
    {
      items: this.props.appstate.ResourceManager.resources,
      searchValue: event.target.value,
    },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
}
};

searchInputCancel = () => {
  // document.getElementById("resourcesearchinput").value = "";
  this.setState(
    { items: this.props.appstate.ResourceManager.resources, searchValue: "" },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
};

filterselection = (event, value) => {
  switch (event.currentTarget.name) {
    case "image":
    this.setState(
      { imagefilter: this.state.imagefilter === true ? false : true },
      () => {
        this.filterengine(
          ["image", "imagecloud"],
          this.state.imagefilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "audio":
    this.setState(
      { audiofilter: this.state.audiofilter === true ? false : true },
      () => {
        this.filterengine(
          ["audio", "audiocloud"],
          this.state.audiofilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "video":
    this.setState(
      { videofilter: this.state.videofilter === true ? false : true },
      () => {
        this.filterengine(
          ["video", "videocloud"],
          this.state.videofilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "map":
    this.setState({ map: this.state.map === true ? false : true }, () => {
      this.filterengine(undefined, undefined, undefined, "");
    }); // caso da controllare
    // this.filterengine();
    break;
    case "publicright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: !event.target.checked,
          otherRight: !event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "publicright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;
    case "ccright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: !event.target.checked,
          ccRight: event.target.checked,
          otherRight: !event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "ccright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;
    case "otherright":
    if (event.target.checked) {
      this.setState(
        {
          publicRight: !event.target.checked,
          ccRight: !event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "otherright");
        }
      );
    } else {
      this.setState(
        {
          publicRight: event.target.checked,
          ccRight: event.target.checked,
          otherRight: event.target.checked,
        },
        () => {
          this.filterengine(undefined, undefined, undefined, "");
        }
      );
    }
    break;
    case "document":
    this.setState(
      { documentfilter: this.state.documentfilter === true ? false : true },
      () => {
        this.filterengine(
          ["document", "filecloud", "webcloud", "documentcloud"],
          this.state.documentfilter,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    case "mineFilter":
    this.setState(
      { mineFilter: this.state.mineFilter === true ? false : true },
      () => {
        this.filterengine(
          undefined,
          undefined,
          undefined,
          this.state.rightSelected
        );
      }
    );
    break;
    default:
    break;
  }
};

openAnnotator = (event, value) => {
  console.log("url di rifermento ")
  let urlToModify=this.state.items[event.currentTarget.value].url+"&userid="+this.props.appstate.App.user.userid;
  let urlEdit=urlToModify.replace("/use?","/learning?" )
  console.log(urlEdit);
  let  urlParams = new URLSearchParams(urlEdit);

  this.props.history.push('/App');
  this.props.appstate.App.currentappchild = services.annotator;
  // this.props.appstate.Annotator.fsname = this.state.items[event.currentTarget.value].fsname;
  this.props.appstate.Annotator.resType = urlParams.get('resType') ;
  this.props.appstate.Annotator.itemname = urlParams.get('processId');
  this.props.appstate.Annotator.resourceid = urlParams.get('id');
  this.props.appstate.Annotator.editUrl= urlEdit;
  this.props.appstate.Annotator.annotatorMode = "directEdit";
  // if (this.props.appstate.App.user.domainDetail.backupaws === true && this.state.items[event.currentTarget.value].streamextloaded === true) {
  //   this.props.appstate.Annotator.uri = this.state.items[event.currentTarget.value].streamext;
  // } else {
  //   this.props.appstate.Annotator.uri = this.resourceUrl(this.state.items[event.currentTarget.value]._id, Object.keys(this.state.items[event.currentTarget.value].medialan)[0]);
  // }
  // this.props.dispatch(APP(this.props.appstate));
}

openMapFromCard = (event, value) => {
  if (this.mapref && window.google.maps) {
    this.mapref.setCenter(
      new window.google.maps.LatLng(
        this.state.items[event.currentTarget.name].location.coordinates[1],
        this.state.items[event.currentTarget.name].location.coordinates[0]
      )
    );
  }
  this.setState({
    map: true,
    openmapfromcard: true,
    openmapfromcardpos: [
      this.state.items[event.currentTarget.name].location.coordinates[1],
      this.state.items[event.currentTarget.name].location.coordinates[0],
    ],
  });
};
filterMyRes=(mineFilterStatus , el )=>{
  console.log("filterMyRes" + "filterstatus: " + mineFilterStatus + "owner: "+el.owner )
  if (mineFilterStatus){
    return el.owner===this.props.appstate.App.user.userid;
  }else {
    return true;
  }
}
filterengine = (type, flag, mapbound, rightSel) => {
  let filarr = this.state.filtersarray.slice(
    0,
    this.state.filtersarray.length
  );

  // if (type) {
  //   let indexel = filarr.indexOf(type);
  //   if (indexel !== -1) {
  //     filarr.splice(indexel, 1);
  //   }
  //   if (flag === true) {
  //     filarr.push(type.toLowerCase());
  //   }
  // }

  if (type) {
    for (var i = 0; i < type.length; i++) {
      let indexel = filarr.indexOf(type[i]);
      if (indexel !== -1) {
        filarr.splice(indexel, 1);
      }
      if (flag === true) {
        filarr.push(type[i].toLowerCase());
      }
    }
  }

  var filtered = [];
  var copyResources = [];
  if (this.state.searchValue !== "") {
    copyResources = this.state.items.slice();
  } else {
    copyResources = this.props.appstate.ResourceManager.resources.slice(
      0,
      this.props.appstate.ResourceManager.resources.length
    );
  }
  let self = this;
  if (filarr.length > 0 && rightSel === "") {
    //one or more filter activated
    filtered = copyResources.filter(function (el, index, arr) {
      if (
        self.state.map === true &&
        mapbound !== undefined &&
        self.mapref &&
        parseFloat(el.location.coordinates[1]) !== 0 &&
        parseFloat(el.location.coordinates[0]) !== 0 &&
        typeof mapbound.contains === "function"
      ) {
        return (
          filarr.indexOf(el.type) !== -1 &&
          mapbound.contains(
            new window.google.maps.LatLng(
              parseFloat(el.location.coordinates[1]),
              parseFloat(el.location.coordinates[0])
            )
          )&& self.filterMyRes(self.state.mineFilter,el)
        );
      } else if (self.state.map === true && self.mapref) {
        return (
          filarr.indexOf(el.type) !== -1 &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
        );
      } else {
        return filarr.indexOf(el.type) !== -1 && self.filterMyRes(self.state.mineFilter,el);
      }
    });

    this.setState(
      {
        items: self.order(self.state.orderSelected, false, filtered),
        filtersarray: filarr,
        rightSelected: rightSel,
      },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );
  } else if (filarr.length > 0 && rightSel !== "") {
    switch (rightSel) {
      case "publicright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights === getRights("CC0").value &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "ccright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value&&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "otherright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)&& self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            filarr.indexOf(el.type) !== -1 &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)
            &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            filarr.indexOf(el.type) !== -1 &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value) && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      default:
      break;
    }
  } else if (filarr.length === 0 && rightSel !== "") {
    switch (rightSel) {
      case "publicright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) && el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            el.edmRights.dcRights === getRights("CC0").value &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return el.edmRights.dcRights === getRights("CC0").value && self.filterMyRes(self.state.mineFilter,el);
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "ccright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value&&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (
            el.edmRights.dcRights !== getRights("CC0").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT").value&&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EU ORPHAN WORK").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value &&
            el.edmRights.dcRights !== getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value && self.filterMyRes(self.state.mineFilter,el)
          );
        }
      });
      this.setState(
        {
          items: self.order(self.state.orderSelected, false, filtered),
          filtersarray: filarr,
          rightSelected: rightSel,
        },
        () => {
          loadMarkers(this.state.items, this.mapref, this);
        }
      );
      break;
      case "otherright":
      filtered = copyResources.filter(function (el, index, arr) {
        if (
          self.state.map === true &&
          mapbound !== undefined &&
          self.mapref &&
          parseFloat(el.location.coordinates[1]) !== 0 &&
          parseFloat(el.location.coordinates[0]) !== 0 &&
          typeof mapbound.contains === "function"
        ) {
          return (
            mapbound.contains(
              new window.google.maps.LatLng(
                parseFloat(el.location.coordinates[1]),
                parseFloat(el.location.coordinates[0])
              )
            ) &&
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value) && self.filterMyRes(self.state.mineFilter,el)
          );
        } else if (self.state.map === true && self.mapref) {
          return (
            (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
            el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value)
            &&
            parseFloat(el.location.coordinates[1]) !== 0 &&
            parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
          );
        } else {
          return (el.edmRights.dcRights === getRights("IN COPYRIGHT").value||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - EU ORPHAN WORK").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - EDUCATIONAL USE PERMITTED").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - NON-COMMERCIAL USE PERMITTED").value ||
          el.edmRights.dcRights === getRights("IN COPYRIGHT - RIGHTS-HOLDER(S) UNLOCATABLE OR UNIDENTIFIABLE").value
        ) && self.filterMyRes(self.state.mineFilter,el)
      }
    });
    this.setState(
      {
        items: self.order(self.state.orderSelected, false, filtered),
        filtersarray: filarr,
        rightSelected: rightSel,
      },
      () => {
        loadMarkers(this.state.items, this.mapref, this);
      }
    );
    break;
    default:
    break;
  }
} else if (
  self.state.map === true &&
  mapbound !== undefined &&
  self.mapref &&
  typeof mapbound.contains === "function"
) {
  //fov map filter activated
  filtered = copyResources.filter(function (el, index, arr) {
    return (
      mapbound.contains(
        new window.google.maps.LatLng(
          parseFloat(el.location.coordinates[1]),
          parseFloat(el.location.coordinates[0])
        )
      ) &&
      parseFloat(el.location.coordinates[1]) !== 0 &&
      parseFloat(el.location.coordinates[0]) !== 0 &&
      el.location !== undefined && self.filterMyRes(self.state.mineFilter,el)
    );
  });
  this.setState(
    {
      items: self.order(self.state.orderSelected, false, filtered),
      filtersarray: filarr,
      rightSelected: rightSel,
    },
    () => {
      loadMarkers(this.state.items, this.mapref, this);
    }
  );
} else if (self.state.map === true && self.mapref) {
  filtered = copyResources.filter(function (el, index, arr) {
    return (
      parseFloat(el.location.coordinates[1]) !== 0 &&
      parseFloat(el.location.coordinates[0]) !== 0 && self.filterMyRes(self.state.mineFilter,el)
    );
  });
  this.setState(
    { items: filtered, filtersarray: filarr, rightSelected: rightSel },
    () => {
      loadMarkers(this.state.items, this.mapref, this);
    }
  );
} else {
  //no filter
  filtered = copyResources.filter(function (el, index, arr) {
    return (
      self.filterMyRes(self.state.mineFilter,el)
    );
  });
  this.setState(
    { items: filtered, filtersarray: filarr, rightSelected: rightSel },
    () => {
      loadMarkers(this.state.items, this.mapref, this);
    }
  );
}

//   this.setState(
//     {
//       items: self.order(self.state.orderSelected, false, copyResources),
//       filtersarray: filarr,
//       rightSelected: rightSel,
//     },
//     () => {
//       loadMarkers(this.state.items, this.mapref, this);
//     }
//   );
// }
// if (filarr.length > 0) { //one or more filter activated
//   filtered = copyResources.filter(function (el, index, arr) {    // casi qui dentro devo metterli ok
//     if (self.state.map === true && mapbound !== undefined && self.mapref && el.location.coordinates[1] !== 0 && el.location.coordinates[0] !== 0 && typeof mapbound.contains === 'function') {
//       return (filarr.indexOf(el.type) !== -1 && mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))))
//     } else {
//       return (filarr.indexOf(el.type) !== -1)
//     }
//   });
//   this.setState({ items: filtered, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
// } else if (self.state.map === true && mapbound !== undefined && self.mapref && typeof mapbound.contains === 'function') { //fov map filter activated
//   filtered = copyResources.filter(function (el, index, arr) {
//     return (mapbound.contains(new window.google.maps.LatLng(parseFloat(el.location.coordinates[1]), parseFloat(el.location.coordinates[0]))) && el.location.coordinates[1] !== 0 && el.location.coordinates[0] !== 0 && el.location !== undefined)
//   })
//   this.setState({ items: filtered, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
// }else { //no filter
//   this.setState({ items: copyResources, filtersarray: filarr }, () => { loadMarkers(this.state.items, this.mapref, this) });
// }
};

coverUrl = (id,medialan) => {
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", true);
  ser = ser + "&t=" + new Date().getTime();
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

avatarUrl = (id) => {
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.avatar_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{USERID}", id);
  return ser;
};

resourceUrl = (id,medialan) => {
  console.log("resourceUrl")
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", false);
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

mapCallback = (type, result, mapbound) => {
  switch (type) {
    case "drawcompleted":
    break;
    case "ref":
    this.mapref = result;
    loadMarkers(this.state.items, this.mapref, this);
    //this.filterengine(undefined, undefined, mapbound);
    break;
    case "mapBoundDragend":
    case "mapBoundZoomChanged":
    case "mapBoundonTilesLoaded":
    this.filterengine(undefined, undefined, result, "");
    if (this.state.openmapfromcard && window.google.maps && this.mapref) {
      this.mapref.setCenter(
        new window.google.maps.LatLng(
          this.state.openmapfromcardpos[0],
          this.state.openmapfromcardpos[1]
        )
      );
      this.setState({ openmapfromcard: false, openmapfromcardpos: [] });
    }
    /*if (this.state.coords) {
    this.mapref.panTo(this.state.coords);
  }*/

  /*if (this.state.loadpoint === true) {
  this.mapref.panTo(new window.google.maps.LatLng(this.state.items[event.currentTarget.name].location.coordinates[1], this.state.items[event.currentTarget.name].location.coordinates[0])) : <></>
}*/
break;
default:
break;
}
};

mapMarkerCallback = (type, id, index) => {
  if (type === "delete") {
    this.props.appstate.Modal.openModalDelete = true;
    this.props.appstate.Modal.openModalType = "manager"; //to recognize from resources "emperor"
    this.props.appstate.ResourceManager.index = index;
    this.props.appstate.ResourceManager.id = id;
    const itemcopy = Object.assign({}, this.state.items[index]);
    itemcopy.clientcover = this.coverUrl(id,Object.keys(this.state.items[index].medialan)[0]);
    this.props.appstate.ResourceManager.resourceInfo = itemcopy;
    this.props.dispatch(APP(this.props.appstate));
  }
  //let mapmark = {};
  //mapmark[id] = true;
  //this.setState({ mapmark: mapmark});
};

resourcePreview = (event) => {
  console.log("resourcePreview")
  let index = event.currentTarget.name;
  if (!index) {
    index = event.target.id;
  }
  this.previewDialogRef.init();
  this.props.appstate.PreviewDialog.openModalPreview = true;
  this.props.appstate.PreviewDialog.render = true;
  this.props.appstate.PreviewDialog.item = this.state.items[index];
  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[index].streamextloaded===true){
    this.props.appstate.PreviewDialog.itemurl = this.state.items[index].streamext;
  }else{
    this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,Object.keys(this.state.items[index].medialan)[0]);
  }

  this.props.dispatch(APP(this.props.appstate));
};

qrcode = (event) => {
  this.previewDialogRef.init();
  this.props.appstate.PreviewDialog.openModalPreview = true;
  this.props.appstate.PreviewDialog.qrcode = true;
  this.props.appstate.PreviewDialog.render = true;
  this.props.appstate.PreviewDialog.item =this.state.items[event.currentTarget.name];
  if (getExternalMediaType().findIndex(x => x.value == this.state.items[event.currentTarget.name].type)!==-1){
    let selectedUri= this.state.items[event.currentTarget.name].url;
    let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP);
        url = selectedUri[lang];
      }

    this.props.appstate.PreviewDialog.itemurl = url;
  }else if (this.props.appstate.App.user.domainDetail.backupaws===true && this.state.items[event.currentTarget.name].streamextloaded===true){
    this.props.appstate.PreviewDialog.itemurl = this.state.items[event.currentTarget.name].streamext;
  }else{
    this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[event.currentTarget.name]._id,Object.keys(this.state.items[event.currentTarget.name].medialan)[0]);
  }
  this.props.dispatch(APP(this.props.appstate));
};

copyItem = (event) => {
  //disabilita pulsante se owen ed user nonsono la stessa persona
  let index = event;
  // let index = event.currentTarget.name;
  // if (!index) {
  //   index = event.target.id;
  // }
  this.props.appstate.Modal.openModalLoading = true;
  this.props.appstate.Modal.openModalLoadingTextActive = false;
  this.props.dispatch(APP(this.props.appstate));
  let params = {};
  // params.resId = this.state.items[event.currentTarget.name]._id;
  params.resId = this.state.items[index]._id;
  params.appname = this.props.appstate.App.name;
  params.domainId = this.props.appstate.App.user.domain;
  let jsonlang = this.props.appstate.App.language;
  var self = this;
  let indexFoundExternalType = getExternalMediaType().findIndex(
    (element) =>
    // element.value === this.state.items[event.currentTarget.name].type
    element.value === this.state.items[index].type

  );
  let duplicateUrlService = services.resourcemanager_duplicate_POST_AUTH;
  if (indexFoundExternalType !== -1) {
    duplicateUrlService = services.resourcemanager_weakduplicate_POST_AUTH;
  }
  console.log(indexFoundExternalType);
  console.log(duplicateUrlService);
  postService(
    process.env.REACT_APP_DOMAIN + duplicateUrlService,
    params,
    "",
    "",
    function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoading = false;
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText =
        jsonlang.copyreserror;
        self.props.dispatch(APP(self.props.appstate));
      } else if (result.status === 201) {        
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = jsonlang.copyresok;
        self.props.appstate.Modal.openModalLoadingTextExtra =
        jsonlang.duplicateresname + result.response.name;
        self.props.appstate.ResourceManager.resources.push(result.response);
        self.props.dispatch(APP(self.props.appstate));
        self.setState(
          { items: [...self.state.items, result.response] },
          () => {
            self.filterengine(
              undefined,
              undefined,
              undefined,
              self.state.rightSelected
            );
          }
        );
      }
    }
  );
};
tabOrder = (event) => {
  this.order(event.currentTarget.name, true, this.state.items);
};

order = (orderType, orderByInterface, arrayToOrder) => {
  //ordinamento potrebbe dover essere fatto piu volte anche quando fai edit o quando cambi l'array attenzione
  //nel caso completare implementazione di questa funzione  rendendola chiamabile dall'esterno
  let data = arrayToOrder.slice();
  let orderArray = [];
  // console.log(orderType)
  // console.log(orderByInterface)
  // console.log(data)
  switch (orderType) {
    case "nameup":
    orderArray = itemsSort("name", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: false,
        namedown: false,
        nameup: true,
        items: orderArray,
        orderSelected: "nameup",
      });
    } else {
      return orderArray;
    }
    break;
    case "namedown":
    orderArray = itemsSortInverse("name", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: false,
        namedown: true,
        nameup: false,
        items: orderArray,
        orderSelected: "namedown",
      });
    } else {
      return orderArray;
    }
    break;
    case "dateup":
    orderArray = itemsSortDate("modifiedAt", data);
    if (orderByInterface) {
      this.setState({
        datedown: false,
        dateup: true,
        namedown: false,
        nameup: false,
        items: orderArray,
        orderSelected: "dateup",
      });
    } else {
      return orderArray;
    }
    break;
    case "datedown":
    orderArray = itemsSortInverseDate("modifiedAt", data);
    if (orderByInterface) {
      this.setState({
        datedown: true,
        dateup: false,
        namedown: false,
        nameup: false,
        items: orderArray,
        orderSelected: "datedown",
      });
    } else {
      return orderArray;
    }
    break;
    default:
    break;
  }
};
getUTCData = (data) => {
  try {
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
      timeZoneName: "short",
    }).format(new Date(data));
  } catch (error) {
    return "";
    // console.log(error);
  }
};

getKeyLang = (strinput) => {
  if (this.props.acamar.configuration.Languages && strinput) {
    let keylang = "en";
    if (strinput[this.props.acamar.configuration.Languages.default]) {
      return this.props.acamar.configuration.Languages.default;
    } else if (strinput[keylang]) {
      return keylang;
    } else {
      let allKey = Object.keys(strinput);
      if (allKey.length > 0) {
        return allKey[0];
      }
    }
  }
  return "";
};

dialogResourcesManagerCallBack = () => {
  // document.getElementById("resourcesearchinput").value = "";
  // this.setState({ items: this.props.appstate.ResourceManager.resources});  
  this.setState(
    { items: this.props.appstate.ResourceManager.resources },
    () => {
      this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );
};


toPublic = (id, index) =>{
  let self=this;
  console.log(this.state.items[index])
  let params = {};
     params.domain= this.props.appstate.App.user.domain;
     params.appname= this.props.appstate.App.name;
     params.id=this.state.items[index]._id;
     params.userId= this.props.appstate.App.user.userid;
     params.publicStatusExtRes=this.state.items[index].publicStatusExtRes.toString().toLowerCase() !== "false" ? false : true;
  postService(
    process.env.REACT_APP_DOMAIN + services.resourcemanager_VISIBILITY_AUTH,
    params,
    "",
    "",
    function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoading = false;
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText ="errore";
        self.props.dispatch(APP(self.props.appstate));
      } else if (result.status === 200) {        
        console.log(result)
        self.props.appstate.ResourceManager.resources[index] = result.response;
        self.props.dispatch(APP(self.props.appstate));
        // self.props.appstate.Modal.openModalLoadingTextActive = true;
        // self.props.appstate.Modal.openModalLoadingText = jsonlang.copyresok;
        // self.props.appstate.Modal.openModalLoadingTextExtra =
        // jsonlang.duplicateresname + result.response.name;
        // self.props.appstate.ResourceManager.resources.push(result.response);
        // self.props.dispatch(APP(self.props.appstate));
        // self.setState(
        //   { items: [...self.state.items, result.response] },
        //   () => {
        //     self.filterengine(
        //       undefined,
        //       undefined,
        //       undefined,
        //       self.state.rightSelected
        //     );
        //   }
        // );
      }
    }
  );
  // if(this.props.reducer.publicStatusExtRes !== "" && this.props.reducer.publicStatusExtRes !== undefined){
    //  var data1 = {
    //    domain: this.props.reducer.domain,
    //    appname:this.props.reducer.appname,
    //    id:this.state.webcloudresid,
    //    userId: this.props.reducer.userid,
    //    publicStatusExtRes:this.props.reducer.publicStatusExtRes.toString().toLowerCase() !== "false" ? false : true
    //  };
    //  let self = this;
    //  //const headers = { headers: { 'content-type': 'application/json','Access-Control-Allow-Origin':'*'} };
    //  const headers = { headers: { 'content-type': 'application/json' } };
    //  Axios.post(services.ASSETBACKEND + services.resourcemanager_VISIBILITY_AUTH, data1, headers)
    //    .then(function (result) {
    //      if (result.status === 200) {
    //        self.setState({topublicicon: result.data.message.publicStatusExtRes.toString().toLowerCase() !== "false" ? "eye slash" : "eye"}, ()=> {self.props.dispatch(actionLogin(
    //          self.props.reducer.jwt,
    //          self.props.reducer.userid,
    //          true,
    //          self.props.reducer.name,
    //          self.props.reducer.role,
    //          self.props.reducer.domain,
    //          self.props.reducer.fsname,
    //          self.props.reducer.resType,
    //          self.props.reducer.appname,
    //          self.props.reducer.uri,
    //          self.props.reducer.resourceid,
    //          self.props.reducer.domain,
    //          self.props.reducer.metadata ? self.props.reducer.metadata : null,
    //          self.props.reducer.itemName ?  self.props.reducer.itemName : "",
    //          result.data.message.publicStatusExtRes
    //  ))});
    //      }
    //    })
    //    .catch(function (error) {
    //      //self.setState({webcloudresid:"", topublicicon: "eye slash"},self.autoSaving());
    //      //console.log('Errore in funzione  [Configuration.jsx/saveTopic]: ', error)
    //    });
   //}
 }




render() {
  let jsonlang = this.props.appstate.App.language;
  return (
    <div>
      <DialogDisclaimer open={this.state.openDisclaimer} close={this.handleCloseDisclaim}
        okDisc={this.handleCloseDisclaim}
        onRef={childthis => (this.dialogDisclaimer = childthis)}/>

        <DialogRights
          //  onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
        />
        <DialogResourcesManager
          callback={this.dialogResourcesManagerCallBack}
          onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
        />
        <DialogDelete callback={this.dialogResourcesManagerCallBack} />
        <MapDialog onRef={(childthis) => (this.mapDialogRef = childthis)} />
        <PreviewDialog
          onRef={(childthis) => (this.previewDialogRef = childthis)}
        />
        <AppBar className="appbarstyle" position="fixed" color="default">
          <Typography variant="h6" justify="center" align="center">
            {jsonlang.showroom.toUpperCase()}
          </Typography>
          <Toolbar>
            <InputBase
              style={{  minWidth: 80, marginLeft: 8 }}
              id="resourcesearchinput"
              onChange={this.searchInput}
              placeholder={jsonlang.search}
              value={this.state.searchValue}
            />
            <Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
            <IconButton
              type="submit"
              color="primary"
              aria-label="search"
              className="pad10"
              // onChange={this.searchInput}
              >
                <Search />
              </IconButton>
            </Tooltip>
            <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

            {/* <TextField
              style={{paddingLeft:'30px'}}
              id="resourcesearchinput"
              onChange={this.searchInput}
              value={this.state.searchValue}
              InputProps={{
              startAdornment: (
              <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        placeholder={jsonlang.search}
      /> */}
      <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
      <CancelRounded
        color="primary"
        onClick={this.searchInputCancel}
        fontSize="large"
        style={{ "margin-right": "4.5em" }}
      />
    </Tooltip>
    <Tooltip title={<h2>{jsonlang.onlymyres.toUpperCase()}</h2>}>
    <Checkbox
      checked={this.state.mineFilter}
      onChange={this.filterselection}
      name="mineFilter"
      color="primary"
      icon={<PersonOutlineIcon fontSize="large" />}
      checkedIcon={<PersonIcon fontSize="large" className="btn-dotted"/>}
    />
  </Tooltip>
  <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
  {/* filtri data e alfabetico inizio   */}

  <Tooltip title={<h2>{jsonlang.namesort.toUpperCase()}</h2>}>
  <SortByAlphaIcon fontSize="large" />
</Tooltip>
{/* <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}> */}
<Checkbox
  checked={this.state.nameup}
  onChange={this.tabOrder}
  name="nameup"
  color="primary"
  icon={<ArrowDropUpIcon fontSize="large" />}
  checkedIcon={<ArrowDropUpIcon fontSize="large" className="btn-dotted"/>}
/>
{/* </Tooltip> */}
{/* <Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}> */}
<Checkbox
  checked={this.state.namedown}
  onChange={this.tabOrder}
  name="namedown"
  color="primary"
  icon={<ArrowDropDownIcon fontSize="large" />}
  checkedIcon={<ArrowDropDownIcon fontSize="large" className="btn-dotted"/>}
/>
{/* </Tooltip> */}
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

<Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
<AccessTimeIcon fontSize="large" />
</Tooltip>
<Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.dateup}
  onChange={this.tabOrder}
  name="dateup"
  color="primary"
  icon={<ArrowDropUpIcon fontSize="large" />}
  checkedIcon={<ArrowDropUpIcon fontSize="large" className="btn-dotted"/>}
/>
</Tooltip>
<Tooltip title={<h2>{jsonlang.datesort.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.datedown}
  onChange={this.tabOrder}
  name="datedown"
  color="primary"
  icon={<ArrowDropDownIcon fontSize="large" />}
  checkedIcon={<ArrowDropDownIcon fontSize="large" className="btn-dotted"/>}
/>
</Tooltip>
<Divider
  // style={{ height: 28, margin: 4 }}
  orientation="vertical"
  style={{ height: 28, marginRight: "4.5em" }}
/>

{/* filtri data e alfabetico fine   */}
<Tooltip title={<h2>{jsonlang.resgeo.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.map}
  onChange={this.filterselection}
  name="map"
  color="primary"
  icon={<RoomOutlinedIcon fontSize="large" />}
  checkedIcon={<RoomIcon fontSize="large" className="btn-dotted"/>}
/>
</Tooltip>
{/* <Divider
  style={{
  height: 28,
  margin: 4,
}}
orientation="vertical"
/> */}
{/* <Tooltip title={<h2>{jsonlang.resimage.toUpperCase()}</h2>}>
<Checkbox
checked={this.state.imagefilter}
onChange={this.filterselection}
name="image"
color="primary"
icon={<ImageOutlinedIcon fontSize="large" />}
checkedIcon={<ImageIcon fontSize="large" />}
/>
</Tooltip>
<Divider
style={{
height: 28,
margin: 4,
}}
orientation="vertical"
/> */}
{/* <Tooltip title={<h2>{jsonlang.resaudio.toUpperCase()}</h2>}>
<Checkbox
checked={this.state.audiofilter}
onChange={this.filterselection}
name="audio"
color="primary"
icon={<AudiotrackOutlinedIcon fontSize="large" />}
checkedIcon={<AudiotrackIcon fontSize="large" />}
/>
</Tooltip>
<Divider
style={{
height: 28,
margin: 4,
}}
orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.resvideo.toUpperCase()}</h2>}>
<Checkbox
checked={this.state.videofilter}
onChange={this.filterselection}
name="video"
color="primary"
icon={<PlayCircleOutlineIcon fontSize="large" />}
checkedIcon={<PlayCircleOutlineIcon fontSize="large" />}
/>
</Tooltip>
<Divider
style={{
height: 28,
margin: 4,
}}
orientation="vertical"
/>
<Tooltip title={<h2>{jsonlang.documentfilter.toUpperCase()}</h2>}>
<Checkbox
checked={this.state.documentfilter}
onChange={this.filterselection}
name="document"
color="primary"
icon={<DescriptionIcon fontSize="large" />}
checkedIcon={<DescriptionIcon fontSize="large" />}
/>
</Tooltip> */}

{/* <Divider style={{
  height: 28,
  margin: 4
}} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.search.toUpperCase()}</h2>}>
<IconButton color="primary" >
<Search fontSize="large"/>
</IconButton>
</Tooltip>
<TextField
variant="outlined"
id="resourcesearchinput"
onChange={this.searchInput}
/>*/}

{/* <Divider style={{
  height: 28,
  margin: 4
}} orientation="vertical" /> */}
{/* <Tooltip title={<h2>{jsonlang.addresources.toUpperCase()}</h2>}>
<Fab
  style={{
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  }}
  role="button"
  color="primary"
  size="large"
  aria-label="Add"
  onClick={this.upload}
  >
    <AddIcon />
  </Fab>
</Tooltip> */}
{/* filtri di diritti di autore  */}
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.publicres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.publicRight}
  onChange={this.filterselection}
  name="publicright"
  color="primary"
  icon={
    <img
      className="public-rights-img"
      alt="public_icon"
      src="/images/icons/public_dom.png"
    />
  }
  checkedIcon={
    <img
      className="public-rights-img btn-dotted"
      alt="public_icon_selected"
      src="/images/icons/public_dom_sel.png"
    />
  }
  // icon={ <FontAwesomeIcon icon={faBan} />  }
  // checkedIcon={<FontAwesomeIcon icon={faBan} color="blue"/>}
/>
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.ccres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.ccRight}
  onChange={this.filterselection}
  name="ccright"
  color="primary"
  icon={<FontAwesomeIcon icon={faClosedCaptioning} size="lg" />}
  checkedIcon={
    <FontAwesomeIcon
      icon={faClosedCaptioning}
      color="blue"
      size="lg"
    className="btn-dotted"/>
  }
/>
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
<Tooltip title={<h2>{jsonlang.copyrightres.toUpperCase()}</h2>}>
<Checkbox
  checked={this.state.otherRight}
  onChange={this.filterselection}
  name="otherright"
  color="primary"
  icon={<FontAwesomeIcon icon={faCopyright} size="lg" />}
  checkedIcon={
    <FontAwesomeIcon icon={faCopyright} color="blue" size="lg" className="btn-dotted"/>
  }
/>
</Tooltip>
<Divider style={{ height: 28, margin: 4 }} orientation="vertical" />

{/* fine filtri diritti di autore  */}
</Toolbar>
</AppBar>
<div>
  <Grid
    container
    className={
      this.state.map ? "gridcontainerwithmap" : "gridcontainer"
    }
    >
      {this.state.map ? (
        <Grid xs={9}>
          <GeoMap
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div className="mapcontainer" />}
            mapElement={<div style={{ height: "88%" }} />}
            options={{
              scrollwheel: false,
              streetViewControl: false,
              minZoom: 1,
              maxZoom: 19,
              mapTypeControl: true,
              zoomControl: true,
            }}
            drawingControl={false}
            onlyMarker={false}
            callback={this.mapCallback}
          />{" "}
        </Grid>
      ) : (
        <></>
      )}
      <Grid className="gridcontainerwithmap_catalog" xs={this.state.map ? 1 : 12}>
        {this.state.items && this.state.items.length > 0 ? (
          this.state.items.map((item, index) => (
            <Card className="cardstyle" index={index}>
              <CardHeader
                // className="cardheaderstyle"
                avatar={
                  <Tooltip title={<h2>{item.ownerdetails[0].name}</h2>}>
                  <Avatar
                    aria-label="recipe"
                    src={this.avatarUrl(item.owner)}
                    ></Avatar>
                  </Tooltip>
                }
                action={
                  <ListItem>
                    <IconButton
                      name={index}
                      id={item._id}
                      onClick={this.resourcePreview}
                      style={{padding:0}}
                      >
                        {item.type === "image" ||
                        item.type === "imagecloud" ? (
                          <Tooltip
                            title={<h2>{jsonlang.review.toUpperCase()}</h2>}
                            >
                              <AspectRatioIcon
                                color="primary"
                                style={{ fontSize: 20 }}
                              />
                            </Tooltip>
                          ) : item.type === "video" ||
                          item.type === "videocloud" ? (
                            <Tooltip
                              title={
                                <h2>{jsonlang.resplay.toUpperCase()}</h2>
                              }
                              >
                                <PlayCircleFilledIcon
                                  color="primary"
                                  style={{ fontSize: 20 }}
                                />
                              </Tooltip>
                            ) : item.type === "audio" ||
                            item.type === "audiocloud" ? (
                              <Tooltip
                                title={
                                  <h2>{jsonlang.reslisten.toUpperCase()}</h2>
                                }
                                >
                                  <AudiotrackIcon
                                    color="primary"
                                    style={{ fontSize: 20 }}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={<h2>{jsonlang.review.toUpperCase()}</h2>}
                                  >
                                    <DescriptionIcon
                                      color="primary"
                                      style={{ fontSize: 20 }}
                                    />
                                  </Tooltip>
                                )}
                              </IconButton>

                              {
                                item.location ? (
                                  parseInt(item.location.coordinates[0]) !== 0 &&
                                  parseInt(item.location.coordinates[1]) !== 0 ? (
                                    <Tooltip
                                      title={<h2>{jsonlang.remap.toUpperCase()}</h2>}
                                      >
                                        <IconButton
                                          name={index}
                                          id="map"
                                          onClick={this.openMapFromCard}
                                          style={{padding:0}}
                                          >
                                            <RoomIcon
                                              // style={{padding:0}}
                                              color="primary"
                                              style={{padding:0,fontSize: 20 }}
                                              ></RoomIcon>
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <></>)) : (<></>)
                                        }
                                        <MenuCard
                                          key={item._id}
                                          item={item}
                                          index={index}
                                          from="catalog"
                                          // onCopy={() => this.copyItem(index)}
                                          // onPublic={() => this.toPublic(item._id,index)}
                                          onDelete={() => this.delete(item._id,index)}
                                          onEdit={() => this.edit(item._id)}
                                        />
                                      </ListItem>

                                    }
                                    title={
                                      <Tooltip title={<h2>{item.name}</h2>}>
                                      <div className="cardtextstyle">{item.name}</div>
                                    </Tooltip>
                                  }
                                  subheader={
                                    <div className="cardsubtextstyle">
                                      {this.getUTCData(item.modifiedAt)}
                                    </div>
                                  }
                                />
                                {item.filter && this.state.searchValue !== "" ? (
                                  <div className="type-find">
                                    {item.filter.map((filtering, index) => (
                                      <Chip
                                        style={{ backgroundColor: "#192bb0AA" }}
                                        color="primary"
                                        label={filtering}
                                        icon={<Search />}
                                      />
                                    ))}
                                  </div>
                                ) : null}
                                {/* <Tooltip title={<h2>{jsonlang.backupres.toUpperCase()}</h2>}>
                                <div className="backup">
                                  <Checkbox
                                    disabled
                                    style={{color:'white'}}
                                    checked={this.props.appstate.App.user.domainDetail.backupaws === true && item.streamextloaded === true ? true : false}
                                    icon={<CloudOffIcon />}
                                    checkedIcon={<CloudDoneIcon />}
                                  />
                                </div>
                              </Tooltip> */}
                              { this.props.appstate.App.name.toUpperCase()==="GREENSCENT"? <></>:
                              <Tooltip title={<h2>{item.publicStatusExtRes===true?"PUBLISHED":"UNPUBLISHED" }</h2>}>
                                <div className="backup">
                                  <Checkbox
                                    disabled
                                    style={{color:'white'}}
                                    checked={item.publicStatusExtRes === true  ? true : false}
                                    icon={<VisibilityOffIcon />}
                                    checkedIcon={<VisibilityIcon />}
                                  />
                                </div>
                              </Tooltip>
                                }
                              <Tooltip title={<h2>{item.name}</h2>}>
                              <CardMedia
                                square
                                className="cardmediastyle"
                                id={index}
                                image={
                                  item.type === "audio"
                                  ? "/images/icons/sound-bars.png"
                                  : item.type === "imagecloud"
                                  ? item.url ? item.url : "/images/icons/ext_res.png"
                                  : item.type === "videocloud"
                                  ?  item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/video.png"
                                  : item.type === "audiocloud"
                                  ? "/images/icons/sound-bars.png"
                                  : item.type === "filecloud"
                                  ? "/images/icons/ext_res.png"
                                  : item.type === "documentcloud"
                                  ? "/images/icons/ext_res.png"
                                  : item.type === "webcloud"
                                  ?  item.uriCoverExternalRes ? item.uriCoverExternalRes : "/images/icons/ext_res.png"
                                  : item.type === "document"
                                  ? "/images/icons/document.png"
                                  : this.coverUrl(item._id, Object.keys(item.medialan)[0])
                                }
                                title=""
                                onClick={this.resourcePreview}
                              />

                            </Tooltip>
                            {/* <CardContent className="cardcontentstyle">
                            <Typography variant="body2">
                            {<div className="cardtextstylefooter">{item.description}</div>}
                          </Typography>
                        </CardContent> */}
                        <CardActions disableSpacing className="actionarea">
                          {/* <div className="carddesc">
                          {<Tooltip title={<h2>{item.description}</h2>}>
                          <div className="cardtextstylefooter">{item.description}</div>  //description va gestita perchè ora ha valori multipli
                        </Tooltip>}
                      </div> */}
                      <div className="cardshare">
                        <Tooltip
                          title={
                            <h2>
                              {item ? (item.edmRights ? item.edmRights.dcRights : <></>) : <></>}
                            </h2>
                          }
                          >
                            {item ? (item.edmRights ? getRights(item.edmRights.dcRights).uri === "" ? (
                              <img
                                className="rights-img-RM"
                                alt="CC-BY-NC-ND"
                                src={
                                  item.edmRights
                                  ? getRights(item.edmRights.dcRights).logo
                                  : ""
                                }
                              />
                            ) : (
                              <img
                                className="rights-img-RM"
                                alt="CC-BY-NC-ND"
                                id={item._id}
                                src={
                                  item.edmRights
                                  ? getRights(item.edmRights.dcRights).logo
                                  : ""
                                }
                                onClick={this.openRightsDetail}
                              />
                              // <Link
                              //   color="inherit"
                              //   role="link"
                              //   aria-label="Public Domain - No Copyright"
                              //   href={
                              //     item.edmRights
                              //       ? getRights(item.edmRights.dcRights).uri
                              //       : ""
                              //   }
                              //   target="blank"
                              // >
                              //   <img
                              //     className="rights-img-RM"
                              //     alt="CC-BY-NC-ND"
                              //     src={
                              //       item.edmRights
                              //         ? getRights(item.edmRights.dcRights).logo
                              //         : ""
                              //     }
                              //   />
                              // </Link>
                            ) : <></>) : <></>}
                          </Tooltip>
                          <Tooltip
                            title={<h2>{"DISCLAIMER"}</h2>}
                            >
                              <IconButton
                                name={index}
                                id={item._id}
                                onClick={this.handleClickOpenDisclaim}
                                >
                                  <ReportProblemIcon
                                    color="primary"
                                    style={{ fontSize: 20 }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={<h2>{jsonlang.resshare.toUpperCase()}</h2>}
                                >
                                  <IconButton
                                    name={index}
                                    id={item._id}
                                    onClick={this.urlCopy}
                                    >
                                      <ShareIcon
                                        color="primary"
                                        style={{ fontSize: 20 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={<h2>{jsonlang.rescode.toUpperCase()}</h2>}
                                    >
                                      <IconButton
                                        name={index}
                                        id={item._id}
                                        onClick={this.qrcode}
                                        >
                                          {/* <span style={{ color: this.props.appstate.App.theme.refmaterial.palette.primary.main }}> */}
                                          <FontAwesomeIcon icon={faQrcode} />
                                          {/* </span> */}
                                          {/* <CenterFocusWeakIcon color="primary" style={{ fontSize: 20 }} /> */}
                                        </IconButton>
                                      </Tooltip>
                                      {/* <Tooltip
                                        title={<h2>{jsonlang.copyreslabel.toUpperCase()}</h2>}
                                        >
                                        <IconButton
                                        name={index}
                                        id={item._id}
                                        onClick={this.copyItem}
                                        disabled={
                                        this.props.appstate.App.user.userid === item.owner
                                        ? false
                                        : true
                                      }
                                      >
                                      <FileCopyIcon
                                      color={
                                      this.props.appstate.App.user.userid ===
                                      item.owner
                                      ? "primary"
                                      : "disabled"
                                    }
                                    style={{ fontSize: 20 }}
                                  />
                                </IconButton>
                              </Tooltip> */}

                              {/* <Tooltip
                                title={<h2>{jsonlang.remove.toUpperCase()}</h2>}
                                >
                                <IconButton
                                name={index}
                                id={item._id}
                                onClick={this.delete}
                                disabled={
                                this.props.appstate.App.user.userid === item.owner
                                ? false
                                : true
                              }
                              >
                              <DeleteIcon
                              color={
                              this.props.appstate.App.user.userid ===
                              item.owner
                              ? "primary"
                              : "disabled"
                            }
                            style={{ fontSize: 20 }}
                          />
                        </IconButton>
                      </Tooltip>*/}
                      {/* <Tooltip title={<h2>EDIT</h2>}>
                      <IconButton
                      name={index}
                      id={item._id}
                      onClick={this.edit}
                      disabled={
                      this.props.appstate.App.user.userid === item.owner
                      ? false
                      : true
                    }
                    >
                    <EditIcon
                    color={
                    this.props.appstate.App.user.userid ===
                    item.owner
                    ? "primary"
                    : "disabled"
                  }
                  style={{ fontSize: 20 }}
                />
              </IconButton>
            </Tooltip> */}
            {(this.props.appstate.App.name.toUpperCase() === "SMST" ||this.props.appstate.App.name.toUpperCase() === "GREENSCENT") && (this.props.appstate.App.user.userid===item.owner) ? 
            <>
            <Fab
             style={{
              margin: 50,
              top: "auto",
              right: 0,
              bottom: 45,
              left: "auto",
              position: "relative",
            }}
              role="button"
              color="primary"
              size="small"
              aria-label="Add"
              name="edit"
              value={index}
              id={item._id}
              onClick={this.openAnnotator}
              >
                <ThreeSixtyIcon />
              </Fab>
              {/* <Fab
                style={{
                  margin: 0,
                  top: "auto",
                  right: 75,
                  bottom: 45,
                  left: "auto",
                  position: "relative",
                }}
                role="button"
                color="primary"
                size="small"
                aria-label="Play"
                value={index}
                name="play"
                id={item._id}
                onClick={this.openAnnotator}
                >
                  <PlayCircleFilledIcon />
                </Fab> */}
              </>
              :<></>}
            </div>
            {/*<IconButton name={index} id={item._id} onClick={this.resourcePreview}>
            <VisibilityIcon color="primary" style={{ fontSize: 20 }} />
          </IconButton>*/}
        </CardActions>
      </Card>
    ))
  ) : (
    <></>
  )}
</Grid>
</Grid>
</div>
</div>
);
}
}
export default connect(mapStateToProps)(withRouter(Catalog));
