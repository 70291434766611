import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  // List,
  // ListItemSecondaryAction,
  ListItem,
  IconButton,
  // Typography,
  Tooltip
} from "@material-ui/core";

// import AspectRatioIcon from "@material-ui/icons/AspectRatio";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import AudiotrackIcon from "@material-ui/icons/Audiotrack";
// import DescriptionIcon from "@material-ui/icons/Description";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SettingsIcon from '@material-ui/icons/Settings';

const mapStateToProps = (state) => {
  return {
    acamar: state.acamar,
    appstate: state.appstate,
  };
};
class MenuCard extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { item } = this.props;
    const { anchorEl } = this.state;
    let jsonlang = this.props.appstate.App.language;   
    return (
      <ListItem style={{ padding: 0 }}>
        {/* <ListItemSecondaryAction> */}
        <div style={{ padding: 0 }}>
          <IconButton
            aria-label="More"
            aria-owns={anchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            style={{ padding: 0 }}
            onClick={this.handleClick.bind(this)}
          // // disabled={
          // //   this.props.appstate.App.user.userid === item.owner
          // //       ? false
          // //       : true
          //   }
          >
            <MoreVertIcon color="primary"


            // color={this.props.appstate.App.user.userid ===
            //   item.owner
            //   ? "primary"
            //   : "disabled"}
            />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose.bind(this)}
            PaperProps={{
              style: {
                maxHeight: 300,
                width: 80,
              },
            }}
          >
            <MenuItem onClick={() => { this.handleClose(); this.props.onView() }}>
              <Tooltip title={<h2>{jsonlang.view_report.toUpperCase()}</h2>} >
                <IconButton
                  name={this.props.index}
                  id={item._id}
                // onClick={() => { this.handleClose();this.props.onCopy()}}
                // disabled={
                //   this.props.appstate.App.user.userid === item.owner
                //     ? false
                //     : true
                // }
                >
                  {/* check se utente corrente è owner delle risorsa */}
                  <VisibilityIcon
                    color="primary"
                    // color={
                    //   this.props.appstate.App.user.userid ===
                    //     item.owner
                    //     ? "primary"
                    //     : "disabled"
                    // }
                    style={{ fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            <MenuItem onClick={() => { this.handleClose(); this.props.onChangeStatus() }}
              disabled={
                (this.props.appstate.App.user.role === "domainadmin" && (item.status.toUpperCase() === "INSERTED" || item.status.toUpperCase() === "APPROVED")) ||
                  (this.props.appstate.App.user.userid === item.ownerdetails[0]._id && item.status.toUpperCase() === "APPROVED")
                  ? false
                  : true
              }>

              <Tooltip title={<h2>{jsonlang.change_status_title.toUpperCase()}</h2>}>
                <IconButton
                  name={this.props.index}
                  id={item._id}
                // onClick={() =>{ this.handleClose();this.props.onEdit()}}

                >
                  {/* funzionalità attiva se 
                    uteente è amministratore di dominio e stato inserito o approvato 
                    utente proprietario e stato inserito 
                  */}
                  <SettingsIcon
                    color={"primary"}
                    //   (this.props.appstate.App.user.role === "domainadmin" && (item.status.toUpperCase() === "INSERTED" || item.status.toUpperCase() === "APPROVED")) ||
                    //     (this.props.appstate.App.user.userid === item.ownerdetails[0]._id && item.status.toUpperCase() === "APPROVED")
                    //     ? "primary"
                    //     : "disabled"
                    // }
                    style={{ fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            <MenuItem onClick={() => { this.handleClose(); this.props.onOpenChat() }}  
              disabled={
                 ((item.status.toUpperCase() === "SOLVED" || item.status.toUpperCase() === "APPROVED") && item.nodebb!=="" )
                  ? false
                  : true
              }  >
              <Tooltip title={<h2>{jsonlang.open_chat.toUpperCase()}</h2>}>
                <IconButton
                  name={this.props.index}
                  id={item._id}
                // onClick={() =>{ this.handleClose();this.props.onDelete()}}
                // disabled={
                //   this.props.appstate.App.user.userid === item.owner
                //     ? false
                //     : true
                // }
                >
                  {/* check se utente corrente è owner delle risorsa */}
                  <ChatBubbleOutlineIcon
                    color="primary"
                    // color={
                    //   this.props.appstate.App.user.userid ===
                    //     item.owner
                    //     ? "primary"
                    //     : "disabled"
                    // }
                    style={{ fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            <MenuItem onClick={() => { this.handleClose(); this.props.onDelete() }}
              disabled={
                (this.props.appstate.App.user.role === "domainadmin" || (this.props.appstate.App.user.userid === item.owner && item.status.toUpperCase() === "INSERTED"))
                  ? false
                  : true
              } >
              <Tooltip title={<h2>{jsonlang.delete_report.toUpperCase()}</h2>}>
                <IconButton
                  name={this.props.index}
                  id={item._id}
                // onClick={() =>{ this.handleClose();this.props.onDelete()}}
                // disabled={
                //   (this.props.appstate.App.user.role === "domainadmin" || (this.props.appstate.App.user.userid === item.owner && item.status.toUpperCase() === "INSERTED"))
                //     ? false
                //     : true
                // }
                >
                  {/* check se utente corrente è owner delle risorsa */}
                  <DeleteIcon
                    color={"primary"}
                    //   (this.props.appstate.App.user.role === "domainadmin" || (this.props.appstate.App.user.userid === item.owner && item.status.toUpperCase() === "INSERTED"))
                    //     ? "primary"
                    //     : "disabled"
                    // }
                    style={{ fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            </MenuItem>
            {/* <MenuItem onClick={() => { this.handleClose();this.props.onCopy()}}>
                <Tooltip title={<h2>{jsonlang.copyreslabel.toUpperCase()}</h2>} >
                        <IconButton
                            name={this.props.index}
                            id={item._id} */}
            {/* // onClick={() => { this.handleClose();this.props.onCopy()}} */}
            {/* disabled={
                            this.props.appstate.App.user.userid === item.owner
                                ? false
                                : true
                            }
                        > */}
            {/* check se utente corrente è owner delle risorsa */}
            {/* <FileCopyIcon
                            color={
                                this.props.appstate.App.user.userid ===
                                item.owner
                                ? "primary"
                                : "disabled"
                            }
                            style={{ fontSize: 20 }}
                            />
                        </IconButton>
                </Tooltip>
              </MenuItem> */}

          </Menu>
        </div>
        {/* </ListItemSecondaryAction> */}

      </ListItem>
    )
  }
}
export default connect(mapStateToProps)(withRouter(MenuCard));
