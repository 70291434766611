import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import {
  postService,
  // getService,
  deleteService } from "../../services/services";
import {
  // Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Slide,
  Grid,
  Typography,
  // FormControl,
  // TextField,
  // InputLabel,
  // IconButton,
  // MenuItem,
  Tooltip,
  // InputAdornment,
  // OutlinedInput,
  // Paper,
  // Select,
  // Chip,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider
} from "@material-ui/core";
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DialogYesNo from "../Dialog/DialogYesNo"
import EditIcon from '@material-ui/icons/Edit';


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

// const Roles = [
//   {
//     value: '1',
//     label: 'basic',
//   },
//   {
//     value: '2',
//     label: 'editor',
//   },
// ];

class UserModifyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      newRole:this.props.utente.role,
      user:this.props.utente,
      radioValue:this.props.utente.status
    });
  }
  componentDidMount() {
  }

  saveRole=()=>{
    this.save("role");
  }
  saveStatus=()=>{
    this.save("status");
  }
  onChangeRole=(e,d)=>{
    this.setState({newRole:d.props.value});
  }
  handleRadio=(e)=>{
    this.setState({radioValue:e.target.value});
  }

  deleteUser=()=>{
    var self=this;
    //chiudi YesNo dialog
    this.props.appstate.YesNoDialog.open = false;
    this.props.appstate.YesNoDialog.title = "";
    this.props.appstate.YesNoDialog.message = "" ;
    //dialog di conferma e loading
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    //fare chiamata di cancellazione
    deleteService(
      process.env.REACT_APP_DOMAIN + services.deleteusere_DELETE,
      { id:this.state.user._id  , appname: this.props.appstate.App.name , myself:this.props.appstate.App.user.userid, domainId:this.state.user.domain._id},
      "",
      function (err, result) {
        if (err) {
          console.log(err)
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.userdeleteerror;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        if (result.status === 204) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.userdeleteok;
          self.props.dispatch(APP(self.props.appstate));
          self.props.closeCallback();
          return;
        }

      }
    );
  }

  openDialogForDelete =()=>{
    console.log("delete User modal");
    this.props.appstate.YesNoDialog.open = true;
    this.props.appstate.YesNoDialog.title = this.props.appstate.App.language.canceluser;
    this.props.appstate.YesNoDialog.message = this.props.appstate.App.language.messagecanceluser+" " +this.state.user.name+"?";
    this.props.dispatch(APP(this.props.appstate))
  }



  save=(updateItem)=>{
    if (this.state.radioValue === "rejected"){
      console.log("rejected")
      this.openDialogForDelete();
    }else {
      console.log("update")
      var self=this;
      const headers = { headers: {  'Content-Type': 'application/x-www-form-urlencoded' } };
      const params = new URLSearchParams();
      params.append('appname',this.props.appstate.App.name );
      params.append('id', this.state.user._id );
      params.append('myself',this.props.appstate.App.user.userid);
      params.append('domainId', this.state.user.domain._id);
      if (updateItem==="role"){
        params.append("role", this.state.newRole);
      }else{
        params.append("status",this.state.radioValue );
      }
      this.props.appstate.Modal.openModalLoading = true;
      this.props.appstate.Modal.openModalLoadingTextActive = false;
      this.props.dispatch(APP(this.props.appstate));
      postService(
        process.env.REACT_APP_DOMAIN + services.modifyuser_POST,params, "", headers,
        function (err, result) {
          // console.log("res "+JSON.stringify(result));
          if (err) {
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.updateusererror;
            self.props.dispatch(APP(self.props.appstate));
            return;
          }
          if (result.status === 204) {
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.updateuserok;
            self.props.dispatch(APP(self.props.appstate));
            self.props.closeCallback();

          }
        }
      );
    }
  }




  render() {
    let jsonlang = this.props.appstate.App.language;
    return(
      <>
      <DialogTitle>

        <EditIcon
          aria-label="modify name"
          style={{
            color: "#fff",
            backgroundColor: "#3f51b5",
            borderRadius: "50px",
            fontSize: "36px",
            padding: "2px",
          }}
        />
        <span style={{
          padding: 5,
          position: 'fixed',
        }}>
        {jsonlang.modifyuser.toUpperCase() + " " +this.state.user.name.toUpperCase()}
      </span>


    </DialogTitle>
    <DialogContent dividers>
      <Grid container >
        {/* primo rilascio <Grid item xs={12}> */}
        {/* <Typography variant="h7">{jsonlang.role}t</Typography> */}
        {/* primo rilascio <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          >
          <Grid item xs={4} sm={4}>
          <Typography
          variant="button"
          display="block"
          gutterBottom
          >
          {jsonlang.currentrole}
        </Typography> */}
        {/* </Grid>
          <Grid item xs={4} sm={4}> */}
          {/* primo rilascio <Chip size="large" label={this.state.user.role}/> */}
          {/* <InputLabel >{this.state.user.role}</InputLabel> */}
          {/* primo rilascio
          </Grid>
          <Grid item xs={4} sm={4}>
          <Typography
          variant="button"
          display="block"
          gutterBottom
          >
          {jsonlang.changerole}
        </Typography> */}
        {/* <InputLabel id="hint">{jsonlang.changerole}:</InputLabel></Typography> */}
        {/* primo rilascio
          <Select
          fullWidth
          labelId="hint"
          id="roles"
          value={this.state.user.newRole}
          onChange={this.onChangeRole}
          >
          {Roles.map((option) =>
          <MenuItem
          key={option.label}
          value={option.label}
          >
          {option.label}
        </MenuItem>
      )}
    </Select>
  </Grid>
  <Grid item xs={4} sm={4} style={{padding:30}}>
  <Button
  variant="contained"
  size="small"
  color="primary"
  onClick={this.saveRole} >{jsonlang.save}</Button>
</Grid>
</Grid>
</Grid> */}

<Divider variant="fullWidth"/>
{this.props.appstate.App.user.role === "superadmin" ?<span/>
:
<Grid container style={{padding:10}}>
  <Typography
    variant="button"
    display="block"
    gutterBottom
    >
      {jsonlang.status}
    </Typography>
    <Grid container>
      <Grid item xs={8}>
        <RadioGroup
          row
          name="rolecheck"
          value={this.state.radioValue}
          onChange={this.handleRadio}
          >
            <Tooltip title={<h2>{jsonlang.approved.toUpperCase()}</h2>}>
            <FormControlLabel
              value="approved"
              control={<Radio color="primary"/>}
              label={jsonlang.approved}
            />
            </Tooltip>
            {/* {this.state.radioValue==="pending"? */}
            <Tooltip title={<h2>{jsonlang.notapproved.toUpperCase()}</h2>}>
            <FormControlLabel
              value="pending"
              control={<Radio color="primary"/>}
              label={jsonlang.notapproved} />
              </Tooltip>
              <Tooltip title={<h2>{jsonlang.rejected.toUpperCase()}</h2>}>
              <FormControlLabel
                value="rejected"
                control={<Radio color="primary"/>}
                label={jsonlang.rejected.toUpperCase()} />
                </Tooltip>
                {/* :<></>} */}
              </RadioGroup>
            </Grid>
            {/* <Grid item xs={4}>
            <Button
            onClick={this.saveStatus}
            variant="contained"
            size="small"
            color="primary"
            >
            {jsonlang.save}
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  }
</Grid>
</DialogContent>
<DialogActions>
  {/* <Button
    onClick={this.saveStatus}
    variant="contained"
    size="small"
    color="primary"
    disabled={this.state.radioValue==="pending" ? true: false}
    >
      {jsonlang.save.toUpperCase()}
    </Button>
    <Button
      onClick={this.props.closeCallback}
      variant="contained"
      size="small"
      color="primary"
      style={{marginRight:10}}
      >
        {jsonlang.cancel.toUpperCase()}
      </Button> */}
      <Button
        variant="contained"
        size="large"
        onClick={this.props.closeCallback}
        color="primary"
        startIcon={<ClearIcon />}>
        <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
        <Typography variant="button">{jsonlang.cancel}</Typography>
      </Tooltip>
    </Button>
    <Button
      disabled={this.state.radioValue==="pending" ? true: false}
      variant="contained"
      size="large"
      onClick={this.saveStatus}
      color="primary"
      startIcon={<SaveIcon />}>
      <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
      <Typography variant="button">{jsonlang.save}</Typography>
    </Tooltip>
  </Button>
    </DialogActions>
    <DialogYesNo callback={this.deleteUser}/>
  </>

)}
};
export default connect(mapStateToProps)(UserModifyDialog);
