import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import TimelineIcon from '@material-ui/icons/Timeline';


const actions = [
  { icon: <AddLocationIcon />,
    //  name: 'AGGIUNGI POI',
    value: 'POI'
  },
  { icon: <TimelineIcon />,
    //  name: 'AGGIUNGI PATH',
    value: 'PATH' },
  ];


const mapStateToProps = (state) => {
    return {
      acamar: state.acamar,
      appstate: state.appstate,
    };
  };
class AddPlace extends Component {
    state = {
      openSpeedDial: false,
    };

    OpenSpeed = () => {

      this.setState({openSpeedDial:true});
    }

    CloseSpeed = () => {
      this.setState({openSpeedDial:false});
    }
    render() {
      // const { item } = this.props;
      // const { anchorEl } = this.state;
      let jsonlang = this.props.appstate.App.language;

      return (

          <SpeedDial
            ariaLabel="addplace"
            // className={classes.speedDial}
            // hidden={hidden}
            icon={
              // <Tooltip title={<h2>{jsonlang.addresources.toUpperCase()}</h2>}>
              <SpeedDialIcon />
              // </Tooltip>
            }
            onClose={this.CloseSpeed}
            onOpen={this.OpenSpeed}
            open={this.state.openSpeedDial}
            // direction={direction}
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
              '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: 10,
                right: 10,
              },
              '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                top: 10,
                left: 10,
              }
            }}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.value}
                  icon={action.icon}
                  tooltipTitle={jsonlang.addresources.toUpperCase() + " " + action.value}
                  onClick={()=>this.props.onClick(action.value)}

                />
              ))}

            ))
          </SpeedDial>
      )
    }
  }
  export default  connect(mapStateToProps)(withRouter(AddPlace));
