import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as services from "../../services/services.json";
import { postService, deleteService, getService, multipartformService } from "../../services/services";
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RoomIcon from '@material-ui/icons/Room';
import InfoIcon from '@material-ui/icons/Info';

import {
    getResourceDataForLanguage
} from "../../Utils/utility";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Tooltip,
    Typography,
    Grid,
    Box,
    TextField,
    Checkbox,
    FormControlLabel,
    Fab,
    ImageList,
    ImageListItem,
    IconButton,
    ImageListItemBar,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import { APP } from '../../redux/actions/actions';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogResourcesManagerAnnotator from "../ResourcesManager/DialogResourcesManagerAnnotator.jsx"
import DialogResourcesManager from "../ResourcesManager/DialogResourcesManager.jsx"
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import * as mediatypes from "../../Utils/mediatype.json";


const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const maxElementToAttach=3;

class DialogCreateWarning extends Component {
    constructor(props) {
        super(props);
        console.log("constructor create warning ")
        this.setRefFileinput = element => {
            this.refFileinput = element;
        };
        this.refFileinputClick = () => {
            if (this.refFileinput) {
                this.refFileinput.click();
            }
        };
        this.state = ({
            type: this.props.type, //tipo di dialog se edit o create 
            availableTags: [],
            selectedTags: [],
            mediaAssociatedArray: [], //array dei media associati alla segnalazione ininzailmente vuoto
            title: "",
            description: "",
            gravity: 0,
            gravityInfo: false,
            openResDialog: false,
            openUploadResDialog: false,
            coverobj: "",
            titleerror: false,
            descerror: false
        });
    }



    init = () => {
        //TODO: aggiungi if stato di redux è pieno se vuoi popolare gli stati con i dati di redux 
        this.setState({
            type: this.props.type, //tipo di dialog se edit o create 
            availableTags: [],
            selectedTags: [],
            mediaAssociatedArray: [], //array dei media associati alla segnalazione ininzailmente vuoto            
            title: "",
            description: "",
            gravity: 0,
            gravityInfo: false,
            openResDialog: false,
            openUploadResDialog: false,
            coverobj: "",
            titleerror: false,
            descerror: false
        });
        this.getTags()
    }

    componentDidMount() {
        this.props.onRef(this); //for the parent
    }
    getTags = () => {
        let self = this;
        let ser = process.env.REACT_APP_DOMAIN + services.warning_tags_GET.replace("{APPNAME}", this.props.appstate.App.name);
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        getService(ser, "", function (err, result) {
            if (err) {
                self.props.appstate.Modal.openModalLoadingTextActive = true;
                self.props.appstate.Modal.openModalLoadingText =
                    self.props.appstate.App.language.resourcerror;
                self.props.dispatch(APP(self.props.appstate));
                return;
            }
            if (result.status === 200) {
                console.log("tags")
                console.log(result.response)
                self.setState({ availableTags: result.response });
                return;
            }
            return;
        });
    }
    //metodo per la chiusura della dialog
    onclose = () => {
        //this.props.appstate.ResourceManager.render = false;
        //  this.props.appstate.ResourceManager.openModalResourcesCatalog = false;
        //this.props.appstate.ResourceManager.edit = false;
        this.props.appstate.ResourceManager.openModalIconLang = false;
        this.props.appstate.ResourceManager.index = 0;
        this.props.appstate.ResourceManager.id = 0;
        this.props.appstate.ResourceManager.resourceInfo = {};
        this.props.dispatch(APP(this.props.appstate));
        this.props.onClose();
    }
    //metodo per ottenre url della cover della risorsa selezionata 
    coverUrl = (id, medialan) => {
        // console.log(medialan)
        let ser =
            process.env.REACT_APP_DOMAIN +
            services.singleresourcemanager_GET_AUTH.replace(
                "{APPNAME}",
                this.props.appstate.App.name
            );
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{RESOURCEID}", id);
        ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
        ser = ser.replace("{COVER}", true);
        ser = ser + "&t=" + new Date().getTime();
        ser = ser.replace("{MEDIALAN}", medialan);
        ser = ser.replace("{MEDIARES}", "first");
        return ser;
    };
    //gestisce il pulsante di cancellazione di un media nella lista di quelli associati 
    removeMediaAssociated = (e) => {
        let indexToDelete = e.currentTarget.id;
        let newMediaAssociatedArray = this.state.mediaAssociatedArray;
        if (newMediaAssociatedArray[indexToDelete]._id === this.state.coverobj) { //se immmagine cancellata era settata come cover 
            newMediaAssociatedArray.splice(indexToDelete, 1); //la cancello 
            if (newMediaAssociatedArray.length > 0) { //sse l'array delle immagini contiene ancora immagini
                //setto come cover quella nella posizione 0
                this.setState({ mediaAssociatedArray: newMediaAssociatedArray, coverobj: newMediaAssociatedArray[0]._id });
            } else {
                //non ci sono altre immagini la cover è allo satato iniziale 
                this.setState({ mediaAssociatedArray: newMediaAssociatedArray, coverobj: "" });
            }
        } else {//l'immagine da cancellare non è una cover può essere eliminata senza check  
            newMediaAssociatedArray.splice(indexToDelete, 1); //la cancello 
            this.setState({ mediaAssociatedArray: newMediaAssociatedArray });
        }
    }
    //gestisce input testuali 
    handleTextMethod = (e) => {
        switch (e.target.id) {
            case "nameWarining":
                this.setState({ title: e.target.value, titleerror: (e.target.value.length > 2 ? false : true) });
                break;
            case "descriptionWorning":
                this.setState({ description: e.target.value, descerror: (e.target.value.length > 7 ? false : true) });
                break;
            default:
                break;
        }

    }
    //gestione dei tags 
    handleChangeTags = (event) => {
        // console.log("handleChangeTags")
        // console.log(event.target.name)
        const i = this.state.selectedTags.findIndex(e => e == event.target.name);
        console.log(i);
        if (i > -1) { //caso tag presente quindi lo elimino 
            let newSelectedTag = this.state.selectedTags;
            newSelectedTag.splice(i, 1);
            this.setState({ selectedTags: newSelectedTag });
        } else { // caso tag non presente quindi lo aggiungo 
            const y = this.state.availableTags.findIndex(e => e._id === event.target.name);
            console.log(this.state.availableTags[y]._id)
            this.setState({ selectedTags: [...this.state.selectedTags, this.state.availableTags[y]._id] });
        }
    }
    checkTags = (searchedID) => {
        if (this.state.selectedTags.length > 0) {
            return this.state.selectedTags.find(e => e === searchedID);
        } else {
            return false
        }
    }

    changeGravity = (event) => {

        const MAX_GRAVITY = 99;

        if (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 100) {
            this.setState({
                gravity: parseInt(event.target.value),
                gravityInfo: parseInt(event.target.value) > MAX_GRAVITY ? true : false
            });
        }
        else if (event.target.value === "") {
            this.setState({
                gravity: parseInt(event.target.value),
                gravityInfo: parseInt(event.target.value) > MAX_GRAVITY ? true : false
            });
        }
    }
    showMap = () => {
        this.props.appstate.MapDialog.render = true;
        this.props.appstate.MapDialog.openModalMap = true;
        this.props.dispatch(APP(this.props.appstate));
    }
    // apertura dialog di scleta media  
    openListModal = (event, data) => {
        this.annotatorResourcesDialogRef.init("onlyvideoimage");
        this.props.appstate.DialogResourcesManagerAnnotator.render = true;
        this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
        this.props.appstate.DialogResourcesManagerAnnotator.type = "switchingresource";
        this.props.dispatch(APP(this.props.appstate));
        this.setState({ openResDialog: true });
    }
    //callback dialog di scelta media da associare 
    resourceDialogCallback = (resource) => {
        // console.log("resourceDialogCallback")        
        // console.log(this.props.appstate.ResourceManager.annotatorinfo)
        // if (resource) { //caso uploado risorsa da res managere         
        if (this.state.mediaAssociatedArray.length === 0) {
            this.setState({ mediaAssociatedArray: [...this.state.mediaAssociatedArray, resource], coverobj: resource._id })
        } else {
            this.setState({ mediaAssociatedArray: [...this.state.mediaAssociatedArray, resource] })
        }
        // } else { //caso upload nuova risorsa
        //     this.setState({ mediaAssociatedArray: [...this.state.mediaAssociatedArray, this.props.appstate.ResourceManager.annotatorinfo] })
        // }
    }
    // openUploadModal = (event, data) => {
    //     this.resourcesManagerDialogRef.init({ from: "warning", });
    //     // this.mapDialogRef.init();
    //     //this.setState({ items: this.props.appstate.ResourceManager.resources });
    //     this.props.appstate.ResourceManager.render = true;
    //     this.props.appstate.ResourceManager.openModalResources = false;
    //     this.props.appstate.ResourceManager.openModalResourcesHeader = true;
    //     this.props.appstate.ResourceManager.fromannotator = true;
    //     // this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
    //     this.props.dispatch(APP(this.props.appstate));
    //     this.setState({ openUploadResDialog: true });
    // }


    saveWarnings = () => {
        let data = {};
        data.appname = this.props.appstate.App.name;
        data.domain = this.props.appstate.App.user.domain;
        data.name = this.state.title;
        data.description = this.state.description;
        data.userId = this.props.appstate.App.user.userid;
        // data.resources=this.state.mediaAssociatedArray;
        let resourcesIdArray = this.state.mediaAssociatedArray.map(element => element._id); //estrapola solo gli id dall'array di media
        data.resources = resourcesIdArray.join();//create a string of elemnts array 
        data.tags = this.state.selectedTags.join();//create a string of elemnts array 
        data.locationlng = this.props.appstate.MapDialog.position.lng;
        data.locationlat = this.props.appstate.MapDialog.position.lat;
        data.gravity = this.state.gravity;
        data.coverobj = this.state.coverobj;
        data.uid = this.props.appstate.App.user.nodebb.uid; //----> ---> ---> ---> START 20/05/24
        data.title=this.state.title;
        data.content=this.state.description;
        let self = this;
        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;
        this.props.dispatch(APP(this.props.appstate));
        let jsonlang = this.props.appstate.App.language;
        // const headers= {'Content-Type': 'application/x-www-form-urlencoded'}
        postService(process.env.REACT_APP_DOMAIN + services.warning_create_POST, data,
            "", "",
            function (err, result) {
                if (err) {
                    console.log("errore")
                    console.log(err)
                    self.props.appstate.Modal.openModalLoading = false;
                    self.props.appstate.Modal.openModalLoadingTextActive = true;
                    self.props.appstate.Modal.openModalLoadingText = jsonlang.create_report_message_ko.toUpperCase();
                    self.props.dispatch(APP(self.props.appstate));
                    return;
                }
                if (result.status === 201) {
                    console.log("risposta creazione")
                    console.log(result.response)
                    console.log("201")
                    self.props.appstate.ResourceManager.openModalResources = false;
                    self.props.appstate.Modal.openModalLoadingTextActive = true;
                    self.props.appstate.Modal.openModalLoadingText = jsonlang.create_report_message_ok.toUpperCase();
                    if (result.response) {
                        self.props.appstate.ResourceManager.resources.unshift(result.response.ops[0]);
                        self.props.appstate.ResourceManager.annotatorinfo = Object.assign(
                            {},
                            result.response
                        );
                    }
                    self.props.dispatch(APP(self.props.appstate));
                    self.props.callback();
                } else if (result.status === 200) {
                    // console.log("sono in edit");
                    // self.props.appstate.Modal.openModalLoading = false;
                    // // self.props.appstate.Modal.openModalLoadingTextActive = true;
                    // // self.props.appstate.Modal.openModalLoadingText = "Update ok";
                    // self.props.appstate.Modal.openModalEditConfirm=true;
                    // let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
                    // self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
                    // self.props.dispatch(APP(self.props.appstate));
                    // self.props.callback();
                } else {
                    self.props.appstate.Modal.openModalLoading = false;
                    console.log("sono in else" + result.status);
                    self.props.dispatch(APP(this.props.appstate));
                }
            })
    }


    fileChoiced = (event) => {
        console.log(event.target.files[0])
        // if (this.props.from) { //check for greenscent to limit size to uplaod 
        //     if (this.props.from === "warning") {
        const sizeLimit = 500; //for testing mode  2Mb in production mode 500Mb         
        if (event.target.files.length > 0) {
            const fileSize = event.target.files.item(0).size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= sizeLimit) {
                console.log("file troppo grande ")
                let jsonlang = this.props.appstate.App.language;
                this.props.appstate.Modal.openModalLoading = true;
                this.props.appstate.Modal.openModalLoadingTextActive = true;
                this.props.appstate.Modal.openModalLoadingText = jsonlang.upload_max_size_message;
                this.props.dispatch(APP(this.props.appstate));
                return;
            }
        }
        //     }
        // }
        // file: event.target.files[0] dato file da inviare 
        let fullname = event.target.files[0].name; // elimino l'estesione del filename
        let nameclean = fullname.substr(0, fullname.lastIndexOf('.')) || fullname;
        // costruisci il dataset e invia richiesta  di upload con messaggio di conferma 
        let data = {};
        data.appname = this.props.appstate.App.name;// prendi dato dinamico  
        data.domain = this.props.appstate.App.user.domain;// prendi dato dinamico 
        data.userId = this.props.appstate.App.user.userid;// prendi dato dinamico 
        data.degree = 2;//trova un modo per capire se è 360 o meno 
        data.name = nameclean;
        data.creator = "";
        data.dash = "";
        data.dcRightsHolder = "";
        data.description = "{}";
        data.dest = "resource";
        data.hls = "";
        data.linkright = "https://creativecommons.org/publicdomain/zero/1.0/";
        data.locationlat = 0;
        data.locationlng = 0;
        data.mediaLanguage = "en";
        data.medialan = "en";
        data.mediares = "first";
        data.origin = "asset";
        data.right = "CC0";
        data.textcloudcontent = "";
        data.title = "{}";
        let serviceurl = process.env.REACT_APP_DOMAIN + services.resourcemanager_POST_AUTH;
        this.props.appstate.Modal.openModalLoading = true;
        this.props.dispatch(APP(this.props.appstate));
        let self = this;
        multipartformService(serviceurl, data, this.props.appstate.App.user.token, event.target.files[0], function (err, result) {
            console.log("STATUS " + result.status);
            if (err) {
                self.props.appstate.ResourceManager.openModalResources = false;
                self.props.appstate.Modal.openModalLoadingTextActive = true;
                self.props.appstate.Modal.openModalLoadingText = "Errore";
                self.props.dispatch(APP(self.props.appstate));
                return;
            }
            if (result.status === 201) {
                self.props.appstate.ResourceManager.openModalResources = false;
                self.props.appstate.Modal.openModalLoadingTextActive = true;
                self.props.appstate.Modal.openModalLoadingText = "Risora associata correttamente ";
                if (result.response) {
                    if (self.state.mediaAssociatedArray.length === 0) {
                        self.setState({ mediaAssociatedArray: [...self.state.mediaAssociatedArray, result.response], coverobj: result.response._id });
                    } else {
                        self.setState({ mediaAssociatedArray: [...self.state.mediaAssociatedArray, result.response] });
                    }

                }
                self.props.dispatch(APP(self.props.appstate));
            }
            // self.props.callback();
            //   } else if (result.status === 200) {
            //     console.log("result response di update")
            //     console.log(result.response);
            //     self.props.appstate.Modal.openModalLoading = false;
            //     // self.props.appstate.Modal.openModalLoadingTextActive = true;
            //     // self.props.appstate.Modal.openModalLoadingText = "Update ok";
            //       self.props.appstate.Modal.openModalEditConfirm=true;
            //     let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
            //     self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            //     self.props.dispatch(APP(self.props.appstate));
            //      self.resourcesEditDialogRef.firstInit(self.state.form.medialan)
            //     self.props.callback();
            //   } else {
            //     self.props.appstate.Modal.openModalLoading = false;
            //     self.props.dispatch(APP(this.props.appstate));
            //   }
        })
    }

    handleChangeCover = (e) => {
        // console.log("change cover ");
        // console.log(e.currentTarget.id);
        this.setState({ coverobj: e.currentTarget.id });
    }

    render() {

        let jsonlang = this.props.appstate.App.language;
        return (
            <>
                {this.props.renderCreate === true ?
                    <>
                        <DialogResourcesManager
                            callback={this.resourceDialogCallback}
                            onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
                            open={this.state.openUploadResDialog}
                        />
                        <DialogResourcesManagerAnnotator
                            callback={this.resourceDialogCallback}
                            onRef={(childthis) => (this.annotatorResourcesDialogRef = childthis)}
                            /*prova per riutilizzare in piu punti il componente */
                            open={this.state.openResDialog}
                        />
                        <div className="iframe">
                            <Dialog
                                disableBackdropClick
                                disableEscapeKeyDown
                                TransitionComponent={Transition}
                                open={this.props.open}
                                onClose={this.onclose}
                                aria-labelledby="dialog-add"
                                fullWidth
                                maxWidth={'xl'}
                            >
                                <DialogTitle id="dialog-add">
                                    {this.props.type !== "" ? jsonlang.create_report.toUpperCase() : "MODIFICA SEGNALAZIONE"}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Box margin={1}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12}>
                                                <Tooltip title={"Titile lenght (min:3-max:15)"}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="nameWarining"
                                                        label={jsonlang.title_report.toUpperCase()}
                                                        defaultValue=""
                                                        onChange={this.handleTextMethod}
                                                        inputProps={{ maxLength: 15 }}
                                                        error={this.state.titleerror}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title={"Description lenght (min:8-max:350)"}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="descriptionWorning"
                                                        label={jsonlang.description_report.toUpperCase()}
                                                        defaultValue=""
                                                        rows="3"
                                                        onChange={this.handleTextMethod}
                                                        multiline
                                                        inputProps={{ maxLength: 350 }}
                                                        error={this.state.descerror}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <ImageList cols={0} rowHeight="auto" style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }}>
                                                    {this.state.availableTags.length > 0 ?
                                                        (this.state.availableTags.map((tag, index) => (
                                                            // <Tooltip title={<h2>{tag.description}</h2>}>
                                                            <FormControlLabel control={
                                                                <Checkbox
                                                                    name={tag._id}
                                                                    color="primary"
                                                                    // checked={this.state.statusRisk} 
                                                                    checked={this.checkTags(tag._id)}
                                                                    onChange={this.handleChangeTags}
                                                                />} label={tag.name[this.props.appstate.App.currentLanguage] ? tag.name[this.props.appstate.App.currentLanguage] : tag.name["en"]} />
                                                            // </Tooltip>
                                                        ))) : <></>}
                                                </ImageList>
                                            </Grid>
                                            <Grid item xs={2} justify="flex-end">
                                                <InputLabel id="gravity-label">{jsonlang.severity.toUpperCase()}</InputLabel>
                                                <Select
                                                    labelId="gravity-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.gravity}
                                                    onChange={this.changeGravity}
                                                    label={<h2>{jsonlang.severity.toUpperCase()}</h2>}>
                                                    <MenuItem value={0}>LOW</MenuItem>
                                                    <MenuItem value={50}>MEDIUM</MenuItem>
                                                    <MenuItem value={100}>HIGH</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                                <Tooltip title={<h2>{jsonlang.upload_content.toUpperCase()}</h2>}>
                                                    <>
                                                        <input
                                                            ref={this.setRefFileinput}
                                                            onChange={this.fileChoiced}
                                                            type="file"
                                                            accept={mediatypes.resourcesReports}
                                                            style={{ display: "none" }}
                                                        // multiple={false}
                                                        />
                                                        <IconButton
                                                            disabled={this.state.mediaAssociatedArray.length === maxElementToAttach ? true : false}
                                                            onClick={this.refFileinputClick}
                                                            size="large"
                                                        >
                                                            <CloudUploadIcon
                                                                color="primary"
                                                                fontSize="inherit"
                                                            ></CloudUploadIcon>
                                                        </IconButton>
                                                    </>
                                                </Tooltip>
                                                <Tooltip title={<h2>{jsonlang.upload_content.toUpperCase()}</h2>}>
                                                    <IconButton
                                                        disabled={this.state.mediaAssociatedArray.length === maxElementToAttach ? true : false}
                                                        onClick={this.openListModal}
                                                        size="large"
                                                    >
                                                        <PlaylistAddIcon
                                                            color="primary"
                                                            fontSize="inherit"
                                                        ></PlaylistAddIcon>
                                                    </IconButton>
                                                </Tooltip>
                                                {jsonlang.upload_content.toUpperCase()}
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Grid item xs={12}>
                                                    <Tooltip title={<h2>{jsonlang.position_report.toUpperCase()}</h2>}>
                                                        <IconButton
                                                            onClick={this.showMap}
                                                            size="large"
                                                        >
                                                            <RoomIcon
                                                                color="primary"
                                                                fontSize="inherit"
                                                            ></RoomIcon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    {jsonlang.position_report.toUpperCase()}
                                                    {this.props.appstate.MapDialog.position.lat.toFixed(3) !== "0.000" && this.props.appstate.MapDialog.position.lng.toFixed(3) !== "0.000" ?
                                                        <label> {this.props.appstate.MapDialog.position.lat.toFixed(3) + "," + this.props.appstate.MapDialog.position.lng.toFixed(3)} </label> : null}
                                                    {/* </div> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            <Grid item xs={12}>
                                                {this.state.mediaAssociatedArray.length >= 0 ?
                                                    <ImageList cols={4} style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }}>
                                                        {this.state.mediaAssociatedArray.map((item, index) => (
                                                            <ImageListItem key={item.img}>
                                                                <img src={this.coverUrl(item._id, getResourceDataForLanguage(item))} alt={item.name} />
                                                                <ImageListItemBar
                                                                    title={item.name}
                                                                    actionIcon={
                                                                        <>
                                                                            <Tooltip title={<h2>{"IMPOSTA COME COVER"}</h2>}>
                                                                                <Checkbox
                                                                                    id={item._id}
                                                                                    checked={this.state.coverobj === item._id ? true : false}
                                                                                    onChange={this.handleChangeCover}
                                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                                    style={{ color: "white" }}

                                                                                />
                                                                            </Tooltip>
                                                                            <IconButton >
                                                                                <DeleteIcon style={{ color: "white" }} onClick={this.removeMediaAssociated} id={index} />
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                />
                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList>
                                                    : <></>}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={this.onclose}
                                        color="primary"
                                        startIcon={<ClearIcon />}>
                                        <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.instcancel}</Typography>
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        disabled={
                                            (!this.state.titleerror &&
                                                !this.state.descerror &&
                                                this.state.title != "" &&
                                                this.state.description != "" &&
                                                this.state.selectedTags.length > 0 &&
                                                this.state.mediaAssociatedArray.length > 0 &&
                                                this.props.appstate.MapDialog.position != null &&
                                                this.props.appstate.MapDialog.position.lat.toFixed(3) !== "0.000" &&
                                                this.props.appstate.MapDialog.position.lng.toFixed(3) !== "0.000" &&
                                                this.props.appstate.MapDialog.position.lat != null &&
                                                this.props.appstate.MapDialog.position.lng != null ? false : true)
                                            //             && this.props.appstate.MapDialog.position.lat > 0 && this.props.appstate.MapDialog.position.lng > 0
                                            //     this.props.type !== "" ? controllo se decido di usare la stessa dialog 
                                            //         //ADD
                                            //         (this.state.form.name.length > 0 && (this.state.form.url != null && Object.keys(this.state.form.url).length > 0)
                                            //             && this.props.appstate.MapDialog.position != null && this.props.appstate.MapDialog.position.lat != null && this.props.appstate.MapDialog.position.lng != null
                                            //             && this.props.appstate.MapDialog.position.lat > 0 && this.props.appstate.MapDialog.position.lng > 0

                                            //             ? false
                                            //             : true)
                                            //         :
                                            //         //  false
                                            //         //EDIT
                                            //         (this.state.form.name.length > 0 && (this.state.form.url != null && Object.keys(this.state.form.url).length > 0))
                                            //             || (this.props.appstate.MapDialog.position.lat !== this.state.form.locationlat && this.props.appstate.MapDialog.position.lng !== this.state.form.locationlng)
                                            //             ? false
                                            //             : true

                                        }
                                        variant="contained"
                                        size="small"
                                        onClick={this.saveWarnings}
                                        color="primary"
                                        startIcon={<SaveIcon />}>
                                        <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.save}</Typography>
                                        </Tooltip>
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                    </>
                    : <></>}
            </>
        )
    }
}
export default connect(mapStateToProps)(DialogCreateWarning);
