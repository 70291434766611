import React, { Component } from 'react';
import PlacesUpload from './PlacesUploadManager';
import PlacesEdit from './PlacesEditManager';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography
} from "@material-ui/core";

import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import * as services from "../../services/services.json";
import { postService , deleteService} from "../../services/services";
import { APP } from '../../redux/actions/actions';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogPlacesManager extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      isExtRes:false,
      type:this.props.type,
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        name: "",
        description: "",
        dest: "resource",
        isExtRes:false,
      },
      file: undefined
    });
  }


  componentDidMount() {

  }


  dataform = (data, file) => {
    console.log("dataform " +data);
    let stringifyDescription="";
    if (data.description)
    stringifyDescription=JSON.stringify(data.description);
    let stringifyTitle="";
    if (data.title)
    stringifyTitle=JSON.stringify(data.title);
    this.setState({
      isExtRes:data.isExtRes,
      form: {
        type:this.props.type.toLowerCase(),
        url:data.uriExternalRes,
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        domain: this.props.appstate.App.user.domain,
        name: data.name,
        description:stringifyDescription,// data.description ,
        dest: "resource",
        locationlat: this.props.appstate.MapDialog.position.lat,
        locationlng: this.props.appstate.MapDialog.position.lng,
        title:stringifyTitle,
        right:data.selectedRight,
        linkright:data.linkRight,
        degree:data.degreeMediaSelected,
        mediaLanguage:data.selectedMediaLanguage,
        creator:data.creator,
        dcRightsHolder:data.dcRightsHolder,
        medialan:data.selectedMediaLanguage,
        mediares:data.mediares,
        uriCoverExternalRes:data.uriCoverExternalRes,
        publicStatusExtRes:data.publicStatusExtRes,
        gravity:data.gravity
      }
    }
  );

}



dataformEdit = (data, file) => {
  let stringifyDescription = "";
  if (data.description)
  stringifyDescription = JSON.stringify(data.description);
  let stringifyTitle = "";
  if (data.title)
  stringifyTitle = JSON.stringify(data.title);
  console.log(data);
  this.setState({
    isExtRes:true,
    form: {
      type:data.type,
      url:data.url,
      appname: this.props.appstate.App.name,
      userId: this.props.appstate.App.user.userid,
      domain: this.props.appstate.App.user.domain,
      name: data.name,
      description: stringifyDescription,// data.description ,
      dest: "resource",
      locationlat: this.props.appstate.MapDialog.position.lat,
      locationlng: this.props.appstate.MapDialog.position.lng,
      title: stringifyTitle,
      right: data.selectedRight,
      linkright: data.linkRight,
      degree: data.degree,
      mediaLanguage: data.selectedMediaLanguage,
      id: data.id,
      dcCreator: data.creator,
      dcRightsHolder:data.dcRightsHolder,
      uriCoverExternalRes:data.uriCoverExternalRes,
      publicStatusExtRes:data.publicStatusExtRes,
      gravity:data.gravity

    }
  }
);

}

saveResource = () => {
  this.setState(
    prevState => ({
      form: {
        ...prevState.form,
        locationlat: this.props.appstate.MapDialog.position.lat,
        locationlng: this.props.appstate.MapDialog.position.lng
      }
    }), this.callDBforSave);

    this.props.onClose();

  }

  callDBforSave = () => {


    console.log(this.state.form);
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false; //da commentare
    this.props.dispatch(APP(this.props.appstate));
    let jsonlang = this.props.appstate.App.language;
    let self = this;
    postService(process.env.REACT_APP_DOMAIN + services.resourcemanager_external_res_POST, this.state.form ,
      "", "",
      function (err, result) {
        self.props.appstate.ResourceManager.fromannotator = false;
        if (err) {
          self.props.appstate.Modal.openModalLoading = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = jsonlang.copyreserror;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        if (result.status === 201) {
          self.props.appstate.Modal.openModalLoading = false;
          self.props.appstate.ResourceManager.openModalResourcesCatalog = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "Upload  ok";
          if (result.response) {
            self.props.appstate.ResourceManager.resources.push(result.response);
          }
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        }else if (result.status === 200) {
          self.props.appstate.Modal.openModalLoading = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "Update ok";
          let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id === result.response._id);
          self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        } else {
          self.props.appstate.Modal.openModalLoading = false;
          self.props.dispatch(APP(this.props.appstate));
        }
      });

    }

    deleteLanguage=(data)=>{
      var self=this;
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resLanguage_DELETE_AUTH,
        data,
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 200) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Delete ok";
            let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id === result.response._id);
            self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            self.props.dispatch(APP(self.props.appstate));
            self.resourcesEditDialogRef.firstInit(self.state.form.medialan)

            return;
          }

        }
      );
    }


    onclose = () => {
      //this.props.appstate.ResourceManager.render = false;
      //  this.props.appstate.ResourceManager.openModalResourcesCatalog = false;
      //this.props.appstate.ResourceManager.edit = false;
      this.props.appstate.ResourceManager.openModalIconLang = false;
      this.props.appstate.ResourceManager.index = 0;
      this.props.appstate.ResourceManager.id = 0;
      this.props.appstate.ResourceManager.resourceInfo = {};
      this.props.dispatch(APP(this.props.appstate));
      this.props.onClose();
    }

    /*  getDisabledSave=()=>{
    console.log("getDisabledSave");

    if(this.state.form.type!==""){
    //ADD
    if(this.state.form.name.length > 0 &&this.state.form.url!==""
    && this.props.appstate.MapDialog.position!=null && this.props.appstate.MapDialog.position.lat!=null && this.props.appstate.MapDialog.position.lng!=null
    && this.props.appstate.MapDialog.position.lat>0&& this.props.appstate.MapDialog.position.lng>0
  )
  return false
  else return true;

}
else
{//EDIT
if(this.state.form.name.length > 0 &&this.state.form.url!==""
&& this.props.appstate.MapDialog.position.lat!==this.props.form.locationlat&& this.props.appstate.MapDialog.position.lng!==this.props.form.locationlng)
return false
else return true;

}
}*/

render() {

  let jsonlang = this.props.appstate.App.language;
  return (
    <>

    <div className="iframe">
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        open={this.props.open}
        onClose={this.onclose}
        aria-labelledby="dialog-add"
        fullWidth
        maxWidth={'xl'}
        >
          <DialogTitle id="dialog-add">
            {this.props.type!=="" ?
            <Add
              aria-label="Add Icon"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            />
            :
            <EditIcon
              aria-label="Add Icon"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            />}
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {this.props.type!=="" ? jsonlang.addresources.toUpperCase() + " " +this.props.type.toUpperCase()  : jsonlang.modify.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          {this.props.type!=="" ?
          <PlacesUpload type={this.props.type} dataform={this.dataform} /> :
          <PlacesEdit type={this.props.type} dataform={this.dataformEdit} deleteLan={this.deleteLanguage}  />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={this.onclose}
            color="primary"
            startIcon={<ClearIcon />}>
            <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
            <Typography variant="button">{jsonlang.instcancel}</Typography>
          </Tooltip>
        </Button>
        <Button
          disabled={
            this.props.type!=="" ?
            //ADD
            (this.state.form.name.length > 0 && (this.state.form.url!=null && Object.keys(this.state.form.url).length >0)
            && this.props.appstate.MapDialog.position!=null && this.props.appstate.MapDialog.position.lat!=null && this.props.appstate.MapDialog.position.lng!=null
            && this.props.appstate.MapDialog.position.lat>0&& this.props.appstate.MapDialog.position.lng>0

            ? false
            : true)
            :
            //  false
            //EDIT
            (this.state.form.name.length > 0 && (this.state.form.url!=null && Object.keys(this.state.form.url).length >0))
            || (this.props.appstate.MapDialog.position.lat!==this.state.form.locationlat&& this.props.appstate.MapDialog.position.lng!==this.state.form.locationlng)
            ? false
            : true

          }
          variant="contained"
          size="small"
          onClick={this.saveResource}
          color="primary"
          startIcon={<SaveIcon />}>
          <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
          <Typography variant="button">{jsonlang.save}</Typography>
        </Tooltip>
      </Button>

    </DialogActions>
  </Dialog>
</div>

</>
)
}
}
export default connect(mapStateToProps)(DialogPlacesManager);
