
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {APP } from '../../redux/actions/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  // TextField,
  Typography,
  Tooltip,
  TextField,
  Input,
  MenuItem,
  Select
} from "@material-ui/core";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogInputAction extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      email: "",
      emailerror: true
    });
  }
  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  init = (reciveDomains) => {
    this.setState({
      email: "",
      emailerror: true,
      render: true,
      domain:reciveDomains,
      selectDomain:""
    });
  }

  onclose = () => {
    this.setState({ render: false }, () => {
      this.props.appstate.InputAndActionDialog.open = false
      this.props.dispatch(APP(this.props.appstate))
    });

    // this.props.closeCallback();
  }

  callback = () => {
    if (this.state.email.length > 0 && this.state.emailerror === false) {
      this.props.appstate.InputAndActionDialog.open = false;
      this.props.dispatch(APP(this.props.appstate));
      this.props.callback(this.state.email,this.state.selectDomain);
    }
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailerror: ((event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null || event.target.value.length === 0)? true : false) });
    }

    handleChange=(e)=> {
      this.setState({
        selectDomain: e.target.value
      })
    }

    render() {
      let jsonlang = this.props.appstate.App.language;
      return (
        <>
        {
          this.state.render === true ?
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={this.props.appstate.InputAndActionDialog.open}
            onClose={this.onclose}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            >
              <DialogTitle >
                <LockOpenIcon
                  aria-label="recovery password"
                  style={{
                    color: "#fff",
                    backgroundColor: "#3f51b5",
                    borderRadius: "50px",
                    fontSize: "40px",
                    padding: "5px",
                  }}
                />
                <span style={{
                  padding: 5,
                  position: 'fixed',
                }}>
                {jsonlang.forgotpwd.toUpperCase()}
              </span>
            </DialogTitle>
            <DialogContent dividers>

              <div>
                <div style={{paddingBottom:20}}>
                  {/* <label for="email">{jsonlang.recoverymail.toUpperCase()}</label> */}
                  <TextField
                    variant="outlined"
                    label={jsonlang.recoverymail.toUpperCase()}
                    required
                    type="email"
                    id="email"
                    fullWidth
                    error={this.state.emailerror}
                    onChange={this.handleChangeEmail}
                  />
                </div>
                <div style={{paddingBottom:40}}>
                  {/* <label for="confirm">{jsonlang.selecteddomain.toUpperCase()}</label> */}
                  <Select
                    required
                    className="MuiInputBase-input-custom"
                    id="id"
                    displayEmpty
                    fullWidth
                    value={this.state.selectDomain}
                    onChange={this.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>{jsonlang.selecteddomain.toUpperCase()}</em>
                      </MenuItem>
                      {this.state.domain.map((domain, i) =>
                        <MenuItem  key={i} className="MuiInputBase-input-custom" value={domain._id}>{domain.shortname}</MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.onclose}
                  color="primary"
                  startIcon={<ClearIcon />}>
                  <Tooltip title={<h2>{jsonlang.cancel.toUpperCase()}</h2>}>
                  <Typography variant="button">{jsonlang.cancel}</Typography>
                </Tooltip>
              </Button>
              {this.state.emailerror === false && this.state.selectDomain!==""?
              <Button
                variant="contained"
                size="small"
                onClick={this.callback}
                color="primary"
                startIcon={<SendIcon />}>
                <Tooltip title={<h2>{jsonlang.send.toUpperCase()}</h2>}>
                <Typography >{jsonlang.send}</Typography>
              </Tooltip>
            </Button>
            : <></>}
          </DialogActions>
        </Dialog>
        : <></>
      }
    </>)
  }
};
export default connect(mapStateToProps)(DialogInputAction);
