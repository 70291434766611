import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Tooltip,
    Typography,
    Grid,
    IconButton
} from "@material-ui/core";

import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import LanguageIcon from '@material-ui/icons/Language';
import * as services from "../../services/services.json";
import { getService, postService } from "../../services/services";
import InfoIcon from '@material-ui/icons/Info';
const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class InfoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            name:"",
            size:""
        });
    }

    init = () => {
        console.log("INIT INFO DIALOG")
        console.log(this.props.appstate.InfoDialog.items);
        console.log(this.props.appstate.InfoDialog.tagElement);
        this.getInfoResource();
    }

    componentDidMount() {
        this.props.onRef(this); //for the parent

    }
    getInfoResource = () => {
        let self=this;
        let ser = process.env.REACT_APP_DOMAIN + services.singleInfoResource_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{RESOURCEID}", this.props.appstate.InfoDialog.items._id);
        ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
        ser = ser.replace("{COVER}", false);
        ser=ser.replace("{MEDIARES}",this.props.appstate.InfoDialog.tagElement);
        ser=ser.replace("{MEDIALAN}",this.props.appstate.InfoDialog.lan);
        console.log("Servizio chiamato")
        console.log(ser)
        getService(ser, "", function (err, result) {
            if (err) {
              console.log("Error to get info resource getInfoResource() " );
              console.log(err);
              return;
            }
            if (result.status === 200) {}
            console.log("Risultato")
            console.log(result);
            self.setState({name:result.response.name,size:result.response.sizebyte });
//             name: "resource_623adfed60ca4270bf519ca3_5_1_8k.ktx2"
// sizebyte: 4902254
        });
      }
    onclose = () => {
        this.props.appstate.InfoDialog.render = false;
        this.props.appstate.InfoDialog.openModalInfo = false;
        this.props.appstate.InfoDialog.items = [];
        this.props.dispatch(APP(this.props.appstate));
    }
    itemClick = (event) => {
        // this.props.callback(event.currentTarget.name);
        // this.props.appstate.FlagsDialog.render = false;
        // this.props.appstate.FlagsDialog.openModalFlags = false;
        // this.props.appstate.FlagsDialog.items = [];
        // this.props.dispatch(APP(this.props.appstate));
    }
    render() {
        let jsonlang = this.props.appstate.App.language;
        let items = this.props.appstate.InfoDialog.items;
        console.log(items)
        return (
            <>
                {this.props.appstate.InfoDialog.render === true ?
                    <div className="iframe">
                        <Dialog
                          disableBackdropClick
                          disableEscapeKeyDown
                          TransitionComponent={Transition}
                            open={this.props.appstate.InfoDialog.openModalInfo}
                            onClose={this.onclose}
                            aria-labelledby="dialog-add"
                            maxWidth={'sm'}
                            fullWidth
                        >
                            <DialogTitle id="dialog-add">
                                <InfoIcon
                                />
                                <span style={{
                                    padding: 5,
                                    position: 'fixed',
                                }}>
                                    {'Info Resource'}
                                </span>
                            </DialogTitle>
                            <DialogContent dividers>

                            <p>Name:  {this.state.name}</p>
                            <p>Size:  {  (this.state.size / Math.pow(1024, 2)).toFixed(2)+ "MB"} </p>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={this.onclose}
                                    color="primary"
                                    startIcon={<ClearIcon />}>
                                    <Tooltip title={<h2>OK</h2>}>
                                        <Typography variant="button">OK</Typography>
                                    </Tooltip>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    : <></>}
            </>
        )
    }
}
export default connect(mapStateToProps)(InfoDialog);
