import React, { Component } from 'react';
import ResourcesUpload from '../Resources/ResourcesUpload';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Slide
} from "@material-ui/core";

import AttachmentIcon from '@material-ui/icons/Attachment';
import * as services from "../../services/services.json";
import { multipartformService } from "../../services/services";
import { APP } from '../../redux/actions/actions';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

class DialogIconLang extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        name: "",
        description: "",
        dest: "resource",
      },
      file: undefined
    });
  }
  componentDidMount() {

  }

  dataform = (data, file) => {
    this.setState({
      form: {
        appname: this.props.appstate.App.name,
        //userId: this.props.appstate.App.user.userid,
        userId: "6077f52aeec4dc8288f88af8",
        name: data.name,
        description: data.description,
        dest: "resource",
      },
      file: file
    });
  }

  saveResource = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    if (this.state.form.name.length > 0 && this.state.form.description.length > 0 && this.state.file) {
      let serviceurl = process.env.REACT_APP_DOMAIN + services.resource_POST_AUTH;
      multipartformService(serviceurl, this.state.form, this.props.appstate.App.user.token, this.state.file, function (err, result) {
        if (err) {
          self.props.appstate.Resource.openModalIconLang = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "resource upload error";
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        if (result.status === 201) {
          self.props.appstate.Resource.openModalIconLang = false;
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = "Upload ok";
          self.props.appstate.Resource.resources.push(result.response.ops[0]);
          self.props.viewIcon(result.response.ops[0]);
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        self.props.appstate.Modal.openModalLoading = false;
        self.props.dispatch(APP(this.props.appstate));
        return;
      })
    }
  }

  onclose = () => {
    this.props.appstate.Resource.openModalIconLang = false;
    this.props.dispatch(APP(this.props.appstate));
  }

  render() {
    return (
      <div className="iframe">
        <Dialog
          open={this.props.appstate.Resource.openModalIconLang}
          onClose={this.onclose}
          aria-labelledby="dialog-title"
          fullWidth
          maxWidth={'sm'}
          >
            <DialogTitle id="dialog-title"><AttachmentIcon fontSize="large" />Resource Upload</DialogTitle>
            <DialogContent dividers>
              <ResourcesUpload dataform={this.dataform} fileAccept={'.png, .jpg, .jpeg'}/>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" size="small" color="primary"
                onClick={this.onclose}>
                Cancel
              </Button>
              {(this.state.form.name.length > 0 && this.state.form.description.length && this.state.file) ? <Button variant="contained" size="small" color="primary"
                onClick={this.saveResource}>
                Save
              </Button> : <></>}
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }
  export default connect(mapStateToProps)(DialogIconLang);
