import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Divider,
  Button,
  // Container,
  // IconButton,
  // Input,
  Fab,
  Link,
  Typography,
  Tooltip,
  Checkbox,
  Box,
  TextField
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode,faFilePdf,faFileExcel,faFileWord,faFile  } from '@fortawesome/free-solid-svg-icons';
import ShareIcon from '@material-ui/icons/Share';
// import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
// import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import { APP } from '../../redux/actions/actions';
import './PreviewDialog.css'
import QRCode from 'qrcode.react';
import { getRights } from "../../Utils/utility";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import { getStreamFileService} from "../../services/services";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
  getResourceDataForLanguage,
  getExternalMediaType
} from "../../Utils/utility";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Share = (props) => (
  <div>
    <div style={{
      width: "90%",
      display:"inline"
    }}>

    <input id="sharecopyinput" style={{
      marginLeft: "70px",
      marginTop: "15px",
      width: "90%"
    }} type="text"
    name="firstname" value={props.url} />
  {/* </div>
  <div> */}
    <Tooltip title={<h2>URL COPY</h2>}>
    {props.copied === false ?
      <Fab
        style={{
          
          marginLeft: "40%",          
        }}
        role="button"
        color="primary"
        size="large"
        aria-label="Add"
        onClick={props.copyurl}
        >
          COPY
        </Fab> : <Fab
          style={{
            
            marginLeft: "40%",            
          }}
          role="button"
          color="primary"
          size="large"
          aria-label="Add"
          onClick={props.onclose}
          >
            OK
          </Fab>}
        </Tooltip>
      </div>
    </div>
  );

  class PreviewDialog extends Component {
    constructor(props) {
      super(props);
      this.state = ({ copied: false, logo:"" });
    }
    init = () => {
      this.setState({ copied: false });
    }

    componentDidMount() {
      this.props.onRef(this); //for the parent

      switch (this.props.appstate.App.name.toUpperCase()) {
        case 'SOCLOSE':
        this.setState({logo:"images/partner/logo.png"});
        break;
        case 'VDA':
        this.setState({logo:"images/partner/logo_vda.png"});
        break;
        case 'GREENSCENT':
        this.setState({logo:"images/partner/logo_greenscent_piccolo.png"});
        break;
        case 'SMST':
        this.setState({logo:"images/partner/logo_ideha.png"});
        break;
        case 'SMSTAPP':
        this.setState({logo:"images/partner/logo_lungo_SMST.png"});
        break;
        case 'IDEHA':
        this.setState({logo:"images/partner/logo_ideha.png"});
        break;
        default:
        this.setState({logo:"images/partner/logo.png"});
      }

    }

    onclose = () => {
      this.props.appstate.PreviewDialog.render = false;
      this.props.appstate.PreviewDialog.qrcode = false;
      this.props.appstate.PreviewDialog.share = false;
      this.props.appstate.PreviewDialog.openModalPreview = false;
      this.props.appstate.PreviewDialog.item = {};
      this.props.appstate.PreviewDialog.itemurl = "";
      this.props.dispatch(APP(this.props.appstate));
    }

    copyurl = () => {
      const temp = document.getElementById("sharecopyinput");
      temp.select();
      document.execCommand("copy");
      this.setState({ copied: true });
    }

    //download qrcode
    downloadQRcode = () => {
      if(this.props.appstate.PreviewDialog.qrcode===true){
        const canvas = document.getElementById("qrcode");
        if(canvas !=null){
          const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
          let downloadLink = document.createElement("a");
          downloadLink.href = pngUrl;
          downloadLink.download = this.props.appstate.PreviewDialog.item.name+".png";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }}
      }

      getDescriptionOrTitleMultilanguage=(textObject)=>{
        let returnText="";
        let arrayTextObj=Object.entries(textObject);
        if (arrayTextObj.length === 0 && textObject.constructor === Object){
          return "N.A.";
        }else {
          let selectedTextLang="";
          arrayTextObj.forEach(([key, value]) => {
            if ((key===this.props.appstate.App.currentLanguage) || (key==="en")){
              selectedTextLang=key;
            }
          })
          // console.log(selectedTextLang)
          if (selectedTextLang===""){
            returnText=textObject[Object.keys(textObject)[0]];
          }else{
            returnText=textObject[selectedTextLang];
          }
          console.log("KEY after :"+selectedTextLang);
          console.log("Testo:"+returnText)
          return   returnText;
        }
      }
      openDocument=()=>{
        if (this.props.appstate.PreviewDialog.item.extension!=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&this.props.appstate.PreviewDialog.item.extension!=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          window.open(this.props.appstate.PreviewDialog.itemurl, "_blank")
        }else {
          let self=this;
          self.props.appstate.Modal.openModalLoading = true;
          self.props.appstate.Modal.openModalLoadingTextActive = false;
          self.props.dispatch(APP(this.props.appstate));
          getStreamFileService(this.props.appstate.PreviewDialog.itemurl,"",function (err,result) {
            if (err){
              console.log(err)
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = "Error with preview docuement";
              self.props.dispatch(APP(self.props.appstate));
              return;
            }
            self.props.appstate.Modal.openModalLoading = false;
            self.props.dispatch(APP(self.props.appstate));
            const file = new Blob([result.data],{type: self.props.appstate.PreviewDialog.item.extension});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
          })
        }
      }
      getUTCData=(data)=>{
        return new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'UTC',
          timeZoneName: 'short'}).format(new Date( data ));
        }
        
      
        openTab=()=>{
          let url="";
          if (typeof(this.props.appstate.PreviewDialog.item.url)==='string'){ //retrocompatibilità
            url = this.props.appstate.PreviewDialog.item.url;
          }else {
            let item={};
            item.medialan=this.props.appstate.PreviewDialog.item.url;
            let lang =  getResourceDataForLanguage(item);
            url = this.props.appstate.PreviewDialog.item.url[lang];
          }
          
          console.log(url)
          window.open(url);
        }

        truncate = (str, n) =>{
          return (str.length > n) ? str.substr(0, n-1) + '...' : str;
        };
        getLangText=()=>{
          let item={};
          item.medialan=this.props.appstate.PreviewDialog.item.textcloudcontent;
          let lang =  getResourceDataForLanguage(item);
          return this.props.appstate.PreviewDialog.item.textcloudcontent[lang];          
        }
        getURlShare = (currentLang) => {
          if (getExternalMediaType().findIndex(x => x.value === this.props.appstate.PreviewDialog.item.type) !== -1) {
            let selectedUri = this.props.appstate.PreviewDialog.item.url;
            let url = "";
            if (typeof (selectedUri) === 'string') { //retrocompatibilità
              url = selectedUri;
            } else {
              let itemHELP = {};
              itemHELP.medialan = selectedUri;
              let lang = getResourceDataForLanguage(itemHELP, currentLang);
              url = selectedUri[lang];
            }
      
            this.props.appstate.PreviewDialog.itemurl = url;
          } else if (this.props.appstate.App.user.domainDetail.backupaws === true && this.props.appstate.PreviewDialog.item.streamextloaded === true) {
            this.props.appstate.PreviewDialog.itemurl = this.props.appstate.PreviewDialog.item.streamext;
          } else {
            this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.props.appstate.PreviewDialog.item._id, getResourceDataForLanguage(this.props.appstate.PreviewDialog.item, currentLang));
      
          }
          console.log("url " + this.props.appstate.PreviewDialog.itemurl);
          return this.props.appstate.PreviewDialog.itemurl;
        }
        render() {
          let jsonlang = this.props.appstate.App.language;
          return (
            <>
            {this.props.appstate.PreviewDialog.render === true ?

              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.props.appstate.PreviewDialog.openModalPreview}
                onClose={this.onclose}
                aria-labelledby="dialog-add"
                fullWidth
                maxWidth={'xl'}
                >
                  <Fab
                    style={{
                      margin: 0,
                      top: 0,
                      right: 0,
                      bottom: 'auto',
                      left: 'auto',
                      position: 'fixed'
                    }}
                    role="button"
                    color="primary"
                    size="large"
                    aria-label="Add"
                    onClick={this.onclose}
                    >
                      <CloseIcon
                      />
                    </Fab>
                    <div className="divexitbutton">


                      {(this.props.appstate.PreviewDialog.qrcode === true ?

                        <div>
                          {/* <DialogTitle>{jsonlang.resshare.toUpperCase()}</DialogTitle> */}

                          <DialogTitle>
                            {/* <span style={{
                              color: this.props.appstate.App.theme.refmaterial.palette.primary.main,
                              borderRadius: "50px",
                              fontSize: "32px"
                            }}> */}
                            <FontAwesomeIcon icon={faQrcode}/>
                            {/* </span> */}
                            {/* <ShareIcon
                              aria-label="share info"
                              style={{
                              color: "#fff",
                              backgroundColor: "#3f51b5",
                              borderRadius: "50px",
                              fontSize: "36px",
                              padding: "7px",
                            }}
                          /> */}
                          <span style={{
                            padding: 10,
                            position: 'fixed',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '55vmin'
                          }}>
                          {jsonlang.rescode.toUpperCase()}
                        </span>
                      </DialogTitle>
                      <DialogContent style={{textAlign:"center" }}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={2}
                          >


                            <Grid item xs='auto'>
                              {this.props.appstate.PreviewDialog.item.edmRights !== null?
                                <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                                <Link
                                  color="inherit"
                                  role="link"
                                  aria-label="Public Domain - No Copyright"
                                  href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                                  target="blank"
                                  >
                                    <img className="rights-img" alt="CC-BY-NC-ND" src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                                  </Link>
                                </Tooltip>
                                : <span/>}
                              </Grid>

                              <Grid item  xs='auto'>
                                {this.props.appstate.PreviewDialog.item.edmRights?
                                  this.props.appstate.PreviewDialog.item.edmRights.dcRights:""
                                }
                              </Grid>

                              <Grid item  xs='auto'>
                                <Link
                                  color="inherit"
                                  role="link"
                                  aria-label="Public Domain - No Copyright"
                                  href={
                                    this.props.appstate.PreviewDialog.item.edmRights
                                    ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                                    : ""
                                  }
                                  target="blank"
                                  >
                                    {
                                      this.props.appstate.PreviewDialog.item.edmRights
                                      ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                                      : ""
                                    }
                                  </Link>
                                </Grid>


                                <Grid item xs={12} sm={12}>
                                  <QRCode
                                    // className="qrcode"
                                    id="qrcode"
                                    size={320}
                                    includeMargin="true"
                                    value={this.props.appstate.PreviewDialog.itemurl}
                                    level={"Q"}
                                    // includeMargin={false}
                                    //renderAs={"svg"}
                                    imageSettings={{
                                      src: this.state.logo,
                                      x: null,
                                      y: null,
                                      height: 36,
                                      width: 72,
                                      excavate: true,
                                    }}
                                  />
                                </Grid>


                                <Grid item xs={12} sm={12}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={ this.downloadQRcode}
                                    color="primary"
                                    >
                                      <Tooltip title={<h2>{jsonlang.downloadqr.toUpperCase()}</h2>}>
                                      <Typography >{jsonlang.downloadqr.toUpperCase()}</Typography>
                                    </Tooltip>
                                  </Button>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>

                            </DialogActions>
                          </div>


                          :

                          this.props.appstate.PreviewDialog.share === true ?

                        //   <div>
                        //     <DialogTitle>
                        //       <ShareIcon
                        //         aria-label="share info"
                        //         style={{
                        //           color: "#fff",
                        //           backgroundColor: "#3f51b5",
                        //           borderRadius: "50px",
                        //           fontSize: "36px",
                        //           padding: "7px",
                        //         }}
                        //       />
                        //       <span style={{
                        //         paddingLeft: 15,
                        //         position: 'fixed',
                        //         whiteSpace: 'nowrap',
                        //         overflow: 'hidden',
                        //         textOverflow: 'ellipsis',
                        //         width: '55vmin'
                        //       }}>
                        //       {jsonlang.resshare.toUpperCase()}
                        //     </span>
                        //   </DialogTitle>

                        //   {this.props.appstate.PreviewDialog.item.edmRights !== null?
                        //     <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                        //     <Link
                        //       color="inherit"
                        //       role="link"
                        //       aria-label="Public Domain - No Copyright"
                        //       href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                        //       target="blank"
                        //       >
                        //         <img className="rights-img" alt="CC-BY-NC-ND" src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                        //       </Link>
                        //     </Tooltip>
                        //     : <span/>
                        //   }
                        //   <Share url={this.props.appstate.PreviewDialog.itemurl} copyurl={this.copyurl} copied={this.state.copied} onclose={ this.onclose}/>
                        // </div>
<>

                  <DialogTitle>
                    <ShareIcon
                      aria-label="share info"
                      style={{
                        color: "#fff",
                        backgroundColor: "#3f51b5",
                        borderRadius: "50px",
                        fontSize: "36px",
                        padding: "7px",
                      }}
                    />
                    <span style={{
                      paddingLeft: 15,
                      position: 'fixed',
                      // whiteSpace: 'nowrap',
                      // overflow: 'hidden',
                      // textOverflow: 'ellipsis',
                      // width: '55vmin'
                    }}>
                      {jsonlang.resshare.toUpperCase()}
                    </span>
                  </DialogTitle>

                  <DialogContent dividers
                    style={{
                      textAlign: "center",
                      // paddingBottom:"180px"
                      height: "80vh"
                    }}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={12} sm={12}>
                        <div className="ellipsed">  <span><b>{jsonlang.previewname.toUpperCase()}: </b></span>
                          <label>{this.props.appstate.PreviewDialog.item.name}</label></div>
                      </Grid>


                      <Grid item xs='auto' style={{ marginRight: "10px" }}>
                        {this.props.appstate.PreviewDialog.item.edmRights !== null ?
                          <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                            <Link
                              color="inherit"
                              role="link"
                              aria-label="Public Domain - No Copyright"
                              href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                              target="blank"
                            >
                              <img className="rights-img" alt="CC-BY-NC-ND" src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo} />
                            </Link>
                          </Tooltip>
                          : <span />}
                      </Grid>

                      <Grid item xs='auto' style={{ marginRight: "10px" }}>
                        {this.props.appstate.PreviewDialog.item.edmRights ?
                          this.props.appstate.PreviewDialog.item.edmRights.dcRights : ""
                        }
                      </Grid>

                      <Grid item xs='auto'>
                        <Link
                          color="inherit"
                          role="link"
                          aria-label="Public Domain - No Copyright"
                          href={
                            this.props.appstate.PreviewDialog.item.edmRights
                              ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                              : ""
                          }
                          target="blank"
                        >
                          {
                            this.props.appstate.PreviewDialog.item.edmRights
                              ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                              : ""
                          }
                        </Link>
                      </Grid>

                      {/* {this.state.itemsLan.map((item, index) => */}

                        <Grid item xs={12} sm={12}>

                          {/* <Grid item xs={2} sm={2}> */}
                          {/* <div value={item}> */}
                          
                          {/* </div> */}
                          {/* </Grid> */}
                          <>&nbsp;&nbsp;&nbsp;</>


                          {/* <Grid item xs={8} sm={8}> */}
                          <input
                            id="sharecopyinput"
                            style={{
                              width: "90%"
                            }}
                            type="text"
                            name={this.props.appstate.PreviewDialog.item}

                            value={this.getURlShare(this.props.appstate.PreviewDialog.item)}
                          />
                          {/* </Grid> */}
                          <>&nbsp;&nbsp;</>

                          {/* <Grid item xs={2} sm={2}> */}
                          <Tooltip title={<h2>URL COPY</h2>}>
                            {this.state.copied === false ?
                              <Fab
                                role="button"
                                color="primary"
                                size="medium"
                                aria-label="Add"
                                onClick={() => this.copyurl(this.props.appstate.PreviewDialog.item)}
                              >
                                <Typography variant="button" display="block">
                                  COPY
                                </Typography>
                              </Fab> : this.state.copied === true ? <Fab
                                style={{
                                  // marginTop: "22%",
                                }}
                                role="button"
                                color="primary"
                                size="large"
                                aria-label="Add"
                                onClick={this.onclose}
                              >
                                OK
                              </Fab>
                                : <Fab
                                  role="button"
                                  color="primary"
                                  size="medium"
                                  aria-label="Add"
                                  onClick={() => this.copyurl(this.props.appstate.PreviewDialog.item)}
                                >
                                  <Typography variant="button" display="block">
                                    COPY
                                  </Typography>
                                </Fab>}
                          </Tooltip>
                          {/* </Grid> */}

                        </Grid>
                      {/* // )} */}
                    </Grid>
                  </DialogContent>
                </>
                        :

                        this.props.appstate.PreviewDialog.item.type === 'image' ||

                        this.props.appstate.PreviewDialog.item.type === 'video' ||

                        this.props.appstate.PreviewDialog.item.type === 'audio'||

                        this.props.appstate.PreviewDialog.item.type === 'document'||
                        this.props.appstate.PreviewDialog.item.type==="imagecloud"||
                        this.props.appstate.PreviewDialog.item.type==="videocloud"||
                        this.props.appstate.PreviewDialog.item.type==="audiocloud"||
                        this.props.appstate.PreviewDialog.item.type==="documentcloud"||
                        this.props.appstate.PreviewDialog.item.type==="webcloud"||
                        this.props.appstate.PreviewDialog.item.type==="filecloud"  ||
                        this.props.appstate.PreviewDialog.item.type==="textcloud"?


                        // ******************************  INIZIO STILE DIALOG COMUNE


                        <div class="info-button">

                          {/* INIZIO PER TYPE */}
                          {  this.props.appstate.PreviewDialog.item.type === 'image' ?

                          <img class="mediacontent" src={this.props.appstate.PreviewDialog.itemurl} alt={"preview_resource"} onClick={()=> window.open(this.props.appstate.PreviewDialog.itemurl, "_blank")}/>

                          : this.props.appstate.PreviewDialog.item.type === 'video' ?

                          <video controls className="mediacontent" style={{height:'95%'}} src={this.props.appstate.PreviewDialog.itemurl} alt={"preview_resource"}></video>

                          : this.props.appstate.PreviewDialog.item.type === 'audio' ?

                          <audio controls className="mediacontent" style={{height:'95%'}} src={this.props.appstate.PreviewDialog.itemurl}></audio>

                          : this.props.appstate.PreviewDialog.item.type === 'document' ?


                          <div class="document-div">
                            <div class="document-div-inner">
                              <Typography style={{margin:"10px"}}>{jsonlang.documentalert.toUpperCase()}</Typography>
                              <Tooltip title={jsonlang.documentdownload.toUpperCase()}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small" onClick={this.openDocument } >{jsonlang.documentdownload.toUpperCase()}</Button>
                                </Tooltip>
                              </div>
                            </div>


                            :
                            this.props.appstate.PreviewDialog.item.type==="imagecloud"||
                            this.props.appstate.PreviewDialog.item.type==="videocloud"||
                            this.props.appstate.PreviewDialog.item.type==="audiocloud"||
                            this.props.appstate.PreviewDialog.item.type==="documentcloud"||
                            this.props.appstate.PreviewDialog.item.type==="filecloud"||
                            this.props.appstate.PreviewDialog.item.type==="textcloud"
                            ?
                            <div class="document-div">
                              <div class="document-div-inner">
                                <img alt="img prev" style={{
                                  display: 'block',
                                  maxWidth: '230px',
                                  maxHeight:'95px',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  width: 'auto',
                                  height: '100px',
                                }} src="/images/icons/ext_res.png"></img>

                                {/*<a href={this.props.appstate.PreviewDialog.item.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>
                                */}
                               <Box m={2} pt={3}>
                                {this.props.appstate.PreviewDialog.item.type==="textcloud"?
                                            <TextField id="outlined-basic" label="Text" variant="outlined" disabled  value={this.getLangText()}/>
                                          :
                                  <Tooltip title={jsonlang.review.toUpperCase()}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="large"
                                      onClick={this.openTab}
                                      >
                                        {jsonlang.review.toUpperCase()}</Button>
                                      </Tooltip>
                                      }
                                    </Box>


                                  </div>
                                </div>
                                : this.props.appstate.PreviewDialog.item.type==="webcloud"  ?
                                this.props.appstate.PreviewDialog.item.uriCoverExternalRes ?
                                <div class="document-div">
                                  <div class="document-div-inner">
                                    <img alt="img prev" style={{
                                      display: 'block',
                                      maxWidth: '230px',
                                      maxHeight:'95px',
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                      width: 'auto',
                                      height: '100px',
                                    }} src={this.props.appstate.PreviewDialog.item.uriCoverExternalRes}></img>

                                    {/*<a href={this.props.appstate.PreviewDialog.item.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>
                                    */}
                                    <Box m={2} pt={3}>

                                      <Tooltip title={jsonlang.review.toUpperCase()}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          size="large"
                                          onClick={this.openTab}
                                          >
                                            {jsonlang.review.toUpperCase()}</Button>
                                          </Tooltip>
                                        </Box>

                                      </div>
                                    </div>
                                    :
                                    <div class="document-div">
                                      <div class="document-div-inner">
                                        <img alt="img prev" style={{
                                          display: 'block',
                                          maxWidth: '230px',
                                          maxHeight:'95px',
                                          marginLeft: 'auto',
                                          marginRight: 'auto',
                                          width: 'auto',
                                          height: '100px',
                                        }} src="/images/icons/ext_res.png"></img>

                                        {/*<a href={this.props.appstate.PreviewDialog.item.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>
                                        */}
                                        <Box m={2} pt={3}>

                                          <Tooltip title={jsonlang.review.toUpperCase()}>
                                            <Button
                                              variant="outlined"
                                              color="primary"
                                              size="large"
                                              onClick={this.openTab}
                                              >
                                                {jsonlang.review.toUpperCase()}</Button>
                                              </Tooltip>
                                            </Box>

                                          </div>
                                        </div>: <></>

                                      }
                                      {/* FINE PER TYPE */}

                                      <div class="info-overlay">
                                        <Grid
                                          container
                                          direction="row"
                                          justify="flex-start"
                                          alignItems="flex-start"
                                          >
                                            <Grid item xs={12} sm={12}>
                                              {this.props.appstate.PreviewDialog.item.type === 'image'? <ImageOutlinedIcon className="fsize25"/> :
                                              this.props.appstate.PreviewDialog.item.type === 'video' ?<PlayCircleOutlineIcon className="fsize25"/>:
                                              this.props.appstate.PreviewDialog.item.type === 'audio' ? <AudiotrackOutlinedIcon className="fsize25"/> :
                                              this.props.appstate.PreviewDialog.item.type === 'document' &&  this.props.appstate.PreviewDialog.item.extension==="application/pdf"?  <FontAwesomeIcon icon={faFilePdf}   size="3x"/>:
                                              this.props.appstate.PreviewDialog.item.type === 'document' &&  this.props.appstate.PreviewDialog.item.extension==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?  <FontAwesomeIcon icon={faFileExcel}   size="3x"/>:
                                              this.props.appstate.PreviewDialog.item.type === 'document' &&  this.props.appstate.PreviewDialog.item.extension==="application/vnd.openxmlformats-officedocument.wordprocessingml.document"?  <FontAwesomeIcon icon={faFileWord}   size="3x"/>:
                                              <FontAwesomeIcon icon={faFile}   size="3x"/>}
                                              <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                                              { getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri ===""?
                                              <img className="rights-img" alt={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).value} src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                                              :
                                              <Link
                                                color="inherit"
                                                role="link"
                                                aria-label="Public Domain - No Copyright"
                                                href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                                                target="blank"
                                                disabled //{getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri==="" ? true : false}
                                                >
                                                  <img className="rights-img" alt={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).value} src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                                                </Link>
                                              }
                                            </Tooltip>
                                            <Tooltip title={<h2>{jsonlang.backupres.toUpperCase()}</h2>}>
                                            <Checkbox
                                              className="positioncloud"
                                              checked={this.props.appstate.PreviewDialog.item.streamextloaded}
                                              icon={<CloudOffIcon />}
                                              checkedIcon={<CloudDoneIcon />}
                                            />
                                          </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.previewname.toUpperCase()}</h4>}
                                          <label>{this.props.appstate.PreviewDialog.item.name}</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {/* {<h4>{jsonlang.previewname.toUpperCase()}</h4>} */}
                                          {<h4>{jsonlang.owner.toUpperCase()}</h4>}
                                          <label>{this.props.appstate.PreviewDialog.item.ownerdetails[0].name}</label>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.creator.toUpperCase()}</h4>}
                                          {this.props.appstate.PreviewDialog.item.dcCreator?
                                            <label>{this.props.appstate.PreviewDialog.item.dcCreator}</label>
                                            :  <label>{"N.A."}</label>
                                          }
                                        </Grid>


                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.rightsholder.toUpperCase()}</h4>}
                                          {this.props.appstate.PreviewDialog.item.dcRightsHolder ?
                                            <label>{this.props.appstate.PreviewDialog.item.dcRightsHolder}</label>
                                            : <label>{"N.A."}</label>
                                          }
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.creationdate.toUpperCase()}</h4>}
                                          <label>{this.getUTCData(this.props.appstate.PreviewDialog.item.createdAt)}</label>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.previewdate.toUpperCase()}</h4>}
                                          <label>{this.getUTCData(this.props.appstate.PreviewDialog.item.modifiedAt)}</label>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.previewtitle.toUpperCase()}</h4>}
                                          {Object.entries(this.props.appstate.PreviewDialog.item.title).length!==0?
                                            <label>{this.getDescriptionOrTitleMultilanguage(this.props.appstate.PreviewDialog.item.title)}</label>
                                            : <label>{"N.A."}</label>
                                          }
                                        </Grid>


                                        <Grid item xs={12} sm={12}>
                                          {<h4>{jsonlang.previewdesc.toUpperCase()}</h4>}
                                          {Object.entries(this.props.appstate.PreviewDialog.item.description).length!==0?
                                            <label>{this.getDescriptionOrTitleMultilanguage(this.props.appstate.PreviewDialog.item.description)}</label>
                                            : <label>{"N.A."}</label>
                                          }
                                        </Grid>

                                      </Grid>
                                    </div>
                                    <div class="info-sub">
                                      {/* <Typography variant="h6" component="h2">
                                      {this.props.appstate.PreviewDialog.item.name}
                                    </Typography> */}
                                    <Typography variant="h6" component="h2">
                                      {this.truncate(this.props.appstate.PreviewDialog.item.name, 80)}
                                    </Typography>
                                  </div>
                                </div>

                                : <></>)

                              }
                              {/* FINE STILE DIALOG COMUNE */}
                            </div>
                          </Dialog>
                          : <></>}
                        </>
                      )
                    }
                  }
                  export default connect(mapStateToProps)(PreviewDialog);
