
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import {
  // getService,
  postService  } from "../../services/services";
import {
  // Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // Slide,
  Grid,
  Typography,
  // FormControl,
  TextField,
  // InputLabel,
  // IconButton,
  MenuItem,
  Tooltip,
  Switch,
  FormControlLabel
  // InputAdornment,
  // OutlinedInput
} from "@material-ui/core";
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import MuseumIcon from '@material-ui/icons/Museum';

import EditIcon from '@material-ui/icons/Edit';


/*
TODO:
1-controlli di coerenza dati di input
2-nazionalita da servizi google devono essere tutte del mondo (bassa priorità)
3-convalida  mail
4-confirmation dialog per success creation
5-failed dialog per unseccess creation
6-loader durante la richiesta
*/


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const MapNations = [
  {
    value: '1',
    label: 'SPAIN',
  },
  {
    value: '2',
    label: 'POLAND',
  },
  {
    value: '3',
    label: 'GREECE',
  },
  {
    value: '4',
    label: 'ITALY',
  },
  {
    value: '5',
    label: 'SERBIA',
  },
  {
    value: '6',
    label: 'ROMANIA',
  },
  {
    value: '7',
    label: 'FINLAND',
  }
];



class DomainsModifyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      legalnameerror:false,
      shortnameerror:false,
      saveButtonDisabled:false,
      domainuser:this.props.dataToModify.usersList[0].domainusers,
      modDomainAdmin:this.props.dataToModify.domain.domainadmin,
      modLegalName:this.props.dataToModify.domain.legalname,
      modCountry:this.props.dataToModify.domain.country,
      modShortName:this.props.dataToModify.domain.shortname,
      cloudfrontflag:this.props.dataToModify.domain.cloudfrontflag,
      backupaws:this.props.dataToModify.domain.backupaws,
      bucketname:this.props.dataToModify.domain.bucketname,
      cloudfrontdomain:this.props.dataToModify.domain.cloudfrontdomain,
      bucketnameerror:(this.props.dataToModify.domain.bucketname ? this.props.dataToModify.domain.bucketname.length >0? false : true : false),
      cloudfronterror:(this.props.dataToModify.domain.cloudfrontdomain ? this.props.dataToModify.domain.cloudfrontdomain.length> 0 ? false : true:false)
    });
  }

  componentDidMount(){
    // console.log("componentDidMount")
    // console.log(this.props.dataToModify)
  }

  onSaveDomian=()=>{
    var self=this;
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    let modifyDomainOBJ={};
    modifyDomainOBJ.id=this.props.dataToModify.domain._id;
    modifyDomainOBJ.country=this.state.modCountry;
    modifyDomainOBJ.legalname=this.state.modLegalName;
    modifyDomainOBJ.appname=this.props.acamar.app;
    if (this.state.modShortName!==this.props.dataToModify.oldShortName){
      modifyDomainOBJ.shortname=this.state.modShortName;
    }
    if (this.state.modDomainAdmin!==this.props.dataToModify.oldDomainAdmin){
      modifyDomainOBJ.newdomainadmin=this.state.modDomainAdmin;
      modifyDomainOBJ.olddomainadmin=this.props.dataToModify.oldDomainAdmin;
    }
    modifyDomainOBJ.cloudfrontflag=this.state.cloudfrontflag;
    modifyDomainOBJ.backupaws=this.state.backupaws;
    modifyDomainOBJ.bucketname=this.state.bucketname;
    modifyDomainOBJ.cloudfrontdomain=this.state.cloudfrontdomain;
    console.log("modifyDomainOBJ")
    console.log(modifyDomainOBJ)
    postService(process.env.REACT_APP_DOMAIN + services.new_updatedomain_POST, modifyDomainOBJ,
      "", "",
      function (err, result) {
        if (err) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.updatedomainerror;
          self.props.dispatch(APP(self.props.appstate));
          // console.log("update domain failed")
        }else  if (result.status === 200) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.updatedomainok;
          self.props.dispatch(APP(self.props.appstate));
          self.onclose();
          self.props.callback()
        }
      }
    );
  }
  onChangeLabel=(e)=>{
    // console.log(e.target.value)
    // console.log(e.target.name)
    var self=this;
    switch (e.target.name) {
      case "MOD_legalname":
      self.setState({modLegalName:e.target.value, legalnameerror: (e.target.value.length > 0 ? false : true)});
      break;
      case "MOD_shortname":
      self.setState({modShortName:e.target.value, shortnameerror: (e.target.value.length > 0 ? false : true)});
      break;
      case "CRE_bucket":
      this.setState({bucketname:e.target.value, bucketnameerror: (e.target.value.length > 0 ? false : true)});
      break;
      case "CRE_cloudfront":
      this.setState({cloudfrontdomain:e.target.value, cloudfronterror: (e.target.value.length > 0 ? false : true)});
      break;
      default:
      break;
    }
  }

  onChagneSelect =(e,d)=>{
    let self=this;
    // console.log("change selector")
    // console.log(d.props)
    switch (e.target.name) {
      case "MOD_country":
      self.setState({modCountry:d.props.value});
      break;
      case "MOD_useradmin":
      self.setState({modDomainAdmin:d.props.value});
      break;
      default:
      break;
    }
  }

  EnableSaving = (event) => {
    if(this.state.saveButtonDisabled===false)
      this.setState({saveButtonDisabled:true});
  }



  cloudfrontflagChange=(event)=>{
    if (event.target.checked ===false){
    this.setState({cloudfrontflag:event.target.checked, cloudfronterror:false});
    }else{
    this.setState({cloudfrontflag:event.target.checked, cloudfronterror: (this.state.cloudfrontdomain.length > 0 ? false : true)});
    }
  }
  backupawsChange=(event)=>{
    if (event.target.checked ===false){
      this.setState({backupaws:event.target.checked , cloudfrontflag:event.target.checked, bucketnameerror:false, cloudfronterror:false});
    }else {
       this.setState({backupaws:event.target.checked , bucketnameerror: (this.state.bucketname.length > 0 ? false : true)});
    }    
  }

  onclose = () => {

    // this.props.callback()
  }
  render() {
    //  console.log("render print:");
    //  console.log(this.props.appstate.ModifyDomDialog.data);
    // var data =this.props.appstate.ModifyDomDialog.data;

    let jsonlang = this.props.appstate.App.language;

    return(
      <>
      {/* {(Object.keys(data).length!==0 ?
        <Dialog
        disableBackdropClick
        open={this.props.appstate.ModifyDomDialog.open}
        onClose={this.onclose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth = {'md'}
        > */}
        <DialogTitle>

            <EditIcon
              aria-label="modify domain"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "2px",
              }}
            />
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {jsonlang.instmodify.toUpperCase()}
          </span>

        </DialogTitle>
        <DialogContent dividers>

          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={this.state.shortnameerror}
                  label={jsonlang.instshortname.toUpperCase()}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onFocus={this.EnableSaving}
                  onChange={this.onChangeLabel}
                  name="MOD_shortname"
                  value={this.state.modShortName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  color="primary"
                  label={jsonlang.country.toUpperCase()}
                  variant="outlined"
                  fullWidth
                  value={this.state.modCountry}
                  onFocus={this.EnableSaving}
                  onChange={this.onChagneSelect}
                  name="MOD_country"
                  >
                    {MapNations.map((nation) =>
                      <MenuItem
                        key={nation.label}
                        value={nation.label}
                        >
                          {nation.label.toUpperCase()}
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>

                    <TextField
                      fullWidth
                      color="primary"
                      error={this.state.legalnameerror}
                      label={jsonlang.legalname.toUpperCase()}
                      variant="outlined"
                      name="MOD_legalname"
                      onChange={this.onChangeLabel}
                      onFocus={this.EnableSaving}
                      defaultValue={this.state.modLegalName}
                    />

                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  spacing={2}
                  >
                    <Grid item xs={6}>
                      <TextField
                        select
                        color="primary"
                        label={jsonlang.users.toUpperCase()}
                        value={this.state.modDomainAdmin}
                        onChange={this.onChagneSelect}
                        onFocus={this.EnableSaving}
                        // helperText="select your nation"
                        // value={this.state.newdomainadmin}
                        name="MOD_useradmin"
                        variant="outlined"
                        fullWidth
                        >
                          {this.state.domainuser.map((utente,index) => (
                            <MenuItem
                              key={utente._id}
                              value={utente._id}
                              >
                                {utente.name} - ({utente.email})
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                     {/* new features */}
                    <br/>
                      <Typography variant="h6" color="primary">{jsonlang.backupoption.toUpperCase()}</Typography>
                    <br/>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                           <Tooltip placement="top"  title={<h2 >{jsonlang.awsbackup.toUpperCase()}</h2>}>
                            <FormControlLabel
                              checked={this.state.backupaws}  
                              onFocus={this.EnableSaving}                                                             
                              control={<Switch color='primary' onChange={this.backupawsChange}/>}
                              label={<Typography>{jsonlang.awsbackup.toUpperCase()}</Typography>}/>
                            </Tooltip> 
                        </Grid>
                        {this.state.backupaws ? 
                        <Grid container>
                        <Grid item xs={12}>
                              <TextField
                                disabled={!this.state.backupaws}
                                autocomplete="new-password"
                                color="primary"
                                onFocus={this.EnableSaving}
                                fullWidth
                                // placeholder={jsonlang.instemail}
                                label={jsonlang.awsbucket.toUpperCase()}
                                variant="outlined"
                                required
                                onChange={this.onChangeLabel}
                                name="CRE_bucket"
                                error={this.state.bucketnameerror}                                        
                                value={this.state.bucketname}
                              />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Tooltip placement="top"  title={<h2 >{jsonlang.awscloudfront.toUpperCase()}</h2>}>
                            <FormControlLabel
                              onFocus={this.EnableSaving}
                              checked={this.state.cloudfrontflag}                                                               
                              control={<Switch color='primary' onChange={this.cloudfrontflagChange}/>}
                              label={<Typography>{jsonlang.awscloudfront.toUpperCase()}</Typography>}/>
                            </Tooltip>                            
                        </Grid> */}
                        </Grid>: null}
                        {/* {this.state.cloudfrontflag ? 
                        <Grid item xs={12}>
                              <TextField
                                onFocus={this.EnableSaving}
                                autocomplete="new-password"
                                color="primary"
                                value={this.state.cloudfrontdomain}
                                fullWidth
                                label={jsonlang.awscloudfrontdomain.toUpperCase()}
                                variant="outlined"
                                required
                                onChange={this.onChangeLabel}
                                name="CRE_cloudfront"
                                error={this.state.cloudfronterror}
                              />
                        </Grid>:null} */}
                      </Grid>                      
                    </div>
                    {/* new features end */}
                  </DialogContent>

                  <DialogActions>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={this.props.closeCallback}
                      color="primary"
                      startIcon={<ClearIcon />}>
                      <Tooltip title={<h2>{jsonlang.instexit.toUpperCase()}</h2>}>
                      <Typography variant="button">{jsonlang.instcancel}</Typography>
                    </Tooltip>
                  </Button>
                  <Button
                    disabled={(
                      this.state.shortnameerror===false
                      && this.state.legalnameerror===false
                      && this.state.saveButtonDisabled!==false
                          && this.state.bucketnameerror===false
                        && this.state.cloudfronterror===false
                    )
                    ? false : true}
                    variant="contained"
                    size="large"
                    onClick={ this.onSaveDomian}
                    color="primary"
                    startIcon={<SaveIcon />}>
                    <Tooltip title={<h2>{jsonlang.instsavedata.toUpperCase()}</h2>}>
                    <Typography variant="button">{jsonlang.instsave}</Typography>
                  </Tooltip>
                </Button>
              </DialogActions>
              {/* </Dialog>
                :
                <span/>
              )} */}
            </>
          )
        }
      }export default connect(mapStateToProps)(DomainsModifyDialog);
