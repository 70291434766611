import React, { Component } from 'react';
import ResourcesUpload from './ResourcesUploadManager';
import ResourcesEdit from './ResourcesEditManager';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography
} from "@material-ui/core";

import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import * as services from "../../services/services.json";
import { multipartformService, postService , deleteService} from "../../services/services";
import { APP } from '../../redux/actions/actions';
import * as mediatypes from "../../Utils/mediatype.json";
import { getExternalMediaType } from "../../Utils/utility";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogResourceManager extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      isExtRes:false,
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        name: "",
        description: "",
        dest: "resource",
        isExtRes:false,
      },
      file: undefined
    });
  }
  init = () => {
    this.setState({
      isExtRes:false,
      form: {
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        name: "",
        description: "",
        dest: "resource",
        isExtRes:false,
         },
      file: undefined
    });
  }

  componentDidMount() {
    this.props.onRef(this); //for the parent
  }


  dataform = (data, file) => {
    let stringifyDescription="";
    if (data.description)
    stringifyDescription=JSON.stringify(data.description);
    let stringifyTitle="";
    if (data.title)
     stringifyTitle=JSON.stringify(data.title);
     if (data.isExtRes){//gestione upload risorsa esterna
      this.setState({
          isExtRes:data.isExtRes,
          form: {
            type:data.selectedExternalMediaType,
            url:data.uriExternalRes,
            appname: this.props.appstate.App.name,
            userId: this.props.appstate.App.user.userid,
            domain: this.props.appstate.App.user.domain,
            name: data.name,
            description:stringifyDescription,// data.description ,
            dest: "resource",
            locationlat: this.props.appstate.MapDialog.position.lat,
            locationlng: this.props.appstate.MapDialog.position.lng,
            title:stringifyTitle,
            right:data.selectedRight,
            linkright:data.linkRight,
            degree:data.degreeMediaSelected,
            mediaLanguage:data.selectedMediaLanguage,
            creator:data.creator,
            dcRightsHolder:data.dcRightsHolder,
            medialan:data.selectedMediaLanguage,
            mediares:data.mediares,
            uriCoverExternalRes:data.uriCoverExternalRes,
            publicStatusExtRes:data.publicStatusExtRes
        }
       }
      );
     }else{     //gestione upload risorsa interna
        this.setState({
          isExtRes:data.isExtRes,
          form: {
            appname: this.props.appstate.App.name,
            userId: this.props.appstate.App.user.userid,
            /*domain: this.props.appstate.App.user.domain,*/
            /*userId: "6077f52aeec4dc8288f88af8",*/
            domain: this.props.appstate.App.user.domain,
            name: data.name,
            description:stringifyDescription,// data.description ,
            dest: "resource",
            locationlat: this.props.appstate.MapDialog.position.lat,
            locationlng: this.props.appstate.MapDialog.position.lng,
            title:stringifyTitle,
            right:data.selectedRight,
            linkright:data.linkRight,
            degree:data.degreeMediaSelected,
            mediaLanguage:data.selectedMediaLanguage,
            creator:data.creator,
            dcRightsHolder:data.dcRightsHolder,
            medialan:data.selectedMediaLanguage,
            mediares: data.mediares,
            origin: this.props.appstate.ResourceManager.fromannotator === true ? "annotator" : "asset"
          },
          file: file
      });
    }
  }



  dataformEdit = (data, file) => {
    let stringifyDescription = "";
    if (data.description)
      stringifyDescription = JSON.stringify(data.description);
    let stringifyTitle = "";
    if (data.title)
      stringifyTitle = JSON.stringify(data.title);
    if (getExternalMediaType().findIndex(x => x.value == data.type)!==-1 ){//gestione upload risorsa esterna
     this.setState({
          isExtRes:true,
          form: {
            type:data.type,
            url:data.url,
            appname: this.props.appstate.App.name,
            userId: this.props.appstate.App.user.userid,
            /*domain: this.props.appstate.App.user.domain,*/
            /*userId: "6077f52aeec4dc8288f88af8",*/
            domain: this.props.appstate.App.user.domain,
            name: data.name,
            description: stringifyDescription,// data.description ,
            dest: "resource",
            locationlat: this.props.appstate.MapDialog.position.lat,
            locationlng: this.props.appstate.MapDialog.position.lng,
            title: stringifyTitle,
            right: data.selectedRight,
            linkright: data.linkRight,
            degree: data.degree,
            mediaLanguage: data.selectedMediaLanguage,
            id: data.id,
            dcCreator: data.creator,
            dcRightsHolder:data.dcRightsHolder,
             uriCoverExternalRes:data.uriCoverExternalRes,
            publicStatusExtRes:data.publicStatusExtRes

        }
       }
      );

    }else{     //gestione upload risorsa interna
    console.log("dati nuovi da inviare")
    console.log(data.mediaresToSend)
    console.log(data.medialanToSend)
    console.log(data.folderIdentifier)
    this.setState({
      isExtRes:false,
      form: {
        type:data.type,
        appname: this.props.appstate.App.name,
        userId: this.props.appstate.App.user.userid,
        /*domain: this.props.appstate.App.user.domain,*/
        /*userId: "6077f52aeec4dc8288f88af8",*/
        domain: this.props.appstate.App.user.domain,
        name: data.name,
        description: stringifyDescription,// data.description ,
        dest: "resource",
        locationlat: this.props.appstate.MapDialog.position.lat,
        locationlng: this.props.appstate.MapDialog.position.lng,
        title: stringifyTitle,
        right: data.selectedRight,
        linkright: data.linkRight,
        degree: data.degree,
        mediaLanguage: data.selectedMediaLanguage,
        id: data.id,
        dcCreator: data.creator,
        dcRightsHolder:data.dcRightsHolder,
        mediares:data.mediaresToSend,
        medialan:data.medialanToSend,
        folderIdentifier:data.folderIdentifier

      },
      file: file
    });
    }
  }

  saveResource = () => {
    console.log(this.state.form);
    console.log(this.state.isExtRes)
    if (this.state.isExtRes){
      console.log("dati external res");
      console.log(this.state.form);
      this.props.appstate.Modal.openModalLoading = true;
      this.props.appstate.Modal.openModalLoadingTextActive = false;
      this.props.dispatch(APP(this.props.appstate));
      let jsonlang = this.props.appstate.App.language;
      let self = this;
      postService(process.env.REACT_APP_DOMAIN + services.resourcemanager_external_res_POST, this.state.form ,
        "", "",
        function (err, result) {
          self.props.appstate.ResourceManager.fromannotator = false;
          if (err) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = jsonlang.copyreserror;
            self.props.dispatch(APP(self.props.appstate));
            return;
          }
           if (result.status === 201) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.ResourceManager.openModalResourcesCatalog = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Upload  ok";
            if (result.response) {
              self.props.appstate.ResourceManager.resources.push(result.response);
            }
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
          }else if (result.status === 200) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Update ok";
            self.props.appstate.Modal.openModalEditConfirm=true;
            let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
            self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
          } else {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.dispatch(APP(this.props.appstate));
          }
        })
    }else{
      console.log("file")
      console.log(this.state.file)
    if (this.state.file) {
      this.props.appstate.Modal.openModalLoading = true;
      this.props.dispatch(APP(this.props.appstate));
      let self = this;
      if (this.state.form.name.length > 0 && this.state.file) { //this.state.form.description!=="{}" && this.state.form.description!=="{}" &&
        let serviceurl = process.env.REACT_APP_DOMAIN + services.resourcemanager_POST_AUTH;
        multipartformService(serviceurl, this.state.form, this.props.appstate.App.user.token, this.state.file, function (err, result) {
          if (err) {
            self.props.appstate.ResourceManager.openModalResourcesCatalog = false;

            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "resource upload error";
            self.props.dispatch(APP(self.props.appstate));
            return;
          }
          if (result.status === 201) {
            self.props.appstate.ResourceManager.openModalResourcesCatalog = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Upload ok";
            if (result.response) {
              //console.log(result.response)
              self.props.appstate.ResourceManager.resources.push(result.response);
              //New field annotator 08/12/2021
              self.props.appstate.ResourceManager.annotatorinfo = Object.assign(
                {},
                result.response
              );
            }
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
          } else if (result.status === 200) {
            console.log("result response di update")
            console.log(result.response);
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Update ok";
            self.props.appstate.Modal.openModalEditConfirm=true;
            let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
            self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            self.props.dispatch(APP(self.props.appstate));
             self.resourcesEditDialogRef.firstInit(self.state.form.medialan)
            self.props.callback();
          } else {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.dispatch(APP(this.props.appstate));
          }
        })
      }
    } else { //update only field
      //disabilita pulsante se owen ed user nonsono la stessa persona
      this.props.appstate.Modal.openModalLoading = true;
      this.props.appstate.Modal.openModalLoadingTextActive = false;
      this.props.dispatch(APP(this.props.appstate));
      let jsonlang = this.props.appstate.App.language;
      let self = this;
      postService(process.env.REACT_APP_DOMAIN + services.resourcemanager_POST_NOFILE_AUTH, { data: this.state.form },
        "", "",
        function (err, result) {
          if (err) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = jsonlang.copyreserror;
            self.props.dispatch(APP(self.props.appstate));
          } else if (result.status === 200) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Update ok";
            self.props.appstate.Modal.openModalEditConfirm=true;
            let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
            self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            self.props.dispatch(APP(self.props.appstate));
             self.resourcesEditDialogRef.firstInit(self.state.form.medialan)
            self.props.callback();
          }
        })
     }
    }
  }

  deleteLanguage=(data)=>{
    // this.props.appstate.Modal.openModalLoading = true;
    //   this.props.appstate.Modal.openModalLoadingTextActive = false;
    //   this.props.dispatch(APP(this.props.appstate));
    var self=this;
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resLanguage_DELETE_AUTH,
         data,
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 200) {
            self.props.appstate.Modal.openModalLoading = false;
            self.props.appstate.Modal.openModalLoadingTextActive = true;
            self.props.appstate.Modal.openModalLoadingText = "Delete ok";
            let itemIndex = self.props.appstate.ResourceManager.resources.findIndex(x => x._id == result.response._id);
            self.props.appstate.ResourceManager.resources[itemIndex] = result.response;
            self.props.dispatch(APP(self.props.appstate));
             self.resourcesEditDialogRef.firstInit(self.state.form.medialan)

            return;
          }

        }
      );
  }

  deleteSingleResolutionRes=(data)=>{
    var self=this;
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resResolution_DELETE_AUTH,
         {    domain: '61ac9dfb698c4b0f017ce2c7',
              id: '61c3aa3ba701325ebf4cc720',
              user: '61ac9dfb698c4b0f017ce2c8',
              appname: 'smst',
              mediares: 'third',
              medialan: 'it'
          },
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 204) {
            console.log("cancellazione ok ");
            console.log(result)
            // self.props.appstate.ResourceManager.resources.splice(self.props.appstate.ResourceManager.index, 1);
            // self.props.appstate.Modal.openModalDelete = false;
            // self.props.dispatch(APP(self.props.appstate));
            // self.props.callback();
            return;
          }
          // self.props.appstate.Modal.openModalDelete = false;
          // self.props.dispatch(APP(self.props.appstate));
          // self.props.callback();
        }
      );
  }


  onclose = () => {
    this.props.appstate.ResourceManager.render = false;
    this.props.appstate.ResourceManager.openModalResourcesCatalog = false;
    this.props.appstate.ResourceManager.edit = false;
    this.props.appstate.ResourceManager.openModalIconLang = false;
    this.props.appstate.ResourceManager.index = 0;
    this.props.appstate.ResourceManager.id = 0;
    this.props.appstate.ResourceManager.resourceInfo = {};
    this.props.dispatch(APP(this.props.appstate));
  }

  render() {
    let jsonlang = this.props.appstate.App.language;    
    return (
      <>
      {this.props.appstate.ResourceManager.render === true ?
        <div className="iframe">
          <Dialog
            open={this.props.appstate.ResourceManager.openModalResourcesCatalog}
            onClose={this.onclose}
            TransitionComponent={Transition}
            aria-labelledby="dialog-add"
            fullWidth
            maxWidth={'xl'}
            >
              <DialogTitle id="dialog-add">
                {this.props.appstate.ResourceManager.edit === false ?
                  <Add
                    aria-label="Add Icon"
                    style={{
                      color: "#fff",
                      backgroundColor: "#3f51b5",
                      borderRadius: "50px",
                      fontSize: "36px",
                      padding: "2px",
                    }}
                  />
                  :
                  <EditIcon
                    aria-label="Add Icon"
                    style={{
                      color: "#fff",
                      backgroundColor: "#3f51b5",
                      borderRadius: "50px",
                      fontSize: "36px",
                      padding: "2px",
                    }}
                  />}
                <span style={{
                  padding: 5,
                  position: 'fixed',
                }}>
                  {this.props.appstate.ResourceManager.edit === false ? jsonlang.addresources.toUpperCase()  : "EDIT"}
              </span>
            </DialogTitle>
              <DialogContent dividers>
                {this.props.appstate.ResourceManager.edit === false ?
                <ResourcesUpload dataform={this.dataform} fileAccept={mediatypes.resourcesMT} /> :
                <ResourcesEdit dataform={this.dataformEdit} deleteLan={this.deleteLanguage} fileAccept={mediatypes.resourcesMT}   onRef={(childthis) => (this.resourcesEditDialogRef = childthis)} />}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
                <Typography variant="button">{jsonlang.instcancel}</Typography>
              </Tooltip>
            </Button>
            {/* {(this.state.form.name.length > 0 && this.state.form.description.length && this.state.file) ?
              <Button
              variant="contained"
              size="small"
              onClick={this.saveResource}
              color="primary"
              startIcon={<SaveIcon />}>
              <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
              <Typography variant="button">{jsonlang.save}</Typography>
            </Tooltip>
          </Button>
          : <></>} */}
                {this.props.appstate.ResourceManager.edit === false ?
                  <Button
                    disabled={this.state.isExtRes?
                     (this.state.form.name.length > 0 && this.state.form.type!==""&&this.state.form.url!==""  )? false: true :
                     (this.state.form.name.length > 0 && this.state.file) ?false : true}
                    variant="contained"
                    size="small"
                    onClick={this.saveResource}
                    color="primary"
                    startIcon={<SaveIcon />}>
                    <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
                      <Typography variant="button">{jsonlang.save}</Typography>
                    </Tooltip>
                  </Button>
                  :
                  <Button
                  // this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index].name.length > 0||
                    disabled={ this.state.form.name!="" ?
                      false : true}
                    variant="contained"
                    size="small"
                    onClick={this.saveResource}
                    color="primary"
                    startIcon={<SaveIcon />}>
                    <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
                      <Typography variant="button">{jsonlang.save}</Typography>
                    </Tooltip>
                  </Button>}
        </DialogActions>
      </Dialog>
    </div>
    : <></>}
  </>
)
}
}
export default connect(mapStateToProps)(DialogResourceManager);
