import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // CircularProgress,
  DialogActions,
  Button,
  Tooltip,
  Slide,
  Typography
} from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import { deleteService} from "../../services/services";
import * as services from "../../services/services.json";

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogConfirm extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  onclose = () => {
    this.props.appstate.Modal.openModalDelete = false;
    this.props.appstate.Modal.openModalType = "";
    this.props.appstate.ResourceManager.resourceInfo={ };
    this.props.dispatch(APP(this.props.appstate));
  }

  deleteconfirm = () => {
    let self = this;
    //let index = event.currentTarget.name;
    if (this.props.appstate.Modal.openModalType === "manager") {
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resourcemanager_DELETE_AUTH,
        {
          id: this.props.appstate.ResourceManager.id,
          appname: this.props.appstate.App.name,
          domain: this.props.appstate.App.user.domain,
          user: this.props.appstate.App.user.userid,
          bucket: this.props.appstate.App.user.domainDetail.bucketname
        },
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 204) {
            self.props.appstate.ResourceManager.resources.splice(self.props.appstate.ResourceManager.index, 1);
            self.props.appstate.Modal.openModalDelete = false;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
            return;
          }
          self.props.appstate.Modal.openModalDelete = false;
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        }
      );
    }else{
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resource_DELETE_AUTH,
        { id: this.props.appstate.Resource.id, appname: this.props.appstate.App.name  , domain: this.props.appstate.App.user.domain , user: this.props.appstate.App.user.userid},
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 204) {
            self.props.appstate.Resource.resources.splice(self.props.appstate.Resource.index, 1);
            self.props.appstate.Modal.openModalDelete = false;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
            return;
          }
          self.props.appstate.Modal.openModalDelete = false;
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        }
      );
    }
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          TransitionComponent={Transition}
          open={this.props.appstate.Modal.openModalDelete}
          onClose={this.onclose}
          aria-labelledby="dialog-title"
          fullWidth
          maxWidth={'sm'}
          >
            <DialogTitle id="dialog-remove">
              <DeleteIcon
                aria-label="Delete info"
                style={{
                  color: "#fff",
                  backgroundColor: "#3f51b5",
                  borderRadius: "50px",
                  fontSize: "36px",
                  padding: "5px",
                }}
              />
              <span style={{
                padding: 5,
                position: 'fixed',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '55vmin'
              }}>
              {jsonlang.remove.toUpperCase() + " " + this.props.appstate.ResourceManager.resourceInfo.name}
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <div>
            {
            this.props.appstate.ResourceManager.resourceInfo.type==="imagecloud"||
            this.props.appstate.ResourceManager.resourceInfo.type==="videocloud"||
            this.props.appstate.ResourceManager.resourceInfo.type==="audiocloud"||
            this.props.appstate.ResourceManager.resourceInfo.type==="documentcloud"||
            this.props.appstate.ResourceManager.resourceInfo.type==="webcloud"||
            this.props.appstate.ResourceManager.resourceInfo.type==="filecloud"
            ?
            <div style={{"text-align": "center"}}>
            <img style={{
              display: 'block',
              maxWidth: '230px',
              maxHeight:'95px',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 'auto',
              height: '100px',
            }} src="/images/icons/ext_res.png"></img>

            <a href={this.props.appstate.ResourceManager.resourceInfo.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>
            </div>
             :     this.props.appstate.ResourceManager.resourceInfo.type==="textcloud" ?
             <div style={{"text-align": "center"}}>
             <img style={{
               display: 'block',
               maxWidth: '230px',
               maxHeight:'95px',
               marginLeft: 'auto',
               marginRight: 'auto',
               width: 'auto',
               height: '100px',
             }} src="/images/icons/text.png"></img>

             </div>


            :
            <img style={{
              display: 'block',
              maxWidth: '230px',
              maxHeight:'95px',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 'auto',
              height: '100px',
            }} src={this.props.appstate.ResourceManager.resourceInfo.clientcover}></img>
            }
            </div>
            <Typography
              variant='h6'
              justify='center'
              align='center'
              >
                {jsonlang.messageconfirm}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.no}</h2>}>
                <Typography variant="button">{jsonlang.no}</Typography>
              </Tooltip>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={this.deleteconfirm}
              color="primary"
              startIcon={<DoneIcon />}>
              <Tooltip title={<h2>{jsonlang.yes}</h2>}>
              <Typography variant="button">{jsonlang.yes}</Typography>
            </Tooltip>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
}
export default connect(mapStateToProps)(DialogConfirm);
