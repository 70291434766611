import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
import {
    WHOLE ,
  APP} from '../../redux/actions/actions';
  // import ReactDOM from 'react-dom';
  import './Login.css';
  import * as services from "../../services/services.json";
  import {
    postService,
    getService
  } from "../../services/services";
  // import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
  import DialogInputAction from "../Dialog/DialogInputAction";

  import {
    AppBar,
    // Box,
    Button,
    Container,
    Grid,
    // Input,
    // InputLabel,
    FormHelperText,
    Link,
    Paper,
    Tooltip,
    Typography,
    OutlinedInput,
    InputAdornment,
    IconButton,
    MenuItem,
    Select,
    TextField,
  } from "@material-ui/core";
  // import { FlashOnOutlined } from '@material-ui/icons';
  import Visibility from '@material-ui/icons/Visibility';
  import VisibilityOff from '@material-ui/icons/VisibilityOff';
  import { createMuiTheme } from "@material-ui/core/styles";


  const mapStateToProps = state => {
    return {
      acamar: state.acamar,
      appstate: state.appstate
    };
  };

  class Login extends Component {
    constructor(props) {
      super(props);
      this.state = ({
        email: "",
        password: "",
        emailerror: false,
        passworderror: false,
        showPassword: false,
        selectDomain:"",
        domain:[],
      });
    }


    componentDidMount() {
      this.props.appstate.InputAndActionDialog.open = false;
      this.props.dispatch(APP(this.props.appstate));
      let self=this;
      getService(process.env.REACT_APP_DOMAIN + services.new_domains_GET.replace("{APPNAME}", self.props.acamar.app),
      "",
      function (err, result) {
        if (err) {
          console.log("errore get domini");
        } else if (result.status === 200) {
          console.log("domini arrivati");
          console.log(result.response);
          result.response.push({"_id":"admin","shortname":"Administration","legalname":"admin","country":"admin" })
          self.setState({domain:result.response});
        }
      }
    );
  }


  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
      emailerror: (event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null ? true : false)});
    }

    handleChangePassword = (event) => {
      this.setState({
        password: event.target.value,
        passworderror: (event.target.value.length > 6 ? false : true)});
      }

      goToRegistration = () => {
        //const container = document.getElementById('content');
        this.props.history.push('/Registration');
        /*ReactDOM.render(<></>, container, () => {
        this.props.history.push('/Registration');
      })*/
    }

    loginRequest = () => {
      if (this.state.emailerror === false && this.state.passworderror === false) {
        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;
        this.props.dispatch(APP(this.props.appstate));
        var self = this;
        let params = {
          email: this.state.email,
          password: this.state.password,
          appname:this.props.acamar.app,
          domainId:this.state.selectDomain
        }
        postService(process.env.REACT_APP_DOMAIN + services.login_POST, params,
          "", "",
          function (err, result) {
            if (err) {
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.loginfailed;
              self.props.dispatch(APP(self.props.appstate));
            } else if (result.status === 201) {
              self.props.appstate.Modal.openModalLoading = false;
              self.props.appstate.App.user.gologin=true;
              self.props.appstate.App.user.logged = true;
              self.props.appstate.App.user.userid = result.response.userId;
              self.props.appstate.App.user.token = result.response.token;
              if (result.response.avatar) {
                self.props.appstate.App.user.avatar = result.response.avatar;
              }
              if (result.response.domain) {
                self.props.appstate.App.user.domain = result.response.domain;
                self.props.appstate.App.user.domainDetail = result.response.domainDetail;
              }
              self.props.appstate.App.user.name = result.response.name;
              self.props.appstate.App.user.role = result.response.role;
              self.props.appstate.App.user.email = result.response.email;
              self.props.appstate.App.user.status = result.response.status;
              self.props.appstate.App.user.createdOn = result.response.createdOn;
              self.props.appstate.App.user.preferences = result.response.preferences;
              if (result.response.nodebb){
              self.props.appstate.App.user.nodebb = result.response.nodebb;
              }
              //console.log("dal DB "+JSON.stringify(result.response.preferences));
              self.props.appstate.Resource.resources = [];
              self.props.appstate.ResourceManager.resources = [];
              if(result.response.preferences){
                if(result.response.preferences.language){
                //  console.log("preferenza lingua " +result.response.preferences.language);
                  self.props.appstate.App.currentLanguage=result.response.preferences.language;
                  self.getDefaultLanguage(self.props.acamar.app, result.response.preferences.language);

                }
                if(result.response.preferences.accessibility){
                //  console.log("preferenze accessibility "+JSON.stringify(result.response.preferences.accessibility));
                 self.props.appstate.App.accessibility.valuefont=result.response.preferences.accessibility.fontSize;
                  self.props.appstate.App.accessibility.valuecontrast=result.response.preferences.accessibility.contrast;
                  self.props.appstate.App.accessibility.valuecolored=result.response.preferences.accessibility.yellowLinks;
                  self.props.appstate.App.accessibility.valuelinks=result.response.preferences.accessibility.underlineLinks;
                  self.props.appstate.App.accessibility.valuefamilyfont=result.response.preferences.accessibility.familyFont;
                  self.handleChangeFamily(result.response.preferences.accessibility.familyFont);
                  self.setvalueFont(result.response.preferences.accessibility.fontSize);
                  self.checkboxthemeEvent(result.response.preferences.accessibility.contrast);
                  self.handleChangeLink(result.response.preferences.accessibility);

                }
                self.props.dispatch(APP(self.props.appstate));
                self.props.history.push('/Home');
              }
              else {
                self.props.dispatch(APP(self.props.appstate));
                self.props.history.push('/Home');
              }

            }else{
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.loginfailed;
              self.props.dispatch(APP(self.props.appstate));
            }
          }
        );
      }
    }

    handleChangeFamily = (familyFont)  => {

      const iframe = document.getElementById('iframecontent');
      if (familyFont === "Lexend") {
        let currentconfig = this.props.acamar;
        currentconfig.configuration.Theme.currenttheme ="Lexend";
        this.props.appstate.App.theme.valuethemefirst = "Lexend";
        this.props.appstate.App.theme.refmaterial = {};
        if (iframe) {
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
        };
        if(this.props.appstate.App.accessibility.valuecontrast==="light")
        this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexend());
        else {
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexendDark());
        }
       this.props.dispatch(WHOLE(currentconfig));
      } else if (familyFont === "familydefault") {
        let currentconfig = this.props.acamar;
        currentconfig.configuration.Theme.currenttheme ="familydefault";
        this.props.appstate.App.theme.valuethemefirst = "familydefault";
        this.props.appstate.App.theme.refmaterial = {};
        if (iframe) {
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
        };
        if(this.props.appstate.App.accessibility.valuecontrast==="light")
        this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLightTheme());
        else {
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getDarkTheme());
        }

        this.props.dispatch(WHOLE(currentconfig));
      }

    }

    setvalueFont = (value) => {
      if (value.length > 0) {
        var fontList = [
          'fontnormal',
          'fontlarge',
          'fontxlarge',
          'fontxxlarge'
        ];
        if (document.documentElement.classList.length === 0) {
          document.documentElement.classList.add(`${value}`);
          return;
        }

        for (let i = 0; i < fontList.length; i++) {

          if (fontList[i] === value)
            document.documentElement.classList.replace(document.documentElement.classList[0], fontList[i]);
          else {
            document.documentElement.classList.add(`${value}`);
          }

        }

        const iframe = document.getElementById('iframecontent');
        if (iframe) {
          iframe.contentWindow.postMessage({
            type: "FONT", font: value
          }, this.props.appstate.App.currentappchild);
        }
      }
    }

      checkboxthemeEvent = (contrast) => {

        const iframe = document.getElementById('iframecontent');
        if (contrast === "dark") { //black
          let currentconfig = this.props.acamar;
          currentconfig.configuration.Theme.currenttheme ="dark";
          this.props.appstate.App.theme.valuethemefirst = "dark";
          this.props.appstate.App.theme.refmaterial = {};
          if (iframe) {
            let appstate = Object.assign({}, this.props.appstate);
            iframe.contentWindow.postMessage({
              type: "WHOLE", acamar: this.props.acamar, appstate: appstate
            }, this.props.appstate.App.currentappchild);
          };
          if(this.props.appstate.App.accessibility.valuefamilyfont==="Lexend")
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexendDark());
          else {
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getDarkTheme());
          }

          this.props.dispatch(WHOLE(currentconfig));

        } else if (contrast === "light") { //white
          let currentconfig = this.props.acamar;
          currentconfig.configuration.Theme.currenttheme ="light";
          this.props.appstate.App.theme.valuethemefirst = "light";
          this.props.appstate.App.theme.refmaterial = {};
          if (iframe) {
            let appstate = Object.assign({}, this.props.appstate);
            iframe.contentWindow.postMessage({
              type: "WHOLE", acamar: this.props.acamar, appstate: appstate
            }, this.props.appstate.App.currentappchild);
          };
          if(this.props.appstate.App.accessibility.valuefamilyfont==="Lexend")
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexend());
          else {
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLightTheme());
          }

          this.props.dispatch(WHOLE(currentconfig));
        }
      }

      handleChangeLink = (accessibility)=>{
        const iframe = document.getElementById('iframecontent');
        let confitAppstate = this.props.appstate;
        if(confitAppstate.App.accessibility.valuecolored==="coloryellow"){
          if (confitAppstate.App.accessibility.valuelinks==="linksdefault"){
            confitAppstate.App.theme.themelink = window.getYellowTheme();
            confitAppstate.App.theme.valuethemelink="yellow";
          }
          else{
            confitAppstate.App.theme.themelink = window.getBothYelUnderTheme();
            confitAppstate.App.theme.valuethemelink="bothyelunder";
          }
        }
        else if(confitAppstate.App.accessibility.valuecolored==="colornormal"){
          if (confitAppstate.App.accessibility.valuelinks==="linksdefault"){
            confitAppstate.App.theme.themelink = window.getNoTheme();
            confitAppstate.App.theme.valuethemelink="notheme";
          }
          else{
            confitAppstate.App.theme.themelink =  window.getUnderlineTheme();
            confitAppstate.App.theme.valuethemelink="underline";
          }
        }
        else if(confitAppstate.App.accessibility.valuelinks==="linksunderline"){
          if (confitAppstate.App.accessibility.valuecolored==="coloryellow"){
            confitAppstate.App.theme.themelink = window.getBothYelUnderTheme();
            confitAppstate.App.theme.valuethemelink="bothyelunder";
          }
          else{
            confitAppstate.App.theme.themelink =  window.getUnderlineTheme();
            confitAppstate.App.theme.valuethemelink="underline";
          }
        } else{
          if (confitAppstate.App.accessibility.valuecolored==="coloryellow"){
            confitAppstate.App.theme.themelink = window.getYellowTheme();
            confitAppstate.App.theme.valuethemelink="yellow";
          }
          else{
            confitAppstate.App.theme.themelink = window.getNoTheme();
            confitAppstate.App.theme.valuethemelink="notheme";
          }

        }

        if (iframe) {
          this.props.appstate.App.theme.refmaterial = {};
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
          if (this.props.acamar.configuration.Theme.currenttheme === "light") {
            this.props.appstate.App.theme.refmaterial = createMuiTheme(window.getLightTheme());
          } else if (this.props.acamar.configuration.Theme.currenttheme === "dark") {
            this.props.appstate.App.theme.refmaterial = createMuiTheme(window.getDarkTheme());
          }
        }


      }

      getDefaultLanguage= (appname, defaultlang) =>{
        var thisRef=this;
        console.log("sono in getDefaultLanguage "+appname +" "+defaultlang);
        let serviceurl =
        process.env.REACT_APP_DOMAIN + services.language_GET.replace("{APPNAME}", appname);
        serviceurl = serviceurl.replace("{LANG}", defaultlang);
        getService(serviceurl, "", function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 200) {
            let currentconfig = thisRef.props.appstate;
            currentconfig.App.currentLanguage = defaultlang;
            currentconfig.App.language = result.response.labels.language;
            const iframe = document.getElementById('iframecontent');
            if (iframe) {
              thisRef.props.appstate.App.theme.refmaterial = {};
              let appstate = Object.assign({}, thisRef.props.appstate);
              iframe.contentWindow.postMessage({
                type: "WHOLE", acamar: thisRef.props.acamar, appstate: appstate
              }, thisRef.props.appstate.App.currentappchild);
              if (thisRef.props.acamar.configuration.Theme.currenttheme === "light") {
                thisRef.props.appstate.App.theme.refmaterial = createMuiTheme(window.getLightTheme());
              } else if (thisRef.props.acamar.configuration.Theme.currenttheme === "dark") {
                thisRef.props.appstate.App.theme.refmaterial = createMuiTheme(window.getDarkTheme());
              }
            }
            thisRef.props.dispatch(APP(currentconfig));
          }
        });
      }

      forgotPasswordCallBack = (email,domainId) => {
        let self = this;
        let user = {
          email: email,
          appname: this.props.appstate.App.name,
          domainId:domainId
        };

        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;
        this.props.dispatch(APP(this.props.appstate));
        //const headers = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
        postService(process.env.REACT_APP_DOMAIN + services.forgotpassword_POST, user,
          "", "",
          function (err, result) {
            if (err) {
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.forgoterror;
              self.props.dispatch(APP(self.props.appstate));
              return;
            } else if (result.status === 200) {
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.checkemail;
              self.props.dispatch(APP(self.props.appstate));
              return;
            }
            self.props.appstate.Modal.openModalLoadingTextActive = false;
            self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.forgoterror;
            self.props.dispatch(APP(self.props.appstate));
            return;
          });
        }

        openDialogForgotPassword = () => {
          this.dialogInputActionRef.init(this.state.domain);
          this.props.appstate.InputAndActionDialog.open = true;
          this.props.dispatch(APP(this.props.appstate));
        }

        showPassword = () => {
          this.setState({ showPassword: !this.state.showPassword });
        }
        handleChange=(e)=> {
          this.setState({
            selectDomain: e.target.value
          })
        }


        getImageRotation = () => {
          switch (this.props.appstate.App.name.toUpperCase()) {
            case 'SOCLOSE':
            return(  <div className="fadein login-image">
              <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/migration-scaled.jpg"/>
              <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/emigranti-ellis-island.jpeg"/>
              <img className="fadein login-image" alt="immagine di migranti"  src="/images/sfondi-login/angiolo-tommasi-emigranti.jpg"/>
              <img className="fadein login-image" alt="immagine di murales" src="/images/sfondi-login/murales-athens.jpg"/>
            </div>);
            case 'VDA':
            return(<div className="login-image">
              <img className="staticImg login-image" alt="immagine di migranti" src="/images/sfondi-login/rotazione1_vda.png"/>
            </div>);
            case 'GREENSCENT':
            return(  <div className="fadein login-image">
              <img className="fadein login-image" alt="immagine di presentazione" src="/images/sfondi-login/rotazione1_greenscent.png"/>
              <img className="fadein login-image" alt="immagine di presentazione" src="/images/sfondi-login/rotazione2_greenscent.png"/>
              <img className="fadein login-image" alt="immagine di presentazione"  src="/images/sfondi-login/rotazione3_greenscent.png"/>
              <img className="fadein login-image" alt="immagine di presentazione" src="/images/sfondi-login/rotazione4_greenscent.png"/>
            </div>);
            case 'SMST':
            return(<div className="fadein login-image">
              <img className="fadein login-image" alt="immagine favignana" src="/images/sfondi-login/favignana.jpg"/>
              <img className="fadein login-image" alt="immagine santa marinella" src="/images/sfondi-login/santa-marinella.jpg"/>
              <img className="fadein login-image" alt="immagine carditello"  src="/images/sfondi-login/carditello.jpg"/>
              <img className="fadein login-image" alt="immagine venosa" src="/images/sfondi-login/venosa.jpg"/>
            </div>);
            case 'SMSTAPP':
            return(<div className="login-image">
              <img className="staticImg login-image" alt="immagine smst" src="/images/sfondi-login/rotazione1_SMSTapp.jpg"/>
            </div>);
            case 'IDEHA':
            return(<div className="fadein login-image">
              <img className="fadein login-image" alt="immagine favignana" src="/images/sfondi-login/favignana.jpg"/>
              <img className="fadein login-image" alt="immagine santa marinella" src="/images/sfondi-login/santa-marinella.jpg"/>
              <img className="fadein login-image" alt="immagine carditello"  src="/images/sfondi-login/carditello.jpg"/>
              <img className="fadein login-image" alt="immagine venosa" src="/images/sfondi-login/venosa.jpg"/>
            </div>);
            default:
            return(  <div className="fadein login-image">
              <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/migration-scaled.jpg"/>
              <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/emigranti-ellis-island.jpeg"/>
              <img className="fadein login-image" alt="immagine di migranti"  src="/images/sfondi-login/angiolo-tommasi-emigranti.jpg"/>
              <img className="fadein login-image" alt="immagine di murales" src="/images/sfondi-login/murales-athens.jpg"/>
            </div>);
          }

        }

        getFooter = () => {
          switch (this.props.appstate.App.name.toUpperCase()) {
            case 'SOCLOSE':
            return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
              <img
                // style={{height: 56}}
                src='/images/partner/footer.png'
                alt="logo-eu_label"/>
              </AppBar>);
              case 'VDA':
              return(<div></div>);
              case 'GREENSCENT':
              return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
                <img
                  // style={{height: 56}}
                  src='/images/partner/barra.png'
                  alt="logo-eu_label"/>
                </AppBar>);
                case 'SMST':
                return(<div></div>);
                case 'SMSTAPP':
                return(<div></div>);
                case 'IDEHA':
                return(<div></div>);
                default:
                return(<div></div>);
              }
            }

            render() {

              let jsonlang = this.props.appstate.App.language;

              return(
                <div style={{paddingBottom:56}}>
                  <DialogInputAction onRef={childthis => (this.dialogInputActionRef = childthis)} callback={this.forgotPasswordCallBack}/>
                  <Container>
                    <Grid container>
                      <Grid item xs={false} sm={4} md={7} >

                        {/* qui ci andranno le *quattro* immagini prese dal file di configurazione */}
                        {/* l'animazione "fadein" in login.css richiede esattamente 4 immagini */}
                        {this.getImageRotation()}

                      </Grid>
                      <Grid
                        item xs={12} sm={8} md={5}
                        component={Paper}
                        elevation={6}
                        square
                        >
                          <div className="paper front-slide">
                            <div className="form-group">
                              {/* <label for="name-input">{jsonlang.email.toUpperCase()}</label>
                              <OutlinedInput
                              autocomplete="new-password"
                              placeholder={jsonlang.email.toUpperCase()}
                              error={this.state.emailerror}
                              fullWidth
                              onChange={this.handleChangeEmail}
                              name="email"
                              type="text"
                              value={this.state.email}
                            /> */}
                            {/* <label for="Email">Email address</label> */}
                            <label className="visuallyhidden" for="email-input">{jsonlang.email.toUpperCase()}</label>
                            <TextField
                              // aria-describedby="Email address"
                              id="email-helper-text"
                              label={jsonlang.email.toUpperCase()}
                              variant="outlined"
                              autocomplete="new-password"
                              error={this.state.emailerror}
                              fullWidth
                              onChange={this.handleChangeEmail}
                              name="email"
                              type="text"
                              value={this.state.email}
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}
                            />
                            <FormHelperText className="visuallyhidden" id="email-helper-text">Password</FormHelperText>

                          </div>

                          <div className="form-group">
                            <label className="visuallyhidden" htmlFor="password-input">{jsonlang.instpwd.toUpperCase()}</label>
                            <OutlinedInput
                              //  inputProps={{
                              //     autocomplete: 'new-password',
                              //     form: {
                              //       autocomplete: 'off',
                              //     },
                              //   }}
                              id="password-input"
                              aria-describedby="password-helper-text"
                              autocomplete="new-password"
                              placeholder={jsonlang.instpwd.toUpperCase()}
                              // label={jsonlang.instpwd.toUpperCase()}
                              error={this.state.passworderror}
                              fullWidth
                              onChange={this.handleChangePassword}
                              name="password"
                              type={this.state.showPassword ? 'text' : 'password'}
                              value={this.state.password}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    color="inherit"
                                    fontSize="large"
                                    onClick={this.showPassword}
                                    edge="start"
                                    aria-label="hide show password"
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText className="visuallyhidden" id="password-helper-text">Password</FormHelperText>
                            </div>
                            <div className="form-group">
                              {/* <label for="confirm">{jsonlang.selecteddomain.toUpperCase()}</label> */}
                              {/* <label className="visuallyhidden" htmlFor="institution-select">{jsonlang.selecteddomain.toUpperCase()}</label> */}
                              <Select
                                required
                                className="MuiInputBase-input-custom"
                                id="id"
                                displayEmpty
                                fullWidth
                                value={this.state.selectDomain}
                                onChange={this.handleChange}
                                >
                                  <MenuItem value="" disabled aria-hidden="true">
                                    <em>{jsonlang.selecteddomain.toUpperCase()}</em>
                                  </MenuItem>
                                  {this.state.domain.map((domain, i) =>
                                    <MenuItem  key={i} className="MuiInputBase-input-custom" value={domain._id}>{domain.shortname}</MenuItem>
                                  )}
                                </Select>
                              </div>
                              <div className="form-group">
                                <Tooltip title={<h2>{jsonlang.loginuser.toUpperCase()}</h2>}>
                                <Button
                                  role="button"
                                  variant="contained"
                                  color="primary"
                                  type='submit'
                                  onClick={this.loginRequest}
                                  disabled={(this.state.emailerror === false && this.state.passworderror === false && this.state.password.length > 6 && this.state.selectDomain!== ""? false : true)}
                                  >
                                    <span>{jsonlang.loginuser.toUpperCase()}</span>
                                  </Button>
                                </Tooltip>
                              </div>
                              <div className="form-group">
                                <Link
                                  component="button"
                                  role="button"
                                  onClick={this.goToRegistration}>
                                  <Typography >
                                    <span>{jsonlang.questionforregister}</span>
                                  </Typography>
                                </Link>
                                <br></br>
                                <Link
                                  component="button"
                                  role="button"
                                  onClick={this.openDialogForgotPassword}>
                                  <Typography>
                                    <span>{jsonlang.questionforpwd}</span>
                                  </Typography>
                                </Link>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <div role="navigation">
                          {/* <Box
                            display="flex"
                            justifyContent="center" m={1} p={1}
                            >
                            <Box>
                            {'Copyright © '}
                            <Link
                            color="inherit"
                            role="link"
                            aria-label="SO CLOSE HOME PAGE LINK"
                            href="https://www.so-close.eu"
                            target="blank"
                            >
                            <span>SO CLOSE</span>
                          </Link>
                          {' '}
                          {new Date().getFullYear()}
                          {' '}
                        </Box>
                      </Box> */}
                    </div>
                    {this.getFooter()}
                  </Container>

                </div>
              )
            }
          }
          export default connect(mapStateToProps)(Login);
