import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  // WHOLE,
  APP
} from '../../redux/actions/actions';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  InputAdornment,
  OutlinedInput,
  Divider
} from "@material-ui/core";
// import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PasswordModifyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      newpassword: "",
      oldpassword: "",
      repeatnewpassword: "",
      showNewPassword: false,
      showOldPassword: false,
      showRepeatNewPassword: false,
      newPasswordError: false,
      newRepeatPasswordError: false
    });
  }
  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  init = () => {
    this.setState({
      newpassword: "",
      oldpassword: "",
      repeatnewpassword: "",
      showNewPassword: false,
      showOldPassword: false,
      showRepeatNewPassword: false,
      newPasswordError: false,
      newRepeatPasswordError: false,
      render: true
    });
  }

  onclose = () => {
    this.props.appstate.PasswordModifyDialog.open = false;
    this.props.dispatch(APP(this.props.appstate));
    // this.props.closeCallback();
  }

  callback = () => {
    this.props.appstate.PasswordModifyDialog.open = false;
    this.props.dispatch(APP(this.props.appstate));
    this.props.callback(this.state.newpassword, this.state.oldpassword);
  }

  onChangeNewPassword = (event) => {
    this.setState({
      newpassword: event.target.value, newPasswordError: ((event.target.value !== this.state.repeatnewpassword) && (event.target.value.length < 7) ? true : false)
    }, () => { this.setState({ newRepeatPasswordError: (this.state.newpassword !== this.state.repeatnewpassword ? true : false) })});
  }

  onChangeRepeatNewPassword = (event) => {
    this.setState({
      repeatnewpassword: event.target.value, newRepeatPasswordError: ((event.target.value !== this.state.newpassword) || (event.target.value.length < 7) ? true : false)
    }, () => { this.setState({ newPasswordError: this.state.newRepeatPasswordError })});
  }

  onChangeOldPassword = (event) => {
    this.setState({
      oldpassword: event.target.value
    });
  }

  showOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword });
  }

  showNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  }

  showRepeatNewPassword = () => {
    this.setState({ showRepeatNewPassword: !this.state.showRepeatNewPassword });
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <>
      {this.state.render === true ?
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.props.appstate.PasswordModifyDialog.open}
          TransitionComponent={Transition}
          onClose={this.onclose}
          aria-labelledby="dialog-modify-password"
          fullWidth
          maxWidth={'sm'}>
          <DialogTitle>
            <LockOpenIcon
              aria-label="modify password"
              style={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                borderRadius: "50px",
                fontSize: "36px",
                padding: "6px",
              }}
            />
            <span style={{
              padding: 5,
              position: 'fixed',
            }}>
            {jsonlang.modifypwd.toUpperCase()}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Divider variant="fullWidth" />
            <Grid container style={{ padding: 10 }} spacing={2}>
              {/* <Typography
                variant="button"
                display="block"
                gutterBottom
                >
                {jsonlang.currentpwd.toUpperCase()}
              </Typography> */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <FormControl variant="outlined"> */}
                  {/* <InputLabel htmlFor="password">{jsonlang.instpwd}</InputLabel> */}
                  <OutlinedInput
                    autoComplete="new-password"
                    placeholder={jsonlang.currentpwd.toUpperCase()}
                    error={this.state.passworderror}
                    fullWidth
                    onChange={this.onChangeOldPassword}
                    name="CRE_password"
                    type={this.state.showOldPassword ? 'text' : 'password'}
                    value={this.state.oldpassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          color="inherit"
                          fontSize="large"
                          onClick={this.showOldPassword}
                          // onMouseDown={this.handleMouseDownPassword}
                          edge="start"
                          >
                            {this.state.showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      // labelWidth={120}
                    />
                    {/* </FormControl> */}
                  </Grid>
                  {/* <Grid item xs={6}>
                  <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  >
                  {jsonlang.newpwd.toUpperCase()}
                </Typography>
              </Grid> */}
              {/* <Grid item xs={6}>
              <Typography
              variant="button"
              display="block"
              gutterBottom
              >
              {jsonlang.renewpwd.toUpperCase()}
            </Typography>
          </Grid> */}
          <Grid item xs={6}>
            {/* <FormControl variant="outlined"> */}
            {/* <InputLabel htmlFor="password">{jsonlang.instpwd}</InputLabel> */}
            <OutlinedInput
              inputProps={{
                autoComplete: 'off'
              }}
              placeholder={jsonlang.newpwd.toUpperCase()}
              error={this.state.newPasswordError}
              fullWidth
              onChange={this.onChangeNewPassword}
              name="CRE_password"
              type={this.state.showNewPassword ? 'text' : 'password'}
              value={this.state.newpassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="inherit"
                    fontSize="large"
                    onClick={this.showNewPassword}
                    // onMouseDown={this.handleMouseDownPassword}
                    edge="start"
                    >
                      {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                // labelWidth={120}
              />
              {/* </FormControl> */}
            </Grid>

            <Grid item xs={6}>
              {/* <FormControl variant="outlined"> */}
              {/* <InputLabel htmlFor="password">{jsonlang.instpwd}</InputLabel> */}
              <OutlinedInput
                inputProps={{
                  autoComplete: 'off'
                }}
                placeholder={jsonlang.renewpwd.toUpperCase()}
                error={this.state.newRepeatPasswordError}
                fullWidth
                onChange={this.onChangeRepeatNewPassword}
                name="CRE_password"
                type={this.state.showRepeatNewPassword ? 'text' : 'password'}
                value={this.state.repeatnewpassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      fontSize="large"
                      onClick={this.showRepeatNewPassword}
                      // onMouseDown={this.handleMouseDownPassword}
                      edge="start"
                      >
                        {this.state.showRepeatNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // labelWidth={120}
                />
                {/* </FormControl> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          onClick={this.onclose}
          color="primary"
          startIcon={<ClearIcon />}>
          <Tooltip title={<h2>{jsonlang.instcancel.toUpperCase()}</h2>}>
          <Typography variant="button">{jsonlang.instcancel}</Typography>
        </Tooltip>
      </Button>
      {/* {(this.state.newRepeatPasswordError === false && this.state.newPasswordError === false) && (this.state.newpassword.length > 0 && this.state.repeatnewpassword.length > 0 && this.state.oldpassword.length) ?
        <Button
        disabled={this.state.radioValue === "pending" ? true : false}
        variant="contained"
        size="large"
        onClick={this.callback}
        color="primary"
        startIcon={<SaveIcon />}>
        <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
        <Typography variant="button">{jsonlang.save}</Typography>
      </Tooltip>
    </Button> : <></>} */}

    <Button
      disabled={(this.state.newRepeatPasswordError === false && this.state.newPasswordError === false) && (this.state.newpassword.length > 0 && this.state.repeatnewpassword.length > 0 && this.state.oldpassword.length) ?
        false : true}
        variant="contained"
        size="large"
        onClick={this.callback}
        color="primary"
        startIcon={<SaveIcon />}>
        <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
        <Typography variant="button">{jsonlang.save}</Typography>
      </Tooltip>
    </Button>

  </DialogActions>
</Dialog >
: <></>}
</>)
}
};
export default connect(mapStateToProps)(PasswordModifyDialog);
