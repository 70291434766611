import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // CircularProgress,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography
} from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import { deleteService} from "../../services/services";
import * as services from "../../services/services.json";
import {    
    getResourceDataForLanguage
} from "../../Utils/utility";
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


class DialogDelete extends Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  onclose = () => {
    this.props.appstate.Modal.openModalDelete = false;
    this.props.appstate.Modal.openModalType = "";
    this.props.appstate.ResourceManager.resourceInfo={ };
    this.props.dispatch(APP(this.props.appstate));
  }

  deleteconfirm = () => {
    let self = this;
    //let index = event.currentTarget.name;
    if (this.props.appstate.Modal.openModalType === "manager") {
      deleteService(
        process.env.REACT_APP_DOMAIN + services.warning_delete_DELETE,
        {
          warningid: this.props.appstate.ResourceManager.id,
          appname: this.props.appstate.App.name,
          domain: this.props.appstate.App.user.domain          
        },
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 204) {
            self.props.appstate.ResourceManager.resources.splice(self.props.appstate.ResourceManager.index, 1);
            self.props.appstate.Modal.openModalDelete = false;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
            return;
          }
          self.props.appstate.Modal.openModalDelete = false;
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        }
      );
    }else{
      deleteService(
        process.env.REACT_APP_DOMAIN + services.resource_DELETE_AUTH,
        { id: this.props.appstate.Resource.id, appname: this.props.appstate.App.name  , domain: this.props.appstate.App.user.domain , user: this.props.appstate.App.user.userid},
        "",
        function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 204) {
            self.props.appstate.Resource.resources.splice(self.props.appstate.Resource.index, 1);
            self.props.appstate.Modal.openModalDelete = false;
            self.props.dispatch(APP(self.props.appstate));
            self.props.callback();
            return;
          }
          self.props.appstate.Modal.openModalDelete = false;
          self.props.dispatch(APP(self.props.appstate));
          self.props.callback();
        }
      );
    }
  }
  
  coverUrl = (id, medialan) => {
    // console.log(medialan)
    let ser =
        process.env.REACT_APP_DOMAIN +
        services.singleresourcemanager_GET_AUTH.replace(
            "{APPNAME}",
            this.props.appstate.App.name
        );
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{RESOURCEID}", id);
    ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
    ser = ser.replace("{COVER}", true);
    ser = ser + "&t=" + new Date().getTime();
    ser = ser.replace("{MEDIALAN}", medialan);
    ser = ser.replace("{MEDIARES}", "first");

    return ser;
};
  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          TransitionComponent={Transition}
          open={this.props.appstate.Modal.openModalDelete}
          onClose={this.onclose}
          aria-labelledby="dialog-title"
          fullWidth
          maxWidth={'sm'}
          >
            <DialogTitle id="dialog-remove">
              <DeleteIcon
                aria-label="Delete info"
                style={{
                  color: "#fff",
                  backgroundColor: "#3f51b5",
                  borderRadius: "50px",
                  fontSize: "36px",
                  padding: "5px",
                }}
              />
              <span style={{
                padding: 5,
                position: 'fixed',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '55vmin'
              }}>
              {jsonlang.remove.toUpperCase() + " " + this.props.appstate.ResourceManager.resourceInfo.name}
            </span>
          </DialogTitle>
          <DialogContent dividers>

            <img alt="" style={{
              display: 'block',
              maxWidth: '230px',
              maxHeight:'95px',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 'auto',
              height: '100px',
            }} 
            src={this.props.appstate.ResourceManager.resourceInfo.coverobj ? this.coverUrl(this.props.appstate.ResourceManager.resourceInfo.coverobj._id, getResourceDataForLanguage(this.props.appstate.ResourceManager.resourceInfo.coverobj)):  this.props.appstate.ResourceManager.resourceInfo.resources ? (this.props.appstate.ResourceManager.resourceInfo.resources.length > 0 ? this.coverUrl(this.props.appstate.ResourceManager.resourceInfo.resources[0]._id, getResourceDataForLanguage(this.props.appstate.ResourceManager.resourceInfo.resources[0])) : "/images/sfondi-home/gs-citizen.png") : "/images/sfondi-home/gs-citizen.png"}

            
            // src={
            //   this.props.appstate.ResourceManager.resourceInfo.uriCoverExternalRes ? this.props.appstate.ResourceManager.resourceInfo.uriCoverExternalRes
            //   : this.props.appstate.ResourceManager.resourceInfo.type==="poi"? "/images/icons/poi.jpg" : "/images/icons/path.jpg"

            // }
            />

            <Typography
              variant='h6'
              justify='center'
              align='center'
              >
                {jsonlang.messageconfirm}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="small"
                onClick={this.onclose}
                color="primary"
                startIcon={<ClearIcon />}>
                <Tooltip title={<h2>{jsonlang.no}</h2>}>
                <Typography variant="button">{jsonlang.no}</Typography>
              </Tooltip>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={this.deleteconfirm}
              color="primary"
              startIcon={<DoneIcon />}>
              <Tooltip title={<h2>{jsonlang.yes}</h2>}>
              <Typography variant="button">{jsonlang.yes}</Typography>
            </Tooltip>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
}
export default connect(mapStateToProps)(DialogDelete);
