import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogActions,
  // Divider,
  Button,
  // Container,
  // IconButton,
  // Input,
  Fab,
  Link,
  Slide,
  Typography,
  Tooltip,
  Checkbox,
  Box,
  // TextField
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  // faFilePdf,
  // faFileExcel,
  // faFileWord,
  faFile  } from '@fortawesome/free-solid-svg-icons';
import ShareIcon from '@material-ui/icons/Share';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import TimelineIcon from '@material-ui/icons/Timeline';
import RoomIcon from '@material-ui/icons/Room';
// import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
// import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import { APP } from '../../redux/actions/actions';
import './PreviewDialog.css'
import QRCode from 'qrcode.react';
import { getRights } from "../../Utils/utility";
// import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
// import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import { getStreamFileService} from "../../services/services";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import * as services from "../../services/services.json";

import {
  getResourceDataForLanguage,
  // getExternalMediaType
} from "../../Utils/utility";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class PreviewDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      copied: "",
      logo:"",
      itemsLan:[],
      currentLang:"",
      contentPreview: "view",
    });
  }
  init = () => {

    this.setState({ copied: "" , contentPreview: "view"});


    let arrayLan=[];

    // if(this.props.appstate.PreviewDialog.item.type!=="webcloud" && this.props.appstate.PreviewDialog.item.medialan){
    //   Object.keys(this.props.appstate.PreviewDialog.item.medialan).forEach(function(key) {
    //     arrayLan.push(key);});
    //     this.setState({itemsLan:arrayLan,currentLang:arrayLan[0]});
    //   }
    //   else{
    if (typeof(this.props.appstate.PreviewDialog.item.url)==='string'){ //retrocompatibilità
      arrayLan.push("en");
    }else {
      //  let itemHELP=this.props.appstate.PreviewDialog.item;
      //  itemHELP.medialan=this.props.appstate.PreviewDialog.item.url;
      Object.keys(this.props.appstate.PreviewDialog.item.url).forEach(function(key) {
        arrayLan.push(key);});

      }

      // if(this.props.appstate.PreviewDialog.item.url)
      // Object.keys(this.props.appstate.PreviewDialog.item.url).forEach(function(key) {
      // arrayLan.push(key);});
      this.setState({itemsLan:arrayLan, currentLang:arrayLan[0]}, ()=>{this.getURlForLang()});
      //  }
    }

    componentDidMount() {
      this.props.onRef(this); //for the parent


      switch (this.props.appstate.App.name.toUpperCase()) {
        case 'SOCLOSE':
        this.setState({logo:"images/partner/logo.png"});
        break;
        case 'VDA':
        this.setState({logo:"images/partner/logo_vda.png"});
        break;
        case 'GREENSCENT':
        this.setState({logo:"images/partner/logo_greenscent_piccolo.png"});
        break;
        case 'SMST':
        this.setState({logo:"images/partner/logo_ideha.png"});
        break;
        case 'SMSTAPP':
        this.setState({logo:"images/partner/logo_lungo_SMST.png"});
        break;
        case 'IDEHA':
        this.setState({logo:"images/partner/logo_ideha.png"});
        break;
        default:
        this.setState({logo:"images/partner/logo.png"});
      }

    }

    onclose = () => {
      this.props.appstate.PreviewDialog.render = false;
      this.props.appstate.PreviewDialog.qrcode = false;
      this.props.appstate.PreviewDialog.share = false;
      this.props.appstate.PreviewDialog.openModalPreview = false;
      this.props.appstate.PreviewDialog.item = {};
      this.props.appstate.PreviewDialog.itemurl = "";
      this.props.dispatch(APP(this.props.appstate));
    }

    copyurl = (lang) => {
      console.log(lang);
      const temp = document.getElementsByName(lang)[0];
      // const temp = document.getElementById("sharecopyinput");
      console.log(temp);
      //const uniquetemp= temp.filter(input => input.value===this.getURlShare(lang));
      temp.select();
      document.execCommand("copy");
      this.setState({ copied: this.getURlShare(lang) });
    }

    //download qrcode
    downloadQRcode = () => {
      if(this.props.appstate.PreviewDialog.qrcode===true){
        const canvas = document.getElementById("qrcode");
        if(canvas !=null){
          const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
          let downloadLink = document.createElement("a");
          downloadLink.href = pngUrl;
          downloadLink.download = this.props.appstate.PreviewDialog.item.name+".png";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }}
      }

      getDescriptionOrTitleMultilanguage=(textObject)=>{
        let returnText="";
        let arrayTextObj=Object.entries(textObject);
        if (arrayTextObj.length === 0 && textObject.constructor === Object){
          return "N.A.";
        }else {
          let selectedTextLang="";
          arrayTextObj.forEach(([key, value]) => {
            if ((key===this.props.appstate.App.currentLanguage) || (key==="en")){
              selectedTextLang=key;
            }
          })
          // console.log(selectedTextLang)
          if (selectedTextLang===""){
            returnText=textObject[Object.keys(textObject)[0]];
          }else{
            returnText=textObject[selectedTextLang];
          }
          console.log("KEY after :"+selectedTextLang);
          console.log("Testo:"+returnText)
          return   returnText;
        }
      }
      openDocument=()=>{
        if (this.props.appstate.PreviewDialog.item.extension!=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&this.props.appstate.PreviewDialog.item.extension!=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          window.open(this.props.appstate.PreviewDialog.itemurl, "_blank")
        }else {
          let self=this;
          self.props.appstate.Modal.openModalLoading = true;
          self.props.appstate.Modal.openModalLoadingTextActive = false;
          self.props.dispatch(APP(this.props.appstate));
          getStreamFileService(this.props.appstate.PreviewDialog.itemurl,"",function (err,result) {
            if (err){
              console.log(err)
              self.props.appstate.Modal.openModalLoadingTextActive = true;
              self.props.appstate.Modal.openModalLoadingText = "Error with preview docuement";
              self.props.dispatch(APP(self.props.appstate));
              return;
            }
            self.props.appstate.Modal.openModalLoading = false;
            self.props.dispatch(APP(self.props.appstate));
            const file = new Blob([result.data],{type: self.props.appstate.PreviewDialog.item.extension});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
          })
        }
      }
      getUTCData=(data)=>{
        return new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'UTC',
          timeZoneName: 'short'}).format(new Date( data ));
        }


        openTab=()=>{
          /*  let url="";
          if (typeof(this.props.appstate.PreviewDialog.item.url)==='string'){ //retrocompatibilità
          url = this.props.appstate.PreviewDialog.item.url;
        }else {
        let item={};
        item.medialan=this.props.appstate.PreviewDialog.item.url;
        let lang =  getResourceDataForLanguage(item);
        url = this.props.appstate.PreviewDialog.item.url[lang];
      }

      console.log(url)
      window.open(url);*/

      // if (getExternalMediaType().findIndex(x => x.value === this.props.appstate.PreviewDialog.item.type)!==-1){
      let selectedUri= this.props.appstate.PreviewDialog.item.url;
      let url="";
      if (typeof(selectedUri)==='string'){ //retrocompatibilità
        url = selectedUri;
      }else {
        let itemHELP={};
        itemHELP.medialan=selectedUri;
        let lang =  getResourceDataForLanguage(itemHELP, this.state.currentLang);
        url = selectedUri[lang];
      }

      this.props.appstate.PreviewDialog.itemurl = url;
      /*} else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.props.appstate.PreviewDialog.item.streamextloaded===true){
      this.props.appstate.PreviewDialog.itemurl = this.props.appstate.PreviewDialog.item.streamext;
    }else{
    this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.props.appstate.PreviewDialog.item._id, getResourceDataForLanguage(this.props.appstate.PreviewDialog.item, this.state.currentLang));

  }*/

  console.log(this.props.appstate.PreviewDialog.itemurl)
  window.open(this.props.appstate.PreviewDialog.itemurl);
}

truncate = (str, n) =>{
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

getLangText=()=>{
  let item={};
  item.medialan=this.props.appstate.PreviewDialog.item.textcloudcontent;
  let lang =  getResourceDataForLanguage(item);
  return this.props.appstate.PreviewDialog.item.textcloudcontent[lang];
}


getURlForLang=()=>{
  // if (getExternalMediaType().findIndex(x => x.value === this.props.appstate.PreviewDialog.item.type)!==-1){
  let selectedUri= this.props.appstate.PreviewDialog.item.url;
  let url="";
  if (typeof(selectedUri)==='string'){ //retrocompatibilità
    url = selectedUri;
  }else {
    let itemHELP={};
    itemHELP.medialan=selectedUri;
    let lang =  getResourceDataForLanguage(itemHELP, this.state.currentLang);
    url = selectedUri[lang];
  }

  this.props.appstate.PreviewDialog.itemurl = url;
  // } else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.props.appstate.PreviewDialog.item.streamextloaded===true){
  //   this.props.appstate.PreviewDialog.itemurl = this.props.appstate.PreviewDialog.item.streamext;
  // }else{
  //   this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.props.appstate.PreviewDialog.item._id, getResourceDataForLanguage(this.props.appstate.PreviewDialog.item, this.state.currentLang));
  //
  // }
  console.log("url "+this.props.appstate.PreviewDialog.itemurl);
  this.props.dispatch(APP(this.props.appstate));
  //  return this.props.appstate.PreviewDialog.itemurl;
}



getURlShare=(currentLang)=>{
  // if (getExternalMediaType().findIndex(x => x.value === this.props.appstate.PreviewDialog.item.type)!==-1){
  let selectedUri= this.props.appstate.PreviewDialog.item.url;
  let url="";
  if (typeof(selectedUri)==='string'){ //retrocompatibilità
    url = selectedUri;
  }else {
    let itemHELP={};
    itemHELP.medialan=selectedUri;
    let lang =  getResourceDataForLanguage(itemHELP, currentLang);
    url = selectedUri[lang];
  }

  this.props.appstate.PreviewDialog.itemurl = url;
  // } else  if (this.props.appstate.App.user.domainDetail.backupaws===true && this.props.appstate.PreviewDialog.item.streamextloaded===true){
  //   this.props.appstate.PreviewDialog.itemurl = this.props.appstate.PreviewDialog.item.streamext;
  // }else{
  //   this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.props.appstate.PreviewDialog.item._id, getResourceDataForLanguage(this.props.appstate.PreviewDialog.item, currentLang));
  //
  // }
  console.log("url "+this.props.appstate.PreviewDialog.itemurl);
  return this.props.appstate.PreviewDialog.itemurl;
}

resourceUrl = (id,medialan) => {
  console.log("resourceUrl")
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.singleresourcemanager_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{RESOURCEID}", id);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser.replace("{COVER}", false);
  ser = ser.replace("{MEDIALAN}",medialan );
  ser = ser.replace("{MEDIARES}", "first");
  return ser;
};

selectedLang = (event, lang) =>{

  console.log("prima "+this.state.currentLang);
  if (lang !== null) {
    this.setState({currentLang:lang}, () =>{this.getURlForLang();  console.log("dopo "+this.state.currentLang);});
  }

  // this.getURlForLang();
}

changeContentPreview = (event, value)=>{

  if (value !== null) {
    this.setState({contentPreview:value});
  }
}


render() {
  let jsonlang = this.props.appstate.App.language;

  return (
    <>
    {this.props.appstate.PreviewDialog.render === true ?

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        TransitionComponent={Transition}
        open={this.props.appstate.PreviewDialog.openModalPreview}
        onClose={this.onclose}
        aria-labelledby="dialog-add"
        fullWidth
        maxWidth={'xl'}
        >
          <Fab
            style={{
              margin: 0,
              top: 0,
              right: 0,
              bottom: 'auto',
              left: 'auto',
              position: 'fixed'
            }}
            role="button"
            color="primary"
            size="large"
            aria-label="Add"
            onClick={this.onclose}
            >
              <CloseIcon
              />
            </Fab>
            {/* <div className="divexitbutton"> */}


            {(this.props.appstate.PreviewDialog.qrcode === true ?

              <>
              {/* <DialogTitle>{jsonlang.resshare.toUpperCase()}</DialogTitle> */}

              <DialogTitle id="dialog-add">

                {/* <span style={{
                  color: this.props.appstate.App.theme.refmaterial.palette.primary.main,
                  borderRadius: "50px",
                  fontSize: "32px"
                }}> */}
                <FontAwesomeIcon icon={faQrcode} style={{
                  fontSize: "40px"
                }}/>

                <span style={{
                  padding: 10,
                  position: 'fixed',
                  // whiteSpace: 'nowrap',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                  // width: '55vmin'
                }}>
                {jsonlang.rescode.toUpperCase()}
              </span>
            </DialogTitle>
            <DialogContent dividers
              style={{
                textAlign:"center"
              }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                  <Grid item xs={12} sm={12}
                    >
                      <div className="ellipsed">  <span><b>{jsonlang.previewname.toUpperCase()}: </b></span>
                      <label>{this.props.appstate.PreviewDialog.item.name}</label></div>
                    </Grid>

                    <Grid item  xs='auto' style={{marginRight:"10px"}}>
                      {this.props.appstate.PreviewDialog.item.edmRights !== null?
                        <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                        <Link
                          color="inherit"
                          role="link"
                          aria-label="Public Domain - No Copyright"
                          href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                          target="blank"
                          >
                            <img className="rights-img" alt="CC-BY-NC-ND" src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                          </Link>
                        </Tooltip>
                        : <span/>}
                      </Grid>

                      <Grid item  xs='auto' style={{marginRight:"10px"}}>
                        {this.props.appstate.PreviewDialog.item.edmRights?
                          this.props.appstate.PreviewDialog.item.edmRights.dcRights:""
                        }
                      </Grid>

                      <Grid item  xs='auto'>
                        <Link
                          color="inherit"
                          role="link"
                          aria-label="Public Domain - No Copyright"
                          href={
                            this.props.appstate.PreviewDialog.item.edmRights
                            ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                            : ""
                          }
                          target="blank"
                          >
                            {
                              this.props.appstate.PreviewDialog.item.edmRights
                              ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                              : ""
                            }
                          </Link>
                        </Grid>

                        <Grid item xs={12}>
                          <ToggleButtonGroup
                            className="box-wrap"
                            value={this.state.currentLang}
                            exclusive
                            onChange={this.selectedLang}
                            >
                              {/* {this.getURlForLang(this.props.appstate.PreviewDialog.item)} */}
                              {this.state.itemsLan.map((item, index) =>
                                <Tooltip title={<h2>{jsonlang.selemed.toUpperCase()}</h2>}>
                                <ToggleButton
                                  className="box"
                                  value={item}
                                  selected={item===this.state.currentLang ? true : false}
                                  >
                                    <p>
                                      <img
                                        alt="language flag"
                                        // name="en"
                                        style={{ width: "28px", height: "28px" }}
                                        src={"/images/languages/" + item + ".svg"}>
                                      </img>
                                    </p>
                                  </ToggleButton>
                                </Tooltip>
                              )}
                            </ToggleButtonGroup>
                          </Grid>

                          {/* </Grid> */}

                          <Grid item xs={12}>
                            <QRCode
                              // className="qrcode"
                              id="qrcode"
                              size={320}
                              includeMargin="true"
                              value={this.props.appstate.PreviewDialog.itemurl}
                              level={"Q"}
                              // includeMargin={false}
                              //renderAs={"svg"}
                              imageSettings={{
                                src: this.state.logo,
                                x: null,
                                y: null,
                                height: 36,
                                width: 72,
                                excavate: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={ this.downloadQRcode}
                              color="primary"
                              >
                                <Tooltip title={<h2>{jsonlang.downloadqr.toUpperCase()}</h2>}>
                                <Typography >{jsonlang.downloadqr.toUpperCase()}</Typography>
                              </Tooltip>
                            </Button>
                          </Grid>
                        </Grid>

                      </DialogContent>
                      {/* <DialogActions>

                      </DialogActions> */}
                    </>


                    :

                    this.props.appstate.PreviewDialog.share === true ?

                    <>

                    <DialogTitle>
                      <ShareIcon
                        aria-label="share info"
                        style={{
                          color: "#fff",
                          backgroundColor: "#3f51b5",
                          borderRadius: "50px",
                          fontSize: "36px",
                          padding: "7px",
                        }}
                      />
                      <span style={{
                        paddingLeft: 15,
                        position: 'fixed',
                        // whiteSpace: 'nowrap',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        // width: '55vmin'
                      }}>
                      {jsonlang.resshare.toUpperCase()}
                    </span>
                  </DialogTitle>

                  <DialogContent dividers
                    style={{
                      textAlign:"center",
                      // paddingBottom:"180px"
                      height:"80vh"
                    }}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                      >
                        <Grid item xs={12} sm={12}>
                          <div className="ellipsed">  <span><b>{jsonlang.previewname.toUpperCase()}: </b></span>
                          <label>{this.props.appstate.PreviewDialog.item.name}</label></div>
                        </Grid>


                        <Grid item  xs='auto' style={{marginRight:"10px"}}>
                          {this.props.appstate.PreviewDialog.item.edmRights !== null?
                            <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                            <Link
                              color="inherit"
                              role="link"
                              aria-label="Public Domain - No Copyright"
                              href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                              target="blank"
                              >
                                <img className="rights-img" alt="CC-BY-NC-ND" src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                              </Link>
                            </Tooltip>
                            : <span/>}
                          </Grid>

                          <Grid item  xs='auto' style={{marginRight:"10px"}}>
                            {this.props.appstate.PreviewDialog.item.edmRights?
                              this.props.appstate.PreviewDialog.item.edmRights.dcRights:""
                            }
                          </Grid>

                          <Grid item  xs='auto'>
                            <Link
                              color="inherit"
                              role="link"
                              aria-label="Public Domain - No Copyright"
                              href={
                                this.props.appstate.PreviewDialog.item.edmRights
                                ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                                : ""
                              }
                              target="blank"
                              >
                                {
                                  this.props.appstate.PreviewDialog.item.edmRights
                                  ? getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri
                                  : ""
                                }
                              </Link>
                            </Grid>

                            {this.state.itemsLan.map((item, index) =>

                              <Grid item xs={12} sm={12}>

                                {/* <Grid item xs={2} sm={2}> */}
                                {/* <div value={item}> */}
                                <img
                                  alt="language flag"
                                  style={{ width: "28px", height: "28px" }}
                                  src={"/images/languages/" + item + ".svg"}>
                                </img>
                                {/* </div> */}
                                {/* </Grid> */}
                                <>&nbsp;&nbsp;&nbsp;</>


                                {/* <Grid item xs={8} sm={8}> */}
                                <input
                                  id="sharecopyinput"
                                  style={{
                                    width: "90%"
                                  }}
                                  type="text"
                                  name={item}

                                  value={this.getURlShare(item)}
                                />
                                {/* </Grid> */}
                                <>&nbsp;&nbsp;</>

                                {/* <Grid item xs={2} sm={2}> */}
                                <Tooltip title={<h2>URL COPY</h2>}>
                                {this.state.copied === "" ?
                                <Fab
                                  role="button"
                                  color="primary"
                                  size="medium"
                                  aria-label="Add"
                                  onClick={()=>this.copyurl(item)}
                                  >
                                    <Typography variant="button" display="block">
                                      COPY
                                    </Typography>
                                  </Fab> : this.state.copied === this.getURlShare(item) ? <Fab
                                    style={{
                                      // marginTop: "22%",
                                    }}
                                    role="button"
                                    color="primary"
                                    size="large"
                                    aria-label="Add"
                                    onClick={this.onclose}
                                    >
                                      OK
                                    </Fab>
                                    : <Fab
                                      role="button"
                                      color="primary"
                                      size="medium"
                                      aria-label="Add"
                                      onClick={()=>this.copyurl(item)}
                                      >
                                        <Typography variant="button" display="block">
                                          COPY
                                        </Typography>
                                      </Fab>}
                                    </Tooltip>
                                    {/* </Grid> */}

                                  </Grid>
                                )}
                              </Grid>
                            </DialogContent>
                          </>

                          :

                          this.props.appstate.PreviewDialog.item.type === 'poi' ||

                          this.props.appstate.PreviewDialog.item.type === 'path'   ?



                          // ******************************  INIZIO STILE DIALOG COMUNE


                          <>

                          <DialogTitle>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              >
                                <Grid item xs={2} sm={2} style={{
                                  textAlign:"center",
                                  display: "inline",
                                  top: "12px",
                                  position: "relative"
                                }}>
                                {this.props.appstate.PreviewDialog.item.type === 'poi' ?

                                <RoomIcon icon={faFile} size="1x" style={{bottom: "10px", position: "relative"}}/>
                                :
                                <TimelineIcon icon={faFile} size="1x" style={{bottom: "10px", position: "relative"}}/>
                              }


                              <Tooltip title={<h2>{this.props.appstate.PreviewDialog.item.edmRights.dcRights}</h2>}>
                              { getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri ===""?
                              <img className="rights-img" alt={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).value} src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                              :
                              <Link
                                color="inherit"
                                role="link"
                                aria-label="Public Domain - No Copyright"
                                href={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri}
                                target="blank"
                                disabled //{getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).uri==="" ? true : false}
                                >
                                  <img className="rights-img" alt={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).value} src={getRights(this.props.appstate.PreviewDialog.item.edmRights.dcRights).logo}/>
                                </Link>
                              }
                            </Tooltip>
                            <Tooltip title={<h2>{jsonlang.backupres.toUpperCase()}</h2>}>
                            <Checkbox
                              className="positioncloud"
                              checked={this.props.appstate.PreviewDialog.item.streamextloaded}
                              icon={<CloudOffIcon />}
                              checkedIcon={<CloudDoneIcon />}
                            />
                          </Tooltip>
                        </Grid>




                        <Grid item xs={8} sm={8}>
                          {/* <div className="ellipsedTitle"> */}
                          {/* <span><b>{jsonlang.previewname.toUpperCase()}: </b></span> */}
                          <Typography variant="h6">
                            {this.truncate(this.props.appstate.PreviewDialog.item.name, 103)}
                          </Typography>
                          {/* </div> */}
                          {/* <Typography variant="h6" component="h2">
                          {this.truncate(this.props.appstate.PreviewDialog.item.name, 93)}
                        </Typography> */}
                      </Grid>



                      <Grid item xs={2} sm={2} style={{
                        textAlign:"center"
                      }}>

                      <ToggleButtonGroup
                        value={this.state.contentPreview}
                        exclusive
                        onChange={this.changeContentPreview}
                        >

                          <ToggleButton value="view" aria-label="italic">
                            {this.state.contentPreview==="view" ? <VisibilityIcon
                              color="primary" /> :<VisibilityIcon
                              />}
                            </ToggleButton>
                            <ToggleButton value="info" aria-label="underlined">
                              {this.state.contentPreview==="info" ? <InfoIcon
                                color="primary" /> :<InfoIcon
                                />}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>

                        </Grid>
                      </DialogTitle>
                      <DialogContent dividers
                        style={{
                          textAlign:"center",
                          // paddingBottom:"180px"
                          height:"80vh"
                        }}>


                        {/* inizio blocco PREVIEW RESOURCE  */}


                        {this.state.contentPreview === "view" ?

                        <Grid item xs={12} sm={12}>
                          {/*this.state.itemsLan.map((item, index) =>

                            <p
                            style={{
                            display:"inline-flex",
                            margin: "10px"
                          }}>
                          <img
                          alt="language flag"
                          style={{ width: "32px", height: "32px" }}
                          src={"/images/languages/" + item + ".svg"}>
                        </img>
                      </p>
                    )*/}
                    <ToggleButtonGroup
                      className="box-wrap"
                      value={this.state.currentLang}
                      exclusive
                      onChange={this.selectedLang}
                      >
                        {/* {this.getURlForLang(this.props.appstate.PreviewDialog.item)} */}
                        {this.state.itemsLan.map((item, index) =>
                          <Tooltip title={<h2>{jsonlang.selemed.toUpperCase()}</h2>}>
                          <ToggleButton
                            className="box"
                            value={item}
                            selected={item===this.state.currentLang ? true : false}
                            >
                              <p>
                                <img
                                  alt="language flag"
                                  // name="en"
                                  style={{ width: "28px", height: "28px" }}
                                  src={"/images/languages/" + item + ".svg"}>
                                </img>
                              </p>
                            </ToggleButton>
                          </Tooltip>
                        )}
                      </ToggleButtonGroup>

                      <Grid item xs={12} sm={12}>

                        {
                          (this.props.appstate.PreviewDialog.item.type==="poi"||
                          this.props.appstate.PreviewDialog.item.type==="path") &&
                          ( this.props.appstate.PreviewDialog.item.uriCoverExternalRes!=null &&
                            this.props.appstate.PreviewDialog.item.uriCoverExternalRes!=="")

                            ?
                            <div className="document-div2">
                              <div className="document-div-inner">
                                <img alt="img prev" style={{
                                  display: 'block',
                                  maxWidth: '130px',
                                  maxHeight:'65px',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  width: 'auto',
                                  height: '100px',
                                }} src={this.props.appstate.PreviewDialog.item.uriCoverExternalRes}></img>

                                <Box m={2} pt={3}>

                                  <Tooltip title={jsonlang.review.toUpperCase()}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="large"
                                      onClick={this.openTab}
                                      >
                                        {jsonlang.review.toUpperCase()}</Button>
                                      </Tooltip>
                                    </Box>


                                  </div>
                                </div>

                                :  this.props.appstate.PreviewDialog.item.type==="poi" ?
                                <div className="document-div2">
                                  <div className="document-div-inner">
                                    <img alt="img prev" style={{
                                      display: 'block',
                                      maxWidth: '230px',
                                      maxHeight:'95px',
                                      marginLeft: 'auto',
                                      marginRight: 'auto',
                                      width: 'auto',
                                      height: '100px',
                                    }} src="/images/icons/poi.jpg"></img>

                                    {/* <a href={this.props.appstate.PreviewDialog.item.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>

                                    */}
                                    <Box m={2} pt={3}>

                                      <Tooltip title={jsonlang.review.toUpperCase()}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          size="large"
                                          onClick={this.openTab}
                                          >
                                            {jsonlang.review.toUpperCase()}</Button>
                                          </Tooltip>

                                        </Box>
                                      </div>
                                    </div>
                                    // fine blocco PREVIEW RESOURCE

                                    :   <div className="document-div2">
                                      <div className="document-div-inner">
                                        <img alt="img prev" style={{
                                          display: 'block',
                                          maxWidth: '230px',
                                          maxHeight:'48px',
                                          marginLeft: 'auto',
                                          marginRight: 'auto',
                                          width: 'auto',
                                          height: '100px',
                                        }} src="/images/icons/path.jpg"></img>

                                        {/* <a href={this.props.appstate.PreviewDialog.item.url} target="_blank" >{jsonlang.openlink.toUpperCase()}</a>

                                        */}
                                        <Box m={2} pt={3}>

                                          <Tooltip title={jsonlang.review.toUpperCase()}>
                                            <Button
                                              variant="outlined"
                                              color="primary"
                                              size="large"
                                              onClick={this.openTab}
                                              >
                                                {jsonlang.review.toUpperCase()}</Button>
                                              </Tooltip>

                                            </Box>
                                          </div>
                                        </div>

                                      }
                                    </Grid>
                                  </Grid>

                                  : <>
                                  {/*inizio  blocco  PREVIEW INFO  */}

                                  <div>
                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.previewname.toUpperCase()}
                                      </Grid>

                                      <Grid item xs={10} className="prev-bleft">
                                        {this.props.appstate.PreviewDialog.item.name}
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.owner.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {this.props.appstate.PreviewDialog.item.ownerdetails[0].name}
                                      </Grid>
                                    </Grid>


                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.creator.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {this.props.appstate.PreviewDialog.item.dcCreator?
                                          <>{this.props.appstate.PreviewDialog.item.dcCreator}</>
                                          :  <>{"N.A."}</>
                                        }
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.rightsholder.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {this.props.appstate.PreviewDialog.item.dcRightsHolder ?
                                          <>{this.props.appstate.PreviewDialog.item.dcRightsHolder}</>
                                          : <>{"N.A."}</>
                                        }
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.creationdate.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        <>{this.getUTCData(this.props.appstate.PreviewDialog.item.createdAt)}</>
                                      </Grid>
                                    </Grid>


                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.previewdate.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {this.getUTCData(this.props.appstate.PreviewDialog.item.modifiedAt)}
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.position.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {this.props.appstate.PreviewDialog.item.location.coordinates[1] + ","+ this.props.appstate.PreviewDialog.item.location.coordinates[0]}
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.previewtitle.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {Object.entries(this.props.appstate.PreviewDialog.item.title).length!==0?
                                          <>{this.getDescriptionOrTitleMultilanguage(this.props.appstate.PreviewDialog.item.title)}</>
                                          : <>{"N.A."}</>
                                        }
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.previewdesc.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">
                                        {Object.entries(this.props.appstate.PreviewDialog.item.description).length!==0?
                                          <>{this.getDescriptionOrTitleMultilanguage(this.props.appstate.PreviewDialog.item.description)}</>
                                          : <>{"N.A."}</>
                                        }
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={2} className="prev-bright">
                                        {jsonlang.languagesettings.toUpperCase()}
                                      </Grid>
                                      <Grid item xs={10} className="prev-bleft">

                                        {this.state.itemsLan.map((item, index) =>

                                          <span
                                            style={{
                                              display:"inline-flex",
                                              marginLeft: "5px"
                                            }}>
                                            <img
                                              alt="language flag"
                                              style={{ width: "32px", height: "32px" }}
                                              src={"/images/languages/" + item + ".svg"}>
                                            </img>
                                          </span>
                                        )}

                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/*fine  blocco  PREVIEW INFO  */}
                                </>
                              }
                            </DialogContent>

                          </>
                          //finire vuoto
                          : <></>)

                        }
                        {/* FINE STILE DIALOG COMUNE */}
                        {/* </div> */}
                      </Dialog>
                      : <></>}
                    </>
                  )
                }
              }
              export default connect(mapStateToProps)(PreviewDialog);
