import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
  // import ReactDOM from 'react-dom';
  import './Registration.css';
  import * as services from "../../services/services.json";
  import { postService, getService } from "../../services/services";
  import DialogDisclaimer from '../Dialog/DialogDisclaimer.jsx';
  // import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

  import {
    AppBar,
    Box,
    Button,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Container,
    Grid,
    // Input,
    // Link,
    MenuItem,
    Paper,
    Slide,
    Select,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Tooltip,
    TextField,
    Typography,
  } from "@material-ui/core";
  import Visibility from '@material-ui/icons/Visibility';
  import VisibilityOff from '@material-ui/icons/VisibilityOff';
  import WarningIcon from '@material-ui/icons/Warning';
  const mapStateToProps = state => {
    return {
      acamar: state.acamar,
      appstate: state.appstate
    };
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  class Registration extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        password: '',
        password_confirm:'',
        instance: "ideha",
        role: "user",
        username:'',
        selectDomain:"",
        domain:[],
        emailerror: false,
        passworderror: false,
        nameerror: false,
        password_confirmerror: false,
        showPassword: false,
        showRepeatPassword: false,
        openDisclaimer:false,
        okDisclaimer:false,
        read:false
      }
    }

    componentDidMount() {
      // console.log("Registration component did mount")
      var self=this;
      getService(process.env.REACT_APP_DOMAIN + services.new_domains_GET.replace("{APPNAME}", self.props.acamar.app),
      "",
      function (err, result) {
        if (err) {
          console.log("errore get domini");
        } else if (result.status === 200) {
          console.log("domini arrivati");
          console.log(result.response);
          self.setState({domain:result.response});
        }
      }
    );

  }
  handleChange=(e)=> {
    this.setState({
      selectDomain: e.target.value
    });
  //  this.getEmailDomain(e.target.value);
  }
  handleChangeName= (event) => {
    this.setState({ name: event.target.value, nameerror: (event.target.value.length > 0 ? false : true) });
  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value, emailerror: (event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null ? true : false) });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value, passworderror: (event.target.value.length > 6 ? false : true), password_confirmerror: ((event.target.value !== this.state.password_confirm && this.state.password_confirm.length > 0) ? true : false)});

  }

  handleChangeConfirmPassword = (event) => {
    this.setState({
      password_confirmerror: (this.state.password === event.target.value) ? false : true, password_confirm: event.target.value
    })
  }

  handleClickOpenDisclaim = () => {
    this.setState({openDisclaimer:true});
    this.dialogDisclaimer.init(this.state.selectDomain);
  };
  handleCloseDisclaim = () => {
    this.setState({openDisclaimer:false});
  };


  registrationRequest = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    let user ={};
    user.email=this.state.email;
    user.password=this.state.password;
    user.name=this.state.name;
    user.domain = this.state.selectDomain;
    user.appname = this.props.acamar.app;
    let self = this;
    //TODO:IMPORTANTE tutti i controlli di coerenza dei dati di input
    //TODO: start dialog di caricamento
    postService(process.env.REACT_APP_DOMAIN + services.registration_POST, user,
      "", "",
      function (err, result) {
        if (err) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText =self.props.appstate.App.language.registrationerror;
          self.props.dispatch(APP(self.props.appstate));
          console.log(err);
          return;
        } else if (result.status === 200) {
          self.props.history.push('/Login');
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.registrationok;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        self.props.appstate.Modal.openModalLoadingTextActive = false;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.registrationerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
    );
  }

  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  showRepeatPassword = () => {
    this.setState({ showRepeatPassword: !this.state.showRepeatPassword });
  }

  getImageRotation = () => {
    switch (this.props.appstate.App.name.toUpperCase()) {
      case 'SOCLOSE':
      return(  <div className="fadein login-image">
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/migration-scaled.jpg"/>
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/emigranti-ellis-island.jpeg"/>
        <img className="fadein login-image" alt="immagine di migranti"  src="/images/sfondi-login/angiolo-tommasi-emigranti.jpg"/>
        <img className="fadein login-image" alt="immagine di murales" src="/images/sfondi-login/murales-athens.jpg"/>
      </div>);
      case 'VDA':
      return(<div className="login-image">
        <img className="staticImg login-image" alt="immagine di migranti" src="/images/sfondi-login/rotazione1_vda.png"/>
      </div>);
      case 'GREENSCENT':
      return(  <div className="fadein login-image">
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/rotazione1_greenscent.png"/>
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/rotazione2_greenscent.png"/>
        <img className="fadein login-image" alt="immagine di migranti"  src="/images/sfondi-login/rotazione3_greenscent.png"/>
        <img className="fadein login-image" alt="immagine di murales" src="/images/sfondi-login/rotazione4_greenscent.png"/>
      </div>);
      case 'SMST':
      return(<div className="fadein login-image">
        <img className="fadein login-image" alt="immagine favignana" src="/images/sfondi-login/favignana.jpg"/>
        <img className="fadein login-image" alt="immagine santa marinella" src="/images/sfondi-login/santa-marinella.jpg"/>
        <img className="fadein login-image" alt="immagine carditello"  src="/images/sfondi-login/carditello.jpg"/>
        <img className="fadein login-image" alt="immagine venosa" src="/images/sfondi-login/venosa.jpg"/>
      </div>);
      case 'SMSTAPP':
      return(<div className="login-image">
        <img className="staticImg login-image" alt="immagine smst" src="/images/sfondi-login/rotazione1_SMSTapp.jpg"/>
      </div>);
      case 'IDEHA':
      return(<div className="fadein login-image">
        <img className="fadein login-image" alt="immagine favignana" src="/images/sfondi-login/favignana.jpg"/>
        <img className="fadein login-image" alt="immagine santa marinella" src="/images/sfondi-login/santa-marinella.jpg"/>
        <img className="fadein login-image" alt="immagine carditello"  src="/images/sfondi-login/carditello.jpg"/>
        <img className="fadein login-image" alt="immagine venosa" src="/images/sfondi-login/venosa.jpg"/>
      </div>);
      default:
      return(  <div className="fadein login-image">
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/migration-scaled.jpg"/>
        <img className="fadein login-image" alt="immagine di migranti" src="/images/sfondi-login/emigranti-ellis-island.jpeg"/>
        <img className="fadein login-image" alt="immagine di migranti"  src="/images/sfondi-login/angiolo-tommasi-emigranti.jpg"/>
        <img className="fadein login-image" alt="immagine di murales" src="/images/sfondi-login/murales-athens.jpg"/>
      </div>);
    }

  }

  getFooter = () => {
    switch (this.props.appstate.App.name.toUpperCase()) {
      case 'SOCLOSE':
      return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
        <img
          // style={{height: 56}}
          src='/images/partner/footer.png'
          alt="logo-eu_label"/>
        </AppBar>);
        case 'VDA':
        return(<div></div>);
        case 'GREENSCENT':
        return(<AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
          <img
            // style={{height: 56}}
            src='/images/partner/barra.png'
            alt="logo-eu_label"/>
          </AppBar>);
          case 'SMST':
          return(<div></div>);
          case 'SMSTAPP':
          return(<div></div>);
          case 'IDEHA':
          return(<div></div>);
          default:
          return(<div></div>);
        }

      }

      okSubscribeDisclaimer = () =>{
        this.setState({okDisclaimer:true, openDisclaimer:false});
      }

      yesread = (event) => {
        //console.log(event);
        this.setState({read:event.target.checked});
      }

    /*  getEmailDomain = (domValue) => {
        var self=this;
        console.log(domValue);
        if(domValue!==""){
          let domain=this.state.domain.filter(dom => dom._id===domValue);
          if(domain.length>0){
            let serviceurl = process.env.REACT_APP_DOMAIN + services.user_GET;
            serviceurl = serviceurl.replace("{APPNAME}", self.props.acamar.app);
            serviceurl = serviceurl.replace("{DOMAINID}", domain[0]._id);
            serviceurl = serviceurl.replace("{ID}", domain[0].domainadmin);
            // console.log(serviceurl);
            getService(serviceurl,
            "",
            function (err, result) {
              if (err) {
                // console.log("errore get user admin domain");
              } else if (result.status === 200) {
                  let admin=result.response[0].users.filter(adm => adm.role==="domainadmin");
                // console.log(admin[0].email);

                self.setState({emailDomain:admin[0].email});
              }
            }
          );

          }
        }
      }*/


      render() {
        let jsonlang = this.props.appstate.App.language;
        return (
          <div style={{paddingBottom:56}}>

            <DialogDisclaimer open={this.state.openDisclaimer} close={this.handleCloseDisclaim}
            okDisc={this.okSubscribeDisclaimer}
          onRef={childthis => (this.dialogDisclaimer = childthis)}/>

            <Container>
              <Grid container>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                  {/* <Grid item xs> */}
                  <br></br>
                  <div className="paper front-slide">
                    {/* <div className="form-group">
                    <label for="name">{jsonlang.name.toUpperCase()}</label>
                    <OutlinedInput
                    autocomplete="new-password"
                    placeholder={jsonlang.name.toUpperCase()}
                    error={this.state.usernameerror}
                    fullWidth
                    onChange={this.handleChangeName}
                    name="name"
                    id="name"
                    type="text"
                    value={this.state.name}
                    // labelWidth={120}
                  />
                </div> */}
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    autocomplete="new-password"
                    label={jsonlang.name.toUpperCase()}
                    error={this.state.usernameerror}
                    fullWidth
                    onChange={this.handleChangeName}
                    name="name"
                    id="name"
                    type="text"
                    value={this.state.name}
                    inputProps={{ maxLength: 20 }}
                    // labelWidth={120}
                  />
                </div>
                {/* <div className="form-group">
                <label for="email">{jsonlang.email.toUpperCase()}</label>
                <OutlinedInput
                autocomplete="new-password"
                placeholder={jsonlang.email.toUpperCase()}
                error={this.state.emailerror}
                fullWidth
                onChange={this.handleChangeEmail}
                name="email"
                type="text"
                value={this.state.email}
              />
            </div> */}
            <div className="form-group">
              <TextField
                variant="outlined"
                autocomplete="new-password"
                label={jsonlang.email.toUpperCase()}
                error={this.state.emailerror}
                fullWidth
                onChange={this.handleChangeEmail}
                name="email"
                id="email"
                type="text"
                value={this.state.email}
              />
            </div>
            <div className="form-group">
              <label className="visuallyhidden" for="password">PASSWORD</label>
              <OutlinedInput
                aria-label="password"
                autocomplete="new-password"
                placeholder="PASSWORD"
                error={this.state.passworderror}
                fullWidth
                onChange={this.handleChangePassword}
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      fontSize="large"
                      onClick={this.showPassword}
                      edge="start"
                      aria-label="hide show password"
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="form-group">
                {/* <label for="confirm">{jsonlang.confirmpassword.toUpperCase()}</label> */}
                <label className="visuallyhidden" for="confirm password">{jsonlang.confirmpassword.toUpperCase()}</label>
                <OutlinedInput
                  // inputProps={{
                  //   autoComplete: 'off'
                  // }}
                  aria-label="confirm password"
                  autocomplete="new-password"
                  placeholder={jsonlang.confirmpassword.toUpperCase()}
                  error={this.state.password_confirmerror}
                  fullWidth
                  onChange={this.handleChangeConfirmPassword}
                  name="password_confirm"
                  type={this.state.showRepeatPassword ? 'text' : 'password'}
                  value={this.state.password_confirm}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="inherit"
                        fontSize="large"
                        onClick={this.showRepeatPassword}
                        edge="start"
                        >
                          {this.state.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="form-group">
                  {/* <label for="confirm">{jsonlang.selecteddomain.toUpperCase()}</label> */}
                  <Select
                    required
                    className="MuiInputBase-input-custom"
                    id="id"
                    displayEmpty
                    fullWidth
                    value={this.state.selectDomain}
                    onChange={this.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>{jsonlang.selecteddomain.toUpperCase()}</em>
                      </MenuItem>
                      {this.state.domain.map((domain, i) =>
                        <MenuItem  key={i} className="MuiInputBase-input-custom" value={domain._id}>{domain.shortname}</MenuItem>
                      )}
                    </Select>
                  </div>

                  <div className="form-group">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      >
                        <Grid item xs={8}>
                          <Tooltip title={<h2>Disclaimer</h2>}>
                          <Button
                            startIcon={<WarningIcon/>}
                            onClick={this.handleClickOpenDisclaim}
                            disabled={(this.state.emailerror === false && this.state.passworderror === false && this.state.password_confirmerror === false && this.state.password.length > 6 && this.state.selectDomain !== "" && this.state.name.length > 0 && this.state.password_confirm.length > 6 ? false : true)}
                            >
                              Disclaimer
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip title={<h2>{jsonlang.register.toUpperCase()}</h2>}>
                          <Button
                            disabled={(this.state.emailerror === false && this.state.passworderror === false && this.state.password_confirmerror === false && this.state.password.length > 6 && this.state.selectDomain !== "" && this.state.name.length > 0 && this.state.password_confirm.length > 6 &&
                            this.state.read === true ? false : true)}
                            variant="contained"
                            color="primary"
                            onClick={this.registrationRequest}
                            >{jsonlang.register}</Button>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <div>
                            <input
                              type="checkbox"
                              id="disclaimer"
                              name="letto"
                              onClick={this.yesread}
                              disabled={(this.state.emailerror === false && this.state.passworderror === false && this.state.password_confirmerror === false && this.state.password.length > 6 && this.state.selectDomain !== "" && this.state.name.length > 0 && this.state.password_confirm.length > 6 &&
                              this.state.okDisclaimer === true ? false : true)}

                            />
                            <Box
                              component="span" m={1}
                              color={(this.state.emailerror === false && this.state.passworderror === false && this.state.password_confirmerror === false && this.state.password.length > 6 && this.state.selectDomain !== "" && this.state.name.length > 0 && this.state.password_confirm.length > 6 &&
                              this.state.okDisclaimer === true ? "text.primary" : "text.disabled")}
                              >
                                <Typography variant="caption">{jsonlang.read.toUpperCase()}</Typography>
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={false} sm={4} md={7} >

                    {/* qui ci andranno le *quattro* immagini prese dal file di configurazione */}
                    {/* l'animazione "fadein" in login.css richiede esattamente 4 immagini */}
                    {this.getImageRotation()}

                  </Grid>
                </Grid>



                    {/* <ThemeProvider theme={createMuiTheme(this.props.appstate.App.theme.themelink)}> */}
                    {/* <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box>
                    {'Copyright © '}
                    <Link
                    color="inherit"
                    aria-label="SO CLOSE HOME PAGE LINK"
                    href="https://so-close.eu"
                    target="blank"
                    >
                    <span>SO CLOSE</span>
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {' '}
                </Box>
              </Box> */}
              {/* </ThemeProvider> */}
              {this.getFooter()}
            </Container>

          </div>
        )
      }
    }
    export default connect(mapStateToProps)(Registration);
