import {
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    ListSubheader,
    ButtonGroup,
    // Button,
    // TextField,
    // InputAdornment,
    InputBase,
    // Paper,
    Divider,
    Tabs,
    Tab,
    AppBar,
    Toolbar
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CancelRounded from '@material-ui/icons/CancelRounded';
import Search from '@material-ui/icons/Search';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Audiotrack from '@material-ui/icons/Audiotrack';
import Videocam from '@material-ui/icons/Videocam';
import Apps from '@material-ui/icons/Apps';
import Image from '@material-ui/icons/ImageAspectRatioRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import { getService } from "../../services/services";
import * as services from "../../services/services.json";
import './Resources.css';
const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};


class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            items: this.props.appstate.Resource.resources,
            showtile: {},
            currentTab:0
        });
    }
    componentDidMount() {
        this.getResources();
    }

    getResources = () => {
        this.props.appstate.Modal.openModalLoading = true;
        this.props.appstate.Modal.openModalLoadingTextActive = false;

        this.props.dispatch(APP(this.props.appstate));
        let self = this;
        let ser = process.env.REACT_APP_DOMAIN + services.resource_GET_AUTH.replace("{APPNAME}", this.props.appstate.App.name);
        ser = ser.replace("{DEST}", "resource");
        getService(
            ser,
            "",
            function (err, result) {
                if (err) {
                    self.props.appstate.Modal.openModalLoadingTextActive = true;
                    self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.resourcerror;
                    self.props.dispatch(APP(self.props.appstate));
                    return;
                }
                if (result.status === 200) {
                    self.props.appstate.Modal.openModalLoading = false;
                    self.props.appstate.Resource.resources = result.response;
                    self.props.dispatch(APP(self.props.appstate));
                    self.setState({ items: self.props.appstate.Resource.resources, currentTab:0});
                    return;
                }
                self.props.appstate.Modal.openModalLoadingTextActive = true;
                self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.noresource;
                self.props.dispatch(APP(self.props.appstate));
                return;
            }
        );
    }

    upload = () => {
        this.setState({ items: this.props.appstate.Resource.resources });
        this.props.appstate.Resource.openModalResources = true;
        this.props.dispatch(APP(this.props.appstate));
    }

    delete = (event) => {
        //console.log(event.currentTarget.id);
        this.props.appstate.Modal.openModalDelete = true;
        this.props.appstate.Resource.index = event.currentTarget.name;
        this.props.appstate.Resource.id = event.currentTarget.id;
        this.props.dispatch(APP(this.props.appstate));
    }

    urlCopy = (event) => {
        //console.log(event.currentTarget.id);
        let index = event.currentTarget.name;
        if (index && index < this.props.appstate.Resource.resources.length) {
            const temp = document.createElement("input");
            document.body.appendChild(temp);
            temp.value = this.props.appstate.Resource.resources[index].absolutepath;
            temp.select();
            document.execCommand("copy");
            document.body.removeChild(temp);
        }
    }

    imageOnMouseOver = (event) => {
        //console.log(event.currentTarget.id);
        let showtile = {};
        showtile[event.currentTarget.id] = true;
        this.setState({ showtile: showtile });
    }

    imageOnMouseOut = (event) => {
        //console.log(event.currentTarget.id);
        let showtile = {};
        showtile[event.currentTarget.id] = false;
        this.setState({ showtile: showtile });
    }

    searchInput = (event) => {
        console.log(event.currentTarget.value);
        let filtered = this.props.appstate.Resource.resources.filter(function (el, index, arr) {
            // Filtering here
            return ((el.name.toUpperCase()).indexOf(event.currentTarget.value.toUpperCase()) !== -1)
        });
        this.setState({ items: filtered });
    }

    searchInputCancel = () => {
        document.getElementById("resourcesearchinput").value = "";
        this.setState({ items: this.props.appstate.Resource.resources, currentTab:0 });
    }

    filtertabchange = (event, value) => {
        var filtered = [];
        switch (event.currentTarget.name) {
            case "all":
                document.getElementById("resourcesearchinput").value = "";
                this.setState({ items: this.props.appstate.Resource.resources, currentTab: value  });
                break;
            case "image":
            case "audio":
            case "video":
                var copyResources = this.props.appstate.Resource.resources.slice(0, this.props.appstate.Resource.resources.length-1);
                filtered = copyResources.filter(function (el, index, arr) {
                    return (el.type.toUpperCase() === event.currentTarget.name.toUpperCase())
                });
                this.setState({ items: filtered, currentTab: value });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div>
                <AppBar position="static" color="default">
                    <Toolbar>
                    <Tabs
                            onChange={this.filtertabchange}
                            variant="scrollable"
                            scrollButtons="off"
                            value={this.state.currentTab}
                        >
                            <Tab name={"all"} icon={<Apps />} color="primary" />
                            <Tab name={"image"} icon={<Image />} color="primary"/>
                            <Tab name={"audio"} icon={<Audiotrack />} color="primary"/>
                            <Tab name={"video"} icon={<Videocam />} color="primary" />
                        </Tabs>
                            <IconButton color="primary">
                                <Search />
                            </IconButton>
                            <InputBase
                                id="resourcesearchinput"
                                onChange={this.searchInput}
                            />
                            <Divider style={{
                                height: 28,
                                margin: 4
                            }} orientation="vertical" />
                        <CancelRounded color="primary" onClick={this.searchInputCancel}>
                            </CancelRounded>
                    </Toolbar>
                </AppBar>
                <div className={"resourcesbody"}>
                    <GridList cellHeight={150} cols={7} spacing={10}>
                        <GridListTile key="Subheader" cols={1} onClick={this.upload}>
                            <ListSubheader component="div">Add Resource</ListSubheader>
                            <AddIcon color="primary" style={{ fontSize: 50 }} />
                        </GridListTile>
                        {this.state.items.map((item, index) =>
                            <GridListTile index={index} >
                                <img id={"imgres" + item._id} src={item.absolutecoverpath} alt={item.name} onMouseOver={this.imageOnMouseOver} />
                                {this.state.showtile["imgres" + item._id] === true ?
                                    <GridListTileBar id={"imgres" + item._id} onMouseLeave={this.imageOnMouseOut}
                                        title={item.name}
                                        subtitle={<span>{item.description}</span>}
                                        actionIcon={
                                            <ButtonGroup aria-label="small outlined button group">
                                                <IconButton name={index} id={item._id} onClick={this.urlCopy}>
                                                    <FileCopyOutlinedIcon color="primary" style={{ fontSize: 20 }} />
                                                </IconButton >
                                                <IconButton name={index} id={item._id} onClick={this.delete}>
                                                    <DeleteIcon color="primary" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </ButtonGroup>
                                        }
                                    /> : <> </>}
                            </GridListTile>
                        )}
                    </GridList>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(withRouter(Resources))
