import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
import * as mediatypes from "../../Utils/mediatype.json";


import {
  // AppBar,
  // Avatar,
  // Box,
  Button,
  // ButtonGroup,
  // Container,
  // CssBaseline,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Divider,
  // Fab,
  // FormControl,
  // FormControlLabel,
  // FormGroup,
  // FormLabel,
  // Grid,
  // Icon,
  // IconButton,
  // InputLabel,
  // Link,
  // ListSubheader,
  // Menu,
  // MenuItem,
  // Paper,
  // Radio,
  // RadioGroup,
  // Select,
  // Slide,
  // Switch,
  TextField,
  // Toolbar,
  Tooltip,
  // Typography
} from "@material-ui/core";


import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class ResourcesUpload extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      name: "",
      description: "",
      file:undefined
    })
  }

  componentDidMount() {

  }

  fileChoiced = () => {
    this.setState({ file: document.getElementById('file').files[0] }, this.props.dataform({ name: this.state.name, description: this.state.description }, document.getElementById('file').files[0]));
  }
  textfiedlvalue = (event) => {
    switch (event.target.id){
      case "nameres":
      this.setState({ name: event.target.value }, this.props.dataform({ name: event.target.value, description: this.state.description }, document.getElementById('file').files[0]));
      break;
      case "descres":
      this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
      break;
      default:
      break;
    }
  }

  render() {
      let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <div style={{padding:10}}>
          <TextField
            fullWidth
            id="nameres"
            label={jsonlang.nome.toUpperCase()}
            defaultValue=""
            onChange={this.textfiedlvalue}
          />
        </div>
        <div style={{padding:10}}>
          <TextField
            fullWidth
            id="descres"
            label={jsonlang.desc.toUpperCase()}
            defaultValue=""
            onChange={this.textfiedlvalue}
          />
        </div>
        <div style={{padding:10}}>
          {/* <Button variant="contained" size="small" color="primary">
          <input
          id="file"
          type="file"
          onChange={this.fileChoiced}
          // accept={'.mp4, .png, .jpg, .jpeg, .png, .mp3'}
          accept={this.props.fileAccept}/>
        </Button> */}
        <br/>

        <input
          hidden
            accept={mediatypes.resourcesMT}
          id="file"
          type="file"
          onChange={this.fileChoiced}
          size="large"
        />
        <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
        <label
          htmlFor="file"
          >
            <Button
              variant="contained"
              color="primary"
              aria-label="upload resource"
              component="span"
              size="small"
              startIcon={<CloudUploadIcon />}
              >
                {jsonlang.upload.toUpperCase()}
              </Button>
            </label>
          </Tooltip>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(ResourcesUpload));
