import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import RoomIcon from '@material-ui/icons/Room';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
//import ReactFlagsSelect from 'react-flags-select';
import './ResourcesUploadManager.css';
import FlagsDialog from './FlagsDialog';

import {
  Button,
  TextField,
  Tooltip,
  Fab,
  Select,
  MenuItem,
  Divider,
  //ButtonGroup,
  Switch,
  Grid,
  //Checkbox,
  Toolbar,
  //InputLabel,
  FormControl,
  ListItemIcon,
  Typography,
  IconButton,
  Link,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";


import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getRights, getExternalMediaType } from "../../Utils/utility";


//import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
//import ImageIcon from '@material-ui/icons/Image';
import languages from '../../config/languages.json';
import LanguageIcon from '@material-ui/icons/Language';
import * as mediatypes from "../../Utils/mediatype.json";
import InfoIcon from '@material-ui/icons/Info';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class ResourcesUploadManager extends Component {
  constructor(props) {
    super(props);
    this.refFileinput = null; /*https://reactjs.org/docs/refs-and-the-dom.html#legacy-api-string-refs*/
    this.setRefFileinput = element => {
      this.refFileinput = element;
    };
    this.refFileinputClick = () => {
      if (this.refFileinput) {
        this.refFileinput.click();
      }
    };
    this.state = ({
      name: "",
      description: {},
      title: {},
      file: undefined,
      filename: "",
      rights: getRights("all"),
      selectedRight: getRights("CC0").value,
      mediaLanguageList: this.props.acamar.configuration.Languages.list,
      selectedMediaLanguage: "en",
      /*dataLanguage inizializzato con la lingua impostata dall'utente*/
      dataLanguage: this.props.appstate.App.currentLanguage,
      degreeMedia: false,
      degreeMediaSelected: "2d",
      langfilter: new Array(this.props.acamar.configuration.Languages.list.length).fill(false),
      titlestate: "",
      descstate: "",
      linkRight: getRights("CC0").uri,
      creator: "",
      isExtRes: false,
      selectedExternalMediaType: "",
      uriExternalRes: "",
      uriExternalResState: "",
      dcRightsHolder: "",
      mediares: "first",
      uriCoverExternalRes: "",
      publicStatusExtRes: false,
      /**
      * 23/05/22 - text/cloud resources multinlanguage
      */
      textcloudinputType: false,
      fileinputType: true,
      externcloudinputType: false,
      textcloudcontent: "",
      textcloudstate: "",
    })
  }
  componentDidMount() {
  }

  // {this.props.from ?(this.props.from === "warning"? : ) :}
  fileChoiced = (event) => {
    console.log(event.target.files[0])
    if (this.props.from) { //check for greenscent to limit size to uplaod 
      if (this.props.from === "warning") {
        const sizeLimit = 500; //for testing mode  2Mb in production mode 500Mb         
        if (event.target.files.length > 0) {
          const fileSize = event.target.files.item(0).size;
          const fileMb = fileSize / 1024 ** 2;
          if (fileMb >= sizeLimit) {
            console.log("file troppo grande ")
            let jsonlang = this.props.appstate.App.language;
            this.props.appstate.Modal.openModalLoading = true;
            this.props.appstate.Modal.openModalLoadingTextActive = true;
            this.props.appstate.Modal.openModalLoadingText = jsonlang.upload_max_size_message;
            this.props.dispatch(APP(this.props.appstate));
            return;
          }
        }
      }
    }
    var fullname = event.target.files[0].name; // elimino l'estesione del filename
    var nameclean = fullname.substr(0, fullname.lastIndexOf('.')) || fullname;

    // console.log(nameclean)

    if (event.target.files[0]) {
      this.setState({
        file: event.target.files[0],
        filename: event.target.files[0].name,
        name: this.state.name === "" ? nameclean : this.state.name
      }, this.props.dataform({
        name: this.state.name === "" ? nameclean : this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degreeMediaSelected: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        creator: this.state.creator,
        isExtRes: this.state.isExtRes,
        uriExternalRes: this.state.uriExternalRes,
        selectedExternalMediaType: this.state.selectedExternalMediaType,
        dcRightsHolder: this.state.dcRightsHolder,
        mediares: this.state.mediares,
        uriCoverExternalRes: this.state.uriCoverExternalRes,
        publicStatusExtRes: this.state.publicStatusExtRes
      },
        event.target.files[0]));
    }
  }

  textfiedlvalue = (event) => {
    switch (event.target.id) {
      case "nameres":
        this.setState({ name: event.target.value }, this.props.dataform({
          name: event.target.value,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          creator: this.state.creator,
          isExtRes: this.state.isExtRes,
          uriExternalRes: this.state.uriExternalRes,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          dcRightsHolder: this.state.dcRightsHolder,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes,
          textcloudcontent: this.state.textcloudstate
        },
          // document.getElementById('file').files[0]));
          this.state.file ? this.state.file : null));

        break;
      case "descres":
        this.setState({ descstate: event.target.value });
        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      case "title":
        this.setState({ titlestate: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          creator: this.state.creator,
          isExtRes: this.state.isExtRes,
          uriExternalRes: this.state.uriExternalRes,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          dcRightsHolder: this.state.dcRightsHolder,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes,
          textcloudcontent: this.state.textcloudstate
        },
          //document.getElementById('file').files[0]));
          this.state.file ? this.state.file : null));

        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      case "creator":
        this.setState({ creator: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          creator: event.target.value,
          isExtRes: this.state.isExtRes,
          uriExternalRes: this.state.uriExternalRes,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          dcRightsHolder: this.state.dcRightsHolder,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes,
          textcloudcontent: this.state.textcloudstate
        },
          //document.getElementById('file').files[0]));
          this.state.file ? this.state.file : null));

        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      case "url":
        this.setState({ uriExternalResState: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          isExtRes: this.state.isExtRes,
          creator: this.state.creator,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          uriExternalRes: event.target.value,
          dcRightsHolder: this.state.dcRightsHolder,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes,
          textcloudcontent: this.state.textcloudstate
        },
          null));
        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      case "textfieldtextcloud": //saving in charge of onblur
        this.setState({ textcloudstate: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          isExtRes: this.state.isExtRes,
          creator: this.state.creator,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          uriExternalRes: this.state.uriExternalRes,
          dcRightsHolder: this.state.dcRightsHolder,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes,
          textcloudcontent: event.target.value
        },
          null));
        break;
      case "dcRightsHolder":
        this.setState({ dcRightsHolder: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          isExtRes: this.state.isExtRes,
          creator: this.state.creator,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          uriExternalRes: this.state.uriExternalRes,
          dcRightsHolder: event.target.value,
          mediares: this.state.mediares,
          uriCoverExternalRes: this.state.uriCoverExternalRes,
          publicStatusExtRes: this.state.publicStatusExtRes
        },
          null));
        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      case "coverUrl":
        this.setState({ uriCoverExternalRes: event.target.value }, this.props.dataform({
          name: this.state.name,
          description: this.state.description,
          title: this.state.title,
          selectedRight: this.state.selectedRight,
          linkRight: this.state.linkRight,
          degreeMediaSelected: this.state.degreeMediaSelected,
          selectedMediaLanguage: this.state.selectedMediaLanguage,
          isExtRes: this.state.isExtRes,
          creator: this.state.creator,
          selectedExternalMediaType: this.state.selectedExternalMediaType,
          uriCoverExternalRes: event.target.value,
          dcRightsHolder: this.state.dcRightsHolder,
          uriExternalRes: this.state.uriExternalRes,
          mediares: this.state.mediares,
          publicStatusExtRes: this.state.publicStatusExtRes
        },
          null));
        // this.setState({ description: event.target.value }, this.props.dataform({ name: this.state.name, description: event.target.value }, document.getElementById('file').files[0]));
        break;
      default:
        break;
    }
  }
  saveDescForLang = () => {
    let descCopy = Object.assign({}, this.state.description);
    if (this.state.descstate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (descCopy[this.state.dataLanguage]) {
        delete descCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      descCopy[this.state.dataLanguage] = this.state.descstate;
    }
    this.setState({ description: descCopy }, this.props.dataform({
      name: this.state.name,
      description: descCopy,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes,
      publicStatusExtRes: this.state.publicStatusExtRes
    },
      // document.getElementById('file').files[0]));
      this.state.file ? this.state.file : null));
  }

  saveTitleForLang = () => {
    let titleCopy = Object.assign({}, this.state.title);
    if (this.state.titlestate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (titleCopy[this.state.dataLanguage]) {
        delete titleCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      titleCopy[this.state.dataLanguage] = this.state.titlestate;
    }
    this.setState({ title: titleCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: titleCopy,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes,
      publicStatusExtRes: this.state.publicStatusExtRes
    },
      //    document.getElementById('file').files[0]));
      this.state.file ? this.state.file : null));
  }

  /**
  * 24/05/22 - hotspot text
  */
  saveTextForLang = () => {
    console.log("savetextforlang");
    let textContentCopy = Object.assign({}, this.state.textcloudcontent);
    if (this.state.textcloudstate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (textContentCopy[this.state.selectedMediaLanguage]) {
        delete textContentCopy[this.state.selectedMediaLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      textContentCopy[this.state.selectedMediaLanguage] = this.state.textcloudstate;
    }
    this.setState({ textcloudcontent: textContentCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: "",
      selectedExternalMediaType: "textcloud",
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: "",
      publicStatusExtRes: this.state.publicStatusExtRes,
      textcloudcontent: textContentCopy
    },
      this.state.file ? this.state.file : null));
  }

  /**
  * 26/05/22 - extern resources multilanguages
  */
  saveUrlForLang = () => {
    console.log("saveUrlForLang")
    let urlContentCopy = Object.assign({}, this.state.uriExternalRes);
    if (this.state.uriExternalResState === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (urlContentCopy[this.state.selectedMediaLanguage]) {
        delete urlContentCopy[this.state.selectedMediaLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      urlContentCopy[this.state.selectedMediaLanguage] = this.state.uriExternalResState;
    }
    this.setState({ uriExternalRes: urlContentCopy }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: urlContentCopy,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: "",
      publicStatusExtRes: this.state.publicStatusExtRes,
      textcloudcontent: this.state.textcloudcontent
    },
      this.state.file ? this.state.file : null));
  }
  showMap = () => {
    this.props.appstate.MapDialog.render = true;
    this.props.appstate.MapDialog.openModalMap = true;
    this.props.dispatch(APP(this.props.appstate));
  }
  handleChangeRight = (e) => {
    this.setState({ selectedRight: e.target.value, linkRight: getRights(e.target.value).uri }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: e.target.value,
      linkRight: getRights(e.target.value).uri,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes,
      publicStatusExtRes: this.state.publicStatusExtRes
    },
      //    document.getElementById('file').files[0]));
      this.state.file ? this.state.file : null));

  }
  handleChangeMediaLanguage = (e) => {
    //console.log(this.state.textcloudcontent.hasOwnProperty(this.state.selectedMediaLanguage));
    this.setState({
      selectedMediaLanguage: e.target.value,
      textcloudstate: this.state.textcloudcontent.hasOwnProperty(e.target.value) === false ? "" : this.state.textcloudcontent[e.target.value],
      uriExternalResState: this.state.uriExternalRes.hasOwnProperty(e.target.value) === false ? "" : this.state.uriExternalRes[e.target.value],
    }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: e.target.value,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes,
      publicStatusExtRes: this.state.publicStatusExtRes,
      textcloudcontent: this.state.textcloudcontent
    },
      //    document.getElementById('file').files[0]));
      this.state.file ? this.state.file : null));
  }

  worldLangHandler = (lang) => {
    console.log(lang);
    let langfiltercopy = this.state.langfilter.slice().fill(false);
    let currentDescription = this.state.description[lang.toLowerCase()];
    let currentTitle = this.state.title[lang.toLowerCase()];
    if (!currentTitle) {
      currentTitle = "";
    }
    if (!currentDescription) {
      currentDescription = "";
    }
    this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy, titlestate: currentTitle, descstate: currentDescription },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degreeMediaSelected: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        dataLanguage: lang.toLowerCase(),
        creator: this.state.creator,
        isExtRes: this.state.isExtRes,
        uriExternalRes: this.state.uriExternalRes,
        selectedExternalMediaType: this.state.selectedExternalMediaType,
        dcRightsHolder: this.state.dcRightsHolder,
        mediares: this.state.mediares,
        uriCoverExternalRes: this.state.uriCoverExternalRes,
        publicStatusExtRes: this.state.publicStatusExtRes
      },
        //    document.getElementById('file').files[0]));
        this.state.file ? this.state.file : null));

    this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy });
  }
  handleDegreeType = (e) => {
    let internalDegree = "";
    if (e.target.checked) {
      internalDegree = 3;
    } else {
      internalDegree = 2;
    }
    this.setState({ degreeMedia: e.target.checked, degreeMediaSelected: internalDegree }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: internalDegree,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes,
      publicStatusExtRes: this.state.publicStatusExtRes
    },
      this.state.file ? this.state.file : null));
  }

  handlePublicStatusExternalWebResource = (e) => {
    this.setState({ publicStatusExtRes: e.target.checked }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      publicStatusExtRes: e.target.checked,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      creator: this.state.creator,
      isExtRes: this.state.isExtRes,
      uriExternalRes: this.state.uriExternalRes,
      selectedExternalMediaType: this.state.selectedExternalMediaType,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: this.state.uriCoverExternalRes
    },
      this.state.file ? this.state.file : null));
  }


  handleIsExtRes = (e) => {
    let fileChecked,
      filenameChecked,
      mediatypeChecked,
      uriChecked,
      textcloudinputTypeTemp,
      fileinputTypeTemp,
      externcloudinputTypeTemp,
      isExtResTemp;
    switch (e.currentTarget.value) {
      case "text":
        fileChecked = false;
        filenameChecked = "";
        mediatypeChecked = this.state.selectedExternalMediaType;
        uriChecked = "";
        textcloudinputTypeTemp = true;
        fileinputTypeTemp = false;
        externcloudinputTypeTemp = false;
        isExtResTemp = true;
        break;
      case "file":
        fileChecked = true;
        filenameChecked = this.state.filename;
        mediatypeChecked = "";
        uriChecked = "";
        textcloudinputTypeTemp = false;
        fileinputTypeTemp = true;
        externcloudinputTypeTemp = false;
        isExtResTemp = false;
        break;
      case "url":
        fileChecked = false;
        filenameChecked = "";
        mediatypeChecked = this.state.selectedExternalMediaType;
        uriChecked = this.state.uriExternalRes;
        textcloudinputTypeTemp = false;
        fileinputTypeTemp = false;
        externcloudinputTypeTemp = true;
        isExtResTemp = true;
        break;
      default:
        break
    }
    /*let fileChecked = e.target.checked ? undefined: this.state.file;
    let filenameChecked=e.target.checked ? "": this.state.filename;
    let mediatypeChecked=e.target.checked ? "": this.state.selectedExternalMediaType;
    let uriChecked=e.target.checked ? "": this.state.uriExternalRes;*/


    this.setState({
      isExtRes: isExtResTemp,
      file: fileChecked,
      filename: filenameChecked,
      selectedExternalMediaType: mediatypeChecked,
      uriExternalRes: uriChecked,
      textcloudinputType: textcloudinputTypeTemp,
      fileinputType: fileinputTypeTemp,
      externcloudinputType: externcloudinputTypeTemp
    },
      this.props.dataform({
        name: this.state.name,
        description: this.state.description,
        title: this.state.title,
        selectedRight: this.state.selectedRight,
        linkRight: this.state.linkRight,
        degreeMediaSelected: this.state.degreeMediaSelected,
        selectedMediaLanguage: this.state.selectedMediaLanguage,
        creator: this.state.creator,
        uriExternalRes: this.state.uriExternalRes,
        isExtRes: e.target.checked,
        selectedExternalMediaType: this.state.selectedExternalMediaType,
        dcRightsHolder: this.state.dcRightsHolder,
        mediares: this.state.mediares,
        uriCoverExternalRes: this.state.uriCoverExternalRes,
        publicStatusExtRes: this.state.publicStatusExtRes,
        textcloudcontent: this.state.textcloudcontent
      }, this.state.file ? this.state.file : null));
  }
  handleChangeExternalMediaType = (e) => {
    // this.setState({selectedExternalMediaType:e.target.value});
    this.setState({ selectedExternalMediaType: e.target.value, uriCoverExternalRes: "", uriExternalRes: this.state.selectedExternalMediaType !== "" ? "" : this.state.uriExternalRes, publicStatusExtRes: false, uriExternalResState: "" }, this.props.dataform({
      name: this.state.name,
      description: this.state.description,
      title: this.state.title,
      selectedRight: this.state.selectedRight,
      linkRight: this.state.linkRight,
      degreeMediaSelected: this.state.degreeMediaSelected,
      selectedMediaLanguage: this.state.selectedMediaLanguage,
      isExtRes: this.state.isExtRes,
      creator: this.state.creator,
      uriExternalRes: "", //when you change the type, you will lose the old multilanguages setting
      isExtRes: this.state.isExtRes,
      selectedExternalMediaType: e.target.value,
      dcRightsHolder: this.state.dcRightsHolder,
      mediares: this.state.mediares,
      uriCoverExternalRes: "",
      publicStatusExtRes: false
    },
      null));
  }

  openFlagsDialog = () => {
    this.flagsDialogRef.init();
    this.props.appstate.FlagsDialog.render = true;
    this.props.appstate.FlagsDialog.openModalFlags = true;
    let titlearr = Object.keys(this.state.title);
    let descarr = Object.keys(this.state.description);

    //console.log(titlearr);
    //console.log(descarr);

    if (titlearr.length >= descarr.length) {
      this.props.appstate.FlagsDialog.items = titlearr;
    } else {
      this.props.appstate.FlagsDialog.items = descarr;
    }
    this.props.dispatch(APP(this.props.appstate));
  }
  callBackFlagsDialog = (lang) => {
    this.worldLangHandler(lang);
  }
  selectLanguage = (event) => {
    this.worldLangHandler(event.target.value);
  }


  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <div>
        <FlagsDialog onRef={childthis => (this.flagsDialogRef = childthis)} callback={this.callBackFlagsDialog} />
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="gridmargin">
          <Grid item xs={2}>
            <Typography variant="button" display="block">
              {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
              {/* {"NAME"} */}
              {jsonlang.identifier.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <div>
              <TextField
                variant="outlined"
                style={{ width: "98.5%" }}
                id="nameres"
                label={jsonlang.identifier.toUpperCase()}
                value={this.state.name}
                onChange={this.textfiedlvalue}
              />
            </div>
          </Grid>
        </Grid>

        <Divider />

        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="gridmargin"
        >
          <Grid item xs={2}>
            <Typography variant="button" display="block">
              {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
              {"RIGHTS/ POSITION / CREATOR/ RIGHTS HOLDER"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <div
                  style={{padding:5}}
                  className="selectright1"
                  > */}
            <Select
              required
              id="id"
              displayEmpty
              // fullWidth
              value={this.state.selectedRight}
              onChange={this.handleChangeRight}
              style={{ minWidth: 220, maxHeight: 70 }}
            >
              <MenuItem disabled value="">
                <em>{jsonlang.selerig.toUpperCase()}</em>
              </MenuItem>
              {this.state.rights.map((right, i) =>
                <MenuItem key={i} className="MuiInputBase-input-custom" value={right.value}>{right.value}</MenuItem>
              )}
            </Select>
          </Grid>
          {/* </div> */}
          {/* <div style={{paddingLeft:5}}  className="selectright1"> */}
          <Grid item xs={1}>
            {getRights(this.state.selectedRight).uri === "" ?
              <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo} />
              :
              <Tooltip title={jsonlang.rightInfo.toUpperCase()}>
                <Link
                  color="inherit"
                  role="link"
                  aria-label="Public Domain - No Copyright"
                  href={getRights(this.state.selectedRight).uri}
                  target="blank"
                >
                  <img className="rights-img-RM" alt="CC-BY-NC-ND" src={getRights(this.state.selectedRight).logo} />
                </Link>
              </Tooltip>
            }
            {/* </div> */}
          </Grid>
          {/* <div style={{ padding: 10 }} className="selectright1"> */}
          <Grid item xs={1}>
            <Tooltip title={<h2>{jsonlang.position.toUpperCase()}</h2>}>
              <Fab
                role="button"
                color="primary"
                size="small"
                aria-label={jsonlang.adda.toUpperCase()}
                onClick={this.showMap}
              >
                <RoomIcon
                />
              </Fab>
            </Tooltip>
            {this.props.appstate.MapDialog.position.lat.toFixed(3) !== "0.000" && this.props.appstate.MapDialog.position.lng.toFixed(3) !== "0.000" ?
              <label> {this.props.appstate.MapDialog.position.lat.toFixed(3) + "," + this.props.appstate.MapDialog.position.lng.toFixed(3)} </label> : null}
            {/* </div> */}
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={<h2>{jsonlang.creatordesc}</h2>}>
              <TextField
                variant="outlined"
                // fullWidth
                style={{ width: "95%" }}
                id="creator"
                label={jsonlang.creator.toUpperCase()}
                defaultValue=""
                value={this.state.creator}
                onChange={this.textfiedlvalue}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={<h2>{jsonlang.rightsholdertip}</h2>}>
              <TextField
                variant="outlined"
                // fullWidth
                style={{ width: "95%" }}
                id="dcRightsHolder"
                label={jsonlang.rightsholder.toUpperCase()}
                defaultValue=""
                value={this.state.dcRightsHolder}
                onChange={this.textfiedlvalue}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Divider />

        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="gridmargin">

          <Grid item xs={2}>
            <Typography variant="button" display="block">
              {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
              {jsonlang.selelang.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {/* <Toolbar> */}
            <FormControl>
              {/* <InputLabel id="languages_choose">Languages</InputLabel> */}
              <Select
                style={{ minWidth: 358, maxHeight: 70 }}
                labelId="languages_choose"
                id="languages_select"
                value={this.state.dataLanguage.toLowerCase()}
                onChange={this.selectLanguage}
                displayEmpty
                // variant={"outlined"}
                defaultValue={"en"}
              >
                <MenuItem disabled value="">
                  <em>{jsonlang.language.toUpperCase()}</em>
                </MenuItem>
                {/* <MenuItem selected disabled value=""> <h3>Select language </h3></MenuItem> */}
                {languages.map((item, index) =>
                  <MenuItem key={index} value={item.code} selected>
                    <ListItemIcon>
                      <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                    </ListItemIcon>
                    <Typography variant="inherit">{item.name}</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <IconButton color="primary"
              role="button"
              size="small"
              aria-label={jsonlang.adda.toUpperCase()}
              onClick={this.openFlagsDialog}
            >
              <LanguageIcon style={{ fontSize: 40 }}
              />
            </IconButton>
            {/* </Toolbar> */}
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              style={{ width: "95%" }}
              id="title"
              label={jsonlang.title.toUpperCase()}
              defaultValue=""
              onBlur={this.saveTitleForLang}
              value={this.state.titlestate}
              onChange={this.textfiedlvalue}
              disabled={this.state.dataLanguage === "" ? true : false}

            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              style={{ width: "95%" }}
              id="descres"
              label={jsonlang.desc.toUpperCase()}
              defaultValue=""
              onBlur={this.saveDescForLang}
              value={this.state.descstate}
              onChange={this.textfiedlvalue}
              disabled={this.state.dataLanguage === "" ? true : false}
            />
          </Grid>
        </Grid>

        <Divider />


        {/* <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="gridmargin">
                  <Grid item xs={12}>
                  <Select
                  labelId="media_languages"
                  id="media_languages_select"
                  value={this.state.selectedMediaLanguage.toLowerCase()}
                  onChange={this.handleChangeMediaLanguage}
                  displayEmpty
                  defaultValue={"en"}
                  >
                  <MenuItem disabled value="">
                  <em>{"SELECT MEDIA LANGUAGE TO UPLOAD "}</em>
                </MenuItem>
                {languages.map((item, index) =>
                <MenuItem key={index} value={item.code} selected>
                <ListItemIcon>
                <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
              </ListItemIcon>
              <Typography variant="inherit">{item.name}</Typography>
            </MenuItem>
          )}
        </Select>
      </Grid>
    </Grid> */}

        {/*sezione upload*/}
        {this.state.selectedMediaLanguage !== "" ?
          <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="gridmargin">
            <Grid item xs={2}>
              <Typography variant="button" display="block">
                {/* {jsonlang.medialan.toUpperCase()} / {jsonlang.rights.toUpperCase()} / {jsonlang.creator.toUpperCase()} */}
                {jsonlang.upload.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {/* MEDIA LANGUAGE */}
              {/* <Toolbar> */}
              <Select
                // fullWidth
                style={{ minWidth: 360, maxHeight: 70 }}
                labelId="media_languages"
                id="media_languages_select"
                value={this.state.selectedMediaLanguage.toLowerCase()}
                onChange={this.handleChangeMediaLanguage}
                displayEmpty
                // variant={"outlined"}
                defaultValue={"en"}
              >
                <MenuItem disabled value="">
                  <em>{"SELECT MEDIA LANGUAGE TO UPLOAD "}</em>
                </MenuItem>
                {/* <MenuItem selected disabled value=""> <h3>Select language </h3></MenuItem> */}
                {languages.map((item, index) =>
                  <MenuItem key={index} value={item.code} selected>
                    <ListItemIcon>
                      <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                    </ListItemIcon>
                    <Typography variant="inherit">{item.name}</Typography>
                  </MenuItem>
                )}
              </Select>
            </Grid>

            <Grid item xs={3}>
              {/* <div style={{padding:10}}> */}
              {/* <Grid  component="label" container alignItems="center" spacing={1}> */}

              <RadioGroup
                // style={{
                //   width: 'auto',
                //   height: 'auto',
                //   display: 'flex',
                //   flexWrap: 'nowrap',
                //   flexDirection: 'row',
                // }}
                color='primary' aria-label="weaktype" name="weaktype" value={this.state.isExtRes} onChange={this.handleIsExtRes} row>
                <FormControlLabel value="file" control={<Radio checked={this.state.fileinputType} />} label="file" />
                {this.props.from !== "warning" ?
                  <><FormControlLabel value="url" control={<Radio checked={this.state.externcloudinputType} />} label="url" />
                    <FormControlLabel value="text" control={<Radio checked={this.state.textcloudinputType} />} label="text" /> </> : <></>
                }
              </RadioGroup>
              {/*<Switch checked={this.state.isExtRes} onChange={this.handleIsExtRes} color='primary'/>*/}

              {/* </div> */}
              {this.state.isExtRes ?
                <div style={{ padding: 10 }}>
                  {
                    this.state.textcloudinputType === true ?
                      <TextField
                        variant="outlined"
                        style={{ width: "95%" }}
                        id="textfieldtextcloud"
                        label="text"
                        defaultValue=""
                        value={this.state.textcloudstate}
                        onBlur={this.saveTextForLang}
                        onChange={this.textfiedlvalue}
                      /> : <>

                        <Select
                          required
                          id="extmediatype"
                          displayEmpty
                          fullWidth
                          style={{ "marginBottom": "10px" }}
                          value={this.state.selectedExternalMediaType}
                          onChange={this.handleChangeExternalMediaType}
                        >
                          <MenuItem disabled value=""><em>Media Type</em></MenuItem>
                          {getExternalMediaType().map((extType, i) =>
                            <MenuItem key={extType.id} className="MuiInputBase-input-custom" value={extType.value}>{extType.label}</MenuItem>
                          )}
                        </Select>

                        {this.state.selectedExternalMediaType !== "" ?
                          <TextField
                            variant="outlined"
                            style={{ width: "99%" }}
                            id="url"
                            label="Url"
                            defaultValue=""
                            value={this.state.uriExternalResState}
                            onBlur={this.saveUrlForLang}
                            onChange={this.textfiedlvalue}
                          />
                          : null}
                      </>
                  }
                  {this.state.selectedExternalMediaType === "imagecloud" || this.state.selectedExternalMediaType === "videocloud" ?
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>2d</Grid>
                      <Grid item>
                        <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
                      </Grid>
                      <Grid item>360</Grid>
                    </Grid>
                    :
                    null}
                  {this.state.selectedExternalMediaType === "webcloud" ?
                    <Grid component="label" container alignItems="center" >
                      <TextField
                        variant="outlined"
                        style={{ width: "99%", marginTop: 10 }}
                        id="coverUrl"
                        label="Cover Url"
                        defaultValue=""
                        value={this.state.uriCoverExternalRes}
                        onChange={this.textfiedlvalue}
                      />
                      {/* <Grid item>Show In ShowRoom</Grid>
                      <Grid item>
                        <Switch checked={this.state.publicStatusExtRes} onChange={this.handlePublicStatusExternalWebResource} color='primary' />
                      </Grid> */}
                    </Grid>
                    :
                    null}
                </div>
                :
                <>
                  <input
                    ref={this.setRefFileinput}
                    onChange={this.fileChoiced}
                    type="file"
                    accept={this.props.from ? (this.props.from === "warning" ? mediatypes.resourcesReports : mediatypes.resourcesMT) : mediatypes.resourcesMT}
                    style={{ display: "none" }}
                  // multiple={false}
                  />
                  <Tooltip title={<h2>{jsonlang.upload.toUpperCase()}</h2>}>
                    <Button
                      variant="contained"
                      color="primary"
                      aria-label="upload resource"
                      component="span"
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      onClick={this.refFileinputClick}
                    >
                      {jsonlang.upload.toUpperCase()}
                    </Button>
                  </Tooltip>
                  <Tooltip title={jsonlang.mediatype.toUpperCase() + ":" + this.props.from ? (this.props.from === "warning" ? mediatypes.resourcesReports : mediatypes.resourcesMT) : mediatypes.resourcesMT}>
                    <InfoIcon color="grey" fontSize="small" />
                  </Tooltip>
                  {this.state.file ? this.state.file.type === "image/jpeg" || this.state.file.type === "video/mp4" ?
                    <>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>2d</Grid>
                        <Grid item>
                          <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary' />
                        </Grid>
                        <Grid item>360</Grid>
                      </Grid>
                      <Grid component="label" container alignItems="center" spacing={1}>
                        {this.state.degreeMedia ?
                          <Grid item>{jsonlang.aspectalert}</Grid>
                          : <></>}
                      </Grid>
                    </>
                    : null
                    : null}

                </>
              }
            </Grid>

            <Grid item xs={3}>
              <label>{jsonlang.resprev.toUpperCase()}</label> <br />
              <label> {this.state.filename}</label><br />
              {this.state.file ?

                this.state.file.type === "audio/mpeg" ? <audio controls width="50px" height="auto" src={URL.createObjectURL(this.state.file)} /> :
                  this.state.file.type === "video/mp4" ? <video controls width="150px" height="auto" src={URL.createObjectURL(this.state.file)} /> :
                    this.state.file.type === "image/jpeg" || this.state.file.type === "image/png" ? <img alt="" width="150px" height="auto" src={URL.createObjectURL(this.state.file)} /> :
                      <Typography >{jsonlang.notpreview.toUpperCase()}</Typography> :
                null
              }
              {this.state.uriExternalRes !== "" && this.state.selectedExternalMediaType !== "" && this.state.isExtRes ?
                this.state.selectedExternalMediaType === "videocloud" ? <video controls width="150px" height="auto" src={this.state.uriExternalRes} /> :
                  this.state.selectedExternalMediaType === "imagecloud" ? <img alt="" width="150px" height="auto" src={this.state.uriExternalRes} /> :
                    <Typography >{jsonlang.notpreview.toUpperCase()}</Typography> :
                null
              }
            </Grid>
          </Grid>
          : null
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(ResourcesUploadManager));
