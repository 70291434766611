import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as data from '../../services/services.json';
// import { WHOLE, APP } from '../../redux/actions/actions';
import './Settings.css';
import Profile from './Profile';
import Domains from './Domains';
import Userspanel from './Userspanel';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Typography';
import Typography from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-label={`scrollable-auto-tab-${index}`}
        {...other}
        >
          <Box p={3}>{children}</Box>
        </Typography>
      </div>
    );
  }



  class Settings extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value:0,
        profile: false,
        istituzioni: false,
        utenti: false,
        pannelli:[],
        index:0,
        showUser:false,

      }
    }
    componentDidMount(){
      console.log(this.props.appstate.App.user.role)
      switch (this.props.appstate.App.user.role) {
        case "basic":
        this.setState({profile: true, istituzioni:false, utenti:false,pannelli: ["profilo"]});
        break;
        case "editor":
        this.setState({profile: true, istituzioni:false, utenti:false,pannelli: ["profilo"]});
        break;
        case "superadmin":
        this.setState({profile: true, istituzioni:true, utenti:true,pannelli: ["profilo","istituzioni","utenti"]});
        break;
        case "domainadmin":
        this.setState({profile: true, istituzioni:false, utenti:true , pannelli: ["profilo","utenti"]});
        break;
        case "emperor":
        this.setState({profile: true, istituzioni:true, utenti:true,pannelli: ["profilo","istituzioni","utenti"]});
        break;
        default:
        this.setState({profile: true, istituzioni:false, utenti:false, pannelli: ["profilo"]});
        break;
      }
    }



    handleChange=(event, newValue) =>{    
      if (newValue===this.state.pannelli.findIndex(x=>x==="utenti")){
      this.setState({value:newValue, showUser:true});  
      }else{
        this.setState({value:newValue, showUser:false});
      }
    }


    render(){
      let jsonlang = this.props.appstate.App.language;

      return(
        <div>
          <Paper square>
            {/* <AppBar position="static"> */}
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="settings tab panels"
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="primary"
              >
                {this.state.profile ?
                  <Tooltip title={<h2>{jsonlang.userprofile.toUpperCase()}</h2>} >
                  {/* <Tab label={jsonlang.userprofile} /> */}
                  <Tab label={<h3>{jsonlang.userprofile}</h3>}/>
                </Tooltip>
                :
                null
              }
              {this.state.istituzioni ?
                <Tooltip title={<h2>{jsonlang.institution.toUpperCase()}</h2>} >
                {/* <Tab label={jsonlang.institution}  /> */}
                <Tab label={<h3>{jsonlang.institution}</h3>}/>
              </Tooltip>
              :
              null
            }
            {this.state.utenti ?
              <Tooltip title={<h2>{jsonlang.users.toUpperCase()}</h2>} >
              {/* <Tab label={jsonlang.users}  /> */}
              <Tab label={<h3>{jsonlang.users}</h3>}/>
            </Tooltip>
            :
            null
          }
        </Tabs>
        {/* </AppBar> */}
        {this.state.profile ? <TabPanel value={this.state.value} index={this.state.pannelli.findIndex(x=>x==="profilo")}><Profile></Profile></TabPanel>: null}
        {this.state.istituzioni ? <TabPanel value={this.state.value} index={this.state.pannelli.findIndex(x=>x==="istituzioni")}><Domains></Domains></TabPanel>: null}
        {this.state.utenti && this.state.showUser ?  <TabPanel value={this.state.value} index={this.state.pannelli.findIndex(x=>x==="utenti")}><Userspanel></Userspanel></TabPanel>: null}                     
      </Paper>
    </div>
  )
}

}
export default connect(mapStateToProps)(Settings);
