import React, { Component } from 'react';
import {connect} from 'react-redux';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
import { withRouter } from 'react-router-dom';
import * as services from "../../services/services.json";
import { getService, postService } from "../../services/services";
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import clsx from 'clsx';
import './Configuration.css'
import { WHOLE, APP } from '../../redux/actions/actions';
import DialogIconLang from './DialogIconLang';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  // AppBar,
  Box,
  Button,
  // ButtonGroup,
  // Chip,
  Container,
  // CssBaseline,
  Divider,
  // FormControl,
  // FormControlLabel,
  // FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  // Link,
  MenuItem,
  // Paper,
  // Radio,
  // RadioGroup,
  Select,
  TextareaAutosize,
  // TextField,
  // Tooltip,
  Typography
} from "@material-ui/core";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const headers = { headers: {  'Content-Type': 'application/x-www-form-urlencoded' } };


class Configuration extends Component {
  constructor (props) {
    super(props);
    this.state = ({
      typeUpload: "",
      langSelect: this.props.acamar.configuration.Languages.list[0],
      configurationAppJson: JSON.stringify(this.props.acamar, null, 2),
      configurationLangJson: JSON.stringify(this.props.appstate.App.language, null, 2)
    });
  }

  componentDidMount() {
    /*get Lang*/
    this.getLangConfiguration(this.state.langSelect.code);

  }

  /*getLang*/

  getLangConfiguration = (lang) => {
    var thisRef=this;

    let serviceurl =
    process.env.REACT_APP_DOMAIN + services.language_GET.replace("{APPNAME}", this.props.acamar.app);
    serviceurl = serviceurl.replace("{LANG}", lang);
    console.log("serviceurl " +serviceurl);
    getService(serviceurl, "", function (err, result) {
      if (err) {

        return;
      }
      if (result.status === 200) {
        thisRef.setState({configurationLangJson:JSON.stringify(result.response.labels.language, null, 2)});
      }
    });
  }

  /**
  *
  * Get configuration
  */
  getConfiguration = () => {
    getService(
      process.env.REACT_APP_DOMAIN + services.config_GET.replace("{APPNAME}", this.props.acamar.app),
      "",
      function (err, result) {
        if (err) {
          return;
        }
        if (result.status === 200) {

        }
      }
    );
  }

  saveConfiguration = () => {
    console.log("sono in saveConfiguration");
    var thisRef=this;


    let jsonConfigApp = JSON.parse(this.state.configurationAppJson);
    const params = new URLSearchParams();
    params.append('appname', jsonConfigApp.app);
    params.append('lang', jsonConfigApp.languages);
    params.append('config', JSON.stringify(jsonConfigApp.configuration));


    postService(
      process.env.REACT_APP_DOMAIN + services.config_POST,params, this.props.appstate.App.token, headers,
      function (err, result) {
        console.log("res "+JSON.stringify(result));
        if (err) {
          return;
        }
        if (result.status === 201) {
          console.log("ok");
          thisRef.props.dispatch(WHOLE(jsonConfigApp));
          thisRef.props.history.go(0); //può non servire, giusto per dare un senso di aggiornamento
        }
      }
    );
  }

  getLangToModify = (event) => {
    // var thisRef=this;
    let lang = event.target.value;

    //prendo da acamar code/label/icon della lingua selezionata
    let selectLang = this.props.acamar.configuration.Languages.list.filter(el => el.code === lang);
    console.log(selectLang);
    this.setState({langSelect:selectLang[0]});

    this.getLangConfiguration(lang);

  }


  saveLangConfig = () => {
    var thisRef=this;

    //chiamata per salvare il json delle label
    let jsonLang= JSON.parse(this.state.configurationLangJson);
    const params = new URLSearchParams();
    params.append('appname', this.props.acamar.app);
    params.append('lang', this.state.langSelect.code);
    params.append('jsonlang', JSON.stringify({language: jsonLang}));

    postService(
      process.env.REACT_APP_DOMAIN + services.language_POST,params, this.props.appstate.App.token, headers,
      function (err, result) {
        console.log("res" +result);
        if (err) {
          return;
        }
        if (result.status === 201) {
          console.log("ok");
          let currentconfig = thisRef.props.appstate;
          if(currentconfig.App.currentLanguage===thisRef.state.langSelect.code){
            currentconfig.App.language = JSON.parse(thisRef.state.configurationLangJson);
            thisRef.props.dispatch(APP(currentconfig));
          }
          //chiamata per aggiornare code/label/icons
          thisRef.saveConfiguration();

        }
      }
    );


  }

  isValidJSONString = (jsonString) => {
    try {
      JSON.parse(jsonString);
    } catch (e) {
      return true;
    }
    return false;
  }


  onChangeConfigurationLangJson = (event) => {
    this.setState({
      configurationLangJson: event.currentTarget.value
    });
  }

  onChangeConfigurationAppJson = (event) => {
    this.setState({
      configurationAppJson: event.currentTarget.value
    });
  }

  setCodeLang = (event) => {
    let jsonConfigApp = JSON.parse(this.state.configurationAppJson);

    for(let i=0;i<jsonConfigApp.configuration.Languages.list.length;i++){
      if(jsonConfigApp.configuration.Languages.list[i].code===this.state.langSelect.code){
        jsonConfigApp.configuration.Languages.list[i].code=event.target.value;
      }
    }


    this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});

    this.setState(
      prevState => ({
        langSelect: {
          ...prevState.langSelect,
          code: event.target.value
        }
      }));

    }

    setLabelLang = (event) => {
      let jsonConfigApp = JSON.parse(this.state.configurationAppJson);

      for(let i=0;i<jsonConfigApp.configuration.Languages.list.length;i++){
        if(jsonConfigApp.configuration.Languages.list[i].label===this.state.langSelect.label){
          jsonConfigApp.configuration.Languages.list[i].label=event.target.value;
        }
      }


      this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});

      this.setState(
        prevState => ({
          langSelect: {
            ...prevState.langSelect,
            label: event.target.value
          }
        }));
      }

      uploadIcon = () => {
        this.setState({typeUpload:"icon"});
        this.props.appstate.Resource.openModalIconLang = true;
        this.props.dispatch(APP(this.props.appstate));

      }

      uploadLogoLight = () => {
        this.setState({typeUpload:"logo-light"});
        this.props.appstate.Resource.openModalIconLang = true;
        this.props.dispatch(APP(this.props.appstate));

      }

      uploadLogoDark = () => {
        this.setState({typeUpload:"logo-dark"});
        this.props.appstate.Resource.openModalIconLang = true;
        this.props.dispatch(APP(this.props.appstate));

      }
      uploadLogoTab = () => {
        this.setState({typeUpload:"logo-tab"});
        this.props.appstate.Resource.openModalIconLang = true;
        this.props.dispatch(APP(this.props.appstate));

      }

      viewIcon = (obj) => {
        let jsonConfigApp = JSON.parse(this.state.configurationAppJson);

        switch(this.state.typeUpload){
          case "icon":
          for(let i=0;i<jsonConfigApp.configuration.Languages.list.length;i++){
            if(jsonConfigApp.configuration.Languages.list[i].code===this.state.langSelect.code){
              jsonConfigApp.configuration.Languages.list[i].icon=obj.relativepath;
            }
          }

          this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});

          this.setState(
            prevState => ({
              langSelect: {
                ...prevState.langSelect,
                icon: obj.relativepath
              }
            }));
            break;

            case "logo-light":
            jsonConfigApp.configuration.logo.light=obj.relativepath;
            this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});
            this.props.dispatch(WHOLE(jsonConfigApp));
            break;

            case "logo-dark":
            jsonConfigApp.configuration.logo.dark=obj.relativepath;
            this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});
            this.props.dispatch(WHOLE(jsonConfigApp));
            break;

            case "logo-tab":
            jsonConfigApp.configuration.logo.tab=obj.relativepath;
            this.setState({configurationAppJson:JSON.stringify(jsonConfigApp, null, 2),});
            this.props.dispatch(WHOLE(jsonConfigApp));
            break;

            default:
            console.log("typeUpload " + this.state.typeUpload);
          }

        }

        getContentJsFromUrl = (jsurl) => {
          //http://localhost:3004/public/soclose/themes/theme_1619900210855_themefirst.js
          fetch(jsurl)
          .then(response => response.text())
          .then(data => {
            // Do something with your data
            console.log(data);
          });
        }

        render(){
          return (
            <div className="iframe">
            <DialogIconLang viewIcon={this.viewIcon}/>


            <Container className="paddingContainer">

            <Accordion square>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            // style={{backgroundColor:'#1976d2'}}
            >
            <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            >

            <Grid item xs={12} sm={6}>
            <Typography>Configurazione App</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
            <Typography>Inserimento JSON</Typography>
            </Grid>
            </Grid>

            </AccordionSummary>
            <AccordionDetails>
            <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            >
            <Grid item xs={12} sm={6} className="paddingLeft">
            <InputLabel id="demo-simple-select-label" className="paddingTop">Logo</InputLabel>


            <Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
            <Box>
            <Typography className="typogr-logo">Per tema light</Typography>
            </Box>
            <Box>
            <IconButton color="primary" aria-label="upload file icon language"
            onClick={this.uploadLogoLight}
            component="span">
            <AddIcon></AddIcon>
            </IconButton>
            </Box>

            <Box>
            {this.props.acamar.configuration.logo && this.props.acamar.configuration.logo.light!=="" ?
            <img alt="flag"
            src={process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.light}
            minWidth="auto"
            height="36px"
            /> : <></>}

            </Box>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
            <Box>
            <Typography className="typogr-logo">Per tema dark</Typography>
            </Box>
            <Box>
            <IconButton color="primary" aria-label="upload file icon language"
            onClick={this.uploadLogoDark}
            component="span">
            <AddIcon></AddIcon>
            </IconButton>
            </Box>
            <Box>
            {this.props.acamar.configuration.logo.dark!=="" ?
            <img alt="flag"
            src={process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.dark}
            minWidth="auto"
            height="36px"
            /> : <></>}

            </Box>
            </Box>

             <Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
            <Box>
            <Typography className="typogr-logo">Per Tab Browser</Typography>
            </Box>
            <Box>
            <IconButton color="primary" aria-label="upload file icon language"
            onClick={this.uploadLogoTab}
            component="span">
            <AddIcon></AddIcon>
            </IconButton>
            </Box>

            <Box>
            {this.props.acamar.configuration.logo && this.props.acamar.configuration.logo.tab!=="" ?
            <img alt="flag"
            src={process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.tab}
            minWidth="auto"
            height="36px"
            /> : <></>}

            </Box>
            </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextareaAutosize
            id="outlined-multiline-static"
            className="textarea"
            label="Configuration File"
            fullWidth
            multiline
            rows={24}
            defaultValue={this.state.configurationAppJson}
            onChange={this.onChangeConfigurationAppJson}
            variant="outlined"
            />
            </Grid>
            </Grid>

            </AccordionDetails>
            <Divider />
            <AccordionActions>
            <Button size="small">Cancel</Button>
            {this.isValidJSONString(this.state.configurationAppJson) === false ?
              <Button size="small" color="primary" onClick={this.saveConfiguration}>
              Save
              </Button>  : <></>}
              </AccordionActions>
              </Accordion>


              <Accordion>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
              // style={{backgroundColor:'#4791db'}}
              >
              <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              >

              <Grid item xs={12} sm={6}>
              <Typography>Configurazione Lingua</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
              <Typography>Inserimento JSON</Typography>
              </Grid>
              </Grid>
              </AccordionSummary>
              <AccordionDetails>



              <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
              <Grid item xs={12} sm={6} className="paddingLeft">
              <InputLabel id="demo-simple-select-label" className="paddingTop">Lingua default</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-lang"
              defaultValue={this.props.acamar.configuration.Languages.default}
              >
              {this.props.acamar.configuration.Languages.list.map((item,i) =>
                <MenuItem value={item.code}>{item.label}</MenuItem>
              )}

              </Select>

              <InputLabel id="demo-simple-select-label" className="paddingTop">Configura lingua</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-lang"
              defaultValue={this.props.acamar.configuration.Languages.default}
              onChange={this.getLangToModify}
              >
              {this.props.acamar.configuration.Languages.list.map((item,i) =>
                <MenuItem value={item.code}>{item.label}</MenuItem>
              )}
              </Select>
              <Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
              <Box>
              <Typography className="typogr-width">Code</Typography>
              </Box>
              <Box>
              <Input id="outlined-basic" label="codeLang"
              onChange={this.setCodeLang}
              variant="outlined" defaultValue={this.state.langSelect.code}
              value={this.state.langSelect.code} />
              </Box>
              </Box>

              <Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
              <Box>
              <Typography className="typogr-width">Label</Typography>
              </Box>
              <Box>
              <Input id="outlined-basic" label="labelLang"
              onChange={this.setLabelLang}
              variant="outlined" defaultValue={this.state.langSelect.label}
              value={this.state.langSelect.label} />
              </Box>
              </Box>

              {/*}<Box display="flex" flexDirection="row" alignItems="flex-end" className="paddingTop">
              <Box>
              <Typography className="typogr-width">Icon</Typography>
              </Box>
              <Box>
              <IconButton color="primary" aria-label="upload file icon language"
              onClick={this.uploadIcon}
              component="span">
              <AddIcon></AddIcon>
              </IconButton>
              </Box>
              <Box>
              {this.state.langSelect.icon!=="" ?
              <img alt="flag"
              src={process.env.REACT_APP_DOMAIN+"/"+this.state.langSelect.icon}
              minWidth="auto"
              height="36px"
              /> : <></>}
              </Box>
              </Box>*/}

              </Grid>
              <Grid item xs={12} sm={6}>
              <TextareaAutosize
              id="outlined-multiline-static"
              className="textarea"
              label="Configuration File"
              fullWidth
              multiline
              rows={24}
              defaultValue={this.state.configurationLangJson}
              value={this.state.configurationLangJson}
              onChange={this.onChangeConfigurationLangJson}
              variant="outlined"
              />
              </Grid>
              </Grid>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
              <Button size="small">Cancel</Button>
              {this.isValidJSONString(this.state.configurationLangJson) === false ?
                <Button size="small" color="primary" onClick={this.saveLangConfig}>
                Save
                </Button> : <></>}
                </AccordionActions>
                </Accordion>
                </Container>


                </div>
              )}
            }

            export default connect(mapStateToProps)(withRouter(Configuration));
