
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  // Grid,
  Typography,
  // FormControl,
  // TextField,
  // InputLabel,
  // IconButton,
  // MenuItem,
  // Tooltip,
  // InputAdornment,
  // OutlinedInput
} from "@material-ui/core";
import * as services from "../../services/services.json";
import { getService } from "../../services/services";


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogDisclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDomain:""
    }
  }

  componentDidMount(){
    this.props.onRef(this); //for the parent
  }

  init = (domValue) => {
    var self=this;
    console.log("dominio "+domValue);
    console.log("ok");
    let serviceurl = process.env.REACT_APP_DOMAIN + services.new_domainsusers_GET;
    console.log(serviceurl);
    serviceurl = serviceurl.replace("{APPNAME}", self.props.acamar.app);
    serviceurl = serviceurl.replace("{ID}", domValue);
    console.log(serviceurl);
    getService(serviceurl,
      "",
      function (err, result) {
        if (err) {
          console.log("errore get user admin domain");
        } else if (result.status === 200) {
          console.log("chiamata ok"+JSON.stringify(result.response[0]));
          let admin=result.response[0].domainusers.filter(adm => adm.role==="domainadmin");
          console.log(admin[0].email);

          self.setState({emailDomain:admin[0].email});
        }
      }
    );
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return(



      <Dialog
        open={this.props.open}
        maxWidth="md"
        onClose={this.props.close}
        aria-labelledby="disclaimer-dialog-title"
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
        >
          <DialogTitle>
            <Typography variant="h6">&#9888; DISCLAIMER</Typography>
          </DialogTitle>
          <DialogContent
            dividers={true}
            >
              <DialogContentText>
                <Box
                  textAlign="center"
                  color="secondary.main">
                  {jsonlang.disclaimerreg.toUpperCase()}
                </Box>
                <br/>
                <Box
                  textAlign="center"
                  color="text.primary">
                  Acceptable Use Policy
                </Box>
                <br/>
                <Box>
                  We may allow you to upload and publish (collectively, to "submit") content such as videos, recordings, images, and text (collectively, "content"). You must ensure that your content, and your conduct, complies with the Acceptable Use Policy set forth in this Section. Administrators may remove or limit access or availability to any content or account that it considers in good faith to violate this Acceptable Use Policy.
                </Box>
                <br/>
                <Box
                  textAlign="center"
                  color="text.primary">
                  Copyright Policy
                </Box>
                <br/>
                <Box>
                  You may only upload content that you have the right to upload and share.
                  For every piece of content you should include information about the holder of the rights and the type of the copyright license
                </Box>
                <br/>
                <Box
                  textAlign="center"
                  color="text.primary">
                  Content Restrictions
                </Box>
                <br/>
                <Box>
                  You may not submit any content that:
                  <ul>
                    <li>Infringes any third party’s copyrights or other rights (e.g., trademark, privacy rights, etc.);</li>
                    <li>Is sexually explicit or promotes a sexual service;</li>
                    <li>Is defamatory;</li>
                    <li>Is harassing or abusive;</li>
                    <li>Contains hateful or discriminatory speech;</li>
                    <li>Promotes or supports terror or hate groups;</li>
                    <li>Contains instructions on how to assemble explosive/incendiary devices or homemade/improvised firearms;</li>
                    <li>Exploits or endangers minors;</li>
                    <li>Depicts or encourages self-harm or suicide;</li>
                    <li>Depicts (1) unlawful real-world acts of extreme violence, (2) vivid, realistic, or particularly graphic acts of violence and brutality, (3) sexualized violence, including rape, torture, abuse, and humiliation, or (4) animal cruelty or extreme violence towards animals;</li>
                    <li>Promotes fraudulent or dubious money-making schemes, proposes an unlawful transaction, or uses deceptive marketing practices;</li>
                    <li>Contains false or misleading claims about (1) vaccination safety, or (2) health-related information that has a serious potential to cause public harm;</li>
                    <li>Contains false or misleading information about voting;</li>
                    <li>Contains (1) claims that a real-world tragedy did not occur; (2) false claims that a violent crime or catastrophe has occurred; or (3) false or misleading information (including fake news, deepfakes, propaganda, or unproven or debunked conspiracy theories) that creates a serious risk of material harm to a person, group, or the general public; or</li>
                    <li>Violates any applicable law.</li>
                  </ul>
                </Box>
                <br/>
                <Box
                  textAlign="center"
                  color="text.primary">
                  Code of Conduct
                </Box>
                <br/>
                <Box>
                  In using our Services, you may not:
                  <ul>
                    <li>Use an offensive screen name (e.g., explicit language) or avatar (e.g., containing nudity);</li>
                    <li> Act in a deceptive manner or impersonate any person or organization;</li>
                    <li>Harass or stalk any person;</li>
                    <li>Harm or exploit minors;</li>
                    <li>Distribute “spam” in any form or use misleading metadata;</li>
                    <li>Collect personal information about others without their authorization;</li>
                    <li>Access another’s account except as permitted herein;</li>
                    <li>Use or export any of our services in violation of any U.S. law;</li>
                    <li>Engage in any unlawful activity;</li>
                    <li>Embed our video player on or provide links to sites that contain content prohibited by Section 5.2; or </li>
                    <li>Cause or encourage others to do any of the above.</li>
                  </ul>
                </Box>
                <br/>
                <Box
                  textAlign="center"
                  color="text.primary">
                  Prohibited Technical Measures
                </Box>
                <br/>
                <Box>
                  You will not:
                  <ul>
                    <li>Except as authorized by law or as permitted by us in writing: scrape, reproduce, redistribute, frame, mirror, create derivative works from, decompile, reverse engineer, alter, archive, or disassemble any part of our Services; or attempt to circumvent any of our security, rate-limiting, filtering, or digital rights management measures;</li>
                    <li>Remove or modify any logo, watermark, or notice of proprietary rights embedded on or in the Services or any output thereof without our permission;</li>
                    <li>Submit any malicious program, script, or code;</li>
                    <li>Submit an unreasonable number of requests to our servers; or</li>
                    <li>Take any other actions to manipulate, interfere with, or damage our Services.</li>
                  </ul>
                  Users can report any content that infringes the content restriction policy sending an e-mail message to {this.state.emailDomain}
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={this.props.close} color="primary">
                {jsonlang.instcancel.toUpperCase()}
              </Button>
              <Button onClick={this.props.okDisc} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>


        )}
      };
      export default connect(mapStateToProps)(DialogDisclaimer);
