import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
    Typography,
    Grid,
    IconButton
} from "@material-ui/core";

import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};


class FlagsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = ({});
    }

    init = () => {

    }

    componentDidMount() {
        this.props.onRef(this); //for the parent
    }

    onclose = () => {
        this.props.appstate.LanDialog.render = false;
        this.props.appstate.LanDialog.openModalLan = false;
        this.props.appstate.LanDialog.items = [];
        this.props.dispatch(APP(this.props.appstate));
    }
    itemClick = (event) => {
        this.props.callback(event.currentTarget.name);
        this.props.appstate.LanDialog.render = false;
        this.props.appstate.LanDialog.openModalLan = false;
        this.props.appstate.LanDialog.items = [];//??
        this.props.dispatch(APP(this.props.appstate));
    }
    render() {
        let jsonlang = this.props.appstate.App.language;
        let items = this.props.appstate.LanDialog.items;
        return (
            <>
                {this.props.appstate.LanDialog.render === true ?
                    <div className="iframe">
                        <Dialog
                            open={this.props.appstate.LanDialog.openModalLan}
                            onClose={this.onclose}
                            aria-labelledby="dialog-add"
                            maxWidth={'sm'}
                            fullWidth
                        >
                            <DialogTitle id="dialog-add">
                                <PlaylistAddCheckIcon
                                />
                                <span style={{
                                    padding: 5,
                                    position: 'fixed',
                                }}>
                                    {'MEDIA LANGUAGES SET'}
                                </span>
                            </DialogTitle>
                            <DialogContent dividers>
                                {items.length>0 ?
                                <Grid container justifyContent="center" spacing={3}>                                    
                                    {items.map((item, index) =>
                                        <IconButton name={item} onClick={this.itemClick}>
                                            <img name={item} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item + ".svg"}></img>
                                            </IconButton>
                                    )}
                
                                    
                                    
                                    </Grid>
                                    :   
                                     <Typography >{jsonlang.notlanguage.toUpperCase()}</Typography>  
                                    }                                    
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={this.onclose}
                                    color="primary"
                                    startIcon={<ClearIcon />}>
                                    <Tooltip title={<h2>OK</h2>}>
                                        <Typography variant="button">OK</Typography>
                                    </Tooltip>
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    : <></>}
            </>
        )
    }
}
export default connect(mapStateToProps)(FlagsDialog);
