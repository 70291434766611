import { withScriptjs, withGoogleMap, GoogleMap} from "react-google-maps";
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import React, { Component} from 'react';
import './mapcard.css';

const GeoMap = withScriptjs(withGoogleMap((props) => {
    return (
        <GoogleMap
            id="googlemap"
            defaultZoom={4}
            defaultCenter={{ lat: 40.853294, lng: 14.305573 }}
            options={{
                scrollwheel: false,
                streetViewControl: false,
                minZoom: 1,
                maxZoom: 19,
                mapTypeControl: true,
                zoomControl: true
            }}
            onDragEnd={e => {
                //props.callback("onDragEnd", [overlay, overlay.getPosition().lat(), overlay.getPosition().lng()]);
                let ne = refmap.getBounds().getNorthEast();
                let sw = refmap.getBounds().getSouthWest();
                //let bounds = new window.google.maps.LatLngBounds({ lat: ne.lat(), lng: ne.lng() }, { lat: sw.lat(), lng: sw.lng() });
                //let bounds = new window.google.maps.LatLngBounds([{ lat: sw.lat(), lng: sw.lng() }, { lat: ne.lat(), lng: ne.lng() }]);

                props.callback("mapBoundDragend", refmap.getBounds());
                //console.log(ne.lat() + ";" + ne.lng());
                //console.log(sw.lat() + ";" + sw.lng());
            }}
            onZoomChanged={e => {
                props.callback("mapBoundZoomChanged", refmap.getBounds());
            }}
            onTilesLoaded={e => {
                props.callback("mapBoundonTilesLoaded", refmap.getBounds());
            }
            }
            ref={map => {
                if (map) {
                    setRefMap(map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED, props);
                }
            }}
        >
            {props.drawingControl === true ?
                <DrawingManager
                    drawingMode={window.google.maps.drawing.OverlayType.NULL}
                    defaultDrawingMode={window.google.maps.drawing.OverlayType.HAND}
                    defaultOptions={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: props.onlyMarker === true ? [window.google.maps.drawing.OverlayType.MARKER] : [
                                window.google.maps.drawing.OverlayType.MARKER,
                                window.google.maps.drawing.OverlayType.POLYGON,
                                window.google.maps.drawing.OverlayType.POLYLINE,
                                window.google.maps.drawing.OverlayType.RECTANGLE,
                            ],
                        },
                        polygonOptions: {
                            strokeWeight: 1,
                            clickable: true,
                            editable: true,
                            draggable: true,
                            zIndex: 1
                        },
                        polylineOptions: {
                            strokeWeight: 1,
                            clickable: true,
                            editable: true,
                            draggable: true,
                            zIndex: 1
                        },
                        rectangleOptions: {
                            strokeWeight: 1,
                            clickable: true,
                            editable: true,
                            draggable: true,
                            zIndex: 1
                        },
                        markerOptions: {
                            strokeWeight: 1,
                            clickable: true,
                            editable: true,
                            draggable: true,
                            zIndex: 1
                        },

                    }}
                    onOverlayComplete={e => {
                        drawCompleted(e, props);
                    }}
                /> : <> </>}
        </GoogleMap>
    )
}
))

let refmap;
function setRefMap(ref, props) {
    refmap = ref;
    props.callback("ref", ref, refmap.getBounds());
}


function addEventListenerOnOverlay(overlay) {
    let deletebutton = '<button id=' + overlay._id + ' onclick="window.deleteGeoObj(this)">delete ' + overlay.typeObj + '</button>';
    window.google.maps.event.addListener(overlay, 'click', function (e) {
        if (infoWindowMapComp === undefined) {
            infoWindowMapComp = new window.google.maps.InfoWindow();
        }
        infoWindowMapComp.setPosition(e.latLng);
        //infoWindowMapComp.setContent(deletebutton);
        infoWindowMapComp.open(window.google.maps.Map, this);
    });
}

function loadAllLayers(overlayBox, mapRef) {
    if (mapRef && overlayBox.length > 0 ) {
        //remove old layers to avoid duplicated
        for (let [key, value] of overlayBox.entries()) {
            if (typeof value.setMap === 'function') {
                value.setMap(null);
            }
        }
        for (let [key, value] of overlayBox.entries()) {
            if (typeof value.setMap === 'function') {
                value.setMap(mapRef);
                addEventListenerOnOverlay(value);
            }
        }
    }
}
let overlayBox = new Map(); //you must track every thing over the map
let parentself;
function loadMarkers(items, mapRef, parentthis) {
    parentself = parentthis;
    if (window.google && mapRef ) {
        window.google.maps.event.clearListeners(mapRef, 'click');
        if (overlayBox.size > 0) { //remove all old object
            for (let [key, value] of overlayBox.entries()) {
                value.setMap(null);
            }
        }
        if (mapRef && items.length > 0) {
            //remove old layers to avoid duplicated
            for (let i = 0; i < items.length; i++) {
                if (items[i].location.type === "Point") {
                    let mark = new window.google.maps.Marker({
                        position: {
                            lat: parseFloat(items[i].location.coordinates[1]),
                            lng: parseFloat(items[i].location.coordinates[0])
                        }
                    });
                    mark.setMap(mapRef);
                    overlayBox.set(items[i]._id, mark);
                    addEventListenerMarkers(mark, items[i], i);
                }
            }
        }
    }
}


let infoWindowMapComp;
function addEventListenerMarkers(mark, item, index) {
    let imgsrc = item.type === 'audio' ? 'images/icons/sound-bars.png' : parentself.coverUrl(item._id);
    //let card = '<div class="cardmap"> <img src=' + imgsrc + ' alt="Avatar" style="width:100%"></img><div><h4><b>' + item.name + '</b></h4><p>' + item.description + '</p><button id=' + item._id+"_"+index + ' onclick="window.mapPublicDeleteGeoObj(this)">delete</button> </div></div>';
    let card = '<div class="cardmap"> <img src=' + imgsrc + ' alt="Avatar" style="width:100%"></img><p><h4><b>' + item.name + '</b></h4></p><p>' + item.description + '</p></div>';
    //let deletebutton = '<img width="100%" src=' + imgsrc +'></img><button id=' + item._id + ' onclick="window.deleteGeoObj(this)">delete</button>';
    window.google.maps.event.addListener(mark, 'click', function (e) {
        if (infoWindowMapComp === undefined) {
            infoWindowMapComp = new window.google.maps.InfoWindow();
        }
        infoWindowMapComp.setOptions({ maxWidth: 300});
        infoWindowMapComp.setPosition(e.latLng);
        infoWindowMapComp.setContent(card);
        infoWindowMapComp.open(window.google.maps.Map, this);
        //parentself.mapMarkerCallback("click",item._id);
    });
}


function drawCompleted(e, props) {
    let type = e.type.charAt(0).toUpperCase() + e.type.slice(1);
    let overlay = e.overlay;
    //overlay.setMap(window.mapRef);
    overlay.typeObj = type;
    overlay._id = mongoObjectId();
    let geoJson = {
        id: overlay._id,
        type: type,
        coordinates: []
    };
    switch (type) {
        case "Rectangle":
            var bounds = overlay.getBounds();
            geoJson.type = 'Polygon';
            geoJson.coordinates.push([]);
            var NE = bounds.getNorthEast();
            var SW = bounds.getSouthWest();
            var NW = new window.google.maps.LatLng(NE.lat(), SW.lng());
            var SE = new window.google.maps.LatLng(SW.lat(), NE.lng());
            geoJson.coordinates[0].push([NE.lng(), NE.lat()]);
            geoJson.coordinates[0].push([SE.lng(), SE.lat()]);
            geoJson.coordinates[0].push([SW.lng(), SW.lat()]);
            geoJson.coordinates[0].push([NW.lng(), NW.lat()]);
            geoJson.coordinates[0].push([NE.lng(), NE.lat()]);
            break;
        case "Polygon":
            geoJson.coordinates.push([]);
            overlay.getPath().forEach(function (point) {
                geoJson.coordinates[0].push([point.lng(), point.lat()]);
            });
            geoJson.coordinates[0].push([geoJson.coordinates[0][0][0], geoJson.coordinates[0][0][1]]);
            break;
        case "Marker":
            geoJson.type = 'Point';
            geoJson.coordinates.push(overlay.getPosition().lng());
            geoJson.coordinates.push(overlay.getPosition().lat());
            break;
        case "Circle":
            geoJson.type = 'Point';
            geoJson.coordinates.push(overlay.getCenter().lng());
            geoJson.coordinates.push(overlay.getCenter().lat());
            geoJson.radius = overlay.getRadius();
            break;
        case "Polyline":
            geoJson.type = 'LineString';
            overlay.getPath().forEach(function (point) {
            geoJson.coordinates.push([point.lng(), point.lat()]);
            });
            break;
        default:
            break;
    }
    overlay.geojson = geoJson;
    e.overlay.setMap(null);
    props.callback("drawcompleted", [overlay, overlay.getPosition().lat(), overlay.getPosition().lng()]);
}


function deleteGeoObj(e) {
    if (e) {
        let params = e.id.split("_");
        parentself.mapMarkerCallback("delete", params[0], params[1]);
        //const eltodel = overlayBox.get(params[0]);
        //eltodel.setMap(null);
        //overlayBox.delete(e.id);
   /* var tempgeojson = [];
         for (let [key, value] of window.overlayBox.entries()) {
             var tempobj = Object.assign({}, value);
             tempgeojson.push(tempobj.geojson);
         }
         window.overlayBoxJson = JSON.stringify({ type: "GeometryCollection", geometries: tempgeojson }, null, 2);
         //e.removeEventListener("onclick", handleMouseDown, false); */
    }
}
window.mapPublicDeleteGeoObj = deleteGeoObj;

function mongoObjectId() {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16)
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16)
    }).toLowerCase()
}

function loadingJson(overlayBoxJson, overlayBox) {
    let tempjson = overlayBoxJson;
    if (tempjson) {
        if (tempjson.geometries) {
            for (let i = 0; i < tempjson.geometries.length; i++) {
                let overlay;
                if (tempjson.geometries[i].type === "Point") {
                    overlay = new window.google.maps.Marker({
                        position: {
                            lat: tempjson.geometries[i].coordinates[1],
                            lng: tempjson.geometries[i].coordinates[0]
                        }
                    });
                } else if (tempjson.geometries[i].type === "Polygon") {
                    let pathsArg = [];
                    for (let j = 0; j < tempjson.geometries[i].coordinates[0].length; j++) {
                        pathsArg.push({ lat: tempjson.geometries[i].coordinates[0][j][1], lng: tempjson.geometries[i].coordinates[0][j][0] })
                    }
                    overlay = new window.google.maps.Polygon({
                        paths: pathsArg
                    });
                } else if (tempjson.geometries[i].type === "LineString") {
                    let pathsArg = [];
                    for (let j = 0; j < tempjson.geometries[i].coordinates.length; j++) {
                        pathsArg.push({ lat: tempjson.geometries[i].coordinates[j][1], lng: tempjson.geometries[i].coordinates[j][0] })
                    }
                    overlay = new window.google.maps.Polygon({
                        paths: pathsArg
                    });
                }
                overlay.typeObj = tempjson.geometries[i].type;
                overlay._id = tempjson.geometries[i].id;
                let geoJson = {
                    id: tempjson.geometries[i].id,
                    type: tempjson.geometries[i].type,
                    coordinates: tempjson.geometries[i].coordinates
                }
                overlay.geojson = geoJson;
                if (overlayBox === undefined) {
                    overlayBox = new Map();
                }
                overlayBox.set(overlay._id, overlay);
            }
            var tempgeojson = [];
            for (let [key, value] of overlayBox.entries()) {
                var tempobj = Object.assign({}, value);
                tempgeojson.push(tempobj.geojson);
            }
            overlayBoxJson = JSON.stringify({ type: "GeometryCollection", geometries: tempgeojson }, null, 2);
        }
    }
}

export { GeoMap, loadingJson, loadAllLayers, deleteGeoObj, mongoObjectId, loadMarkers}

