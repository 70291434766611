import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
// import * as data from '../../services/services.json';
// import themefirst from '../../config/theme_first.json';
// import themesecond from '../../config/theme_second.json';
import {
  // WHOLE,
  APP } from '../../redux/actions/actions';
  // import ReactDOM from 'react-dom';
  import { withRouter } from 'react-router-dom';
  // import * as services from "../../services/services.json";
  // import { getService } from "../../services/services";
  import DialogResources from '../Resources/DialogResources';
  import DialogLoading from '../Dialog/DialogLoading';
    import DialogEditConfirm from '../Dialog/DialogEditConfirm';
  // import DialogDelete from '../Dialog/DialogDelete';
  import DialogAccessibility from '../Dialog/DialogAccessibility';
  import DialogLanguage from '../Dialog/DialogLanguage';
  import DialogResourcesManager from "../ResourcesManager/DialogResourcesManager";
  import MapDialog from "../ResourcesManager/MapDialog";
  import BurstModeIcon from '@material-ui/icons/BurstMode';
  import SecurityIcon from '@material-ui/icons/Security';
  import DialogResourcesManagerAnnotator from "../ResourcesManager/DialogResourcesManagerAnnotator";
  import * as services from "../../services/services.json";
  // ================================
  // ============ STYLE  ============
  // ================================
  import './Header.css'

  // import { purple } from '@material-ui/core/colors';
  // import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

  // import { light } from '../../config/light';
  // import { dark } from '../../config/dark';
  // import { yellow } from '../../config/yellow';
  // import { notheme } from '../../config/notheme';
  // import { bothyelunder } from '../../config/bothyelunder';
  // import { underline } from '../../config/underline';
  // import { fontlarge } from '../../config/fontlarge';
  // import { fontnormal } from '../../config/fontnormal';


  // ================================
  // ============ ICONS  ============
  // ================================
  // import TextFieldsIcon from '@material-ui/icons/TextFields';
  // import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
  // import InvertColorsIcon from '@material-ui/icons/InvertColors';
  // import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';
  // import BorderColorIcon from '@material-ui/icons/BorderColor';
  // import EditIcon from '@material-ui/icons/Edit';
  import CloseIcon from '@material-ui/icons/Close';
  // import HelpIcon from '@material-ui/icons/Help';
  import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
  // import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
  // import FaceIcon from '@material-ui/icons/Face';
  // import MenuIcon from '@material-ui/icons/Menu';
  // import AccountCircle from '@material-ui/icons/AccountCircle';
  // import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
  import LanguageIcon from '@material-ui/icons/Language';
  // import InsertLinkIcon from '@material-ui/icons/InsertLink';
  // import SettingsIcon from '@material-ui/icons/Settings';
  import HomeIcon from '@material-ui/icons/Home';
  import LockIcon from '@material-ui/icons/Lock';
  // import LockOpenIcon from '@material-ui/icons/LockOpen';
  // import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
  // import PersonIcon from '@material-ui/icons/Person';
  // import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
  import ExitToAppIcon from '@material-ui/icons/ExitToApp';
  // import CancelIcon from '@material-ui/icons/Cancel';
  import Badge from '@material-ui/core/Badge';
  import SettingsIcon from '@material-ui/icons/Settings';
  // =====================================
  // ============ COMPONENTS  ============
  // =====================================
  import {
    AppBar,
    Avatar,
    Box,
    Button,
    // ButtonGroup,
    // Container,
    // CssBaseline,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogTitle,
    Divider,
    Fab,
    // FormControl,
    // FormControlLabel,
    // FormGroup,
    // FormLabel,
    // Grid,
    // Icon,
    IconButton,
    // InputLabel,
    Link,
    // ListSubheader,
    // Menu,
    // MenuItem,
    // Paper,
    Popover,
    // Radio,
    // RadioGroup,
    // Select,
    // Slide,
    // Switch,
    // TextField,
    Toolbar,
    Tooltip,
    Typography
  } from "@material-ui/core";

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="down" ref={ref} {...props} />;
  // });

  const mapStateToProps = state => {
    return {
      acamar: state.acamar,
      appstate: state.appstate
    };
  };


  class Header extends Component {
    constructor(props) {
      super(props);
      this.state = ({
        language:this.props.appstate.App.currentLanguage,
        //  languagePreConfirm:'en',
        // openLangDial:false,
        // DialogOpenAccessibility: false,
        openMenuInfoAvatar: false,
        valuefont: this.props.appstate.App.accessibility.valuefont,
        valuecontrast: this.props.appstate.App.accessibility.valuecontrast,
        valuecolored: this.props.appstate.App.accessibility.valuecolored,
        valuelinks:this.props.appstate.App.accessibility.valuelinks,
        childmessage: "", childrefstate: "", theme: "whitetheme", configurationJson: JSON.stringify(this.props.acamar, null, 2)
      });

      //  this.renderMenu = this.renderMenu.bind(this);
      //  this.handleCloseInfoAvatar = this.handleCloseInfoAvatar.bind(this);


    }



    onClickButton = (event, data) => {
      let id = event.currentTarget.id;
      //document.getElementById(`${id}-li`).classList.add('current');
      if (id === "webstarthome") {
        this.props.history.push('/Home');
        return;
      }
      let childref = "";
      switch (id) {
        case "catstorie":
        this.setState({
          childrefstate: process.env.REACT_APP_CAT_STORIE
        });
        childref = process.env.REACT_APP_CAT_STORIE;
        break;
        case "gestpoi":
        this.setState({
          childrefstate: process.env.REACT_APP_GEST_POI
        });
        childref = process.env.REACT_APP_GEST_POI;
        break;
        case "geststorie":
        this.setState({
          childrefstate: process.env.REACT_APP_GEST_STORIE
        });
        childref = process.env.REACT_APP_GEST_STORIE;
        break;
        case "gestrisorse":
        this.setState({
          childrefstate: process.env.REACT_APP_GEST_RISORSE
        });
        childref = process.env.REACT_APP_GEST_RISORSE;
        break;
        /*case "settings":
        this.setState({
        childrefstate: "http://localhost:3001"
      });
      childref = "http://localhost:3001";
      break;*/
      default:
      break;
    }
    let currentconfig = this.props.appstate;
    currentconfig.App.currentappchild = childref;
    this.props.dispatch(APP(currentconfig));
    this.props.history.push('/App');
  }


  renderMenu = (event) => {
    console.log("renderMenu");
    var thisRef=this;
    //thisRef.state.anchorEl = event.currentTarget;
    thisRef.setState({openMenuInfoAvatar: true, anchorEl: event.currentTarget});
  }

  handleCloseInfoAvatar = () => {
    // this.state.anchorEl= null;
    this.setState({openMenuInfoAvatar: false, anchorEl: null})
  }

  componentDidMount() {
    window.addEventListener('message', this.postMessageHandler);
    /*const container = document.getElementById('content');
    ReactDOM.render(<div><h1>Home</h1></div>, container, () => {
    //render completed
  });*/
  this.props.history.push('/Home');
  let favicon = document.getElementById("favicon");
  favicon.setAttribute('href', process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.tab);
  let apptitle = document.getElementById("apptitle");
  apptitle.innerHTML = this.props.acamar.configuration.tabtitle;

  this.setvalueFont(this.state.valuefont);

  const params = new URLSearchParams(this.props.location.search);
  console.log(this.props.location.search);
  const appname = params.get("app");
  console.log(appname);

  this.props.appstate.Modal.openModalLoading = false;

//in fase di test-inizio
  this.props.appstate.Modal.openModalEditConfirm=false;
  this.props.appstate.ResourceManager.render=false;
//da testare-fine

  this.props.dispatch(APP(this.props.appstate));
  // this.setState({ langObjFilterFab: this.props.acamar.configuration.Languages.list.filter(el => el.code === this.state.language) });
}

componentDidUpdate(prevProps){
  //  this.checkUserPrefereces();
  //  console.log("componentDidUpdate header");
  if ( this.props.appstate.App.currentLanguage !== this.state.language ) {
    this.setState({language:this.props.appstate.App.currentLanguage});
  }
  if(this.props.appstate.App.user.gologin===true){
    this.props.appstate.App.user.gologin=false;
    if(this.props.appstate.App.user.userid!==""){
      console.log("prefer sono loggato");
      if(this.props.appstate.App.user.preferences){
        this.setState({
          valuefont:this.props.appstate.App.user.preferences.accessibility.fontSize,
          valuecontrast:this.props.appstate.App.user.preferences.accessibility.contrast,
          valuecolored:this.props.appstate.App.user.preferences.accessibility.yellowLinks,
          valuelinks:this.props.appstate.App.user.preferences.accessibility.underlineLinks,
        });
      }}
      this.props.dispatch(APP(this.props.appstate));
      this.props.refresh();
    }


  }



  checkUserPrefereces=()=>{
    //  console.log("prefer "+ JSON.stringify(this.props.appstate.App.user.preferences));
    if(this.props.appstate.App.user.userid!==""){
      //  console.log("prefer sono loggato");
      if(this.props.appstate.App.user.preferences){
        //  console.log("prefer campo ci sta");
        // vi è il campo preferences
        if(this.props.appstate.App.user.preferences.language!==""){
          //  console.log("preferenza lingua " +this.props.appstate.App.user.preferences.language);
          //  this.props.appstate.App.currentLanguage=this.props.appstate.App.user.preferences.language;
          this.setState({language:this.props.appstate.App.user.preferences.language});
        }
        if(this.props.appstate.App.user.preferences.accessibility!==null){
          //console.log("preferenze accessibility");

          this.setState({
            valuefont:this.props.appstate.App.user.preferences.accessibility.fontSize,
            valuecontrast:this.props.appstate.App.user.preferences.accessibility.contrast,
            valuecolored:this.props.appstate.App.user.preferences.accessibility.yellowLinks,
            valuelinks:this.props.appstate.App.user.preferences.accessibility.underlineLinks,
          });
          // this.props.appstate.App.accessibility.valuefont=this.props.appstate.App.user.preferences.accessibility.fontSize;
          // this.props.appstate.App.accessibility.valuecontrast=this.props.appstate.App.user.preferences.accessibility.contrast;
          // this.props.appstate.App.accessibility.valuecolored=this.props.appstate.App.user.preferences.accessibility.yellowLinks;
          // this.props.appstate.App.accessibility.valuelinks=this.props.appstate.App.user.preferences.accessibility.underlineLinks;
          // this.props.appstate.App.accessibility.valuefamilyfont=this.props.appstate.App.user.preferences.accessibility.familyFont;
        }

      }else{
        //sono loggato ma non vi è il campo preferences
        // this.props.appstate.Modal.openModalAccessibility=true;
        // this.props.appstate.Modal.openModalLanguage=true;
        // this.props.dispatch(APP(this.props.appstate));
      }
    }
    else{
      //  console.log("prefer non sono loggato");
      //non sono loggato
    }

  }

  static getDerivedStateFromProps(props, state) {
    if (props.store) {
      return {
        store: props.store
      }
      //this.props.history.push('/Home');
    }

    return null
  }

  postMessageHandler = (event) => {
    // if (event.origin === services.annotator) {
      switch (event.data.type) {
        case "CHILDMSG":
        this.setState({ childmessage: event.data.payload.msg });
        break;
        case "CHILDMSG-ANNOTATOR":
        if (event.data.payload.msg === "upload") {
          this.resourcesManagerDialogRef.init({from:"header"});
          this.mapDialogRef.init();
          //this.setState({ items: this.props.appstate.ResourceManager.resources });
          this.props.appstate.ResourceManager.render = true;
          this.props.appstate.ResourceManager.openModalResources = false;
          this.props.appstate.ResourceManager.openModalResourcesHeader = true;
          this.props.appstate.ResourceManager.fromannotator = true;
          this.props.appstate.MapDialog.position = { lat: 0, lng: 0 };
        } else if (event.data.payload.msg === "imglist") {
          this.annotatorResourcesDialogRef.init("image");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "image";
        } else if (event.data.payload.msg === "audiolist") {
          this.annotatorResourcesDialogRef.init("audio");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "audio";
        } else if (event.data.payload.msg === "videolist") {
          this.annotatorResourcesDialogRef.init("video");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "video";
        } else if (event.data.payload.msg === "textlist") {
          this.annotatorResourcesDialogRef.init("textcloud");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "text";
        } else if (event.data.payload.msg === "webcloudlist") {
          this.annotatorResourcesDialogRef.init("webcloud");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "webcloud";
        } else if (event.data.payload.msg === "imagecloudlist") {
          this.annotatorResourcesDialogRef.init("imagecloud");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "imagecloud";
        } else if (event.data.payload.msg === "videocloudlist") {
          this.annotatorResourcesDialogRef.init("videocloud");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "videocloud";
        } else if (event.data.payload.msg === "audiocloudlist") {
          this.annotatorResourcesDialogRef.init("audiocloud");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "audiocloud";
        } else if (event.data.payload.msg === "external") {
          this.annotatorResourcesDialogRef.init("external");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "external";
        }else if (event.data.payload.msg === "switchingresource") {
          this.annotatorResourcesDialogRef.init("switchingresource");
          this.props.appstate.DialogResourcesManagerAnnotator.render = true;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = true;
          this.props.appstate.DialogResourcesManagerAnnotator.type = "switchingresource";
        } else if (event.data.payload.msg === "closeedit") {
          this.props.appstate.Annotator.fsname = "";
          this.props.appstate.Annotator.resType = "";
          this.props.appstate.Annotator.resourceid = "";
          this.props.appstate.Annotator.annotatorMode = "";
          this.props.appstate.Annotator.uri = "";
          this.props.appstate.DialogResourcesManagerAnnotator.render = false;
          this.props.appstate.DialogResourcesManagerAnnotator.openModalResources = false;
          this.props.appstate.DialogResourcesManagerAnnotator.index = 0;
          this.props.appstate.DialogResourcesManagerAnnotator.id = 0;
          this.props.appstate.DialogResourcesManagerAnnotator.resourceInfo = {};
          this.props.appstate.DialogResourcesManagerAnnotator.type = "";
          this.props.dispatch(APP(this.props.appstate));
          this.props.history.push('/Home');
        }
        this.props.dispatch(APP(this.props.appstate));
        break;
        default: break;
      }
    // }
  }



  goToLogin = () => {
    this.props.history.push('/Login');
  }
  goToRegistration = () => {
    this.props.history.push('/Registration');
  }
  goToConfiguration = () => {
    this.props.history.push('/Configuration');
  }
  goToSettings = () => {
    if (this.props.appstate.App.user.logged === true) {
      this.props.history.push('/Settings');
    }
  }
  goToHelp = () => {
    //this.props.history.push('/Help');
    let url="";
    let currentconfig = this.props.acamar.configuration;
    if(currentconfig.Help!=null){
      if(currentconfig.Help[this.state.language]!=null && currentconfig.Help[this.state.language]!==""){
        url=currentconfig.Help[this.state.language];
      }
    }
    window.open(url, "_blank");
  }

  logout = () => {
    let currentconfig = this.props.appstate;
    currentconfig.App.user.logged = false;
    currentconfig.App.user.userid = "";
    currentconfig.App.user.token = "";
    currentconfig.App.user.avatar = "";
    currentconfig.App.user.domain = "";
    currentconfig.App.user.role = "";
    currentconfig.App.user.email = "";
    currentconfig.App.user.status = "";
    currentconfig.App.user.name = "";
    currentconfig.App.user.preferences ="";
    currentconfig.App.user.domainDetail = {};
    this.setState({openMenuInfoAvatar: false});
    this.props.dispatch(APP(currentconfig));
    this.props.history.push('/Home');
  }

  configurationJson = () => {
    this.setState({ modalconfigOpen: true });
  }

  saveConfigurationJson = () => {

  }

  onChangeConfigurationJson = (event) => {
    this.setState({
      configurationJson: event.currentTarget.value
    });
  }

  isValidJSONString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return true;
    }
    return false;
  }

  setvalueFont(value){
    var fontList = [
      'fontnormal',
      'fontlarge',
      'fontxlarge',
      'fontxxlarge'
    ];
    if (value===""){
      value="fontnormal";
    }
    if(document.documentElement.classList.length===0){            
      document.documentElement.classList.add(`${value}`);
      return;
    }
    for(let i=0; i<fontList.length; i++){

      if (fontList[i] === value){
        document.documentElement.classList.replace(document.documentElement.classList[0], fontList[i]);
      }
      else {
        document.documentElement.classList.add(`${value}`);
      }

    }


  }

  openModalAccessibility = () => {
    this.props.appstate.Modal.openModalAccessibility = true;
    this.props.dispatch(APP(this.props.appstate));
  }

  openModalLanguage = () => {
    this.props.appstate.Modal.openModalLanguage = true;
    this.props.dispatch(APP(this.props.appstate));
    this.setState({languagePreConfirm: this.state.language})
  }

  openResources = () => {
    this.props.history.push('/Resources');
  }

  setFabLanguage = (newlanguage) => {
    this.setState({ language: newlanguage });
    // ,langObjFilterFab: this.props.acamar.configuration.Languages.list.filter(el => el.code === this.state.language)});
  }

  getIconLabelLang = () => {
    //let langObj = this.props.acamar.configuration.Languages.list.filter(el => el.code === this.state.language);
    //  console.log("langOBJ "+JSON.stringify(langObj));

    return (

      <Fab
        component="button"
        role="button"
        color="primary"
        size="small"
        aria-label={this.state.language}
        onClick={this.openModalLanguage}
        >
          <Badge
            badgeContent={this.state.language}
            color="primary"
            style={{fontWeight: "900"}}
            >
              <LanguageIcon
                style={{width: "28px", height: "28px"}}
              />
            </Badge>
          </Fab>
        );

      }

      dialogResourcesManagerCallBack = () => {
        /**NUOVA MOADLITà ASSET 08-12-2021 */
        //console.log(this.props.appstate.ResourceManager.annotatorinfo);
        console.log("dialogResourcesManagerCallBack viene chiamata nella creazione ")
        console.log(this.props.appstate.ResourceManager.annotatorinfo);

        document.getElementById('iframecontent').contentWindow.postMessage({ "type": "NEWRESOURCE", "payload": this.props.appstate.ResourceManager.annotatorinfo }, "*");
        // document.getElementById("resourcesearchinput").value = "";
        // this.setState({ items: this.props.appstate.ResourceManager.resources});

        /*this.setState(
        { items: this.props.appstate.ResourceManager.resources },
        () => {
        this.filterengine(
        undefined,
        undefined,
        undefined,
        this.state.rightSelected
      );
    }
  );*/
};

dialogResourcesManagerAnnotator = (data) => {
  /**NUOVA MOADLITà ASSET 13-12-2021 */
  //console.log(data);
  console.log("dialogResourcesManagerAnnotator chiamata in upload")
  document.getElementById('iframecontent').contentWindow.postMessage({ "type": "NEWRESOURCE", "payload": data }, "*");
};

avatarUrl = () => {
  let ser =
  process.env.REACT_APP_DOMAIN +
  services.avatar_GET_AUTH.replace(
    "{APPNAME}",
    this.props.appstate.App.name
  );
  //ser = ser.replace("{APPNAME}", this.props.appstate.App.name);
  //ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
  ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
  ser = ser + "&t=" + new Date().getTime();
  return ser;
}

render() {
  let currentconfig = this.props.acamar.configuration;
  let jsonlang = this.props.appstate.App.language;

  return (
    <div>

      <AppBar position="fixed" color="default">
        <Toolbar>

          <Box sx={{ width: '100%' }}> {/* <--- area contenitore di tutti gli elemnti della toolbar */}
          <Box display="flex" p={0}> {/* <-- flexbox container */}
          <Box p={0} width="100%">  {/* <-- box container LOGO*/}
          {currentconfig.logo && currentconfig.logo.light!=="" ?
          <img
            className="logo"
            src={this.props.acamar.configuration.Theme.currenttheme==="light" ? process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.light : process.env.REACT_APP_DOMAIN+"/"+this.props.acamar.configuration.logo.dark}
            alt="logo" />
            : <img alt="mcp logo"/> }
          </Box>

          {this.props.appstate.App.user.role === "emperor" ?

          <Box p={0} flexShrink={0} display="flex" alignItems="center" color="primary.main"> {/* <-- flexbox container menu amministrazione*/}
          <Box p={1} bgcolor="grey.400">  {/* <-- box container bottone RISORSE*/}
          <Tooltip title={<h2>{jsonlang.resources.toUpperCase()}</h2>}>
          <Link
            aria-label="resource page"
            role="link"
            component="button"
            color="inherit"
            id="resources"
            onClick={this.openResources}
            >
              <Typography
                variant="subtitle1"
                className="bold"
                >
                  <IconButton aria-label={jsonlang.resources.toUpperCase()} color="primary" >
                    <BurstModeIcon/>
                  </IconButton>
                  {jsonlang.resources.toUpperCase()}
                </Typography>
              </Link>
            </Tooltip>
          </Box>
          <Divider orientation="vertical" flexItem/>
          <Box p={1} bgcolor="grey.400">  {/* <-- box container bottone CONFIGURAZIONE*/}
          <Tooltip title={<h2>{jsonlang.configuration.toUpperCase()}</h2>}>
          <Link
            aria-label="configuration page"
            role="link"
            component="button"
            color="inherit"
            onClick={this.goToConfiguration}
            >
              <Typography
                variant="subtitle1"
                className="bold"
                >
                  <IconButton aria-label={jsonlang.configuration.toUpperCase()} color="primary" >
                    <SecurityIcon />
                  </IconButton>
                  {jsonlang.configuration.toUpperCase()}
                </Typography>
              </Link>
            </Tooltip>
          </Box>
        </Box>
        : <></>}

        <Box p={1} flexShrink={0}>  {/* <-- box container icona HOME*/}
        { this.props.location.pathname==='/Home' ?

        <Tooltip title={<h2>HOME</h2>}>
        <Fab
          role="button"
          disabled
          color="secondary"
          size="small"
          aria-label="Home"
          >
            <HomeIcon
            />
          </Fab>
        </Tooltip>
        :
        <Tooltip title={<h2>HOME</h2>}>
        <Fab
          role="button"
          color="primary"
          size="small"
          aria-label="Home"
          id="webstarthome"
          onClick={this.onClickButton}
          >
            <HomeIcon
              // id="webstarthome"
              // onClick={this.onClickButton}
            />
          </Fab>
        </Tooltip>
      }
    </Box>

    <Box p={1} flexShrink={0}>

      <Tooltip title={<h2>{jsonlang.settings.toUpperCase()}</h2>}>
      <Fab
        role="button"
        disabled={this.props.appstate.App.user.logged === false  ||
          (this.props.appstate.App.user.logged === true &&
            this.props.location.pathname==='/Settings')
            ? true : false
          }
          color='primary'
          size="small"
          aria-label="SETTINGS"
          onClick={this.goToSettings}
          >
            <SettingsIcon
              fontSize="large"
            />
          </Fab>
        </Tooltip>

      </Box>


      <Box p={1} flexShrink={0}>  {/* <-- box container icona ACCESSIBILITA */}
      <Tooltip title={<h2>{jsonlang.accessibility.toUpperCase()}</h2>}>
      <Fab
        role="button"
        component="button"
        color="primary"
        size="small"
        aria-label="Accessibility"
        onClick={this.openModalAccessibility}
        style={{
          background:`url(/images/icons/access-icon.png) 50% 50% / 44px 44px`
        }}
        >
        </Fab>
      </Tooltip>
    </Box>

    <Box p={1} flexShrink={0}>  {/* <-- box container icona USER AVATAR*/}
    {/* {this.props.location.pathname!=="/Login" && this.props.appstate.App.user.logged === false ? */}
    { this.props.appstate.App.user.logged === true ?
      <Tooltip title={<h2>{this.props.appstate.App.user.name.toUpperCase()}</h2>}>
      <Avatar
        alt={this.props.appstate.App.user.name.toUpperCase()}
        src={this.avatarUrl(this.props.appstate.App.user.userid)}
        // src={this.props.appstate.App.user.avatar}
        className="avatarSh"
        onClick={this.renderMenu}
      />
    </Tooltip>
    :
    <Tooltip title={<h2>LOGIN</h2>}>
    <Fab
      role="button"
      color="primary"
      size="small"
      aria-label="Login"
      onClick={this.goToLogin}
      disabled={this.props.location.pathname!=="/Login" ? false:true}
      >
        <LockIcon/>
      </Fab>
    </Tooltip>
  }

</Box>
<Box p={1} flexShrink={0}>  {/* <-- box container icona LINGUA*/}
<Tooltip title={<h2>{jsonlang.language.toUpperCase()}</h2>}>
{this.getIconLabelLang()}
</Tooltip>
</Box>


<Box p={1} flexShrink={0}>
  {/* { this.props.appstate.App.user.logged === false  ? */}
  {this.props.appstate.App.name.toUpperCase() === "SOCLOSE" ?
  <Tooltip title={<h2>HELP</h2>}>
  <Fab
    role="button"
    disabled={this.props.appstate.App.user.logged === false ? true:
      currentconfig.Help!=null && currentconfig.Help[this.state.language]!=null && currentconfig.Help[this.state.language]!=="" ?
      false : true
    }
    color={this.props.appstate.App.user.logged === false ? '':'primary'}
    size="small"
    aria-label="HELP"
    onClick={this.goToHelp}
    >
      <HelpOutlineIcon
        fontSize="large"
      />
    </Fab>
  </Tooltip>
  : <></>
}
</Box>
</Box>
</Box>
</Toolbar>

{/* <-- La barra diventa soltanto una decorazione a colore*/}
<Box
  display="flex"
  justifyContent="center"
  bgcolor="primary.main"
  style={{height:6}}
  >
  </Box>
</AppBar>

{/* <-- used to display user info on top of appbar*/}
<Popover
  open={this.state.openMenuInfoAvatar}
  anchorEl={this.state.anchorEl}
  onClose={this.handleCloseInfoAvatar}

  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  >
    <div className="info">
      <div  className="left-close">
        <IconButton aria-label="close">
          <CloseIcon
            fontSize="small"
            onClick={this.handleCloseInfoAvatar}
          />
        </IconButton>
      </div>
      <section className="left-column">
        <div>
          <Typography
            variant="subtitle1"
            className="bold"
            >
              {jsonlang.name.toUpperCase()}:
            </Typography>
          </div>
        </section>
        <section className="right-column">
          <div>
            <Typography
              variant="subtitle1">
              {this.props.appstate.App.user.name}
            </Typography>
          </div>
        </section>
        <section className="left-column">
          <div>
            <Typography variant="subtitle1" className="bold">
              {jsonlang.email.toUpperCase()}:
            </Typography>
          </div>
        </section>
        <section className="right-column">
          <div>
            <Typography variant="subtitle1">
              {this.props.appstate.App.user.email}
            </Typography>
          </div>
        </section>
        <section className="left-column">
          <div>
            <Typography
              variant="subtitle1"
              className="bold"
              >
                {jsonlang.role.toUpperCase()}:
              </Typography>
            </div>
          </section>
          <section className="right-column">
            <div>
              <Typography variant="subtitle1"
                >
                  {this.props.appstate.App.user.role==="domainadmin"?"ADMIN" : (this.props.appstate.App.user.role==="basic"?"STANDARD":this.props.appstate.App.user.role)}
                </Typography>
              </div>
            </section>
            {this.props.appstate.App.user.role !== "superadmin" && this.props.appstate.App.user.role !== "emperor" ?
            <div>
              <section className="left-column">
                <div>
                  <Typography
                    variant="subtitle1"
                    className="bold">
                    {jsonlang.institution.toUpperCase()}:
                  </Typography>
                </div>
              </section>
              <section className="right-column">
                <div>
                  <Typography variant="subtitle1">
                    {this.props.appstate.App.user.domainDetail.shortname}
                  </Typography>
                </div>
              </section>
            </div>
            :
            <></>}

            <div className="info-button">
              <Button
                variant='contained'
                color="primary"
                startIcon={<ExitToAppIcon/>}
                onClick={this.logout}
                >
                  LOGOUT
                </Button>
              </div>
            </div>
          </Popover>

          <div> {/* area richamo dialog di servizio */}
          <DialogLoading/>
            <DialogEditConfirm/>
          <DialogResources/>

          {/* <DialogDelete/> */}
          <DialogLanguage newlanguage={this.setFabLanguage}/>
          <DialogAccessibility refresh={this.props.refresh}/>
          <DialogResourcesManager
            callback={this.dialogResourcesManagerCallBack}
            onRef={(childthis) => (this.resourcesManagerDialogRef = childthis)}
            open={  this.props.appstate.ResourceManager.openModalResourcesHeader }
          />
          <MapDialog onRef={(childthis) => (this.mapDialogRef = childthis)} />
          <DialogResourcesManagerAnnotator
            callback={this.dialogResourcesManagerAnnotator}
            onRef={(childthis) => (this.annotatorResourcesDialogRef = childthis)} 
            /*prova per riutilizzare in piu punti il componente */
            open={this.props.appstate.DialogResourcesManagerAnnotator.openModalResources}
            />
          </div>
        </div>
      )
    }
  }
  export default connect(mapStateToProps)(withRouter(Header))
