import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP } from '../../redux/actions/actions';
import * as services from "../../services/services.json";
import FlagsDialog from './FlagsDialog';
import LanDialog from './LanDialog';
import InfoDialog from './InfoDialog';
import languages from '../../config/languages.json';
import languages2 from '../../config/languages.json';
// import * as services from "../../services/services.json";
// import { getService, postService } from "../../services/services";
// import { makeStyles } from '@material-ui/core/styles';
import * as mediatypes from "../../Utils/mediatype.json";
import './ResourcesEditManager.css';

import {
  TextField,
  Tooltip,
  MenuItem,
  Switch,
  Grid,
  Divider,
  IconButton,
  OutlinedInput,
  Select,
  InputLabel,
  FormControl,
  ListItemIcon,
  Typography,
  Link,
  Checkbox,
  Toolbar,
  Button
} from "@material-ui/core";

import { getRights,getExternalMediaType } from "../../Utils/utility";
import RoomIcon from '@material-ui/icons/Room';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/Info';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};


class WeakResourceHandler extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      dataLanguage:"",
      url:"",
      langfilter: new Array(this.props.acamar.configuration.Languages.list.length).fill(false),
      urltate:"",
      type:"",
      publicStatusExtRes:false,
      degreeMedia: false,
      degreeMediaSelected: 2,
      uriCoverExternalRes:"",

    })
  }
  componentDidMount() {
    console.log("WeakResourceHandler")
    this.init();
  }
  init=(lan)=>{
    let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
    let parseLink;
    try {
      parseLink = itemData.url;
    } catch (e) {
      parseLink = "";
    }
    this.setState({
      url: parseLink,
      urlstate: itemData.url ? itemData.url[this.getKeyLang(parseLink)] : "",
      dataLanguage:itemData.url ? this.getKeyLang(parseLink) : "",
      type:itemData.type,
      publicStatusExtRes:itemData.publicStatusExtRes ?itemData.publicStatusExtRes : false,
      degreeMedia: itemData.degree,
      degreeMediaSelected: parseInt(itemData.degree) === 3 ? true : false,
      uriCoverExternalRes:itemData.uriCoverExternalRes ?itemData.uriCoverExternalRes : "" ,


    });
  }

  getKeyLang = (strinput) => {
    if (this.props.acamar.configuration.Languages && strinput) {
      let keylang = "en";
      if (strinput[this.props.acamar.configuration.Languages.default]) {
        return this.props.acamar.configuration.Languages.default;
      } else if (strinput[keylang]) {
        return keylang;
      } else {
        let allKey = Object.keys(strinput);
        if (allKey.length > 0) {
          return allKey[0];
        }
      }
    }
    return "";
  }

  selectLanguage = (event) => {
    this.worldLangHandler(event.target.value);
  }
  callBackFlagsDialog = (lang) => {
    this.worldLangHandler(lang);
  }
  worldLangHandler = (lang) => {
    let langfiltercopy = this.state.langfilter.slice().fill(false);
    let currentUrl = this.state.url[lang.toLowerCase()];
    if (!currentUrl) {
      currentUrl = "";
    }
    this.setState({ dataLanguage: lang.toLowerCase(), langfilter: langfiltercopy, urlstate: currentUrl })

  }
  openFlagsDialog = () => {
    this.flagsDialogRef.init();
    this.props.appstate.FlagsDialog.render = true;
    this.props.appstate.FlagsDialog.openModalFlags = true;
    let urlearr = Object.keys(this.state.url);
    // let descarr = Object.keys(this.state.description);
    this.props.appstate.FlagsDialog.items = urlearr;
    this.props.dispatch(APP(this.props.appstate));
  }
  textfiedlvalue = (event) => {
    switch (event.target.id) {
      case "url":
      this.setState({ urlstate: event.target.value });
      break;
      case "coverUrl":
      this.setState({ uriCoverExternalRes: event.target.value }, this.props.dataform( event.target.value,"coverUrl"));//salva cover
      break;
    }
  }

  saveUrlForLang = () => {
    let urlCopy = Object.assign({}, this.state.url);
    if (this.state.urlstate === "") {
      // se l'elemento di lingua esiste lo cancelllo altrimenti niente
      if (urlCopy[this.state.dataLanguage]) {
        delete urlCopy[this.state.dataLanguage]
      }
    } else {
      //creo o aggiorno l'elemento di lingua
      urlCopy[this.state.dataLanguage] = this.state.urlstate;
    }
    this.setState({ url: urlCopy }, this.props.dataform(urlCopy,"url")); //salva array di url multilinuga

  }


  handleDegreeType = (e) => {
    let internalDegree = "";
    if (e.target.checked) {
      internalDegree = 3;
    } else {
      internalDegree = 2;
    }
    this.setState({ degreeMedia: e.target.checked, degreeMediaSelected: internalDegree }, this.props.dataform(e.target.checked,"degreeMedia")); //salvataggio degree
  }


  handlePublicStatusExternalWebResource=(e)=>{
    this.setState({ publicStatusExtRes: e.target.checked }, this.props.dataform( e.target.checked,"publicStatus"));
  }
  render() {
    let jsonlang = this.props.appstate.App.language;
    let itemData = this.props.appstate.ResourceManager.resources[this.props.appstate.ResourceManager.index];
    return (
      <>
      <FlagsDialog onRef={childthis => (this.flagsDialogRef = childthis)} callback={this.callBackFlagsDialog}/>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="gridmargin">

        <Grid item xs={6}>
          {/* <Toolbar> */}
          <Select
            style={{ minWidth: 356, maxHeight: 70}}
            labelId="media_languages_choose"
            id="media_languages_select"
            value={this.state.dataLanguage.toLowerCase()}
            onChange={this.selectLanguage}
            displayEmpty
            >
              <MenuItem disabled value="">
                <em>{jsonlang.language.toUpperCase()}</em>
              </MenuItem>
              {languages2.map((item, index) =>
                <MenuItem key={index} value={item.code} selected>
                  <ListItemIcon>
                    <img name={item.code} style={{ width: "32px", height: "32px" }} src={"/images/languages/" + item.code + ".svg"}></img>
                  </ListItemIcon>
                  <Typography variant="inherit">{item.name}</Typography>
                </MenuItem>
              )}
            </Select>
            <IconButton color="primary"
              role="button"
              size="small"
              aria-label={jsonlang.adda.toUpperCase()}
              onClick={this.openFlagsDialog}
              >
                <PlaylistAddCheckIcon style={{ fontSize: 40 }}
                />
              </IconButton>


              {/* </Toolbar> */}
            </Grid>

            <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  style={{width:"97%"}}
                  id="url"
                  label="url"
                  defaultValue=""
                  value={this.state.urlstate}
                  onBlur={this.saveUrlForLang}
                  onChange={this.textfiedlvalue}
                />
              </Grid>

              <Grid item xs={6}>
                  <Select
                    required
                    id="extmediatype"
                    disabled={true}
                    displayEmpty
                    style={{ minWidth: 356, maxHeight: 70}}
                    // style={{"marginBottom":"10px"}}
                    value={this.state.type}
                    onChange={this.handleChangeExternalMediaType}
                    >
                      <MenuItem disabled value="">
                        <em>Media Type</em>
                      </MenuItem>
                      {getExternalMediaType().map((extType, i) =>
                        <MenuItem  key={extType.id} className="MuiInputBase-input-custom" value={extType.value}>
                          {extType.label}
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>

                  {this.state.type ==="imagecloud" || this.state.type==="videocloud"?
                  <Grid item xs={12}>
                    <Grid item>2d</Grid>
                    <Grid item>
                      <Switch checked={this.state.degreeMedia} onChange={this.handleDegreeType} color='primary'/>
                    </Grid>
                    <Grid item>360</Grid>
                  </Grid>
                  :
                  null}
                  {this.state.type==="webcloud" ?
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      style={{width:"97%", marginTop:15}}
                      id="coverUrl"
                      label="Cover Url"
                      defaultValue=""
                      value={this.state.uriCoverExternalRes}
                      onChange={this.textfiedlvalue}
                    />
                    {/* <Grid item style={{marginTop:15}}>Show In ShowRoom</Grid>
                    <Grid item>
                      <Switch checked={this.state.publicStatusExtRes} onChange={this.handlePublicStatusExternalWebResource} color='primary'/>
                    </Grid> */}
                  </Grid>
                  :
                  null}
                </Grid>
              </>

            )
          }
        }
        export default connect(mapStateToProps)(withRouter(WeakResourceHandler));
