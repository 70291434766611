import React, { Component } from 'react';
import ResourcesUpload from './ResourcesUploadManager';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";

import RoomIcon from '@material-ui/icons/Room';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { APP } from '../../redux/actions/actions';
import { GeoMap, mongoObjectId } from './mapcomp';

const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class MapDialog extends Component {
  constructor(props) {
    super(props);
    this.state = ({});
  }

  init = () => {
    this.pinRef = undefined;
    this.mapRef = undefined;
  }

  componentDidMount() {
    this.props.onRef(this); //for the parent
  }

  onclose = () => {
    this.pinRef = undefined;
    this.mapRef = undefined;
    this.props.appstate.MapDialog.render = false;
    this.props.appstate.MapDialog.openModalMap = false;
    this.props.dispatch(APP(this.props.appstate));
  }

  mapCallback = (type, overlay) => {
    if (type === "drawcompleted") {
      if (this.pinRef !== undefined) {
        this.pinRef.setPosition({
          lat: overlay[1],
          lng: overlay[2]
        });
      } else {
        this.pinRef = new window.google.maps.Marker({
          position: {
            lat: overlay[1],
            lng: overlay[2]
          }
        });
        this.pinRef.setMap(this.mapRef);
      }
      this.props.appstate.MapDialog.position = {
        lat: overlay[1],
        lng: overlay[2]
      };
    } else if (!this.mapRef && type === "ref" && overlay) {
      this.mapRef = overlay;
      //to maintail old selection
      this.pinRef = new window.google.maps.Marker({
        position: {
          lat: parseFloat(this.props.appstate.MapDialog.position.lat),
          lng: parseFloat(this.props.appstate.MapDialog.position.lng)
        }
      });
      this.pinRef.setMap(this.mapRef);
    }
  }

  render() {
    let jsonlang = this.props.appstate.App.language;
    return (
      <>
      {this.props.appstate.MapDialog.render === true ?
        <div className="iframe">
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={this.props.appstate.MapDialog.openModalMap}
            onClose={this.onclose}
            TransitionComponent={Transition}
            aria-labelledby="dialog-add"
            fullWidth
            maxWidth={'sm'}
            >
              <DialogTitle id="dialog-add">
                <RoomIcon
                  color="primary"
                />
                <span style={{
                  padding: 5,
                  position: 'fixed',
                }}>
                {'RESOURCE POSITION'}
              </span>
            </DialogTitle>
            <DialogContent dividers>
              < GeoMap
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={< div style={{ height: `100%` }} />}
              containerElement={< div style={{ height: `300px`, width: '100%' }} />}
              mapElement={< div style={{ height: `100%` }} />}
              options={{
                scrollwheel: false,
                streetViewControl: false,
                minZoom: 1,
                maxZoom: 19,
                mapTypeControl: true,
                zoomControl: true,
              }}
              drawingControl={true}
              onlyMarker={true}
              callback={this.mapCallback}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              onClick={this.onclose}
              color="primary"
              startIcon={<ClearIcon />}>
              <Tooltip title={<h2>OK</h2>}>
              <Typography variant="button">OK</Typography>
            </Tooltip>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    : <></>}
  </>
)
}
}
export default connect(mapStateToProps)(MapDialog);
