import React, { Component } from 'react';
import { connect } from 'react-redux';

import { postService } from "../../services/services";
import * as services from "../../services/services.json";

// =====================================
// ============ COMPONENTS  ============
// =====================================
import {
  // AppBar,
  // Box,
  // Button,
  // ButtonGroup,
  // Container,
  // CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  // Fab,
  // FormControl,
  FormControlLabel,
  // FormGroup,
  // FormLabel,
  Grid,
  // Icon,
  IconButton,
  // InputLabel,
  // Link,
  // ListSubheader,
  // Menu,
  // MenuItem,
  // Paper,
  Radio,
  RadioGroup,
  // Select,
  Slide,
  // Switch,
  // TextField,
  // Toolbar,
  // Tooltip,
  Typography
} from "@material-ui/core";


// ================================
// ============ ICONS  ============
// ================================
import TextFieldsIcon from '@material-ui/icons/TextFields';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
// import MenuIcon from '@material-ui/icons/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
// import LanguageIcon from '@material-ui/icons/Language';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
// import SettingsIcon from '@material-ui/icons/Settings';
// import HomeIcon from '@material-ui/icons/Home';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';

import { WHOLE, APP } from '../../redux/actions/actions';

// import { yellow } from '../../config/yellow';
// import { notheme } from '../../config/notheme';
// import { bothyelunder } from '../../config/bothyelunder';
// import { underline } from '../../config/underline';
// import { fontlarge } from '../../config/fontlarge';
// import { fontnormal } from '../../config/fontnormal';

// import * as services from "../../services/services.json";

//import { getConfigLink, getConfigContrast } from "../../config/getConfig";
// import load from 'little-loader';

//const themefirst = 'http://localhost:3004/public/soclose/themes/theme_1620026703876_themefirst.js';
//const themelink = 'http://localhost:3004/public/soclose/themes/theme_1620047238528_themelink.js';

import {createMuiTheme} from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class DialogAccessibility extends Component {
  constructor(props) {
    super(props);
    this.state = ({

      valuefont: this.props.appstate.App.accessibility.valuefont,
      valuecontrast: this.props.appstate.App.accessibility.valuecontrast,
      valuecolored: this.props.appstate.App.accessibility.valuecolored,
      valuelinks:this.props.appstate.App.accessibility.valuelinks,
      valuefamilyfont: this.props.appstate.App.accessibility.valuefamilyfont

    });

    this.handleChangeFont=this.handleChangeFont.bind(this);
    this.handleChangeLink=this.handleChangeLink.bind(this);

  }
  componentDidMount() {

  }

  componentDidUpdate(prevProps){

    if(this.props.appstate.App.user.userid!==""){
      if(this.props.appstate.App.user.preferences){
        //console.log("prefer campo ci sta");
        if(this.props.appstate.App.user.preferences.accessibility){
          //console.log("prefer campo ci sta 2");
          if(this.state.valuefont!==this.props.appstate.App.user.preferences.accessibility.fontSize || this.state.valuecontrast!==this.props.appstate.App.user.preferences.accessibility.contrast || this.state.valuecolored!==this.props.appstate.App.user.preferences.accessibility.yellowLinks ||
            this.state.valuelinks!==this.props.appstate.App.user.preferences.accessibility.underlineLinks || this.state.valuefamilyfont!==this.props.appstate.App.user.preferences.accessibility.familyFont){
              //console.log("prefer campo ci sta 3");
              this.setState({
                valuefont: this.props.appstate.App.user.preferences.accessibility.fontSize,
                valuecontrast: this.props.appstate.App.user.preferences.accessibility.contrast,
                valuecolored: this.props.appstate.App.user.preferences.accessibility.yellowLinks,
                valuelinks:this.props.appstate.App.user.preferences.accessibility.underlineLinks,
                valuefamilyfont: this.props.appstate.App.user.preferences.accessibility.familyFont
              })
            }
          }
        }
      }

    }

    onclose = () => {
      this.props.appstate.Modal.openModalAccessibility = false;
      //  this.props.appstate.App.user.preferences={};
      this.props.dispatch(APP(this.props.appstate));
      if(this.props.appstate.App.user.userid!==""){
        if(this.props.appstate.App.user.preferences===undefined)
        this.props.appstate.App.user.preferences={};

        this.props.appstate.App.user.preferences.accessibility={};
        this.props.appstate.App.user.preferences.accessibility.fontSize=this.state.valuefont;
        this.props.appstate.App.user.preferences.accessibility.contrast=this.state.valuecontrast;
        this.props.appstate.App.user.preferences.accessibility.yellowLinks=this.state.valuecolored;
        this.props.appstate.App.user.preferences.accessibility.underlineLinks=this.state.valuelinks;
        this.props.appstate.App.user.preferences.accessibility.familyFont=this.state.valuefamilyfont;
        this.props.dispatch(APP(this.props.appstate));


        const headers = { headers: {  'Content-Type': 'application/x-www-form-urlencoded' } };
        const params = new URLSearchParams();
        params.append('appname',this.props.appstate.App.name );
        params.append('id',this.props.appstate.App.user.userid);
        params.append('domainId', this.props.appstate.App.user.domain);
        params.append('valuefont', this.state.valuefont);
        params.append('valuecontrast', this.state.valuecontrast);
        params.append('valuecolored', this.state.valuecolored);
        params.append('valuelinks', this.state.valuelinks);
        params.append('valuefamilyfont', this.state.valuefamilyfont);
        let serviceurl =
        process.env.REACT_APP_DOMAIN + services.userpreferences_POST;
        postService(serviceurl, params, "", headers, function (err, result) {
          if (err) {
            return;
          }
          if (result.status === 200) {
            //preferences accessibility ok
          }
        });
      }
    }

    handleChangeFont(event){
      this.setState({valuefont:event.target.value});

      var value = event.target.value;
      this.props.appstate.App.accessibility.valuefont=value;
      if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
        this.props.appstate.App.user.preferences.accessibility.fontSize=value;
      }
      this.props.dispatch(APP(this.props.appstate));

      this.setvalueFont(value);

    }

    handleChangeFamily = (event)  => {
      this.setState({valuefamilyfont:event.target.value});
      var value = event.target.value;
      this.props.appstate.App.accessibility.valuefamilyfont=value;
      const iframe = document.getElementById('iframecontent');


      if (event.target.value === "Lexend") {
        let currentconfig = this.props.acamar;
        currentconfig.configuration.Theme.currenttheme ="Lexend";
        this.props.appstate.App.theme.refmaterial = {};
        if (iframe) {
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
        };
        if(this.state.valuecontrast==="light"){
          this.props.appstate.App.theme.valuethemefirst = "Lexend";
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexend());
        }
        else {
          this.props.appstate.App.theme.valuethemefirst = "LexendDark";
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexendDark());
        }

        if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
          this.props.appstate.App.user.preferences.accessibility.familyFont=event.target.value;
          this.props.appstate.App.user.preferences.accessibility.contrast=this.state.valuecontrast;
        }

        this.props.dispatch(WHOLE(currentconfig));
        this.props.dispatch(APP(this.props.appstate));
        //send info to active child
      } else if (event.target.value === "familydefault") {
        let currentconfig = this.props.acamar;
        currentconfig.configuration.Theme.currenttheme ="familydefault";
        this.props.appstate.App.theme.refmaterial = {};
        if (iframe) {
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
        };
        if(this.state.valuecontrast==="light"){
          this.props.appstate.App.theme.valuethemefirst = "light";
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLightTheme());
        }else {
          this.props.appstate.App.theme.valuethemefirst = "dark";
          this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getDarkTheme());
        }

        if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
          this.props.appstate.App.user.preferences.accessibility.familyFont=event.target.value;
          this.props.appstate.App.user.preferences.accessibility.contrast=this.state.valuecontrast;
        }


        this.props.dispatch(WHOLE(currentconfig));
        this.props.dispatch(APP(this.props.appstate));
      }
      this.props.refresh();

    }


    setvalueFont(value){
      var fontList = [
        'fontnormal',
        'fontlarge',
        'fontxlarge',
        'fontxxlarge'
      ];
      if(document.documentElement.classList.length===0){
        document.documentElement.classList.add(`${value}`);
        return;
      }

      for(let i=0; i<fontList.length; i++){

        if (fontList[i] === value)
        document.documentElement.classList.replace(document.documentElement.classList[0], fontList[i]);
        else {
          document.documentElement.classList.add(`${value}`);
        }

      }

      const iframe = document.getElementById('iframecontent');
      if (iframe) {
        iframe.contentWindow.postMessage({
          type: "FONT", font: value}, this.props.appstate.App.currentappchild);
        }
      }

      checkboxthemeEvent = (event) => {
        //Theme must be comunicated to child
        // var thisRef=this;
        //console.log("contrast "+event.target.value);
        this.setState({ valuecontrast: event.target.value });
        this.props.appstate.App.accessibility.valuecontrast =  event.target.value;
        const iframe = document.getElementById('iframecontent');
        if (event.target.value === "dark") { //black
          let currentconfig = this.props.acamar;
          currentconfig.configuration.Theme.currenttheme ="dark";
          // this.props.appstate.App.theme.valuethemefirst = "dark";
          this.props.appstate.App.theme.refmaterial = {};
          if (iframe) {
            let appstate = Object.assign({}, this.props.appstate);
            iframe.contentWindow.postMessage({
              type: "WHOLE", acamar: this.props.acamar, appstate: appstate
            }, this.props.appstate.App.currentappchild);
          };
          if(this.state.valuefamilyfont==="Lexend"){
            this.props.appstate.App.theme.valuethemefirst = "LexendDark";
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexendDark());
          }else {
            this.props.appstate.App.theme.valuethemefirst = "dark";
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getDarkTheme());
          }

          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){

            this.props.appstate.App.user.preferences.accessibility.contrast=event.target.value;
            this.props.appstate.App.user.preferences.accessibility.familyFont=this.state.valuefamilyfont;
          }

          //  this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getDarkTheme());
          // this.props.appstate.App.theme.themecontrast = window.getDarkTheme();
          this.props.dispatch(WHOLE(currentconfig));
          this.props.dispatch(APP(this.props.appstate));
          //send info to active child
        } else if (event.target.value === "light") { //white
          let currentconfig = this.props.acamar;
          currentconfig.configuration.Theme.currenttheme ="light";
          // this.props.appstate.App.theme.valuethemefirst = "light";
          this.props.appstate.App.theme.refmaterial = {};
          if (iframe) {
            let appstate = Object.assign({}, this.props.appstate);
            iframe.contentWindow.postMessage({
              type: "WHOLE", acamar: this.props.acamar, appstate: appstate
            }, this.props.appstate.App.currentappchild);
          };
          if(this.state.valuefamilyfont==="Lexend"){
            this.props.appstate.App.theme.valuethemefirst = "Lexend";
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLexend());
          }
          else {
            this.props.appstate.App.theme.valuethemefirst = "light";
            this.props.appstate.App.theme.refmaterial=createMuiTheme(window.getLightTheme());
          }
          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
            this.props.appstate.App.user.preferences.accessibility.contrast=event.target.value;

            this.props.appstate.App.user.preferences.accessibility.familyFont=this.state.valuefamilyfont;
          }

          this.props.dispatch(WHOLE(currentconfig));
          this.props.dispatch(APP(this.props.appstate));
        }
        this.props.refresh();
      }

      handleChangeLink (event){
        const iframe = document.getElementById('iframecontent');
        //let currentconfig = this.props.acamar;
        let confitAppstate = this.props.appstate;

        switch (event.target.value) {
          case 'coloryellow':
          this.setState({ valuecolored: "coloryellow" });
          confitAppstate.App.accessibility.valuecolored="coloryellow";
          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
            this.props.appstate.App.user.preferences.accessibility.yellowLinks="coloryellow";
          }
          if (this.state.valuelinks==="linksdefault"){
            confitAppstate.App.theme.themelink = window.getYellowTheme();
            confitAppstate.App.theme.valuethemelink="yellow";
          }
          else{
            confitAppstate.App.theme.themelink = window.getBothYelUnderTheme();
            confitAppstate.App.theme.valuethemelink="bothyelunder";
          }
          break;

          case 'colornormal':
          this.setState({ valuecolored: "colornormal" });
          confitAppstate.App.accessibility.valuecolored="colornormal";
          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
            this.props.appstate.App.user.preferences.accessibility.yellowLinks="colornormal";
          }

          if (this.state.valuelinks==="linksdefault"){
            confitAppstate.App.theme.themelink = window.getNoTheme();
            confitAppstate.App.theme.valuethemelink="notheme";
          }
          else{
            confitAppstate.App.theme.themelink =  window.getUnderlineTheme();
            confitAppstate.App.theme.valuethemelink="underline";
          }
          break;

          case 'linksunderline':
          this.setState({ valuelinks: "linksunderline" });
          confitAppstate.App.accessibility.valuelinks="linksunderline";
          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
            this.props.appstate.App.user.preferences.accessibility.underlineLinks="linksunderline";
          }

          if (this.state.valuecolored==="coloryellow"){
            confitAppstate.App.theme.themelink = window.getBothYelUnderTheme();
            confitAppstate.App.theme.valuethemelink="bothyelunder";
          }
          else{
            confitAppstate.App.theme.themelink =  window.getUnderlineTheme();
            confitAppstate.App.theme.valuethemelink="underline";
          }
          break;
          case 'linksdefault':
          this.setState({ valuelinks: "linksdefault" });
          confitAppstate.App.accessibility.valuelinks="linksdefault";
          if(this.props.appstate.App.user.userid!=="" && this.props.appstate.App.user.preferences!==undefined){
            this.props.appstate.App.user.preferences.accessibility.underlineLinks="linksdefault";
          }


          if (this.state.valuecolored==="coloryellow"){
            confitAppstate.App.theme.themelink = window.getYellowTheme();
            confitAppstate.App.theme.valuethemelink="yellow";
          }
          else{
            confitAppstate.App.theme.themelink = window.getNoTheme();
            confitAppstate.App.theme.valuethemelink="notheme";
          }
          break;
          default:
          confitAppstate.App.theme.themelink = window.getNoTheme();
          confitAppstate.App.theme.valuethemelink = "notheme";
          break;
        }

        //this.props.dispatch(WHOLE(currentconfig));
        //send info to active child

        if (iframe) {
          this.props.appstate.App.theme.refmaterial = {};
          let appstate = Object.assign({}, this.props.appstate);
          iframe.contentWindow.postMessage({
            type: "WHOLE", acamar: this.props.acamar, appstate: appstate
          }, this.props.appstate.App.currentappchild);
          if (this.props.acamar.configuration.Theme.currenttheme === "light") {
            this.props.appstate.App.theme.refmaterial = createMuiTheme(window.getLightTheme());
          } else if (this.props.acamar.configuration.Theme.currenttheme === "dark") {
            this.props.appstate.App.theme.refmaterial = createMuiTheme(window.getDarkTheme());
          }
        }
        this.props.dispatch(APP(this.props.appstate));
        this.props.refresh();
      }


      render() {

        // let currentconfig = this.props.acamar.configuration;
        let jsonlang = this.props.appstate.App.language;

        return (
          <div>
            <Dialog
              role="dialog"
              aria-label="accessibility check boxes"
              TransitionComponent={Transition}
              open={this.props.appstate.Modal.openModalAccessibility}
              onClose={this.onclose}
              fullWidth={true}
              maxWidth={'md'}
              disableBackdropClick
              disableEscapeKeyDown
              >
                <DialogTitle>
                  <AccessibilityNewIcon
                    aria-label="Accessibility"
                    color="primary"
                    // style={{
                    //   color: "#fff",
                    //   backgroundColor: "#3f51b5",
                    //   borderRadius: "50px",
                    //   fontSize: "36px",
                    //   padding: "5px",
                    // }}
                    fontSize="large"
                  />
                  <span style={{
                    padding: 5,
                    position: 'fixed',
                  }}>
                  {jsonlang.accessibility.toUpperCase()}
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <RadioGroup
                  name="font"
                  value={this.state.valuefont}
                  onChange={(e) => this.handleChangeFont(e)}
                  row={true}>
                  <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{paddingLeft:80}}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        fullWidth
                        value="fontnormal"
                        aria-label="normal font"
                        control={<Radio color="primary"/>}
                        label={<Typography variant="button">
                          <TextFieldsIcon/>
                          &nbsp;&nbsp;
                          <b>{jsonlang.normal}</b>
                        </Typography>} />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value="fontlarge"
                          aria-label="large font"
                          control={<Radio color="primary"/>}
                          label={<Typography variant="button">
                            <TextFieldsIcon style={{fontSize: '1.95rem'}}/>
                            &nbsp;&nbsp;
                            <span style={{fontSize: "1.15rem"}}>{jsonlang.large}</span>
                          </Typography>} />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            fullWidth
                            value="fontxlarge"
                            aria-label="xlarge font"
                            control={<Radio color="primary"/>}
                            label={<Typography variant="button">
                              <TextFieldsIcon style={{fontSize: '2.20rem'}}/>
                              &nbsp;&nbsp;
                              <span style={{fontSize: "1.35rem"}}>{jsonlang.xlarge}</span>
                            </Typography>} />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              value="fontxxlarge"
                              aria-label="xxlarge font"
                              control={<Radio color="primary"/>}
                              label={<Typography variant="button">
                                <TextFieldsIcon style={{fontSize: '2.50rem'}}/>
                                &nbsp;&nbsp;
                                <span style={{fontSize: "1.60rem"}}>{jsonlang.xxlarge}</span>
                              </Typography>} />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                        <Divider />


                        <RadioGroup
                          name="fontfamily"
                          value={this.state.valuefamilyfont}
                          onChange={this.handleChangeFamily}
                          row={true}>
                          <Grid container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{paddingLeft:80}}>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="familydefault"
                                aria-label="Font family default"
                                control={<Radio color="primary"/>}
                                label={
                                  <Typography variant="button">
                                    <FontDownloadIcon
                                    />&nbsp;&nbsp;
                                    <b style={{
                                      fontFamily: "Roboto"
                                    }}>{jsonlang.standard}</b>
                                  </Typography>
                                } />
                              </Grid>

                              <Grid item xs={6}>
                                <FormControlLabel
                                  value="Lexend"
                                  aria-label="Font family Lexend"
                                  control={<Radio color="primary"/>}
                                  label={<Typography variant="button">
                                    <BrightnessAutoIcon
                                      // fontSize="large"
                                    />&nbsp;&nbsp;
                                    <b style={{
                                      fontFamily: "Lexend"
                                    }}>{jsonlang.dislexia}</b>&nbsp;&nbsp;</Typography>}
                                  />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                            <Divider />

                            <RadioGroup
                              name="contrast"
                              value={this.state.valuecontrast}
                              onChange={this.checkboxthemeEvent}
                              row={true}>
                              <Grid container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                style={{paddingLeft:80}}>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    value="light"
                                    aria-label="normal contrast"
                                    control={<Radio color="primary"/>}
                                    label={
                                      <Typography variant="button">
                                        <InvertColorsOffIcon
                                          // fontSize="large"
                                        />&nbsp;&nbsp;
                                        <b>{jsonlang.regular}</b>
                                      </Typography>
                                    } />
                                  </Grid>

                                  <Grid item xs={6}>
                                    <FormControlLabel
                                      value="dark"
                                      aria-label="high contrast"
                                      control={<Radio color="primary"/>}
                                      label={<Typography variant="button">
                                        <InvertColorsIcon
                                          // fontSize="large"
                                        />&nbsp;&nbsp;
                                        <b style={{
                                          color: '#FFF',
                                          background: '#000'
                                        }}> {jsonlang.high} </b>&nbsp;&nbsp;</Typography>}
                                      />
                                    </Grid>
                                  </Grid>
                                </RadioGroup>
                                <Divider />
                                <RadioGroup
                                  name="colored"
                                  value={this.state.valuecolored}
                                  onChange={(e) => this.handleChangeLink(e)}
                                  row={true}>
                                  <Grid container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    style={{paddingLeft:80}}>
                                    <Grid item xs={6}>
                                      <FormControlLabel
                                        value="colornormal"
                                        aria-label="default links"
                                        control={<Radio color="primary"/>}
                                        label={<Typography variant="button">
                                          <EditIcon />&nbsp;&nbsp;<b>{jsonlang.default}</b>
                                        </Typography>} />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControlLabel
                                          value="coloryellow"
                                          aria-label="yellow links"
                                          control={<Radio color="primary"/>}
                                          label={<Typography variant="button">
                                            <BorderColorIcon />&nbsp;&nbsp;
                                            <b style={{
                                              background: 'yellow',
                                              padding: '2px',
                                              color: '#000'
                                            }}>{jsonlang.yellow}</b>
                                          </Typography>} />
                                        </Grid>
                                      </Grid>
                                    </RadioGroup>
                                    <Divider />
                                    <RadioGroup
                                      name="links"
                                      value={this.state.valuelinks}
                                      onChange={(e) => this.handleChangeLink(e)}
                                      row={true}>
                                      <Grid container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        style={{paddingLeft:80}}>
                                        <Grid item xs={6}>
                                          <FormControlLabel
                                            className="space"
                                            value="linksdefault"
                                            aria-label="non underlined links"
                                            control={<Radio color="primary"/>}
                                            label={<Typography variant="button">
                                              <InsertLinkIcon />&nbsp;&nbsp;<b>{jsonlang.nounder}</b>
                                            </Typography>} />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <FormControlLabel
                                              value="linksunderline"
                                              aria-label="underlined links"
                                              control={<Radio color="primary"/>}
                                              label={<Typography variant="button">
                                                <FormatUnderlinedIcon />&nbsp;&nbsp;
                                                <b style={{
                                                  textDecoration: 'underline'
                                                }}>{jsonlang.under}</b>
                                              </Typography>} />
                                            </Grid>
                                          </Grid>
                                        </RadioGroup>
                                      </DialogContent>
                                      <DialogActions>
                                        <IconButton
                                          color="primary"
                                          aria-label="chiudi"
                                          onClick={this.onclose}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </DialogActions>
                                      </Dialog>
                                    </div>
                                  )
                                }
                              }
                              export default connect(mapStateToProps)(DialogAccessibility);
