import React, {Component} from 'react';
import { connect } from 'react-redux';

import PasswordModifyDialog from "./PasswordModifyDialog"

import {
  // AppBar,
  Avatar,
  // Box,
  Button,
  // ButtonGroup,
  // Container,
  // CssBaseline,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Divider,
  // Fab,
  // FormControl,
  // FormControlLabel,
  // FormGroup,
  // FormLabel,
  Grid,
  // Icon,
  IconButton,
  // InputLabel,
  // Link,
  // ListSubheader,
  // Menu,
  // MenuItem,
  // Paper,
  // Radio,
  // RadioGroup,
  // Select,
  // Slide,
  // Switch,
  // TextField,
  // Toolbar,
  Tooltip,
  Typography
} from "@material-ui/core";


import PhotoCamera from '@material-ui/icons/PhotoCamera';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import { multipartformService, postService } from "../../services/services";
import * as services from "../../services/services.json";
import { APP } from '../../redux/actions/actions';
import * as mediatypes from "../../Utils/mediatype.json";
import InfoIcon from '@material-ui/icons/Info';
const mapStateToProps = state => {
  return {
    acamar: state.acamar,
    appstate: state.appstate
  };
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogModify:false,
    };
  }

  avatar = () => {
    this.props.appstate.Modal.openModalLoading = true;
    this.props.dispatch(APP(this.props.appstate));
    let self = this;
    let serviceurl = process.env.REACT_APP_DOMAIN + services.resourcemanager_POST_AUTH;
    let dataform = {
      userId: this.props.appstate.App.user.userid,
      appname: this.props.appstate.App.name,
      domain: this.props.appstate.App.user.domain,
      name: "avatar",
      // description: this.props.appstate.App.user.name + " avatar",
      dest: "avatar",
    }
    multipartformService(serviceurl, dataform, this.props.appstate.App.user.token, this.state.file, function (err, result) {
      if (err) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      if (result.status === 201) {
        self.props.appstate.Modal.openModalLoadingTextActive = true;
        self.props.appstate.Modal.openModalLoadingText = "Avatar loaded";
        self.props.appstate.App.user.avatar = result.response.ops[0].avatar + "?t=" + new Date().getTime();
        self.props.dispatch(APP(self.props.appstate));
        return;
      }
      self.props.appstate.Modal.openModalLoading = false;
      self.props.dispatch(APP(this.props.appstate));
      return;
    })
  }

  componentDidMount(){
    this.props.appstate.PasswordModifyDialog.open = false;
    this.props.dispatch(APP(this.props.appstate));


  }

  fileChoiced = () => {
    this.setState({ file: document.getElementById('file').files[0] },this.avatar);
  }

  openDialogForModify = (event) => {
    this.passwordModifyDialogRef.init();
    this.props.appstate.PasswordModifyDialog.open = true;
    this.props.dispatch(APP(this.props.appstate));
  }

  changePasswordCallBack = (newpassword, oldpassword) => {
    let self = this;
    let user = {};
    user.newpassword = newpassword;
    user.oldpassword = oldpassword;
    user.id = this.props.appstate.App.user.userid;
    user.appname = this.props.appstate.App.name;
    user.domainId = this.props.appstate.App.user.domain;
    this.props.appstate.Modal.openModalLoading = true;
    this.props.appstate.Modal.openModalLoadingTextActive = false;
    this.props.dispatch(APP(this.props.appstate));
    postService(process.env.REACT_APP_DOMAIN + services.changepassword_POST, user,
      "", "",
      function (err, result) {
        if (err) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.changerror;
          self.props.dispatch(APP(self.props.appstate));
          return;
        } else if (result.status === 200) {
          self.props.appstate.Modal.openModalLoadingTextActive = true;
          self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.checkemailpwd;
          self.props.dispatch(APP(self.props.appstate));
          return;
        }
        self.props.appstate.Modal.openModalLoadingTextActive = false;
        self.props.appstate.Modal.openModalLoadingText = self.props.appstate.App.language.changerror;
        self.props.dispatch(APP(self.props.appstate));
        return;
      });
  }
  
  getAvatarUrl = () => {
    let ser =
      process.env.REACT_APP_DOMAIN +
      services.avatar_GET_AUTH.replace(
        "{APPNAME}",
        this.props.appstate.App.name
      );
    //ser = ser.replace("{APPNAME}", this.props.appstate.App.name);
    //ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
    ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
    ser = ser + "&t=" + new Date().getTime();
    return ser;
  }

    render(){
      var date = new Date(this.props.appstate.App.user.createdOn);
      let jsonlang = this.props.appstate.App.language;
      return(
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
              <Grid item xs={2}>
                <Tooltip title={<h2>{this.props.appstate.App.user.name.toUpperCase()}</h2>}>
                <Avatar alt={this.props.appstate.App.user.name}
                  src={this.getAvatarUrl()}
                  style={{ width:"100px", height:"100px" }}
                />
              </Tooltip>
              {/* <Button
                variant="comntain"
                component="label"
                color="primary"
                size="large"
                >
                <input
                id="file"
                type="file"
                hidden
                accept={'.jpg, .jpeg, .png'}
                onChange={this.fileChoiced}
              />
              <label htmlFor="icon-button-file">
              <IconButton
              id="file"
              type="file"
              variant="contained"
              color="primary"
              size="small"
              aria-label="upload picture"
              >
              <PhotoCamera />
            </IconButton>
          </label> */}

          <input
            hidden
            accept={mediatypes.avatarMT}
            id="file"
            type="file"
            onChange={this.fileChoiced}
            size="large"
          />
          

          <label
            htmlFor="file"
            style={{margin: '1.69em'}}
            >
            {this.props.appstate.App.user.role!=="superadmin" ?
            <div style={{"display": "inline-block"}}>
            <Tooltip title={jsonlang.avatar.toUpperCase()}>
              <IconButton
                color="primary"
                aria-label="upload avatar"
                component="span"
                >
                  <PhotoCamera/>
                </IconButton>
            </Tooltip>
            <Tooltip title={jsonlang.mediatype.toUpperCase()+":"+ mediatypes.avatarMT}>
                  <InfoIcon color="grey" fontSize="small"/>
            </Tooltip>
            </div>
                 : null }
              </label>
            
            {/* </Button> */}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle2" display='inline'>
              {jsonlang.name.toUpperCase()}:
              &nbsp;
              <Typography variant="subtitle1" display='inline'>
                {this.props.appstate.App.user.name}
              </Typography>
            </Typography>
            <br/>
            {/* <p>{jsonlang.name}: {this.props.appstate.App.user.name}</p> */}


            <Typography variant="subtitle2" display='inline'>
              {jsonlang.email.toUpperCase()}:
              &nbsp;
              <Typography variant="subtitle1" display='inline'>
                {this.props.appstate.App.user.email}
              </Typography>
            </Typography>
            <br/>
            {/* <p>{jsonlang.email}: {this.props.appstate.App.user.email}</p> */}


            <Typography variant="subtitle2" display='inline'>
              {jsonlang.role.toUpperCase()}:
              &nbsp;
              <Typography variant="subtitle1" display='inline'>
                {/* {this.props.appstate.App.user.role} */}
                {this.props.appstate.App.user.role==="domainadmin"?"ADMIN" : (this.props.appstate.App.user.role==="basic"?"STANDARD":this.props.appstate.App.user.role)}
              </Typography>
            </Typography>
            <br/>
            {/* <p>{jsonlang.role}: {this.props.appstate.App.user.role}</p> */}

            {this.props.appstate.App.user.role !== "superadmin" && this.props.appstate.App.user.role !== "emperor" ?
            <>
            <Typography variant="subtitle2" display='inline'>
              {jsonlang.institution.toUpperCase()}:
              &nbsp;
              <Typography variant="subtitle1" display='inline'>
                {this.props.appstate.App.user.domainDetail.shortname}
              </Typography>
            </Typography>
            <br/>
          </>
          : <></>}

          <Typography variant="subtitle2" display='inline'>
            {jsonlang.registered.toUpperCase()}:
            &nbsp;
            <Typography variant="subtitle1" display='inline'>
              {date.toLocaleDateString()}
            </Typography>
          </Typography>
          <br/>
          {/* <p>{jsonlang.registered}: {date.toLocaleDateString()}</p> */}

          <Tooltip title={<h2>{jsonlang.modifypwd.toUpperCase()}</h2>}>
          <Button
            onClick={this.openDialogForModify}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<LockOpenIcon />}
            >
              {jsonlang.modifypwd}
            </Button>
          </Tooltip>

        </Grid>
      </Grid>
      <PasswordModifyDialog onRef={childthis => (this.passwordModifyDialogRef = childthis)} callback={this.changePasswordCallBack} />
    </div>

  )
}
}export default connect(mapStateToProps)(Profile);
