function WHOLE(whole) {
  // Return action
  return {
    // Unique identifier
    type: "WHOLE",
    // Payload
    payload: whole,
  };
}

function APP(app) {
  // Return action
  return {
    // Unique identifier 
    type: "APP",
    // Payload
    payload: app
  };
}

export { WHOLE, APP };
