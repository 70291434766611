import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { IconButton, Box, Grid, Paper, Tooltip, Chip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import * as services from "../../services/services.json";
import {
    getResourceDataForLanguage
} from "../../Utils/utility";
const mapStateToProps = (state) => {
    return {
        acamar: state.acamar,
        appstate: state.appstate,
    };
};

class ExpandingRow extends Component {
    state = { open: false };



    getUTCData = (data) => {
        try {
            return new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                timeZone: "UTC",
                timeZoneName: "short",
            }).format(new Date(data));
        } catch (error) {
            return "";
            // console.log(error);
        }
    };


    onDeleteLocal = (e) => {
        this.props.onDeleteCallBack(e.currentTarget.name, this.props.index);
    }

    onDetailLocal= (e) => {
        this.props.onDetailCallBack(e.currentTarget.name);
    }

    onChangeStatusLocal= (e) => {
        this.props.onChangeStatusCallBack(e.currentTarget.name);
    }

    coverUrl = (id, medialan) => {
        // console.log(medialan)
        let ser =
            process.env.REACT_APP_DOMAIN +
            services.singleresourcemanager_GET_AUTH.replace(
                "{APPNAME}",
                this.props.appstate.App.name
            );
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{RESOURCEID}", id);
        ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
        ser = ser.replace("{COVER}", true);
        ser = ser + "&t=" + new Date().getTime();
        ser = ser.replace("{MEDIALAN}", medialan);
        ser = ser.replace("{MEDIARES}", "first");

        return ser;
    };
    render() {
        const { row } = this.props;
        const { open } = this.state;
        let jsonlang = this.props.appstate.App.language;

        return (
            <>
                <TableRow key={row.id}>
                    <TableCell align="left">
                        {/* {row.tags.length > 0 ? row.tags[0].name[this.props.appstate.App.currentLanguage] ? row.tags[0].name[this.props.appstate.App.currentLanguage] : row.tags[0].name["en"] : "N.A."} */}
                        {row.tags.length > 0 ? row.tags.map((item, index) => (
                            <Typography variant="body2" >
                                {item.name[this.props.appstate.App.currentLanguage] ? item.name[this.props.appstate.App.currentLanguage] : item.name["en"]}
                            </Typography>
                        )) : "N.A."
                        }
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.ownerdetails ? row.ownerdetails[0].name : "N.A."}</TableCell>
                    <TableCell align="left">{this.getUTCData(row.modifiedAt)}</TableCell>
                    <TableCell align="left">
                        <Chip
                            // style={(row.status === "APPROVED" ? { backgroundColor: "#3c7ac6b4" } :
                            //     (row.status === "CLOSED" ? { backgroundColor: "#5d5b5ab4" } :
                            //         (row.status === "INSERTED" ? { backgroundColor: "#42bd0e8c" } :
                            //             { backgroundColor: "#a3442ab4" })))}
                            style={{ backgroundColor: "#404040b4" }}

                            color="primary"

                            label={row.status === "APPROVED" ? jsonlang.approved_report.toUpperCase() :
                                (row.status === "SOLVED" ? jsonlang.solved_report.toUpperCase() :
                                    (row.status === "INSERTED" ? jsonlang.inserted_report.toUpperCase() : jsonlang.rejected_report.toUpperCase()))}
                        />
                    </TableCell>
                    <TableCell>
                        <IconButton
                            type="submit"
                            color="primary"
                            onClick={() => this.setState(({ open }) => ({ open: !open }))}>
                            <UnfoldMoreIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} >
                            <Box margin={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <b>Descrizione:</b> <br />{row.description}
                                    </Grid>
                                    {/*TODO AGGIUNGI IMMAGINE ricorda elemento 0 dell array  */}
                                    <Grid item xs={4} container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 233,
                                                width: 350,
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                            }}
                                            alt={row.name}
                                            src={row.coverobj ? this.coverUrl(row.coverobj._id, getResourceDataForLanguage(row.coverobj)): row.resources ? (row.resources.length > 0 ? this.coverUrl(row.resources[0]._id, getResourceDataForLanguage(row.resources[0])) : "/images/sfondi-home/gs-citizen.png") : "/images/sfondi-home/gs-citizen.png"}
                                        />
                                    </Grid>

                                    <Grid item xs={6} container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">

                                        <b>{jsonlang.severity.toUpperCase()} :</b>

                                        <Tooltip title={<h2>{row.gravity === 0 ? "LOW" : (row.gravity === 50 ? "MEDIUM" : "HIGH")}</h2>}>
                                            <div>
                                                {row.gravity === 0 ? <><Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'grey' }} /> <Brightness1Icon style={{ color: 'grey' }} /></> :
                                                    row.gravity === 50 ? <><Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'grey' }} /></> :
                                                        <><Brightness1Icon style={{ color: 'red' }} /><Brightness1Icon style={{ color: 'red' }} /><Brightness1Icon style={{ color: 'red' }} /></>}
                                            </div>
                                        </Tooltip>

                                    </Grid>
                                    {/* <Grid item xs={4} container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Tooltip title={<h2>{"VIEW"}</h2>}>
                                            <IconButton
                                                type="submit"
                                                color="primary"
                                                aria-label="search"
                                                className="pad10"
                                            // onChange={this.searchInput}
                                            >
                                                <ChatBubbleOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> */}

                                    <Grid item xs={3}>
                                        <Tooltip title={<h2>{jsonlang.view_report.toUpperCase()}</h2>}>
                                            <IconButton
                                                type="submit"
                                                color="primary"
                                                aria-label="search"
                                                className="pad10"
                                                name={row._id}
                                                onClick={this.onDetailLocal}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<h2>{"CHANGE STATUS"}</h2>}>
                                            <IconButton
                                                type="submit"
                                                color="primary"
                                                aria-label="search"
                                                className="pad10"
                                                name={row._id}
                                                onClick={this.onChangeStatusLocal}
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={<h2>{jsonlang.delete_report.toUpperCase()}</h2>}>
                                            <IconButton
                                                type="submit"
                                                color="primary"
                                                aria-label="search"
                                                className="pad10"
                                                name={row._id}
                                                onClick={this.onDeleteLocal}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                </Grid>
                                {/* </td> */}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow >
            </>
        );
    }
}

export default connect(mapStateToProps)(withRouter(ExpandingRow));
