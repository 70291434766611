import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as services from "../../services/services.json";
import { postService, deleteService, getService, multipartformService } from "../../services/services";
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RoomIcon from '@material-ui/icons/Room';
import {
    getResourceDataForLanguage
} from "../../Utils/utility";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    Tooltip,
    Typography,
    Grid,
    Box,
    Chip,
    BottomNavigation, BottomNavigationAction, Avatar,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from "@material-ui/core";
import { APP } from '../../redux/actions/actions';

import Brightness1Icon from '@material-ui/icons/Brightness1';
import PanoramaIcon from '@material-ui/icons/Panorama';
import MapIcon from '@material-ui/icons/Map';
import { GeoMap, loadMarkers } from "./mapcomp";
import PreviewDialog from "./PreviewDialog";
import VisibilityIcon from '@material-ui/icons/Visibility';

const mapStateToProps = state => {
    return {
        acamar: state.acamar,
        appstate: state.appstate
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});





class DetailReportDialog extends Component {
    constructor(props) {
        super(props);
        console.log("constructor create warning ")
        this.state = ({
            type: this.props.type, //tipo di dialog se edit o create             
            item: "",
            // value: "gallery"
        });
    }



    init = (index) => {
        console.log(this.props.appstate.ResourceManager.resources[index])
        this.pinRef = undefined;
        this.mapRef = undefined;        
        this.setState({
            type: this.props.type, //tipo di dialog se edit o create 
            item: this.props.appstate.ResourceManager.resources[index],
            // value: "gallery"
        });

    }

    componentDidMount() {
        this.props.onRef(this); //for the parent
    }

    //metodo per la chiusura della dialog
    onclose = () => {
        //this.props.appstate.ResourceManager.render = false;
        //  this.props.appstate.ResourceManager.openModalResourcesCatalog = false;
        //this.props.appstate.ResourceManager.edit = false;
        // this.props.appstate.ResourceManager.openModalIconLang = false;
        // this.props.appstate.ResourceManager.index = 0;
        // this.props.appstate.ResourceManager.id = 0;
        // this.props.appstate.ResourceManager.resourceInfo = {};
        // this.props.dispatch(APP(this.props.appstate));
        this.pinRef = undefined;
        this.mapRef = undefined;
        this.props.onClose();
    }



    //metodo per ottenre url della cover della risorsa selezionata 
    coverUrl = (id, medialan) => {
        // console.log(medialan)
        let ser =
            process.env.REACT_APP_DOMAIN +
            services.singleresourcemanager_GET_AUTH.replace(
                "{APPNAME}",
                this.props.appstate.App.name
            );
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{RESOURCEID}", id);
        ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
        ser = ser.replace("{COVER}", true);
        ser = ser + "&t=" + new Date().getTime();
        ser = ser.replace("{MEDIALAN}", medialan);
        ser = ser.replace("{MEDIARES}", "first");
        return ser;
    };


    avatarUrl = (id) => {
        console.log("avatarurl")
        console.log(id)
        console.log(this.state.item)
        let ser =
            process.env.REACT_APP_DOMAIN +
            services.avatar_GET_AUTH.replace(
                "{APPNAME}",
                this.props.appstate.App.name
            );
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{USERID}", id);
        return ser;
    };


    resourceUrl = (id, medialan) => {
        console.log("resourceUrl")
        let ser =
            process.env.REACT_APP_DOMAIN +
            services.singleresourcemanager_GET_AUTH.replace(
                "{APPNAME}",
                this.props.appstate.App.name
            );
        ser = ser.replace("{DOMAIN}", this.props.appstate.App.user.domain);
        ser = ser.replace("{RESOURCEID}", id);
        ser = ser.replace("{USERID}", this.props.appstate.App.user.userid);
        ser = ser.replace("{COVER}", false);
        ser = ser.replace("{MEDIALAN}", medialan);
        ser = ser.replace("{MEDIARES}", "first");
        return ser;
    };

    handleChangeNavigation = (e, v) => {

        console.log(v)

        this.setState({ value: v });
    }


    mapCallback = (type, overlay) => {
        console.log("mapCallback")
        if (type === "drawcompleted") {
            if (this.pinRef !== undefined) {
                this.pinRef.setPosition({
                    lat: overlay[1],
                    lng: overlay[2]
                });
            } else {
                this.pinRef = new window.google.maps.Marker({
                    position: {
                        lat: overlay[1],
                        lng: overlay[2]
                    }
                });
                this.pinRef.setMap(this.mapRef);
            }
            this.props.appstate.MapDialog.position = {
                lat: overlay[1],
                lng: overlay[2]
            };
        } else if (!this.mapRef && type === "ref" && overlay) {
            this.mapRef = overlay;
            //to maintail old selection          
            this.pinRef = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(this.state.item.location.coordinates[1]),
                    lng: parseFloat(this.state.item.location.coordinates[0])
                }
            });
            this.pinRef.setMap(this.mapRef);
        }
    }

    //codice per dettaglio res
    resourcePreview = (event) => {
        console.log("resourcePreview")             
        let index = event.currentTarget.id;
        this.props.appstate.PreviewDialog.openModalPreview = true;
        this.props.appstate.PreviewDialog.render = true;
        // console.log(this.state.items[index])
        this.props.appstate.PreviewDialog.item = this.state.item.resources[index];
        console.log(this.state.item.resources[index])
        // if (this.props.appstate.App.user.domainDetail.backupaws === true && this.state.items[index].streamextloaded === true) {
        //     this.props.appstate.PreviewDialog.itemurl = this.state.items[index].streamext;
        // } else {
            // this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.items[index]._id,Object.keys(this.state.items[index].medialan)[0]);
            const lanList = Object.keys(this.state.item.resources[index].medialan);             
            this.props.appstate.PreviewDialog.itemurl = this.resourceUrl(this.state.item.resources[index]._id, getResourceDataForLanguage(this.state.item.resources[index], lanList[0]));            
        // }
        // console.log(this.props.appstate.PreviewDialog.itemurl);
        
        this.previewDialogRef.init();
        this.props.dispatch(APP(this.props.appstate));
        
    };
    //codice per dettaglio res


    render() {

        let jsonlang = this.props.appstate.App.language;
        return (
            <>
                {this.props.rendereDetailDialogo === true ?
                    <>

                        <div >
                            <PreviewDialog
                                onRef={(childthis) => (this.previewDialogRef = childthis)}
                            />
                            <Dialog
                                disableBackdropClick
                                disableEscapeKeyDown
                                TransitionComponent={Transition}
                                open={this.props.open}
                                onClose={this.onclose}
                                aria-labelledby="dialog-add"
                                fullWidth
                                maxWidth={'xl'}
                            >
                                <DialogTitle id="dialog-add">
                                    {jsonlang.detail_title.toUpperCase() + " : " + "\"" + this.state.item.name + "\""}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Box margin={1}>
                                        <Grid container spacing={3} >
                                            {/* grglia lato sinistro  */}
                                            {/* <Grid item xs={12} > */}
                                            <Grid container xs={8}>
                                                {/* mostrare info proprietario  */}
                                                {/* <Grid item xs={2}>
                                                    <Avatar
                                                        aria-label="recipe"
                                                        src={this.avatarUrl(this.state.item.owner)}
                                                    ></Avatar>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography  >
                                                        {this.state.item.ownerdetails ? this.state.item.ownerdetails[0].name : "N.A."}
                                                    </Typography>
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" > Description</Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        {this.state.item.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {/* <Box
                                                        component="img"
                                                        sx={{
                                                            height: 100,
                                                            width: 150,
                                                            maxHeight: { xs: 167, md: 167 },
                                                            maxWidth: { xs: 250, md: 250 },
                                                        }}
                                                        alt={this.state.item.name}
                                                        src={this.state.item.resources ? (this.state.item.resources.length > 0 ? this.resourceUrl(this.state.item.resources[0]._id, getResourceDataForLanguage(this.state.item.resources[0])) : "/images/sfondi-home/gs-citizen.png") : "/images/sfondi-home/gs-citizen.png"}
                                                    /> */}
                                                    <Typography variant="body1" > Tags</Typography>
                                                    {this.state.item.tags.map((item, index) => (
                                                        <Typography variant="body2" >
                                                            {item.name[this.props.appstate.App.currentLanguage] ? item.name[this.props.appstate.App.currentLanguage] : item.name["en"]}
                                                        </Typography>
                                                    ))}
                                                </Grid>
                                                <Grid item xs={5} justify="flex-end">
                                                    <b>{jsonlang.severity.toUpperCase()} :</b>
                                                    <Tooltip title={<h2>{this.state.item.gravity === 0 ? "LOW" : (this.state.item.gravity === 50 ? "MEDIUM" : "HIGH")}</h2>}>
                                                        <div>
                                                            {this.state.item.gravity === 0 ? <><Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'grey' }} /> <Brightness1Icon style={{ color: 'grey' }} /></> :
                                                                this.state.item.gravity === 50 ? <><Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'red' }} /> <Brightness1Icon style={{ color: 'grey' }} /></> :
                                                                    <><Brightness1Icon style={{ color: 'red' }} /><Brightness1Icon style={{ color: 'red' }} /><Brightness1Icon style={{ color: 'red' }} /></>}
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={2} justify="flex-end">
                                                    <Chip
                                                        style={{ backgroundColor: "#404040b4" }}
                                                        color="primary"
                                                        label={this.state.item.status === "APPROVED" ? jsonlang.approved_report.toUpperCase() :
                                                            (this.state.item.status === "SOLVED" ? jsonlang.solved_report.toUpperCase() :
                                                                (this.state.item.status === "INSERTED" ? jsonlang.inserted_report.toUpperCase() : jsonlang.rejected_report.toUpperCase()))}

                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                <Typography variant="body1" > Tags</Typography>
                                                {this.state.item.tags.map((item, index) => (
                                                    <Typography variant="body2" >
                                                        {item.name["en"]}
                                                    </Typography>
                                                      ))}
                                                </Grid>*/}
                                            </Grid>


                                            {/* grglia lato destro  */}
                                            <Grid container xs={4}>
                                                <Grid item xs={12} justify="flex-end">
                                                    < GeoMap
                                                        isMarkerShown
                                                        centerpos={this.state.item.location}
                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtAWW-Sv99CiDFq5i4cYgE_0UBAuQBwXg&v=3.exp&libraries=geometry,drawing,places"
                                                        loadingElement={< div style={{ height: `100%` }} />}
                                                        containerElement={< div style={{ height: `300px`, width: '100%' }} />}
                                                        mapElement={< div style={{ height: `100%` }} />}
                                                        options={{                                                            
                                                            scrolxlwheel: false,
                                                            streetViewControl: false,
                                                            minZoom: 1,
                                                            maxZoom: 19, 
                                                            mapTypeControl: false,
                                                            zoomControl: true,
                                                        }}
                                                        drawingControl={false}
                                                        onlyMarker={true}
                                                        callback={this.mapCallback}
                                                    />


                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <ImageList cols={5} style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }}>
                                                    {this.state.item.resources.map((item, index) => (
                                                        <ImageListItem key={item.img}>
                                                            <img src={this.coverUrl(item._id, getResourceDataForLanguage(item))} alt={item.name} />
                                                            <ImageListItemBar
                                                                title={item.name}
                                                            actionIcon={
                                                                <IconButton >
                                                                    <VisibilityIcon color="primary" onClick={this.resourcePreview}  id={index} />
                                                                </IconButton>
                                                            }
                                                            />
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={this.onclose}
                                        color="primary"
                                        startIcon={<ClearIcon />}>
                                        <Tooltip title={<h2>{"CLOSE"}</h2>}>
                                            <Typography variant="button">{"CLOSE"}</Typography>
                                        </Tooltip>
                                    </Button>
                                    {/* <Button
                                        disabled={(this.state.newStatus !== "" && this.state.status !== this.state.newStatus) ? false : true}
                                        variant="contained"
                                        size="small"
                                        onClick={this.saveStatus}
                                        color="primary"
                                        startIcon={<SaveIcon />}>
                                        <Tooltip title={<h2>{jsonlang.save.toUpperCase()}</h2>}>
                                            <Typography variant="button">{jsonlang.save}</Typography>
                                        </Tooltip>
                                    </Button> */}
                                </DialogActions>
                            </Dialog>
                        </div>

                    </>
                    : <></>
                }
            </>
        )
    }
}
export default connect(mapStateToProps)(DetailReportDialog);
